import {t} from '@lingui/macro';
import {api} from 'fast-sdk';
import {AvatarPicker} from 'interface/base/AvatarPicker';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {getHashColor} from 'utils/common/color';

import type {ApiResponse} from 'fast-sdk/src/requests/types';
import type {AvatarSize} from 'interface/common/Avatar';
import * as user from 'store/slices/user';

interface ProfileEditDetailsProps {
  id: string;
  type: 'share' | 'workspace' | 'user';
  size?: AvatarSize;
  name?: string;
  photo?: string;
  color?: string;
}

export function ProfileEditDetails(props: ProfileEditDetailsProps) {
  const {id, type, size, name, photo} = props;
  const color = props.color ?? getHashColor(id).slice(1);

  const toast = useToast();
  const dispatch = useDispatch();

  /** Sets the color of the profile */
  const handleSetColor = async (color: string) => {
    let response: ApiResponse<unknown>;
    let update: () => void;
    switch (type) {
      case 'user':
        // response = await api.user.updateUserDetails({});
        // update = () => dispatch(user.default.actions.setUserProfilePic({color}));
        break;
      case 'share':
        // response = await api.share.updateShareDetails(id, {color});
        // update = () => dispatch(user.default.actions.setShareProfilePic({color}));
        break;
      case 'workspace':
        // response = await api.workspace.updateWorkspaceDetails(id, {color});
        // update = () => dispatch(user.default.actions.setWorkspaceProfilePic({color}));
        break;
    }
    if (response?.result) {
      update();
      toast.show(t`Your ${type} color has been changed successfully`, {
        type: 'success',
      });
    }
  };

  /** Sets the image of the profile */
  const handleSetImage = async (file: File, img: string) => {
    let response: ApiResponse<unknown>;
    let update: () => void;
    switch (type) {
      case 'user':
        response = await api.user.addProfilePhoto(id, file);
        update = () => dispatch(user.default.actions.setUserProfilePic({img}));
        break;
      case 'share':
        // response = await api.share.addShareProfilePhoto(id, file);
        // update = () => dispatch(user.default.actions.setShareProfilePic({img}));
        break;
      case 'workspace':
        // response = await api.workspace.addWorkspaceProfilePhoto(id, file);
        // update = () => dispatch(user.default.actions.setShareProfilePic({img}));
        break;
    }
    if (response?.result) {
      update();
      toast.show(t`Your ${type} photo has been changed successfully`, {
        type: 'success',
      });
    }
  };

  /** Deletes the image of the profile */
  const handleDeleteImage = async () => {
    let response: ApiResponse<unknown>;
    let update: () => void;
    switch (type) {
      case 'user':
        response = await api.user.removeProfilePhoto(id);
        update = () =>
          dispatch(user.default.actions.setUserProfilePic({img: undefined}));
        break;
      case 'share':
        // response = await api.share.removeShareProfilePhoto(id);
        // update = () => dispatch(user.default.actions.setShareProfilePic({img: undefined}));
        break;
      case 'workspace':
        // response = await api.workspace.removeWorkspaceProfilePhoto(id);
        // update = () => dispatch(user.default.actions.setWorkspaceProfilePic({img: undefined}));
        break;
    }
    if (response?.result) {
      update();
      toast.show(t`The ${type} photo has been removed successfully`, {
        type: 'success',
      });
    }
  };

  return (
    <AvatarPicker
      size={size}
      name={name}
      color={color}
      image={photo}
      // onColorChange={handleSetColor}
      onImageChange={handleSetImage}
      onImageDelete={handleDeleteImage}
    />
  );
}
