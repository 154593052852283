import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, type TextStyle} from 'react-native';

interface Props {
  text: string;
  customClass?: TextStyle;
}

export function PageTitle(props: Props) {
  return (
    <Typography
      overrides={styles.root}
      variant="semi-bold"
      size="xl"
      color={theme.colors.neutral.$700}>
      {props.text}
    </Typography>
  );
}

const styles = StyleSheet.create({
  root: {
    marginBottom: '2rem',
  },
});
