import {t} from '@lingui/macro';
import theme from 'config/theme';
import AddEmailTextarea from 'interface/base/AddEmailTextarea';
import {TextArea} from 'interface/common/TextArea';
import useOrgInvitation from 'interface/common/hooks/useOrgInvitation';
import usePermissionsPicker from 'interface/common/hooks/usePermissionsPicker';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import NewLayout from './Layout';

interface Props {
  next: () => void;
  loadingUpdateUser?: boolean;
}

export default function InviteCoworkers(props: Props) {
  const subdomain = useSelector(onboarding.selectors.getSubdomain);
  const orgName = useSelector(onboarding.selectors.getOrganizationName);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [message, setMessage] = useState<string>('');
  const toast = useToast();

  const {loading, sendInvitations} = useOrgInvitation(subdomain, orgName);
  const {permission, PickerComponent} = usePermissionsPicker({
    initialPermission: 'member',
    disabled: loading,
  });

  const next = async () => {
    const {successEmails} = await sendInvitations(emails, permission, message);

    if (successEmails.length) {
      toast.show(
        t`Invited ${successEmails.length} ${successEmails.length > 1 ? 'members' : 'member'} to the ${orgName}`,
        {type: 'success'},
      );
    }

    props.next();
  };

  return (
    <NewLayout
      title="Invite your teammates"
      subtitle="Add your teammates’ email addresses to invite them to your workspace. You can add teammates at any time."
      next={next}
      skip={props.next}
      loading={loading || props.loadingUpdateUser}
      loadingSkip={props.loadingUpdateUser}>
      <View style={styles.root}>
        <AddEmailTextarea
          emails={emails}
          setEmails={setEmails}
          customRootStyle={{width: '100%'}}
          placeholder={t`Invite teammates`}
          subdomain={subdomain}
        />
        <TextArea
          text={message}
          onChangeText={setMessage}
          label={t`Message (optional)`}
          numberOfLines={4}
          placeholder={t`Enter a message...`}
          customRootStyle={{width: '100%'}}
        />
        <View style={styles.pickerContainer}>{PickerComponent}</View>
      </View>
    </NewLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    marginTop: 42,
  },
  addButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addButtonText: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#006CFA',
  },
  copyButtonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
    alignItems: 'center',
    textAlign: 'center',
    color: '#006CFA',
  },
  addImage: {
    width: 12,
    height: 12,
    marginRight: 10,
  },
  input: {
    width: '100%',
    height: 48,
  },
  text: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
  },
  actionButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  picker: {
    display: 'flex',
    paddingHorizontal: 8,
    paddingVertical: 6,
    gap: 8,
    borderRadius: 3,
    borderWidth: 0,
    height: 36,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$4,
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$10}`,
    borderColor: 'transparent',
    alignSelf: 'flex-end',
  },
  pickerContainer: {
    alignSelf: 'flex-end',
  },
});
