import type {
  Chat,
  ChatCreator,
  ChatMessage,
} from 'fast-sdk/src/api/workspace/aichat/consts';
import {nowServerDate} from 'utils/common/dates';
import type {
  EntityChat,
  EntityChatCreator,
  EntityChatMessage,
  EntityChatPrivacy,
  Personality,
} from './types';

export function buildChat(
  chat: Chat,
  existingMessages?: EntityChatMessage[],
): EntityChat {
  return {
    id: chat.chat_id,
    creator: buildChatMember(chat.creator),
    name: chat.name,
    messageCount: chat.message_count,
    status: chat.status,
    privacy: buildPrivacy(chat),
    createdAt: chat.created_at,
    updatedAt: chat.updated_at,
    lastMessage: buildLastMessage(chat),
    members: chat.unique_creators.map(buildChatMember),
    messages: existingMessages || chat.messages.map(buildChatMessage),
  };
}

export function buildEmptyChat(
  chatId: string,
  isPrivate?: boolean,
  creatorId?: string,
): EntityChat {
  const now = nowServerDate();
  return {
    id: chatId,
    creator: {id: creatorId, type: 'user'},
    name: undefined,
    messageCount: 0,
    status: undefined,
    privacy: isPrivate
      ? {visibility: 'private', owner: undefined}
      : {visibility: 'public', owner: undefined},
    createdAt: now,
    updatedAt: now,
    lastMessage: undefined,
    members: [],
    messages: [],
  };
}

function buildLastMessage(chat: Chat): EntityChatMessage | undefined {
  const message = !!chat.latest_message && chat.latest_message[0];
  if (!message) {
    if (chat.messages?.length > 0) {
      return buildChatMessage(chat.messages[chat.messages.length - 1]);
    }
    return undefined;
  }
  return buildChatMessage(message);
}

export function buildChatMessage(message: ChatMessage): EntityChatMessage {
  return {
    id: message.message_id,
    chatId: message.chat_id,
    creator: buildChatMember(message.creator),
    state: message.state,
    personality: message.personality as Personality,
    content: message.message,
    response: message.response,
    createdAt: message.created,
    updatedAt: message.updated,
  };
}

function buildPrivacy(chat: Chat): EntityChatPrivacy {
  return {
    visibility: chat.privacy.visibility,
    owner: chat.privacy.owner ? buildChatMember(chat.privacy.owner) : undefined,
  };
}

function buildChatMember(member: ChatCreator): EntityChatCreator {
  return {
    id: member.id,
    type: member.type,
  };
}
