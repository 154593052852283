import {ROUTES} from 'constants/routes';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import type {Share} from 'store/slices/shared/types';
import {formatStringForUrl} from 'utils/fast/common';

const useGenerateShareUrl = () => {
  const {subdomain} = useSubDomain();

  const generateShareUrl = (share: Share | ShareItem, internal = true) => {
    const prefix = internal
      ? `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${share.parent_workspace_folder}`
      : '';
    const suffix = internal ? '' : `/${formatStringForUrl(share.title)}`;
    return `${prefix}/${ROUTES.LOGGED_OR_NOT_LOGGED.SHARED}/${share.custom_name}${suffix}`;
  };

  const generateShareUrlWithSubdomain = (
    share: Share | ShareItem,
    org: OrganizationDetails,
    internal = false,
  ) => {
    if (!org) return '';
    const path = generateShareUrl(share, internal);
    const origin = location.origin.replace(subdomain, org?.domain);
    return `${origin}${path}`;
  };

  return {
    generateShareUrl,
    generateShareUrlWithSubdomain,
  };
};

export default useGenerateShareUrl;
