import {ROUTES} from 'constants/routes';
import type {PendingInvitationDetails} from 'interface/stacks/auth/consts';
import {useNavigate} from 'react-router';
import {useJoinInvitationHandler} from './useJoinInvitationHandler';

export const useProccessPendingInvitation = () => {
  const navigate = useNavigate();

  const {handleInvitationAcceptance, isLoading: isPendingInvitationProcessing} =
    useJoinInvitationHandler(true);

  const handleInvitationFlow = async (invitation: PendingInvitationDetails) => {
    if (!invitation) {
      return;
    }

    if (invitation.hasClickedOnJoined) {
      await handleInvitationAcceptance({invitation, showAppLoader: true});
    } else {
      navigate(
        `/${ROUTES.LOGGED_OR_NOT_LOGGED.JOIN}/?token=${invitation.invitationToken}`,
      );
    }
  };

  return {handleInvitationFlow, isPendingInvitationProcessing};
};
