import {useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {
  combine,
  containsFiles,
  dropTargetForElements,
  dropTargetForExternal,
  getDroppedFiles,
  isDndData,
  moveToFolder,
  moveToShare,
} from '../utils/dragdrop';

import type {CleanupFn} from '@atlaskit/pragmatic-drag-and-drop/types';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {View} from 'react-native';

interface UseDragDropTargetProps {
  ref: React.RefObject<View>;
  targetNs: StorageNamespace;
  targetId: string;
  targetName: string;
  instanceNs: StorageNamespace;
  instanceId: string;
  uploader: UploaderState;
  options?: {
    isUploadOnly?: boolean;
  };
}

export function useDragDropTarget(props: UseDragDropTargetProps) {
  const {
    ref,
    targetNs,
    targetId,
    targetName,
    instanceNs,
    instanceId,
    uploader,
    options,
  } = props;
  const [isDropping, setIsDropping] = useState(false);
  const selection = useSelector(files.selectors.getSelection);
  const toast = useToast();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ref?.current) return;
    const element = ref.current as unknown as HTMLElement;
    return combine(
      ...([
        !options?.isUploadOnly &&
          dropTargetForElements({
            element,
            canDrop: ({source}) => isDndData(source.data),
            onDragEnter: () => setIsDropping(true),
            onDragLeave: () => setIsDropping(false),
            onDrop: async e => {
              setIsDropping(false);
              if (isDndData(e.source.data)) {
                const {item} = e.source.data;
                const keys = selection.length > 1 ? selection : [item.id];
                if (targetNs === 'share') {
                  toast.show(
                    ...(await moveToShare(
                      keys,
                      targetId,
                      targetName,
                      instanceId,
                      instanceNs,
                    )),
                  );
                  return;
                }
                toast.show(
                  ...(await moveToFolder(
                    dispatch,
                    keys,
                    targetId,
                    targetName,
                    instanceId,
                    instanceNs,
                  )),
                );
              }
            },
          }),
        dropTargetForExternal({
          element,
          canDrop: containsFiles,
          getDropEffect: () => 'copy',
          onDragEnter: () => setIsDropping(true),
          onDragLeave: () => setIsDropping(false),
          onDropTargetChange: ({location, self}) => {
            setIsDropping(
              location.current.dropTargets[0]?.element === self.element,
            );
          },
          onDrop: e => {
            if (e.location.current.dropTargets[0]?.element !== e.self.element) {
              return;
            }
            setIsDropping(false);
            getDroppedFiles(e.source, files => {
              if (files.length) {
                let uploadFolder = targetId;
                let uploadInstance = instanceId;
                // Dropping into a share from the workspace
                // we will always be uploading to the share's root
                if (instanceNs === 'workspace' && targetNs === 'share') {
                  uploadInstance = targetId;
                  uploadFolder = 'root';
                }
                uploader.instance.addFiles(files, uploadFolder, uploadInstance);
              }
            });
          },
        }),
      ].filter(Boolean) as CleanupFn[]),
    );
  }, [selection, ref?.current]);

  return {isDropping};
}
