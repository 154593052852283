import {t} from '@lingui/macro';
import {UploadStatus} from 'fast-sdk';

/**
 * Converts an upload status to a localized message
 */
export function toString(status: UploadStatus) {
  switch (status) {
    case UploadStatus.Queued:
      return t`Queued`;
    case UploadStatus.Paused:
      return t`Paused`;
    case UploadStatus.Aborted:
      return t`Aborted`;
    case UploadStatus.Failed:
      return t`Failed`;
    case UploadStatus.Hashing:
      return t`Hashing`;
    case UploadStatus.HashFailed:
      return t`Hash Failed`;
    case UploadStatus.Ready:
      return t`Uploaded`;
    case UploadStatus.Uploading:
      return t`Uploading`;
    case UploadStatus.Assemble:
    case UploadStatus.Assembling:
      return t`Assembling`;
    case UploadStatus.AssemblyFailed:
      return t`Assembly Failed`;
    case UploadStatus.Complete:
      return t`Complete`;
    case UploadStatus.Store:
      return t`Store Queued`;
    case UploadStatus.Storing:
      return t`Storing`;
    case UploadStatus.Stored:
      return t`Stored`;
    case UploadStatus.StoreFailed:
      return t`Store Failed`;
    case UploadStatus.Deleting:
      return t`Deleting`;
    default:
      status satisfies never;
  }
}

export function isQueued(status: UploadStatus) {
  return status === UploadStatus.Queued;
}

export function isPaused(status: UploadStatus) {
  return status === UploadStatus.Paused;
}

export function isComplete(status: UploadStatus) {
  return status === UploadStatus.Complete;
}

export function isTransferring(status: UploadStatus) {
  return status === UploadStatus.Hashing || status === UploadStatus.Uploading;
}

export function isAssembling(status: UploadStatus) {
  return (
    status === UploadStatus.Ready ||
    status === UploadStatus.Assemble ||
    status === UploadStatus.Assembling ||
    status === UploadStatus.Store ||
    status === UploadStatus.Storing
  );
}

export function isFailure(status: UploadStatus) {
  return (
    status === UploadStatus.Failed ||
    status === UploadStatus.StoreFailed ||
    status === UploadStatus.AssemblyFailed
  );
}

export function isRestartable(status: UploadStatus) {
  return (
    status === UploadStatus.Failed ||
    status === UploadStatus.StoreFailed ||
    status === UploadStatus.AssemblyFailed ||
    status === UploadStatus.Aborted
  );
}
