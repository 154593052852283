import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import Footer from 'interface/stacks/app/Footer';
import UserDropdown from 'interface/stacks/content-viewer/components/top-toolbar/UserDropdown';
import type {PropsWithChildren} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import LogoHeader from '../base/LogoHeader';
import {OnboardSteps, getStepFromIndex} from '../lib/consts';

interface Props {
  step: OnboardSteps;
  stepIndex: number;
  next: () => void;
}

export default function LayoutOnboard({
  step,
  stepIndex,
  children,
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const error = useSelector(onboarding.selectors.getError);
  const {imgSrc} = useSelector(onboarding.selectors.getUserData);
  const userPhoto = useSelector(user.selectors.getUserProfilePic);
  const userData = useSelector(onboarding.selectors.getUserData);

  const fullname =
    userData.firstName && userData.lastName
      ? `${userData.firstName} ${userData.lastName}`
      : '';

  const isFirstValidOrganization =
    useSelector(user.selectors.getOnboardedOrganizations).length === 0;

  const shouldGoBack =
    !error &&
    ((isFirstValidOrganization && step === OnboardSteps.Organization) ||
      step === OnboardSteps.Subdomain);

  const goBack = () => {
    if (shouldGoBack) {
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${getStepFromIndex(stepIndex - 1)}`,
      );
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.root}>
      <View style={styles.full}>
        <View style={styles.header}>
          <LogoHeader />
          <UserDropdown
            profilePicture={imgSrc || userPhoto}
            fullname={fullname}
          />
        </View>
        <View style={styles.childrenContainer}>
          {shouldGoBack && (
            <Button
              type="Text"
              onPress={goBack}
              lIcon={
                <Icon
                  name="chevron-left"
                  size={20}
                  color={theme.colors.neutral.$6}
                />
              }
              label="Back"
              customRootStyle={styles.backButton}
            />
          )}
          {children}
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
  },
  backButton: {
    maxWidth: 83,
  },
  full: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  left: {
    width: 500,
    display: 'flex',
    gap: 50,
  },
  right: {
    flex: 1,
    backgroundColor: theme.colors.neutral.$14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  childrenContainer: {
    maxWidth: 500,
    marginHorizontal: 'auto',
    flex: 1,
  },
  top: {
    display: 'flex',
    gap: 50,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    padding: 30,
  },
  button: {
    width: 92,
    height: 48,
    borderRadius: 5,
  },
  profileRightContent: {
    width: 740,
    height: 490,
    boxShadow:
      '0px 5px 15px -10px rgba(48, 50, 90, 0.15), 0px 10px 50px -10px rgba(48, 50, 90, 0.10)',
  },
});
