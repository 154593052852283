import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useFetchChatList} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useFetchChatList';
import {Fragment, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {AiChatListHeader} from '../components/AiChatListHeader';

import {AiChatItem} from '../components/AiChatItem';

import {groupChatsByDay} from '../helpers';

import {useToast} from 'react-native-toast-notifications';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatListSkeleton} from '../components/skeletons/AiChatListSkeleton';

interface Props {
  namespace: string;
  instanceId: string;
  onChatPress: (chat: EntityChat) => void;
  onNewChatPress: (isPrivate: boolean) => void;
  workspaceMembers: Member[];
}

const GROUP_BY_DAY = {
  today: 'Today',
  yesterday: 'Yesterday',
  older: 'Older',
};

export function AiChatList({
  namespace,
  instanceId,
  onChatPress,
  onNewChatPress,
  workspaceMembers,
}: Props) {
  const toast = useToast();
  const [isExpanded, setIsExpanded] = useState(true);
  const chats = useSelector(aichat.selectors.getChats(namespace, instanceId));
  const {isLoading: chatsLoading, error, fetchChatList} = useFetchChatList();

  useEffect(() => {
    fetchChatList();
  }, [instanceId]);

  useEffect(() => {
    if (error) {
      toast.show(error.message);
    }
  }, [error]);

  const groupedChats = groupChatsByDay(chats);

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <AiChatListHeader
        onNewChatPress={onNewChatPress}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />

      <View style={styles.chatsContainer}>
        {chatsLoading ? (
          <Fragment>
            <AiChatListSkeleton />
            <AiChatListSkeleton />
          </Fragment>
        ) : (
          groupedChats &&
          Object.entries(groupedChats).map(([key, value]) => (
            <Fragment>
              {value.length > 0 && (
                <Typography
                  overrides={styles.groupTitle}
                  size="sm"
                  color={theme.colors.neutral.$500}>
                  {GROUP_BY_DAY[key]}
                </Typography>
              )}
              <View style={styles.chatList}>
                {value.map(chat => (
                  <AiChatItem
                    key={chat.id}
                    chat={chat}
                    isExpanded={isExpanded}
                    workspaceMembers={workspaceMembers}
                    onPress={() => onChatPress(chat)}
                  />
                ))}
              </View>
            </Fragment>
          ))
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // padding: 16,
  },
  groupTitle: {
    marginVertical: 16,
  },
  chatsContainer: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
    paddingHorizontal: 16,
  },
  chatList: {
    gap: 8,
    width: '100%',
    alignSelf: 'center',
  },
});
