import theme from 'config/theme';
import {Outlet, useNavigate} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import AppLogo from 'interface/common/AppLogo';
import AppHeaderAccountDropdown from 'interface/stacks/app/layout/AppHeaderAccountDropdown';
import {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

export enum SubscriptionBackButtonState {
  Disabled = 'Disabled',
  Show = 'Show',
  Remove = 'Remove',
}

export function SubscriptionLayout() {
  const navigate = useNavigate();

  const [status, setStatus] = useState<SubscriptionBackButtonState>(
    SubscriptionBackButtonState.Show,
  );

  const handleBackButtonPress = () => {
    navigate(-1);
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        {status === SubscriptionBackButtonState.Show ||
        status === SubscriptionBackButtonState.Disabled ? (
          <Button
            onPress={handleBackButtonPress}
            disabled={status === SubscriptionBackButtonState.Disabled}
            variant="text"
            startEnhancer={
              <Icon
                name="lucide:arrow-left"
                color={theme.colors.neutral.$black}
                size={30}
              />
            }
          />
        ) : (
          <AppLogo />
        )}
        <AppHeaderAccountDropdown />
      </View>
      <ScrollView contentContainerStyle={styles.outletWrapper}>
        <Outlet context={[status, setStatus]} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$15,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
  },
  outletWrapper: {
    width: '100%',
    minHeight: '100%',
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
