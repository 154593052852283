import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {PopupMenu} from 'extensions/viewport/PopupMenu';
import {useModal} from 'extensions/viewport/useModal';
import {Icon} from 'interface/base/Icon';
import {useState} from 'react';
import {Pressable} from 'react-native';
import AiChatDeleteModal from './AiChatDeleteModal';
import AiChatPublishModal from './AiChatPublishModal';

export function AiChatListItemPopup({
  isPrivate,
  chatId,
}: {isPrivate: boolean; chatId: string}) {
  const [hasMenu, setHasMenu] = useState(false);

  const triggerElement = (
    <Pressable onPress={() => setHasMenu(true)}>
      <Icon name="lucide:more-horizontal" color={theme.colors.neutral.$700} />
    </Pressable>
  );

  const modal = useModal();

  const handleProfileAction = (command: string) => {
    switch (command) {
      case 'delete':
        modal.open(<AiChatDeleteModal chatId={chatId} onClose={modal.close} />);
        break;
      case 'rename':
        break;
      case 'publish':
        modal.open(
          <AiChatPublishModal chatId={chatId} onClose={modal.close} />,
        );
        break;
    }
    setHasMenu(false);
  };

  return (
    <Popup
      triggerElement={triggerElement}
      isOpen={hasMenu}
      close={() => setHasMenu(false)}>
      <PopupMenu
        autoClose
        close={() => setHasMenu(false)}
        onSelectItem={handleProfileAction}
        menuItems={[
          {
            command: 'delete',
            text: 'Delete Chat',
          },
          {
            command: 'rename',
            text: 'Rename Chat',
          },
          isPrivate && {
            command: 'publish',
            text: 'Publish to Team',
          },
        ].filter(e => !!e)}
      />
    </Popup>
  );
}
