import {ROUTES, WORKSPACE_STORAGE} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import {SearchOverlay} from 'interface/stacks/search/base/SearchOverlay';
import {useCallback, useEffect} from 'react';
import type {GestureResponderEvent} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import type {FilesItem} from 'store/slices/files/types';
import * as $workspace from 'store/slices/workspace';
import {BaseSearch} from './BaseSearch';

interface SearchProps {
  instanceNs: 'workspace' | 'share';
  instanceId: string;
  instanceAlt: string;
  instanceName: string;
  filterTypes?: Array<'file' | 'folder' | 'link'>;
}

export function Search({
  instanceName,
  instanceId,
  instanceNs,
  instanceAlt,
  filterTypes,
}: SearchProps) {
  const isSearchOpen = useSelector($workspace.selectors.isSearchOpen);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {workspaceId: workspaceAlt} = useParams<{workspaceId: string}>();

  const gotoParent = (item: FilesItem) => {
    if (instanceNs === 'workspace') {
      const base = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${instanceAlt}`;
      const url = `${base}/${WORKSPACE_STORAGE}/${item.parent}`;
      navigate(url, {state: {highlightId: item.id}});
    } else {
      const base = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceAlt}`;
      const url = `${base}/${ROUTES.LOGGED_IN_WITH_ORG.SHARED}/${instanceAlt}`;
      navigate(url, {
        state: {
          highlightId: item.id,
          highlightParent: item.parent,
        },
      });
    }
    close();
  };

  const close = useCallback(
    () => dispatch($workspace.default.actions.toggleSearch(false)),
    [],
  );

  const toggle = useCallback(
    () => dispatch($workspace.default.actions.toggleSearch()),
    [],
  );

  // Hotkey for opening search
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) toggle();
      if (event.key === 'Escape' && isSearchOpen) close();
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [close, toggle, isSearchOpen]);

  return isSearchOpen ? (
    <SearchOverlay onPress={close}>
      <BaseSearch
        alwaysShowResults
        instanceId={instanceId}
        instanceName={instanceName}
        instanceNs={instanceNs}
        instanceAlt={instanceAlt}
        isSearchOpen={isSearchOpen}
        onItemPress={gotoParent}
        onClose={close}
      />
    </SearchOverlay>
  ) : null;
}
