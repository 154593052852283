import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {slices} from 'store';
import {
  combine,
  containsFiles,
  draggable,
  dropTargetForElements,
  dropTargetForExternal,
  getDndData,
  getDroppedFiles,
  getItemDragPreview,
  isDndData,
  moveToFolder,
  moveToShare,
} from '../utils/dragdrop';

import type {CleanupFn} from '@atlaskit/pragmatic-drag-and-drop/types';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

interface UseDragDropItemProps {
  ref: React.RefObject<View>;
  target: FilesItem;
  instanceNs: StorageNamespace;
  instanceId: string;
  uploader: UploaderState;
  selection: string[];
}

export function useDragDropItem(props: UseDragDropItemProps) {
  const {ref, target, instanceNs, instanceId, uploader, selection} = props;
  const [isDropping, setIsDropping] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ref?.current) return;
    const element = ref.current as unknown as HTMLElement;
    const hasDrop =
      target.type === StorageItemType.Folder ||
      target.type === StorageItemType.Link;
    const ids = selection.length > 1 ? selection : [target.id];
    return combine(
      ...([
        draggable({
          element,
          getInitialData: () => getDndData(target),
          onGenerateDragPreview: ({nativeSetDragImage}) =>
            getItemDragPreview(nativeSetDragImage, selection),
          onDragStart: () => dispatch(slices.files.actions.drag({ids})),
          onDrop: () => dispatch(slices.files.actions.drag({ids: []})),
        }),
        hasDrop &&
          dropTargetForElements({
            element,
            canDrop: ({source}) =>
              source.element !== element && isDndData(source.data),
            onDragEnter: () => setIsDropping(true),
            onDragLeave: () => setIsDropping(false),
            onDrop: async e => {
              setIsDropping(false);
              if (isDndData(e.source.data)) {
                const {item} = e.source.data;
                const keys = selection.length > 1 ? selection : [item.id];
                if (target.type === StorageItemType.Link) {
                  toast.show(
                    ...(await moveToShare(
                      keys,
                      target.target_id,
                      target.name,
                      instanceId,
                      instanceNs,
                    )),
                  );
                  return;
                }
                toast.show(
                  ...(await moveToFolder(
                    dispatch,
                    keys,
                    target.id,
                    target.name,
                    instanceId,
                    instanceNs,
                  )),
                );
              }
            },
          }),
        hasDrop &&
          dropTargetForExternal({
            element,
            canDrop: containsFiles,
            getDropEffect: () => 'copy',
            onDragEnter: () => setIsDropping(true),
            onDragLeave: () => setIsDropping(false),
            onDrop: e => {
              setIsDropping(false);
              getDroppedFiles(e.source, files => {
                if (files.length) {
                  let uploadFolder = target.id;
                  let uploadInstance = instanceId;
                  // Dropping into a share from the workspace
                  // we will always be uploading to the share's root
                  if (target.type === StorageItemType.Link) {
                    uploadInstance = target.target_id;
                    uploadFolder = 'root';
                  }
                  uploader.instance.addFiles(
                    files,
                    uploadFolder,
                    uploadInstance,
                  );
                }
              });
            },
          }),
      ].filter(Boolean) as CleanupFn[]),
    );
  }, [target, selection, ref?.current]);

  return {isDropping};
}
