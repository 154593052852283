import {SESSION_COOKIE_KEY} from 'constants/sessionRoutes';
import {clearImagesCache} from 'extensions/images';
import {Requests} from 'fast-sdk';
import {getHostData} from 'interface/common/hooks/useHost';
import {resetAppStore} from 'store';
import Auth from '../../interface/stacks/auth/consts';
import {setCookie} from './cookies';

const IGNORE_COOKIES = ['ve_br_key'];

const clearAllCaches = async () => {
  /*if (caches) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(name => caches.delete(name)));
  }*/
};

const clearAllCookies = () => {
  document.cookie.split(';').forEach(cookie => {
    const [name] = cookie.split('=').map(c => c.trim());
    if (!IGNORE_COOKIES.includes(name)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  });
};

const clearSessionCookie = async () => {
  const {mainDomain, protocol} = await getHostData();

  setCookie({
    name: SESSION_COOKIE_KEY,
    value: '',
    domain: mainDomain.replace(/:\d+$/, ''),
    protocol,
  });
};

export const clearCache = async (stayOnPage = false) => {
  if (window) {
    await resetAppStore();
    await clearSessionCookie();
    clearAllCookies();
    await clearAllCaches();
    localStorage.clear();
    sessionStorage.clear();
    await Auth.clearDB();
    Requests.setAuthToken(null);
    clearImagesCache();
    if (!stayOnPage) {
      window.location.reload();
    }
  }
};
