import theme from 'config/theme';
import {WORKSPACE_ROOT, WORKSPACE_TRASH} from 'constants/routes';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {Icon} from 'interface/base/Icon';
import {MenuItem} from 'interface/base/MenuItem';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as $files from 'store/slices/files';
import {getFileDetails} from 'utils/fast/storage';

import type {GestureResponderEvent, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

interface SearchMenuItemProps {
  item: FilesItem;
  itemRef?: React.RefObject<View>;
  instanceId: string;
  instanceNs: 'workspace' | 'share';
  onItemPress: (e: GestureResponderEvent) => void;
  isEnabled: boolean;
}

export function SearchMenuItem({
  item,
  itemRef,
  instanceId,
  instanceNs,
  onItemPress,
  isEnabled = true,
}: SearchMenuItemProps) {
  const parent = useSelector($files.selectors.getItem(item.parent));

  const dispatch = useDispatch();
  // Fetch parent if not in store
  useEffect(() => {
    if (
      !parent &&
      item.parent !== WORKSPACE_ROOT &&
      item.parent !== WORKSPACE_TRASH
    ) {
      getFileDetails(item.parent, instanceId, instanceNs).then(
        ({result, node}) => {
          if (result) {
            dispatch(
              $files.default.actions.updateFile({
                file: node,
                instanceId,
                instanceNs,
              }),
            );
          }
        },
      );
    }
  }, [parent, item.parent, instanceId, instanceNs, dispatch]);

  return (
    <MenuItem
      itemRef={itemRef}
      label={item.name}
      onPress={onItemPress}
      isEnabled={isEnabled}
      icon={
        <FileThumbnail
          item={item}
          size={FileThumbnailSizes.Tiny}
          options={{
            containerStyle: {
              width: 20,
              height: 20,
              borderRadius: 6,
            },
            imageResizeMode: 'contain',
            previewResizeMode: 'contain',
            imageHeight: 20,
            imageWidth: 20,
          }}
        />
      }
      detailsText={
        item.parent === WORKSPACE_ROOT
          ? 'Root'
          : item.parent === WORKSPACE_TRASH
            ? 'Trash'
            : parent?.name
      }
      detailsIcon={
        item.parent === WORKSPACE_ROOT ||
        item.parent === WORKSPACE_TRASH ||
        parent ? (
          <Icon name="lucide:folder" color={theme.colors.neutral.$400} />
        ) : undefined
      }
    />
  );
}
