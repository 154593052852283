import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import type {InvitationDetails, ShareItem} from 'fast-sdk/src/api/share/consts';
import ShareInviteCard from 'interface/stacks/workspace/shared/components/invite/ShareInviteCard';
import {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import type {User} from 'store/slices/user/types';
import {RouteLayout} from '../_layout/RouteLayout';
import {UserBadge} from './UserBadge';

interface SharedInviteProps {
  loading?: boolean;
  invitation: InvitationDetails;
  userDetails: User;
  userProfilePic: string;
  onJoinButtonPress: (share: ShareItem) => Promise<void>;
}

export function SharedInvite({
  loading,
  invitation,
  userDetails,
  userProfilePic,
  onJoinButtonPress,
}: SharedInviteProps) {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const {org, share} = invitation;

  const title = useMemo(
    () => (
      <View style={styles.titleContainer}>
        <Text>
          <Trans>
            You've been invited to{' '}
            <Text style={styles.shareName}>{share?.title}</Text>
          </Trans>
        </Text>
      </View>
    ),
    [share?.title],
  );

  const subTitle = useMemo(() => {
    if (isLoggedIn) {
      return (
        <UserBadge
          id={userDetails.id}
          email={userDetails.email_address}
          imageSrc={userProfilePic}
          customStyle={{marginBottom: '0.5rem'}}
          initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
        />
      );
    }
    return t`To continue, create a Fastio account or sign in to your existing one.`;
  }, []);

  return (
    <RouteLayout title={title} subTitle={subTitle} customRootMaxWidth={850}>
      <ShareInviteCard
        loading={loading}
        share={share}
        org={org}
        onJoinButtonPress={onJoinButtonPress}
        invitedByEmail={invitation.inviter}
      />
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
  },
  shareName: {
    color: theme.colors.brand.$3,
  },
  titleContainer: {
    alignItems: 'center',
    gap: 6,
  },
});
