import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {FilesItem} from 'store/slices/files/types';
import * as user from 'store/slices/user';
import {CHAT_FOLDER_SCOPE_DEPTH, TEMP_MESSAGE_ID} from '../consts';
import {buildTempMessage} from '../helpers';

export const useCreateNewMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const dispatch = useDispatch();

  const userId = useSelector(user.selectors.getUserDetails)?.id;

  const createChatMessage = async (
    chatId: string,
    message: string,
    personality: string,
    scopeFiles: FilesItem[],
    attachFiles: FilesItem[],
  ) => {
    setIsLoading(true);
    const filesScope = scopeFiles
      .filter(file => file.type === 'file')
      .map(file => `${file.id}:${file.version}`)
      .join(',');
    const foldersScope = scopeFiles
      .filter(file => file.type === 'folder')
      .map(file => `${file.id}:${CHAT_FOLDER_SCOPE_DEPTH}`)
      .join(',');
    const filesAttach = attachFiles
      .filter(file => file.type === 'file')
      .map(file => `${file.id}:${file.version}`)
      .join(',');
    try {
      dispatch(aichat.default.actions.setTriggerStreaming(false));

      const newMessage = buildTempMessage(
        chatId,
        TEMP_MESSAGE_ID,
        message,
        personality,
        userId,
        filesScope,
        foldersScope,
        filesAttach,
      );

      dispatch(
        aichat.default.actions.addChatMessage({
          namespace,
          instanceId,
          message: newMessage,
        }),
      );
      const createMessageResponse = await api.workspaceAichat.createChatMessage(
        namespace,
        instanceName,
        chatId,
        message,
        personality,
        filesScope,
        foldersScope,
        filesAttach,
      );

      if (createMessageResponse.result) {
        return createMessageResponse?.message?.id;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, createChatMessage};
};
