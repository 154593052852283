import {resetSlice} from 'store/utils';
import {initialState} from '.';
import type {Actions, Store} from './types';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setMembersMap(state: Store, action: Actions['setMembersMap']) {
  const {orgId, members} = action.payload;
  state.membersMap[orgId] = members;
}

export function addOrganization(
  state: Store,
  action: Actions['addOrganization'],
) {
  const {orgId, org} = action.payload;
  if (!state.items) {
    state.items = {};
  }
  state.items[orgId] = org;
}
