import {
  differenceInSeconds,
  formatDistanceToNow,
  isToday,
  isYesterday,
} from 'date-fns';
import type {ChatMessage} from 'fast-sdk/src/api/workspace/aichat/consts';
import type {EntityChat} from 'store/slices/aichat/types';
import {nowServerDate, parseServerDate} from 'utils/common/dates';

interface ChatGroups {
  today: EntityChat[];
  yesterday: EntityChat[];
  older: EntityChat[];
}

export function formatRelativeTime(date: Date) {
  const seconds = differenceInSeconds(new Date(), date);

  // If less than 60 seconds, return "now"
  if (seconds < 60) {
    return 'now';
  }

  // Use formatDistanceToNow for other cases
  const distance = formatDistanceToNow(date, {
    addSuffix: true, // adds "ago" at the end
    includeSeconds: false,
  });

  return distance;
}

export function clearMessage(text?: string): string {
  return text?.replace(/\n/g, ' ') ?? '';
}

export function groupChatsByDay(chats: EntityChat[]): ChatGroups {
  const groups = chats.reduce(
    (groups, chat) => {
      const chatDate = parseServerDate(chat.createdAt);
      if (isToday(chatDate)) {
        groups.today.push(chat);
      } else if (isYesterday(chatDate)) {
        groups.yesterday.push(chat);
      } else {
        groups.older.push(chat);
      }
      return groups;
    },
    {
      today: [] as EntityChat[],
      yesterday: [] as EntityChat[],
      older: [] as EntityChat[],
    },
  );

  const sortByDate = (a: EntityChat, b: EntityChat) =>
    parseServerDate(b.createdAt).getTime() -
    parseServerDate(a.createdAt).getTime();

  return {
    today: groups.today.sort(sortByDate),
    yesterday: groups.yesterday.sort(sortByDate),
    older: groups.older.sort(sortByDate),
  };
}

function idsFromFilterString(filterString: string) {
  return filterString.split(',').map(item => item.split(':')[0]);
}

export function buildTempMessage(
  chatId: string,
  messageId: string,
  message: string,
  personality: string,
  userId: string,
  filesScope: string, // comma separated files
  foldersScope: string, // comma separated folders
  filesAttach: string, // comma separated files
) {
  const filesScopeArray = idsFromFilterString(filesScope);
  const foldersScopeArray = idsFromFilterString(foldersScope);
  const filesAttachArray = idsFromFilterString(filesAttach);

  const newMessage: ChatMessage = {
    chat_id: chatId,
    message_id: messageId,
    creator: {
      id: userId,
      type: 'user',
    },
    message: {
      text: message,
      scope: {
        profile_scope: undefined,
        specific_files_scope: {
          files: filesScopeArray.map(file => ({
            node_id: file,
            version_id: undefined,
          })),
          folders: foldersScopeArray.map(folder => ({
            node_id: folder,
          })),
        },
      },
      attached: filesAttachArray.map(file => ({
        node_id: file,
        version_id: undefined,
      })),
    },
    state: 'ready',
    created: nowServerDate(),
    updated: nowServerDate(),
    personality,
  };
  return newMessage;
}
