import {resetSlice} from 'store/utils';
import {nowServerDate} from 'utils/common/dates';
import {initialState} from '.';
import {buildChat, buildChatMessage, buildEmptyChat} from './helpers';
import type {Actions, Store} from './types';

import {
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
} from 'interface/stacks/workspace/layout/WorkspaceAiChat/consts';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setChats(state: Store, action: Actions['setChats']) {
  const {namespace, instanceId, chats} = action.payload;
  // Clear old chats
  getNamespace(state, namespace, instanceId).chats = {};

  chats.forEach(chat => {
    const existingMessages =
      getNamespace(state, namespace, instanceId).chats[chat.chat_id]
        ?.messages || [];
    getNamespace(state, namespace, instanceId).chats[chat.chat_id] = buildChat(
      chat,
      existingMessages,
    );
  });
}

export function setChatMessages(
  state: Store,
  action: Actions['setChatMessages'],
) {
  const {namespace, instanceId, chatId, messages} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].messages =
    messages.map(message => buildChatMessage(message));
}

export function addChat(state: Store, action: Actions['addChat']) {
  const {namespace, instanceId, chatId, chat, isPrivate} = action.payload;
  let newChat = undefined;
  if (!chat || chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT) {
    newChat = buildEmptyChat(chatId, isPrivate);
  } else {
    newChat = buildChat(chat);
  }
  getNamespace(state, namespace, instanceId).chats[chatId] = newChat;
}

export function updateChatName(
  state: Store,
  action: Actions['updateChatName'],
) {
  const {namespace, instanceId, chatId, name} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].name = name;
}

export function publishChat(state: Store, action: Actions['publishChat']) {
  const {namespace, instanceId, chatId} = action.payload;
  getNamespace(state, namespace, instanceId).chats[chatId].privacy.visibility =
    'public';
}

export function deleteChat(state: Store, action: Actions['deleteChat']) {
  const {namespace, instanceId, chatId} = action.payload;
  delete getNamespace(state, namespace, instanceId).chats[chatId];
}

export function addChatMessage(
  state: Store,
  action: Actions['addChatMessage'],
) {
  const {namespace, instanceId, message} = action.payload;
  getNamespace(state, namespace, instanceId).chats[
    message.chat_id
  ].messages.push(buildChatMessage(message));
}

export function addChatResponse(
  state: Store,
  action: Actions['addChatResponse'],
) {
  const {namespace, instanceId, chatId, messageId, response} = action.payload;
  const chat = getNamespace(state, namespace, instanceId).chats[chatId];
  const message = chat.messages.find(m => m.id === messageId);
  if (message) {
    message.state = 'complete';
    message.response = {
      text: response,
      error: null,
      created: nowServerDate(),
      cost: {
        credits: {
          tokens: 0,
        },
      },
      citations: [],
    };
  }
}

export function setCurrentChat(
  state: Store,
  action: Actions['setCurrentChat'],
) {
  const {namespace, instanceId, chatId} = action.payload;
  getNamespace(state, namespace, instanceId).currentChat = chatId;
  state.scopeFiles = {};
  state.attachFiles = {};
}

export function setTriggerStreaming(
  state: Store,
  action: Actions['setTriggerStreaming'],
) {
  state.triggerStreaming = action.payload;
}

const getNamespace = (state: Store, namespace: string, instanceId: string) => {
  if (namespace === 'workspace') {
    // Initialize workspaces if it doesn't exist
    if (!state.workspaces) {
      state.workspaces = {};
    }
    // Create workspace namespace if it doesn't exist
    if (!state.workspaces[instanceId]) {
      state.workspaces[instanceId] = {
        chats: {},
        currentChat: null,
      };
    }
    return state.workspaces[instanceId];
  }
  if (namespace === 'share') {
    // Initialize shares if it doesn't exist
    if (!state.shares) {
      state.shares = {};
    }
    // Create share namespace if it doesn't exist
    if (!state.shares[instanceId]) {
      state.shares[instanceId] = {
        chats: {},
        currentChat: null,
      };
    }
    return state.shares[instanceId];
  }
  return null;
};

export function addScopeFile(state: Store, action: Actions['addScopeFile']) {
  const {file} = action.payload;
  if (!state.scopeFiles) {
    state.scopeFiles = {};
  }
  state.scopeFiles[file.id] = file;
}

export function addAttachFile(state: Store, action: Actions['addAttachFile']) {
  const {file} = action.payload;
  if (!state.attachFiles) {
    state.attachFiles = {};
  }
  state.attachFiles[file.id] = file;
}

export function removeScopeFile(
  state: Store,
  action: Actions['removeScopeFile'],
) {
  const {fileId} = action.payload;
  if (!state.scopeFiles) {
    state.scopeFiles = {};
  }
  delete state.scopeFiles[fileId];
}

export function removeAttachFile(
  state: Store,
  action: Actions['removeAttachFile'],
) {
  const {fileId} = action.payload;
  if (!state.attachFiles) {
    state.attachFiles = {};
  }
  delete state.attachFiles[fileId];
}

export function setSource(state: Store, action: Actions['setSource']) {
  state.source = action.payload;
}
