import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {Personality} from 'store/slices/aichat/types';
import type {FilesItem} from 'store/slices/files/types';
import * as user from 'store/slices/user';
import {
  CHAT_FOLDER_SCOPE_DEPTH,
  NEW_CHAT,
  NEW_PRIVATE_CHAT,
  TEMP_MESSAGE_ID,
} from '../consts';
import {buildTempMessage} from '../helpers';

export const useCreateNewChat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {namespace, instanceId, instanceName, workspaceObj} =
    useGetNamespaceContext();
  const dispatch = useDispatch();
  const userId = useSelector(user.selectors.getUserDetails)?.id;
  const type = workspaceObj?.intelligence ? 'chat_with_files' : 'chat';

  const createChat = async (
    message: string,
    personality: Personality,
    isPrivate: boolean,
    scopeFiles: FilesItem[],
    attachFiles: FilesItem[],
  ) => {
    if (!instanceName) return;
    setIsLoading(true);
    try {
      const filesScope = scopeFiles
        .filter(file => file.type === 'file')
        .map(file => `${file.id}:${file.version}`)
        .join(',');
      const foldersScope = scopeFiles
        .filter(file => file.type === 'folder')
        .map(file => `${file.id}:${CHAT_FOLDER_SCOPE_DEPTH}`)
        .join(',');
      const filesAttach = attachFiles
        .filter(file => file.type === 'file')
        .map(file => `${file.id}:${file.version}`)
        .join(',');

      dispatch(aichat.default.actions.setTriggerStreaming(false));

      const tempChatId = isPrivate ? NEW_PRIVATE_CHAT : NEW_CHAT;
      dispatch(
        aichat.default.actions.addChat({
          namespace,
          instanceId,
          chatId: tempChatId,
          isPrivate,
        }),
      );

      const newMessage = buildTempMessage(
        tempChatId,
        TEMP_MESSAGE_ID,
        message,
        personality,
        userId,
        filesScope,
        foldersScope,
        filesAttach,
      );
      dispatch(
        aichat.default.actions.addChatMessage({
          namespace,
          instanceId,
          message: newMessage,
        }),
      );

      const createChatResponse = await api.workspaceAichat.createNewChat(
        namespace,
        instanceName,
        type,
        message,
        isPrivate,
        personality,
        filesScope,
        foldersScope,
        filesAttach,
      );
      const newChatId = createChatResponse?.chat?.id;

      dispatch(
        aichat.default.actions.deleteChat({
          namespace,
          instanceId,
          chatId: isPrivate ? NEW_PRIVATE_CHAT : NEW_CHAT,
        }),
      );
      dispatch(
        aichat.default.actions.addChat({
          namespace,
          instanceId,
          chatId: newChatId,
          isPrivate,
        }),
      );
      dispatch(
        aichat.default.actions.addChatMessage({
          namespace,
          instanceId,
          message: {...newMessage, chat_id: newChatId},
        }),
      );
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: newChatId,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading: isLoading, createChat};
};
