import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import {useGetCurrentShareMap} from './useGetCurrentShareMap';

export function useGetCurrentShareDetails() {
  const shareMap = useGetCurrentShareMap();
  const share = useSelector(
    shared.selectors.getSingleSharedItem(shareMap?.shareId),
  );

  return share;
}
