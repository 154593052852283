import {Outlet} from 'extensions/navigation';
import {DrawerLayout} from 'extensions/viewport/DrawerLayout';
import AppUserBar from 'interface/stacks/app/AppUserBar';
import {useRef} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

export default function AppBaseLayout() {
  const drawer = useRef<DrawerLayout>(null);

  return (
    <DrawerLayout
      ref={drawer}
      minSwipeDistance={3}
      edgeWidth={20}
      drawerType="front"
      drawerPosition="right"
      drawerLockMode={'locked-closed'}
      renderNavigationView={() => null}>
      <View style={styles.root}>
        <AppUserBar />
        <ScrollView style={styles.content} contentContainerStyle={{flex: 1}}>
          <Outlet />
        </ScrollView>
      </View>
    </DrawerLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    height: '100%',
  },
});
