import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import * as workspace from 'store/slices/workspace';

export const useUpdateChat = (workspaceId: string) => {
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const workspaceObj = useSelector(
    workspace.selectors.getWorkspaceById(workspaceId),
  );
  const workspaceName = workspaceObj?.folder_name;

  const updateChat = async (chatId: string, name: string) => {
    if (!workspaceName) return;
    setIsLoading(true);
    try {
      const updateChatResponse = await api.workspaceAichat.updateChat(
        namespace,
        instanceName,
        chatId,
        name,
      );
      if (updateChatResponse.result) {
        dispatch(
          aichat.default.actions.updateChatName({
            namespace,
            instanceId,
            chatId,
            name,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, updateChat};
};
