import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {useWorkspaceMembers} from './useWorkspaceMembers';

export const useIsWorkspaceAdmin = (workspace: WorkspaceListDetail) => {
  const userDetails = useSelector(user.selectors.getUserDetails);
  const {members, isLoading: loading} = useWorkspaceMembers(workspace?.id, {
    enabled: workspace?.user_status === WorkspaceUserStatus.Joined,
  });
  const currentMember = userDetails?.id
    ? members?.find(member => member.id === userDetails?.id)
    : undefined;
  const isAdmin = currentMember
    ? currentMember?.authentication === 'admin'
    : false;
  const isOwner = currentMember
    ? currentMember?.authentication === 'owner'
    : false;
  return {isAdmin, isOwner, loading};
};
