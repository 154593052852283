import theme from 'config/theme';
import {useRef, useState} from 'react';
import {
  type LayoutChangeEvent,
  Pressable,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import useDebounce from '../../common/hooks/useDebounce';
import {AddEmailChip} from './AddEmailChip';

interface AddEmailInputBoxProps {
  emails: Array<string>;
  placeholder?: string;
  inputValue: string;
  onSetInputValue: (email: string) => void;
  onUpdateInputHeight: (height: number) => void;
  onRemoveEmail: (index: number) => void;
  onRemoveLastEmail: () => void;
  onAddEmail: (email: string) => void;
  onUpdateEmail: (index: number, email: string) => void;
  setIsEditing: (isEditing: boolean) => void;
}

export function AddEmailInputBox(props: AddEmailInputBoxProps) {
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef(null);

  const placeholder =
    props.emails.length > 0
      ? ''
      : (props.placeholder ?? 'Enter your text here');

  const handleInputLayout = (event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout;
    props.onUpdateInputHeight(height);
  };

  const onBlur = () => {
    setFocused(false);
    if (props.inputValue) {
      props.onAddEmail(props.inputValue);
    }
  };
  const onBlurDelayed = useDebounce(() => onBlur(), 150);

  return (
    <Pressable
      style={[styles.root, focused && styles.backgroundActive]}
      onLayout={handleInputLayout}
      onPress={() => inputRef.current?.focus()}>
      <View style={styles.chipContainer}>
        {props.emails.map((email, index) => (
          <View key={email} style={styles.chipWrapper}>
            <AddEmailChip
              email={email}
              showError={true}
              enableEdit={true}
              setIsEditing={props.setIsEditing}
              onEmailChange={email => {
                props.onUpdateEmail(index, email);
              }}
              deleteString={() => {
                props.onRemoveEmail(index);
              }}
            />
          </View>
        ))}
        <TextInput
          ref={inputRef}
          value={props.inputValue}
          onChangeText={text => {
            props.onSetInputValue(text);
          }}
          aria-label={'Email Address'}
          placeholder={placeholder}
          placeholderTextColor={theme.colors.neutral.$6}
          style={[
            styles.input,
            {
              // @ts-ignore
              outline: 'none',
            },
          ]}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => onBlurDelayed()}
        />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.neutral.$13,
    borderTopEndRadius: 4,
    borderTopStartRadius: 4,
    borderBottomWidth: 1,
    padding: 5,
    zIndex: 999,
    width: '100%',
    minWidth: 0,
    borderBottomColor: '#e4e4e7',
  },
  backgroundActive: {
    borderBottomWidth: 2,
    borderBottomColor: '#006cfa',
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  chipWrapper: {
    maxWidth: '100%',
    flexShrink: 1,
    flexBasis: 'auto',
  },
  input: {
    height: 36,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 100,
  },
});
