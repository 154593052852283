import {useNavigate} from 'extensions/navigation';
import {useLayoutEffect} from 'react';

import {ROUTES, WORKSPACE_PREVIEW, WORKSPACE_STORAGE} from 'constants/routes';

import type {MultiplayerConnection, MultiplayerMember} from '../types';

export function useMultiplayerFollow(
  connection: MultiplayerConnection,
  following: MultiplayerMember,
) {
  const {profile} = connection;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    // Do nothing if we're not following anyone connected
    if (!following) return;

    let pathProfile: string;
    let pathResource: string;

    switch (profile.type) {
      case 'workspace':
        pathProfile = ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE;
        break;
      case 'share':
        pathProfile = ROUTES.LOGGED_OR_NOT_LOGGED.SHARED;
        break;
      default:
        profile.type satisfies never;
    }

    switch (following.currentItemType) {
      case 'folder':
        pathResource = WORKSPACE_STORAGE;
        break;
      case 'file':
        pathResource = WORKSPACE_PREVIEW;
        break;
      default:
        following.currentItemType satisfies never;
    }

    // Build the url based on the profile and resource
    const url = `/${pathProfile}/${profile.alt}/${pathResource}/${following.currentItemId}`;

    // Do nothing if followed member is not on a resource
    if (!following?.currentItemId) return;

    // Do nothing if we're already on the resource
    if (window.location.pathname === url) return;

    navigate(url, {replace: true});
  }, [profile, following]);
}
