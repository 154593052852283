import type {ShareMemberListInvitationItem} from 'fast-sdk/src/api/share/consts';
import useUpdateShare from 'interface/stacks/workspace/shared/hooks/useUpdateShare';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import {GeneralAccess} from './GeneralAccess';
import ShareMembers from './ShareMembers';
import {ShareMembersView} from './ShareModal';

interface Props {
  share: Share;
  emails: Array<string>;
  setEmails: (emails: Array<string>) => void;
  done: () => void;
  currentView: ShareMembersView;
  invitations: Array<ShareMemberListInvitationItem>;
  loadingInvitations: boolean;
  fetchInvitations: () => void;
}

export function ShareSettings({
  share,
  emails,
  setEmails,
  done,
  currentView,
  invitations,
  loadingInvitations,
  fetchInvitations,
}: Props) {
  const isEditable = true;

  const {updateShare} = useUpdateShare({
    share,
  });

  return (
    <View style={styles.root}>
      <ShareMembers
        share={share}
        emails={emails}
        setEmails={setEmails}
        canAddRecipients={isEditable}
        currentView={currentView}
        invitations={invitations}
        loadingInvitations={loadingInvitations}
        fetchInvitations={fetchInvitations}
      />
      {currentView === ShareMembersView.ShareSettings && (
        <GeneralAccess
          share={share}
          updateShare={updateShare}
          isEditable={isEditable}
          done={done}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    gap: 21,
  },
});
