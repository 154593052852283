import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import {Icon} from 'interface/base/Icon';
import {MenuItem} from 'interface/base/MenuItem';
import {MenuItemSkeleton} from 'interface/base/MenuItemSkeleton';
import {Section} from 'interface/base/Section';
import {SearchInput} from 'interface/stacks/search/base/SearchInput';
import {SearchMenuItem} from 'interface/stacks/search/base/SearchMenuItem';
import {SearchNoResults} from 'interface/stacks/search/base/SearchNoResults';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {buildFileDetails} from 'store/slices/files/helpers';
import {getFileDetails, search as searchStorage} from 'utils/fast/storage';

import * as $files from 'store/slices/files';
import * as $workspace from 'store/slices/workspace';

import type {StorageItem} from 'fast-sdk/src/api/storage/consts';
import type {StyleProp, View, ViewStyle} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

const RECENT_FILES_COUNT = 5;
const PLACEHOLDER_COUNT = 3;

interface BaseSearchProps {
  instanceNs: 'workspace' | 'share';
  instanceId: string;
  instanceAlt: string;
  instanceName: string;
  filterTypes?: Array<'file' | 'folder' | 'link'>;
  onItemPress: (item: FilesItem) => void;
  isSearchOpen?: boolean;
  alwaysShowResults?: boolean;
  addedSection?: React.ReactNode;
  filterForShowing?: (item: FilesItem) => boolean;
  filterForEnabling?: (item: FilesItem) => boolean;
  scrollStyles?: StyleProp<ViewStyle>;
  onClose?: () => void;
}

export function BaseSearch({
  instanceName,
  instanceId,
  instanceNs,
  instanceAlt,
  filterTypes,
  onItemPress,
  alwaysShowResults,
  addedSection,
  filterForShowing,
  filterForEnabling,
  isSearchOpen,
  scrollStyles,
  onClose,
}: BaseSearchProps) {
  const allItems = useSelector($files.selectors.getItems);
  const firstItemRef = useRef<View>(null);

  const recentSearches =
    useSelector($workspace.selectors.getRecentSearches(instanceId)) ?? [];
  const recentFiles = Object.values(allItems)
    .filter(item => item.type === 'file')
    .filter(item => item.parent !== WORKSPACE_TRASH)
    .filter(item => filterForShowing?.(item) ?? true)
    .filter(item =>
      instanceNs === 'workspace'
        ? item.workspaceId === instanceId
        : item.shareId === instanceId,
    )
    .sort(
      (a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime(),
    )
    .slice(0, RECENT_FILES_COUNT);

  const [results, setResults] = useState<Array<FilesItem>>([]);
  const [loading, setLoading] = useState(0);
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const fetchList = useCallback(
    async (searchQuery: string) => {
      const results = await searchStorage(searchQuery, instanceId, instanceNs);
      if (results.error) return [];
      const list = Object.entries(results.files).map(([key, data]) => ({
        id: key,
        ...data,
      }));
      return filterTypes
        ? list.filter(({type}) => filterTypes.includes(type))
        : list;
    },
    [instanceId, instanceNs, filterTypes],
  );

  const fetchItems = useCallback(
    async (itemList: Awaited<ReturnType<typeof fetchList>>) => {
      const results = await Promise.all(
        itemList.map(item => getFileDetails(item.id, instanceId, instanceNs)),
      );
      results.forEach(({result, node}) => {
        if (result) {
          dispatch(
            $files.default.actions.updateFile({
              file: node,
              instanceId,
              instanceNs,
            }),
          );
        }
      });
      return results.map(({node}) => node);
    },
    [instanceId, instanceNs, dispatch],
  );

  const buildItems = useCallback(
    (items: Array<StorageItem>): Array<FilesItem> => {
      return items.map(item => buildFileDetails(item, instanceId, instanceNs));
    },
    [instanceId, instanceNs],
  );

  // If query is empty, clear results and loading
  useEffect(() => {
    if (!query) {
      setSearch('');
      setResults([]);
      setLoading(0);
    }
  }, [query]);

  // When search changes, fetch results
  useEffect(() => {
    if (!search) {
      setResults([]);
      setLoading(0);
      return;
    }
    (async () => {
      setLoading(PLACEHOLDER_COUNT);
      const results = await fetchList(search);
      setLoading(results.length);
      const items = await fetchItems(results);
      setResults(
        filterForShowing
          ? buildItems(items).filter(filterForShowing)
          : buildItems(items),
      );
      setLoading(0);
    })();
  }, [search]);

  // Clear state when search is closed
  useEffect(() => {
    if (!isSearchOpen) {
      setQuery('');
      setSearch('');
      setResults([]);
      setLoading(0);
    }
  }, [isSearchOpen]);

  const hasLoadingPlaceholder = loading && search.length > 0;
  const hasRecentSearches = recentSearches.length > 0;
  const hasRecentFiles = recentFiles.length > 0;
  const hasResults = !loading && query.length > 0 && results.length > 0;
  const hasNoResults = !loading && !hasResults && search.length > 0;
  const hasContent =
    hasLoadingPlaceholder ||
    hasRecentSearches ||
    hasRecentFiles ||
    hasNoResults;

  return (
    <>
      <SearchInput
        value={query}
        instanceId={instanceId}
        instanceName={instanceName}
        firstItemRef={firstItemRef}
        onSubmit={setSearch}
        onChange={setQuery}
        close={onClose}
        autoSearch
      />
      {(alwaysShowResults || hasContent) && (
        <ScrollView
          style={[{width: '100%'}, scrollStyles]}
          contentContainerStyle={styles.content}
          showsVerticalScrollIndicator={false}>
          {addedSection}
          {hasResults || hasLoadingPlaceholder ? (
            <Section label="Files">
              {hasResults
                ? results.map((item, index) => (
                    <SearchMenuItem
                      key={item.id}
                      isEnabled={filterForEnabling?.(item) ?? true}
                      itemRef={index === 0 ? firstItemRef : null}
                      onItemPress={() => {
                        onItemPress(item);
                      }}
                      {...{
                        item,
                        instanceId,
                        instanceNs,
                      }}
                    />
                  ))
                : Array.from({length: loading}).map((_, index) => (
                    <MenuItemSkeleton key={index} icon={true} />
                  ))}
            </Section>
          ) : hasNoResults ? (
            <SearchNoResults />
          ) : (
            <>
              {hasRecentSearches && (
                <Section label="Recent Searches">
                  {recentSearches.map(item => (
                    <MenuItem
                      key={item.query}
                      label={item.query}
                      onPress={() => {
                        setQuery(item.query);
                        setSearch(item.query);
                      }}
                      icon={
                        <Icon
                          name="lucide:search"
                          color={theme.colors.neutral.$700}
                        />
                      }
                    />
                  ))}
                </Section>
              )}
              {hasRecentFiles && (
                <Section label="Recent Files">
                  {recentFiles.map(item => (
                    <SearchMenuItem
                      key={item.id}
                      isEnabled={filterForEnabling?.(item) ?? true}
                      onItemPress={() => {
                        onItemPress(item);
                      }}
                      {...{
                        item,
                        instanceId,
                        instanceNs,
                      }}
                    />
                  ))}
                </Section>
              )}
            </>
          )}
        </ScrollView>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  content: {
    gap: 16,
    width: '100%',
    paddingVertical: 16,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.colors.neutral.$75,
  },
});
