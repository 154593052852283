import {getSession} from '../utils';

export default function useHasAccessToOrg() {
  const checkOrgAccess = (subdomain: string) => {
    const {orgs} = getSession();
    const hasAccessToOrganization = orgs.find(
      ({domain, id}) => subdomain === domain || id === subdomain,
    );
    return Boolean(hasAccessToOrganization);
  };

  return {checkOrgAccess};
}
