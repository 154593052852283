import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {ItemChip} from 'interface/base/ItemChip';
import {ProgressCircle} from 'interface/stacks/uploads/base/ProgressCircle';
import * as uploadStatus from 'interface/stacks/uploads/utils/status';
import {StyleSheet, View} from 'react-native';

import type {FilesItem} from 'store/slices/files/types';

interface ItemUploadProps {
  size: number;
  upload: FilesItem['upload'];
}

export function ItemUpload(props: ItemUploadProps) {
  const {size, upload} = props;
  return (
    <ItemChip
      label={uploadStatus.toString(upload.status)}
      padding={{
        left:
          !uploadStatus.isTransferring(upload.status) &&
          !uploadStatus.isQueued(upload.status),
        right: true,
      }}
      startEnhancer={
        <View style={styles.icon}>
          {uploadStatus.isQueued(upload.status) && (
            <Icon
              name="lucide:clock"
              size={18}
              color={theme.colors.neutral.$8}
            />
          )}
          {uploadStatus.isComplete(upload.status) && (
            <Icon
              name="lucide:check"
              size={18}
              color={theme.colors.success.$4Base}
            />
          )}
          {uploadStatus.isAssembling(upload.status) && (
            <Icon
              name="lucide:check"
              size={18}
              color={theme.colors.neutral.$8}
            />
          )}
          {uploadStatus.isFailure(upload.status) && (
            <Icon
              name="lucide:alert-triangle"
              size={18}
              color={theme.colors.danger.$4Base}
            />
          )}
          {uploadStatus.isTransferring(upload.status) && (
            <ProgressCircle
              progress={upload.bytes / size}
              outerFill={theme.colors.neutral.$9}
              innerFill={theme.colors.neutral.$75}
              size={18}
            />
          )}
        </View>
      }
    />
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
