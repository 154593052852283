import {SESSION_COOKIE_KEY} from 'constants/sessionRoutes';
import {LOCALHOST_DOMAINS} from 'fast-sdk/src/config';
import {getCookie, setCookie} from 'utils/common/cookies';
import type {ISession} from './types';

const VE_AUTH_COOKIE_NAME = 've-authorization';

export const spreadVeAuthCookie = () => {
  const host = window.location?.host;

  if (LOCALHOST_DOMAINS.some(domain => host.includes(domain))) {
    return;
  }

  const authCookie = getCookie(VE_AUTH_COOKIE_NAME);
  if (authCookie && host) {
    const domains = host.split('.');
    const cookieDomain =
      domains.length > 2 ? `.${domains[1]}.${domains[2]}` : `.${host}`;
    setCookie({
      name: VE_AUTH_COOKIE_NAME,
      value: authCookie,
      domain: cookieDomain,
      path: '/',
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT',
    });
  }
};

export const getSession = () => {
  const session = getCookie(SESSION_COOKIE_KEY);
  return session ? (JSON.parse(session) as ISession) : null;
};
