import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {getFileTypeFromMime} from 'utils/fast/files';
import {getFileDetails} from 'utils/fast/storage';

// Promise cache outside the hook to be shared across all instances
const fileFetchPromiseCache: Record<string, Promise<any>> = {};

export function useGetFileDetailsCached(
  fileId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const formattedFileId = fileId.includes('-')
    ? fileId
    : fileId.match(/.{1,5}/g)?.join('-') || fileId;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const item = useSelector(files.selectors.getItem(formattedFileId));

  const parentItemId = item?.parent;
  const fileType = item && getFileTypeFromMime(item);
  const itemName = item?.name;

  useEffect(() => {
    let mounted = true;

    const fetchFile = async () => {
      // If file exists in store, don't fetch
      if (item || !formattedFileId) return;

      try {
        setIsLoading(true);
        setError(null);

        // If there's already a fetch in progress for this fileId, reuse that promise
        if (!fileFetchPromiseCache[formattedFileId]) {
          fileFetchPromiseCache[formattedFileId] = getFileDetails(
            formattedFileId,
            instanceId,
            instanceNs,
          )
            .then(response => {
              if (response.result) {
                dispatch(
                  files.default.actions.updateFile({
                    file: response.node,
                    instanceId,
                    instanceNs,
                  }),
                );
              }
              return response.node;
            })
            .finally(() => {
              // Clean up the cache after the promise resolves or rejects
              delete fileFetchPromiseCache[formattedFileId];
            });
        }

        // Wait for the cached promise to resolve
        await fileFetchPromiseCache[formattedFileId];
      } catch (err) {
        if (mounted) {
          setError(
            err instanceof Error ? err : new Error('Failed to fetch file'),
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchFile();

    return () => {
      mounted = false;
    };
  }, [formattedFileId, dispatch, item]);

  return {
    item,
    parentItemId,
    fileType,
    itemName,
    isLoading,
    error,
  };
}
