import theme from 'config/theme';
import {format, isToday, isYesterday} from 'date-fns';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Typography from 'interface/base/Typography';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import {Image, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors as filesSelectors} from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {selectors as sharedSelectors} from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import {bytesize} from 'utils/common/data';
import {parseServerDate} from 'utils/common/dates';
import {getFileTypeFromMime, typeToIcon} from 'utils/fast/files';
import {useGetNodeType} from '../hooks/useGetNodeType';

interface Props {
  focusedParentId: string;
  workspaceId: string;
}

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today at' h:mm a");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday at' h:mm a");
  }

  return format(date, "MM/dd/yy 'at' h:mm a");
};

export function InfoPanelHeader({focusedParentId, workspaceId}: Props) {
  const {type, item} = useGetNodeType(focusedParentId, workspaceId);

  switch (type) {
    case 'folder':
      return <FileHeader item={item as FilesItem} />;
    case 'share':
      return <ShareHeader item={item as Share} />;
    case 'workspace':
      return <WorkspaceHeader item={item as WorkspaceListDetail} />;
    default:
      return null;
  }
}

function FileHeader({item}: {item: FilesItem}) {
  const created = parseServerDate(item.created);
  const formattedCreated = `Created: ${renderDate(created)}`;
  const type = getFileTypeFromMime(item);
  return (
    <View style={[styles.root, styles.column]}>
      <View style={styles.header}>
        <Image
          style={styles.folderIcon}
          source={{uri: typeToIcon(type)}}
          resizeMode="center"
        />
        <View style={styles.column}>
          <Typography size="md" color={theme.colors.neutral.$700}>
            {item.name}
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            {formattedCreated}
          </Typography>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Type
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            {type}
          </Typography>
        </View>
        <View style={styles.divider} />
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Size
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            {bytesize(item.size, 2)}
          </Typography>
        </View>
      </View>
    </View>
  );
}

function WorkspaceHeader({item}: {item: WorkspaceListDetail}) {
  const created = parseServerDate(item.created);
  const formattedCreated = `Created: ${renderDate(created)}`;
  const type = 'Workspace';
  return (
    <View style={[styles.root, styles.column]}>
      <View style={styles.header}>
        <WorkspaceBadge id={item.id} size={30} />
        <View style={styles.column}>
          <Typography size="md" color={theme.colors.neutral.$700}>
            {item.name}
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            {formattedCreated}
          </Typography>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Type
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            {type}
          </Typography>
        </View>
        <View style={styles.divider} />
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Size
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            -
          </Typography>
        </View>
      </View>
    </View>
  );
}
function ShareHeader({item}: {item: Share}) {
  const created = parseServerDate(item.created);
  const formattedCreated = `Created: ${renderDate(created)}`;
  const type = `Share, ${item.share_type}`;
  return (
    <View style={[styles.root, styles.column]}>
      <View style={styles.header}>
        <ShareTypeBadge shareType={item.share_type} size={34} />
        <View style={styles.column}>
          <Typography size="md" color={theme.colors.neutral.$700}>
            {item.name}
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            {formattedCreated}
          </Typography>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Type
          </Typography>
          <Typography
            size="xs"
            color={theme.colors.neutral.$700}
            overrides={{textTransform: 'capitalize'}}>
            {type}
          </Typography>
        </View>
        <View style={styles.divider} />
        <View style={styles.item}>
          <Typography size="xs" color={theme.colors.neutral.$500}>
            Size
          </Typography>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            {bytesize(item.size, 2)}
          </Typography>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
  },
  header: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$100,
  },
  icon: {
    width: 34,
    height: 34,
    backgroundColor: theme.colors.neutral.$500,
  },
  folderIcon: {
    width: 34,
    height: 34,
  },
  column: {
    flexDirection: 'column',
  },
  divider: {
    width: 1,
    height: '100%',
    backgroundColor: theme.colors.neutral.$100,
  },
  row: {
    flexDirection: 'row',
  },
  item: {
    padding: 12,
    flex: 1,
    flexDirection: 'column',
  },
});
