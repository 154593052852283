import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import {StyleSheet, View} from 'react-native';
import {AppTabs} from '../../types';

interface Props {
  activeTab: AppTabs;
  setActiveTab: (value: AppTabs) => void;
  onNewOrganizationPress: () => void;
  isWorkspacesHidden: boolean;
}

export default function Header({
  activeTab,
  setActiveTab,
  onNewOrganizationPress,
  isWorkspacesHidden,
}: Props) {
  const tabs = [
    isWorkspacesHidden
      ? undefined
      : createTabItem(
          AppTabs.Workspaces,
          'Workspaces',
          'lucide:app-window-mac',
          activeTab,
        ),
    createTabItem(AppTabs.Shares, 'Shares', 'lucide:package-2', activeTab),
  ].filter(Boolean);

  return (
    <View style={styles.root}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={value => setActiveTab(value as AppTabs)}
      />
      <Button
        onPress={onNewOrganizationPress}
        variant="secondary"
        startEnhancer={
          <Icon name="lucide:plus" color={theme.colors.neutral.$black} />
        }>
        <Trans>New Org</Trans>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
});
