import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {FilesItem} from 'store/slices/files/types';
import {AiChatFilterSection} from './AiChatFilterSection';

export function AiChatFilterScopeSection() {
  const dispatch = useDispatch();
  const scopeFiles = useSelector(aichat.selectors.getScopeFiles());
  const fileList = Object.values(scopeFiles);
  const hasSearchFiles = fileList.length > 0;

  const handleRemoveFile = (file: FilesItem) => {
    dispatch(aichat.default.actions.removeScopeFile({fileId: file.id}));
  };

  return (
    hasSearchFiles && (
      <AiChatFilterSection
        files={fileList}
        onRemove={handleRemoveFile}
        type="scope"
      />
    )
  );
}
