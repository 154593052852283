import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import {ProfileEditDetails} from 'interface/stacks/app/ProfileEditDetails';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as user from 'store/slices/user';

export function Profile() {
  const userDetails = useSelector(user.selectors.getUserDetails);
  const profilePic = useSelector(user.selectors.getUserProfilePic);

  const [firstName, setFirstName] = useState<string>(userDetails.first_name);
  const [lastName, setLastName] = useState<string>(userDetails.last_name);
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();
  const dispatch = useDispatch();

  const saveChanges = () => {
    setLoading(true);
    api.user
      .updateUserDetails({
        ...userDetails,
        first_name: firstName,
        last_name: lastName,
      })
      .then(res => {
        if (res.result) {
          toast.show('The user information successfully changed', {
            type: 'success',
          });
          return api.user.userDetails();
        }
        throw Error(res.error?.text);
      })
      .then(res => {
        if (res.result) {
          dispatch(user.default.actions.setUserDetails(res.user));
        } else {
          throw Error(res.error?.text);
        }
      })
      .catch(err => {
        toast.show(err.message, {type: 'danger'});
        analytics.notify(err);
      })
      .finally(() => setLoading(false));
  };

  const disabled =
    firstName !== userDetails.first_name || lastName !== userDetails.last_name;
  useFormSubmit(saveChanges, disabled);

  return (
    <View style={styles.root}>
      <Text style={styles.header}>
        <Trans>Profile</Trans>
      </Text>
      <View style={styles.infoContainer}>
        <Text style={styles.title}>
          <Trans>Your Photo</Trans>
        </Text>
        <Text style={styles.text}>
          <Trans>Upload a photo to help your teammates recognize you.</Trans>
        </Text>
        <ProfileEditDetails
          id={userDetails.id}
          photo={profilePic}
          name={`${firstName} ${lastName}`}
          color={userDetails.color}
          size={5}
          type="user"
        />
      </View>
      <View style={styles.inputsContainer}>
        <Text style={[styles.title, {marginBottom: '1.5rem'}]}>
          <Trans>Your Name</Trans>
        </Text>
        <CustomTextInput
          label={t`First Name`}
          value={firstName}
          placeholder={t`First Name`}
          setValue={setFirstName}
          disabled={loading}
          ariaLabel={t`First Name`}
          customRootStyle={styles.inputRoot}
          customInputStyle={styles.input}
        />
        <CustomTextInput
          label={t`Last Name`}
          value={lastName}
          placeholder={t`Last Name`}
          setValue={setLastName}
          disabled={loading}
          ariaLabel={t`Last Name`}
          customRootStyle={styles.inputRoot}
          customInputStyle={styles.input}
        />
        <View style={styles.buttonContainer}>
          <Button
            variant="secondary"
            onPress={saveChanges}
            disabled={disabled}
            loading={loading}
            overrides={{
              Loading: {
                style: {
                  color: theme.colors.neutral.$5,
                },
              },
            }}>
            {t`Save Changes`}
          </Button>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 50,
    paddingVertical: 45,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 34,
    color: theme.colors.neutral.$2Base,
    marginBottom: '2rem',
  },
  infoContainer: {
    marginBottom: '2.5rem',
  },
  title: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: 7,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
  },
  inputsContainer: {
    maxWidth: 450,
  },
  inputRoot: {
    marginBottom: '1rem',
  },
  input: {
    height: 36,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
