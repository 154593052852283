import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {useHover} from 'interface/common/hooks/useHover';
import type {Zoom} from 'interface/stacks/content-viewer/hooks/useZoom';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Document} from 'react-pdf';
import type {FilesItem} from 'store/slices/files/types';
import {HEIGHT_CONTROL_BAR} from '../ControlBar';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';
import ObservedPage from './ObservedPage';

type Props = {
  file: FilesItem;
  fileSrc: any;
  onDocumentLoadSuccess: (data: {numPages: number}) => void;
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  zoom: Zoom;
};

const PDFContent = ({
  file,
  fileSrc,
  onDocumentLoadSuccess,
  onPageChange,
  currentPage,
  totalPages,
  zoom,
}: Props) => {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const {isHover, onHoverIn, onHoverOut} = useHover();

  useEffect(() => {
    const obs = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry?.isIntersecting) {
          onPageChange(Number(entry?.target.id));
        }
      },
      {threshold: 0.5},
    );

    setObserver(obs);

    return () => {
      obs.disconnect();
    };
  }, []);

  return (
    <ScrollView
      // @ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      showsVerticalScrollIndicator={false}>
      <ScrollView horizontal={true} centerContent>
        <Document
          loading={
            <View style={styles.loading}>
              <FileThumbnail
                item={file}
                size={FileThumbnailSizes.Preview}
                options={{previewResizeMode: 'center'}}
              />
            </View>
          }
          file={fileSrc}
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from({length: totalPages}).map((_, index) => {
            const page = index + 1;
            return (
              <ObservedPage
                key={page}
                page={page}
                zoom={zoom}
                observer={observer}
                currentPage={currentPage}
                isHover={isHover}
              />
            );
          })}
        </Document>
      </ScrollView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  loading: {
    width: '100vh',
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_CONTROL_BAR}px)`,
  },
});

export default PDFContent;
