import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  workspaces: {},
  shares: {},
  attachFiles: {},
  scopeFiles: {},
  triggerStreaming: false,
  source: undefined,
};

const sliceName = 'aichat';

export default createSlice({name: sliceName, reducers, initialState});
export {selectors};
