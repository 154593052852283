import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import type {User} from 'store/slices/user/types';
import {RouteLayout} from '../_layout/RouteLayout';
import {UserBadge} from './UserBadge';

interface InvitationUserNotMatchProps {
  userDetails: User;
  userProfilePic: string;
  onJoinWithDifferentAccount: () => void;
  onJoinWithCurrentAccount: () => void;
}

export function InvitationUserNotMatch({
  userDetails,
  userProfilePic,
  onJoinWithDifferentAccount,
  onJoinWithCurrentAccount,
}: InvitationUserNotMatchProps) {
  return (
    <RouteLayout
      title={t`Is this the right account?`}
      subTitle={
        <UserBadge
          id={userDetails.id}
          email={userDetails.email_address}
          imageSrc={userProfilePic}
          initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
        />
      }
      customRootMaxWidth={500}
      customContentContainerStyle={styles.titleContainer}>
      <Typography
        size="md"
        color={theme.colors.neutral.$2Base}
        overrides={styles.description}>
        {t`This email is different from the email where you received your invitation. Do you want to join with this account?`}
      </Typography>
      <View style={styles.buttonContainer}>
        <Button size="lg" variant="primary" onPress={onJoinWithCurrentAccount}>
          {t`Join with this account`}
        </Button>
        <Button
          size="lg"
          variant="secondary"
          onPress={onJoinWithDifferentAccount}>
          {t`Use a different account`}
        </Button>
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: '1rem',
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 42,
    width: '100%',
    gap: 14,
  },
  description: {
    textAlign: 'center',
  },
});
