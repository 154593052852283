import {createSlice} from '@reduxjs/toolkit';
import {WorkspaceInfoPanelTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FileSummaryTabs} from 'interface/stacks/workspace/storage/FolderHeaderSummary';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  sort: {category: 'name', order: 'asc'},
  filter: {type: 'all'},
  layout: FileListItemLayout.ListNormal,
  items: {},
  share: {},
  workspace: {},
  selection: [],
  focusedParentId: '',
  focusedId: '',
  following: null,
  focusedType: WorkspaceInfoPanelTab.FileInfo,
  fileSummaryType: FileSummaryTabs.Details,
  thumbnails: {},
  skipClearFocus: false,
};

export default createSlice({name: 'files', reducers, initialState});
export {selectors};
