import {useNavigate, useParams} from 'extensions/navigation';
import {useAndroidBackButton} from 'extensions/navigation/hooks/useAndroidBackButton';
import {FolderContents} from 'interface/stacks/workspace/storage/FolderContents';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as files from 'store/slices/files';

export function RouteFolder() {
  const {folderKey} = useParams<'folderKey' | 'workspaceId'>();
  const parentKey = useSelector(files.selectors.getParent(folderKey));
  const skipClearFocus = useSelector(files.selectors.shouldSkipClearFocus());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(files.default.actions.focusParent({id: folderKey}));
    if (skipClearFocus) {
      dispatch(files.default.actions.skipClearFocus({skip: false}));
    } else {
      dispatch(files.default.actions.focus({id: ''}));
    }
  }, [folderKey]);

  const handleBackButton = useCallback(() => {
    if (folderKey === 'root') return false;
    navigate(parentKey);
    return true;
  }, [folderKey, parentKey]);

  useAndroidBackButton(handleBackButton);

  return <FolderContents id={folderKey} />;
}

export default RouteFolder;
