import {pointerOutsideOfPreview} from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import {setCustomNativeDragPreview} from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import theme from 'config/theme';
import {move, transfer} from 'utils/fast/storage';

import type {ExternalDragPayload} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {Dispatch} from 'redux';
import type {FilesItem} from 'store/slices/files/types';

export {
  getFiles,
  containsFiles,
} from '@atlaskit/pragmatic-drag-and-drop/external/file';
export {combine} from '@atlaskit/pragmatic-drag-and-drop/combine';
export {dropTargetForExternal} from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
export {
  dropTargetForElements,
  draggable,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

export const DND_DATA_KEY = Symbol('dnd:item');

export type DndData = {[DND_DATA_KEY]: true; item: FilesItem};

export function getDndData(item: FilesItem): DndData {
  return {[DND_DATA_KEY]: true, item};
}

export function isDndData(
  data: Record<string | symbol, unknown>,
): data is DndData {
  return data[DND_DATA_KEY] === true;
}

export async function moveToShare(
  keys: string[],
  targetId: string,
  targetName: string,
  instanceId: string,
  instanceNs: StorageNamespace,
): Promise<[string, {type: 'danger' | undefined}]> {
  const result = await transfer(keys, targetId, 'root', instanceId, instanceNs);
  let message: string;
  let type: 'danger' | undefined;
  if (result.completes.length === keys.length) {
    message = `Added ${result.completes.length} item${
      result.completes.length === 1 ? '' : 's'
    } to ${targetName}`;
  } else {
    type = 'danger';
    message = `Failed to add ${result.errors.length} item${
      result.errors.length === 1 ? '' : 's'
    } to ${targetName}`;
  }
  return [message, {type}];
}

export async function moveToFolder(
  dispatch: Dispatch,
  keys: string[],
  targetId: string,
  targetName: string,
  instanceId: string,
  instanceNs: StorageNamespace,
): Promise<[string, {type: 'danger' | undefined}]> {
  const result = await move(dispatch, keys, targetId, instanceId, instanceNs);
  let message: string;
  let type: 'danger' | undefined;
  if (result.completes.length === keys.length) {
    message = `Moved ${result.completes.length} item${
      result.completes.length === 1 ? '' : 's'
    } to ${targetName}`;
  } else {
    type = 'danger';
    message = `Failed to move ${result.errors.length} item${
      result.errors.length === 1 ? '' : 's'
    } to ${targetName}`;
  }
  return [message, {type}];
}

export function getItemDragPreview(
  nativeSetDragImage: (image: Element, x: number, y: number) => void,
  selection?: string[],
) {
  setCustomNativeDragPreview({
    nativeSetDragImage,
    getOffset: pointerOutsideOfPreview({x: '8px', y: '8px'}),
    render({container}) {
      const badge = document.createElement('div');
      badge.style.backgroundColor = theme.colors.brand.$4Base;
      badge.style.color = theme.colors.neutral.$white;
      badge.style.fontSize = '12px';
      badge.style.fontWeight = 'bold';
      badge.style.width = '20px';
      badge.style.height = '20px';
      badge.style.borderRadius = '50%';
      badge.style.display = 'flex';
      badge.style.alignItems = 'center';
      badge.style.justifyContent = 'center';
      badge.textContent = (selection?.length || 1).toString();
      container.appendChild(badge);
    },
  });
}

export function getDroppedFiles(
  payload: ExternalDragPayload,
  callback: (files: File[]) => void,
) {
  const {items} = payload;

  const ignored = [
    '.git',
    '.DS_Store',
    'node_modules',
    'Thumbs.db',
    'desktop.ini',
    '$RECYCLE.BIN',
    '.Spotlight-V100',
    '.Trash',
    '.fseventsd',
  ];

  const entries = [];
  let directoryFound = false;
  let fileList: any = [];
  let counter = 0;
  const toArray = (list: any) => Array.prototype.slice.call(list || [], 0);
  const errorHandler = () => {};
  const getDirectoryItems = (reader: any, callback: any) => {
    let entries: any = [];
    const readEntries = () => {
      counter++;
      reader.readEntries((results: any) => {
        if (!results.length) {
          entries.sort();
          counter--;
          callback(entries);
        } else {
          entries = entries.concat(toArray(results));
          counter--;
          readEntries();
        }
      }, errorHandler);
    };
    readEntries();
  };

  const readDirectory = (entries: any) => {
    if (entries <= 0) callback(fileList);
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]) {
        const name = entries[i].name;
        if (ignored.includes(name)) continue;
        if (entries[i].isDirectory) {
          const reader = entries[i].createReader();
          getDirectoryItems(reader, readDirectory);
        } else {
          // Path to the current directory (first entry path in directory)
          let folderPath = entries[i].fullPath.slice(1).split('/'); // Remove starting slash.
          folderPath.pop(); // Remove filename.
          folderPath = folderPath.join('/');
          // Creates a file object from the entry.
          counter++;
          entries[i].file((file: any) => {
            counter--;
            try {
              // Attach the url so we know where its located.
              file.mfRelativePath = folderPath;
              // Add the file to the array.
              fileList = [...fileList, file];
            } catch (e) {}
            // Check if we are completely done.
            if (counter <= 0) callback(fileList);
          }, errorHandler);
        }
      }
    }
  };

  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].webkitGetAsEntry) break;
      entries[i] = items[i].webkitGetAsEntry();
      if (entries[i]?.isDirectory) directoryFound = true;
    }
  }

  if (directoryFound) {
    readDirectory(entries);
  } else {
    callback(items.map(item => item.getAsFile()));
  }
}
