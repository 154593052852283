import {t} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import {useMenu} from 'extensions/viewport/useMenu';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import theme from 'config/theme';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as files from 'store/slices/files';

import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {CONTEXT_MENU_BUTTONS} from 'interface/stacks/workspace/storage/hooks/consts';

import type {ViewStyle} from 'react-native';

interface ContextMenuProps {
  headerText: string;
  noDividers?: boolean;
  ignoreMultiSelect?: boolean;
  addToExistingShareOnPress?: () => void;
  commentsOnPress?: () => void;
  previewShareOnPress?: () => void;
  copyToOnPress?: () => void;
  createFolderOnPress?: () => void;
  createShareOnPress?: () => void;
  downloadOnPress?: () => void;
  moveToOnPress?: () => void;
  moveToTrashOnPress?: () => void;
  purgeFromTrashOnPress?: () => void;
  restoreFromTrashOnPress?: () => void;
  openCustomizeShareOnPress?: () => void;
  openShareDialogOnPress?: () => void;
  quickShareOnPress?: () => void;
  renameOnPress?: () => void;
  uploadHereOnPress?: () => void;
  viewOnPress?: () => void;
  archiveSharesOnPress?: () => void;
  unarchiveShareOnPress?: () => void;
  deleteShareOnPress?: () => void;
}

const createIcon = (
  iconName: string,
  iconColor?: string,
  additionalIconStyle?: ViewStyle,
) => (
  <MCIcon
    name={iconName}
    size={20}
    color={iconColor ?? theme.colors.neutral.$6}
    style={additionalIconStyle as any}
  />
);

export function useContextMenu(props: ContextMenuProps) {
  const hasSinglePurge = useFlag('storage-single-purge');
  const hasComments = useFlag('storage-comments');
  const selection = useSelector(files.selectors.getSelection);
  const items = useSelector(files.selectors.getItems);
  const title =
    selection.length > 0
      ? t`${selection.length} ${selection.length === 1 ? 'item' : 'items'} selected`
      : props.headerText;

  const typeCounts = useMemo(
    () =>
      selection?.reduce<Record<StorageItemType, number>>(
        (acc, id) => {
          acc[items[id].type] = (acc[items[id].type] || 0) + 1;
          return acc;
        },
        {} as Record<StorageItemType, number>,
      ),
    [items, selection],
  );

  const isShared = selection.some(id => items[id].shareId);
  const hasMultiple = selection.length > 1;
  const hasFolders = typeCounts[StorageItemType.Folder] > 0;
  const hasFiles = typeCounts[StorageItemType.File] > 0;
  const hasLinks = typeCounts[StorageItemType.Link] > 0;
  const isLinkMixed = hasMultiple && hasLinks && (hasFolders || hasFiles);
  const hasQuickShare = !isShared && !hasMultiple && !hasFolders && !hasLinks;
  const hasAddToExistingShare = !isShared;

  const {TOP, MIDDLE, BOTTOM} = CONTEXT_MENU_BUTTONS;
  const {
    addToExistingShare,
    comments,
    previewShare,
    createShare,
    createFolder,
    customizeShare,
    download,
    openShareDialog,
    quickShare,
    view,
    unarchiveShare,
    deleteShare,
  } = TOP;

  const {uploadHere, moveTo, copyTo, rename} = MIDDLE;

  const {moveToTrash, purgeFromTrash, restoreFromTrash, archiveShare} = BOTTOM;

  const menuItemsTop = [
    props.previewShareOnPress &&
      !isLinkMixed &&
      !hasMultiple && {
        command: previewShare.command,
        text: t`${previewShare.title}`,
        icon: createIcon(
          previewShare.iconName,
          previewShare.iconColor,
          previewShare.additionalIconStyle,
        ),
      },
    props.openShareDialogOnPress &&
      !isLinkMixed &&
      !hasMultiple && {
        command: openShareDialog.command,
        text: t`${openShareDialog.title}`,
        icon: createIcon(
          openShareDialog.iconName,
          openShareDialog.iconColor,
          openShareDialog.additionalIconStyle,
        ),
      },
    props.openCustomizeShareOnPress &&
      !isLinkMixed &&
      !hasMultiple && {
        command: customizeShare.command,
        text: t`${customizeShare.title}`,
        icon: createIcon(
          customizeShare.iconName,
          customizeShare.iconColor,
          customizeShare.additionalIconStyle,
        ),
      },
    props.createShareOnPress &&
      !isLinkMixed && {
        command: createShare.command,
        text: t`${createShare.title}`,
        icon: createIcon(
          createShare.iconName,
          createShare.iconColor,
          createShare.additionalIconStyle,
        ),
      },
    props.createFolderOnPress &&
      !isLinkMixed && {
        command: createFolder.command,
        text: t`${createFolder.title}`,
        icon: createIcon(
          createFolder.iconName,
          createFolder.iconColor,
          createFolder.additionalIconStyle,
        ),
      },
    hasAddToExistingShare &&
      props.addToExistingShareOnPress &&
      !isLinkMixed && {
        command: addToExistingShare.command,
        text: t`${addToExistingShare.title}`,
        icon: createIcon(
          addToExistingShare.iconName,
          addToExistingShare.iconColor,
          addToExistingShare.additionalIconStyle,
        ),
      },
    hasQuickShare &&
      props.quickShareOnPress &&
      !isLinkMixed && {
        command: quickShare.command,
        text: t`${quickShare.title}`,
        icon: createIcon(
          quickShare.iconName,
          quickShare.iconColor,
          quickShare.additionalIconStyle,
        ),
      },
    hasComments &&
      selection.length === 0 &&
      props.commentsOnPress &&
      !isLinkMixed && {
        command: comments.command,
        text: t`${comments.title}`,
        icon: createIcon(
          comments.iconName,
          comments.iconColor,
          comments.additionalIconStyle,
        ),
      },
    props.unarchiveShareOnPress &&
      !isLinkMixed && {
        command: unarchiveShare.command,
        text: t`${unarchiveShare.title}`,
        icon: createIcon(
          unarchiveShare.iconName,
          unarchiveShare.iconColor,
          unarchiveShare.additionalIconStyle,
        ),
      },
    props.deleteShareOnPress &&
      !isLinkMixed && {
        command: deleteShare.command,
        text: t`${deleteShare.title}`,
        icon: createIcon(
          deleteShare.iconName,
          deleteShare.iconColor,
          deleteShare.additionalIconStyle,
        ),
      },
  ].filter(Boolean);

  const menuItemsMid = [
    props.downloadOnPress &&
      !isLinkMixed && {
        command: download.command,
        text: t`${download.title}`,
        icon: createIcon(
          download.iconName,
          download.iconColor,
          download.additionalIconStyle,
        ),
      },
    props.viewOnPress &&
      !isLinkMixed && {
        command: view.command,
        text: t`${view.title}`,
        icon: createIcon(
          view.iconName,
          view.iconColor,
          view.additionalIconStyle,
        ),
      },
    selection.length === 0 &&
      props.uploadHereOnPress &&
      !isLinkMixed && {
        command: uploadHere.command,
        text: t`${uploadHere.title}`,
        icon: createIcon(
          uploadHere.iconName,
          uploadHere.iconColor,
          uploadHere.additionalIconStyle,
        ),
      },
    props.moveToOnPress && {
      command: moveTo.command,
      text: t`${moveTo.title}`,
      icon: createIcon(
        moveTo.iconName,
        moveTo.iconColor,
        moveTo.additionalIconStyle,
      ),
    },
    props.copyToOnPress &&
      !isLinkMixed && {
        command: copyTo.command,
        text: t`${copyTo.title}`,
        icon: createIcon(
          copyTo.iconName,
          copyTo.iconColor,
          copyTo.additionalIconStyle,
        ),
      },
    selection.length === 0 &&
      props.renameOnPress &&
      !isLinkMixed && {
        command: rename.command,
        text: t`${rename.title}`,
        icon: createIcon(
          rename.iconName,
          rename.iconColor,
          rename.additionalIconStyle,
        ),
      },
  ].filter(Boolean);

  const menuItemsBottom = [
    props.moveToTrashOnPress &&
      !hasLinks && {
        command: moveToTrash.command,
        text: t`${moveToTrash.title}`,
        icon: createIcon(
          moveToTrash.iconName,
          moveToTrash.iconColor,
          moveToTrash.additionalIconStyle,
        ),
      },
    props.restoreFromTrashOnPress && {
      command: restoreFromTrash.command,
      text: t`${restoreFromTrash.title}`,
      icon: createIcon(
        restoreFromTrash.iconName,
        restoreFromTrash.iconColor,
        restoreFromTrash.additionalIconStyle,
      ),
    },
    hasSinglePurge &&
      props.purgeFromTrashOnPress && {
        command: purgeFromTrash.command,
        text: t`${purgeFromTrash.title}`,
        icon: createIcon(
          purgeFromTrash.iconName,
          purgeFromTrash.iconColor,
          purgeFromTrash.additionalIconStyle,
        ),
      },
    props.archiveSharesOnPress &&
      !isLinkMixed && {
        command: archiveShare.command,
        text: t`${archiveShare.title}`,
        icon: createIcon(
          archiveShare.iconName,
          archiveShare.iconColor,
          archiveShare.additionalIconStyle,
        ),
      },
  ].filter(Boolean);

  const {open, close, reference} = useMenu(
    () => [
      props.headerText && {
        command: 'header',
        text: title,
      },
      ...menuItemsTop,
      !props.noDividers &&
        menuItemsTop.length > 0 && {
          command: '',
          text: '-',
        },
      ...menuItemsMid,
      !props.noDividers &&
        menuItemsMid.length > 0 &&
        menuItemsBottom.length > 0 && {
          command: '',
          text: '-',
        },
      ...menuItemsBottom,
    ],
    command => {
      close();
      switch (command) {
        case createShare.command:
          return props.createShareOnPress();
        case addToExistingShare.command:
          return props.addToExistingShareOnPress();
        case createFolder.command:
          return props.createFolderOnPress();
        case quickShare.command:
          return props.quickShareOnPress();
        case download.command:
          return props.downloadOnPress();
        case view.command:
          return props.viewOnPress();
        case comments.command:
          return props.commentsOnPress();
        case uploadHere.command:
          return props.uploadHereOnPress();
        case moveTo.command:
          return props.moveToOnPress();
        case copyTo.command:
          return props.copyToOnPress();
        case rename.command:
          return props.renameOnPress();
        case moveToTrash.command:
          return props.moveToTrashOnPress();
        case restoreFromTrash.command:
          return props.restoreFromTrashOnPress();
        case purgeFromTrash.command:
          return props.purgeFromTrashOnPress();
        case previewShare.command:
          return props.previewShareOnPress();
        case openShareDialog.command:
          return props.openShareDialogOnPress();
        case customizeShare.command:
          return props.openCustomizeShareOnPress();
        case archiveShare.command:
          return props.archiveSharesOnPress();
        case unarchiveShare.command:
          return props.unarchiveShareOnPress();
        case deleteShare.command:
          return props.deleteShareOnPress();
      }
    },
    selection.length > 0,
  );

  return {
    open: props.ignoreMultiSelect && selection.length > 1 ? () => {} : open,
    close,
    reference,
  };
}
