import theme from 'config/theme';
import {ROUTES, WORKSPACE_PREVIEW} from 'constants/routes';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import type {Citation} from 'fast-sdk/src/api/workspace/aichat/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {useMemo, useState} from 'react';
import {Pressable, ScrollView, StyleSheet, View} from 'react-native';
import {useNavigate} from 'react-router';
import type {FilesItem} from 'store/slices/files/types';
import {AiChatCitationModalItem} from './AiChatCitationModalItem';

interface Props {
  item: FilesItem;
  citation: Citation;
  onClose: () => void;
}

export default function AiChatCitationModal({citation, item, onClose}: Props) {
  const [openCitationId, setOpenCitationId] = useState<string | null>(null);
  const navigate = useNavigate();
  const {copyLink} = useCopyLink();

  const renderThumbnail = useMemo(() => {
    if (!item) {
      return null;
    }
    return (
      <FileThumbnail
        item={item}
        uploading={false}
        options={{
          previewResizeMode: 'contain',
          imageResizeMode: 'contain',
          imageWidth: 56,
          imageHeight: 56,
          containerStyle: {
            width: 56,
            height: 56,
          },
          imageContainerStyle: {
            backgroundColor: theme.colors.neutral.$50,
          },
        }}
        size={FileThumbnailSizes.Medium}
      />
    );
  }, [item?.id]);

  const citations = citation.page?.map(page => ({
    page,
    snippet: citation.snippet,
    node_id: citation.node_id,
    version_id: citation.version_id,
    hash: citation.hash,
  }));

  const title = `Citation${citations.length > 1 ? 's' : ''} of ${item?.name}`;

  const toggleCitation = (page: number) => {
    if (openCitationId === page.toString()) {
      setOpenCitationId(null);
    } else {
      setOpenCitationId(page.toString());
    }
  };

  // TODO: Aichat: Change for SHARES
  const showFile = () => {
    if (item?.type === 'file') {
      onClose();
      navigate(
        `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${item.workspaceId}/${WORKSPACE_PREVIEW}/${item.id}`,
      );
    }
  };

  const copySnippet = (snippet: string) => {
    copyLink(snippet, 'Copied to clipboard');
  };

  return (
    <View style={styles.root}>
      <View style={styles.iconContainer}>
        <Pressable onPress={onClose}>
          <Icon name="lucide:x" size={24} color={theme.colors.neutral.$700} />
        </Pressable>
      </View>
      <View style={styles.header}>
        {renderThumbnail}
        <View style={styles.headerContent}>
          <Typography
            variant="medium"
            size="base"
            color={theme.colors.neutral.$700}>
            {title}
          </Typography>
          <Button
            variant="secondary"
            size="sm"
            startEnhancer={<Icon name="lucide:eye" size={16} />}
            onPress={showFile}
            overrides={{
              Button: {
                style: {
                  width: 'fit-content',
                },
              },
            }}>
            <Typography
              variant="regular"
              size="xs"
              color={theme.colors.neutral.$700}>
              View File
            </Typography>
          </Button>
        </View>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.citationsScroll}>
        {citations.map(citation => (
          <AiChatCitationModalItem
            key={citation.page}
            citationPage={citation}
            isOpen={openCitationId === citation.page.toString()}
            onPressed={() => toggleCitation(citation.page)}
            onViewFile={showFile}
            onCopy={copySnippet}
          />
        ))}
      </ScrollView>
      <Button
        variant="primary"
        size="md"
        overrides={{
          Button: {
            style: {
              alignSelf: 'flex-end',
              marginHorizontal: 24,
              width: 'fit-content',
            },
          },
        }}>
        Done
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 600,
    maxHeight: 800,
    paddingVertical: 24,
    gap: 24,
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 16,
  },
  iconContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    marginHorizontal: 24,
  },
  headerContent: {
    flexDirection: 'column',
    gap: 6,
  },
  citationsScroll: {
    flex: 1,
  },
});
