import {resetSlice} from 'store/utils';
import {defaultUser, initialState} from '.';
import type {Actions, Store} from './types';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setUserName(state: Store, action: Actions['userName']) {
  const {firstName, lastName} = action.payload;
  state.user.first_name = firstName;
  state.user.last_name = lastName;
}

export function setUserEmail(state: Store, action: Actions['userEmail']) {
  const {email} = action.payload;
  state.user.email_address = email;
}

export function setOrganizationsList(
  state: Store,
  action: Actions['addOrganizationsList'],
) {
  const {orgsList} = action.payload;
  state.organizationsList = orgsList;
}

export function setSelectedOrganization(
  state: Store,
  action: Actions['setSelectedOrganization'],
) {
  const {org} = action.payload;
  state.selectedOrganization = org;
}

export function setUserToken(state: Store, action: Actions['token']) {
  state.token = action.payload;
}

export function setUserProfilePic(
  state: Store,
  action: Actions['userProfilePic'],
) {
  const {img} = action.payload;
  state.profilePic = img;
}

export function setUserDetails(state: Store, action: Actions['userDetails']) {
  state.user = action.payload;
}

export function deleteUserDetails(state: Store) {
  state.token = '';
  state.user = defaultUser;
  state.organizationsList = [];
  state.selectedOrganization = null;
  state.profilePic = '';
  state.websocketAuthTokens = {};
}

export function updateOrganization(
  state: Store,
  action: Actions['updateOrganization'],
) {
  const {org} = action.payload;
  const existingOrgIndex = state.organizationsList.findIndex(
    existingOrg => existingOrg.id === org.id,
  );
  if (existingOrgIndex !== -1) {
    state.organizationsList[existingOrgIndex] = org;
  } else {
    state.organizationsList.push(org);
  }
}

export function setWebsocketAuthToken(
  state: Store,
  action: Actions['setWebsocketAuthToken'],
) {
  const {entityId, authToken} = action.payload;
  if (!state.websocketAuthTokens) {
    state.websocketAuthTokens = {};
  }
  state.websocketAuthTokens[entityId] = authToken;
}

export function setAvailableProfiles(
  state: Store,
  action: Actions['setAvailableProfiles'],
) {
  const {hasOrgs, hasWorkspaces, hasShares} = action.payload;
  state.hasOrgs = hasOrgs;
  state.hasWorkspaces = hasWorkspaces;
  state.hasShares = hasShares;
}

export function setMembersByOrganization(
  state: Store,
  action: Actions['setMembersByOrganization'],
) {
  const {organizationId, members} = action.payload;
  state.membersByOrganization[organizationId] = members;
}
