import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Icon} from 'interface/base/Icon';
import {ToggleWithLabel} from 'interface/base/ToggleWithLabel';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import React, {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys as ColumnKeys} from 'store/slices/app/types';
import * as files from 'store/slices/files';
import {COLUMN_NAME} from './ColumnPanelToggle';

// Main component that renders the column manager button and its popup
export function ColumnManager() {
  // Hook to handle hover state for the button
  const {isHover, onHoverIn, onHoverOut} = useHover();
  // State to control popup visibility
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      showBorder={false}
      close={() => setIsOpen(false)}
      // The button that triggers the popup
      triggerElement={
        <Pressable
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          onPress={() => setIsOpen(true)}>
          <ColumnManagerButton isHover={isHover} />
        </Pressable>
      }
      placement="bottom-start"
      customContainerStyle={styles.popupContainer}
      customPopupStyle={styles.customPopupStyle}>
      <ColumnManagerModal />
    </Popup>
  );
}

// The button component that shows the columns icon and dropdown arrow
function ColumnManagerButton({isHover}: {isHover: boolean}) {
  return (
    <View style={[styles.button, isHover && styles.hovered]}>
      <Icon name="lucide:columns-3" color={theme.colors.brand.$4} size={20} />
      <Icon
        name="lucide:chevron-down"
        color={theme.colors.neutral.$2Base}
        size={20}
        opacity={0.5}
      />
    </View>
  );
}

// The modal content that shows toggle switches for each column
function ColumnManagerModal() {
  // Get column visibility states from Redux
  const columnVisibility = useSelector(app.selectors.columnVisibility);
  const focusedParentId = useSelector(files.selectors.getFocusedParent);

  // Helper to get the current visibility state of a column
  const getInfo = (key: ColumnKeys) => columnVisibility[key];

  // Get current state for each column
  const isFoldersAndFilesChecked = getInfo(ColumnKeys.FoldersAndFiles);
  const isInfoPanelChecked = getInfo(ColumnKeys.InfoPanel);
  const isShareConfigurationChecked = getInfo(ColumnKeys.ShareConfiguration);
  const isAIChatChecked = getInfo(ColumnKeys.AIChat);
  const isWorkspaceBrowserChecked = getInfo(ColumnKeys.WorkspaceBrowser);

  // Check if we're in a share context
  const isShareContext = !!focusedParentId?.startsWith('5');

  const dispatch = useDispatch();

  // Handler to toggle column visibility
  const setColumnManagerInfo = (key: ColumnKeys, value: boolean) => {
    dispatch(app.default.actions.setColumnVisibility({[key]: value}));
  };

  return (
    <>
      <Typography
        variant="regular"
        size="sm"
        color={theme.colors.neutral.$500}
        overrides={{marginBottom: 16}}>
        Toggle Columns:
      </Typography>
      {/* Toggle switches for each column */}
      <ToggleWithLabel
        label={COLUMN_NAME[ColumnKeys.WorkspaceBrowser]}
        checked={isWorkspaceBrowserChecked}
        onChange={() =>
          setColumnManagerInfo(
            ColumnKeys.WorkspaceBrowser,
            !isWorkspaceBrowserChecked,
          )
        }
      />
      <ToggleWithLabel
        label={COLUMN_NAME[ColumnKeys.FoldersAndFiles]}
        checked={isFoldersAndFilesChecked}
        onChange={() =>
          setColumnManagerInfo(
            ColumnKeys.FoldersAndFiles,
            !isFoldersAndFilesChecked,
          )
        }
      />
      <ToggleWithLabel
        label={COLUMN_NAME[ColumnKeys.InfoPanel]}
        checked={isInfoPanelChecked}
        onChange={() =>
          setColumnManagerInfo(ColumnKeys.InfoPanel, !isInfoPanelChecked)
        }
      />
      {/* Only show Share Configuration toggle in share context */}
      {isShareContext && (
        <ToggleWithLabel
          label={COLUMN_NAME[ColumnKeys.ShareConfiguration]}
          checked={isShareConfigurationChecked}
          onChange={() =>
            setColumnManagerInfo(
              ColumnKeys.ShareConfiguration,
              !isShareConfigurationChecked,
            )
          }
        />
      )}
      <ToggleWithLabel
        label={COLUMN_NAME[ColumnKeys.AIChat]}
        checked={isAIChatChecked}
        onChange={() =>
          setColumnManagerInfo(ColumnKeys.AIChat, !isAIChatChecked)
        }
      />
    </>
  );
}

// Styles for the column manager components
const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    padding: 6,
    gap: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hovered: {
    backgroundColor: theme.colors.neutral.$13,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 24,
    width: 280,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
  customPopupStyle: {
    backgroundColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    paddingHorizontal: 8,
  },
});
