import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {useWorkspaceContext} from '../storage/hooks/useWorkspaceContext';
import {useGetCurrentShareDetails} from './useGetCurrentShareDetails';

export function useGetNamespaceContext() {
  const share = useGetCurrentShareDetails();
  const {workspace} = useWorkspaceContext();

  const isShare = !!share;
  const namespace: StorageNamespace = isShare ? 'share' : 'workspace';
  const instanceId = isShare ? share?.id : workspace?.id;
  const instanceName = isShare ? share?.custom_name : workspace?.folder_name;
  const name = isShare ? share?.name : workspace?.name;
  return {namespace, instanceId, instanceName, name, workspaceObj: workspace};
}

export function useGetNamespaceContextInModal() {
  const source = useSelector(aichat.selectors.getSource());
  return {
    namespace: source?.namespace,
    instanceId: source?.instanceId,
    instanceName: source?.instanceName,
    name: source?.name,
    workspaceObj: source?.workspaceObj,
  };
}
