import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import type {AiChatFilterType} from '../../../consts';
import {AiChatChip} from './AiChatChip';

interface Props {
  files: FilesItem[];
  type: AiChatFilterType;
  onRemove: (file: FilesItem) => void;
}

export function AiChatFilterSection({files, type, onRemove}: Props) {
  const iconName = type === 'scope' ? 'lucide:filter' : 'lucide:paperclip';
  const iconBackground =
    type === 'scope' ? theme.colors.brand.$1_400 : theme.colors.neutral.$700;
  return (
    <View style={styles.container}>
      <View style={[styles.icon, {backgroundColor: iconBackground}]}>
        <Icon name={iconName} size={16} color={theme.colors.neutral.$0} />
      </View>
      {files.map(file => (
        <AiChatChip item={file} onRemove={() => onRemove(file)} type={type} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    paddingHorizontal: 16,
    flexWrap: 'wrap',
  },
  icon: {
    borderRadius: 4,
    paddingHorizontal: 6,
    paddingVertical: 2,
    backgroundColor: theme.colors.brand.$1_400,
  },
});
