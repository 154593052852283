import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {type ErrorConfigurations, ErrorFlowActions} from './types';

export const ApiErrorsConfiguration: ErrorConfigurations<ApiErrors> = {
  [ApiErrors.ApiUserTokenBasicInvalid]: {
    key: 'ERROR_API_USER_TOKEN_BASIC_INVALID',
  },
  [ApiErrors.RequestTypeInvalid]: {
    key: 'ERROR_REQUEST_TYPE_INVALID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.ApiUserTokenUserInvalid]: {
    key: 'ERROR_API_USER_TOKEN_USER_INVALID',
  },
  [ApiErrors.ApiUserTokenPassInvalid]: {
    key: 'ERROR_API_USER_TOKEN_PASS_INVALID',
  },
  [ApiErrors.UserNotFound]: {
    key: 'ERROR_USER_NOT_FOUND',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.PassNotSet]: {
    key: 'ERROR_PASS_NOT_SET',
  },
  [ApiErrors.CredentialsInvalid]: {
    key: 'ERROR_CREDENTIALS_INVALID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.JwtCreation]: {
    key: 'ERROR_JWT_CREATION',
  },
  [ApiErrors.Disabled]: {
    key: 'ERROR_DISABLED',
  },
  [ApiErrors.AuthorizationInvalid]: {
    key: 'ERROR_AUTHORIZATION_INVALID',
  },
  [ApiErrors.InitTokenObject]: {
    key: 'ERROR_INIT_TOKEN_OJBECT',
  },
  [ApiErrors.TokenUserSet]: {
    key: 'ERROR_TOKEN_USER_SET',
  },
  [ApiErrors.TokenTooManyKeys]: {
    key: 'ERROR_TOKEN_TOO_MANY_KEYS',
  },
  [ApiErrors.TokenMemoInvalid]: {
    key: 'ERROR_TOKEN_MEMO_INVALID',
  },
  [ApiErrors.TokenCreate]: {
    key: 'ERROR_TOKEN_CREATE',
  },
  [ApiErrors.TokenStore]: {
    key: 'ERROR_TOKEN_STORE',
  },
  [ApiErrors.TokenInvalid]: {
    key: 'ERROR_TOKEN_INVALID',
  },
  [ApiErrors.TokenMissing]: {
    key: 'ERROR_TOKEN_MISSING',
  },
  [ApiErrors.TokenDelete]: {
    key: 'ERROR_TOKEN_DELETE',
  },
  [ApiErrors.EmailInvalid]: {
    key: 'ERROR_EMAIL_INVALID',
  },
  [ApiErrors.UserMiscompare]: {
    key: 'ERROR_USER_MISCOMPARE',
    flowAction: ErrorFlowActions.Dialog,
  },
  [ApiErrors.UserNotLoggedIn]: {
    key: 'ERROR_USER_NOT_LOGGEDIN',
  },
  [ApiErrors.UserEmailInvalid]: {
    key: 'ERROR_USER_EMAIL_INVALID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UserPassInvalid]: {
    key: 'ERROR_USER_PASS_INVALID',
  },
  [ApiErrors.UserFirstNameInvalid]: {
    key: 'ERROR_USER_FIRST_NAME_INVALID',
  },
  [ApiErrors.UserPhoneNumberInvalid]: {
    key: 'ERROR_USER_PHONE_NUMBER_INVALID',
  },
  [ApiErrors.UserNothingToUpdate]: {
    key: 'ERROR_USER_NOTHING_TO_UPDATE',
  },
  [ApiErrors.UserPassEncodeFailure]: {
    key: 'ERROR_USER_PASS_ENCODE_FAILURE',
  },
  [ApiErrors.UserCommitChanges]: {
    key: 'ERROR_USER_COMMIT_CHANGES',
  },
  [ApiErrors.UserCodeInvalid]: {
    key: 'ERROR_USER_CODE_INVALID',
  },
  [ApiErrors.UserCodeCreate]: {
    key: 'ERROR_USER_CODE_CREATE',
  },
  [ApiErrors.UserEmailAlreadyVerified]: {
    key: 'ERROR_USER_EMAIL_ALREADY_VERIFIED',
  },
  [ApiErrors.StorageProviderGenericInit]: {
    key: 'ERROR_STORAGE_PROVIDER_GENERIC_INIT',
  },
  [ApiErrors.SsoProviderInvalid]: {
    key: 'ERROR_SSO_PROVIDER_INVALID',
  },
  [ApiErrors.ProviderInvalid]: {
    key: 'ERROR_PROVIDER_INVALID',
  },
  [ApiErrors.ProviderDeleteFailed]: {
    key: 'ERROR_PROVIDER_DELETE_FAILED',
  },
  [ApiErrors.ServerConfigExists]: {
    key: 'ERROR_SERVER_CONFIG_EXISTS',
  },
  [ApiErrors.CdnNotReady]: {
    key: 'ERROR_CDN_NOT_READY',
  },
  [ApiErrors.CdnProviderGenericInit]: {
    key: 'ERROR_CDN_PROVIDER_GENERIC_INIT',
  },
  [ApiErrors.CdnGetProviderList]: {
    key: 'ERROR_CDN_GET_PROVIDER_LIST',
  },
  [ApiErrors.ServerInit]: {
    key: 'ERROR_SERVER_INIT',
  },
  [ApiErrors.ServerUseridSet]: {
    key: 'ERROR_SERVER_USERID_SET',
  },
  [ApiErrors.DomainInvalidConfigName]: {
    key: 'ERROR_DOMAIN_INVALID_CONFIG_NAME',
  },
  [ApiErrors.DomainRestrictedServerName]: {
    key: 'ERROR_DOMAIN_RESTRICTED_SERVER_NAME',
  },
  [ApiErrors.DomainConfigNameInUse]: {
    key: 'ERROR_DOMAIN_CONFIG_NAME_IN_USE',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.ServerNotReady]: {
    key: 'ERROR_SERVER_NOT_READY',
  },
  [ApiErrors.ServerInvalidConfigValue]: {
    key: 'ERROR_SERVER_INVALID_CONFIG_VALUE',
  },
  [ApiErrors.ServerAnalyticsProfileInvalid]: {
    key: 'ERROR_SERVER_ANALYTICS_PROFILE_INVALID',
  },
  [ApiErrors.ServerCdnProviderInvalid]: {
    key: 'ERROR_SERVER_CDN_PROVIDER_INVALID',
  },
  [ApiErrors.ServerConfigFailure]: {
    key: 'ERROR_SERVER_CONFIG_FAILURE',
  },
  [ApiErrors.ServerConfigStore]: {
    key: 'ERROR_SERVER_CONFIG_STORE',
  },
  [ApiErrors.ServerConfigMissing]: {
    key: 'ERROR_SERVER_CONFIG_MISSING',
  },
  [ApiErrors.ServerDeleteFailed]: {
    key: 'ERROR_SERVER_DELETE_FAILED',
  },
  [ApiErrors.ServerConfigInvalid]: {
    key: 'ERROR_SERVER_CONFIG_INVALID',
  },
  [ApiErrors.ServerUserConfigsGet]: {
    key: 'ERROR_SERVER_USER_CONFIGS_GET',
  },
  [ApiErrors.ServerDeletedNoop]: {
    key: 'ERROR_SERVER_DELETED_NOOP',
  },
  [ApiErrors.ServerFolderExistsCreate]: {
    key: 'ERROR_SERVER_FOLDER_EXISTS_CREATE',
  },
  [ApiErrors.ServerInitStorage]: {
    key: 'ERROR_SERVER_INIT_STORAGE',
  },
  [ApiErrors.ServerPathGet]: {
    key: 'ERROR_SERVER_PATH_GET',
  },
  [ApiErrors.StorageAuth]: {
    key: 'ERROR_STORAGE_AUTH',
  },
  [ApiErrors.StorageInvalidResult]: {
    key: 'ERROR_STORAGE_INVALID_RESULT',
  },
  [ApiErrors.ServerLockedNoop]: {
    key: 'ERROR_SERVER_LOCKED_NOOP',
  },
  [ApiErrors.ServerCdnDeliveryFailed]: {
    key: 'ERROR_SERVER_CDN_DELIVERY_FAILED',
  },
  [ApiErrors.ServerCdnDeliveryFailedInternal]: {
    key: 'ERROR_SERVER_CDN_DELIVERY_FAILED_INTERNAL',
  },
  [ApiErrors.EventIdInvalid]: {
    key: 'ERROR_EVENT_ID_INVALID',
  },
  [ApiErrors.AccountClosed]: {
    key: 'ERROR_ACCOUNT_CLOSED',
  },
  [ApiErrors.AccountLocked]: {
    key: 'ERROR_ACCOUNT_LOCKED',
  },
  [ApiErrors.AccountSuspended]: {
    key: 'ERROR_ACCOUNT_SUSPENDED',
  },
  [ApiErrors.AccountDmca]: {
    key: 'ERROR_ACCOUNT_DMCA',
  },
  [ApiErrors.LogInit]: {
    key: 'ERROR_LOG_INIT',
  },
  [ApiErrors.LogStartInvalid]: {
    key: 'ERROR_LOG_START_INVALID',
  },
  [ApiErrors.LogEndInvalid]: {
    key: 'ERROR_LOG_END_INVALID',
  },
  [ApiErrors.LogIntervalFailed]: {
    key: 'ERROR_LOG_INTERVAL_FAILED',
  },
  [ApiErrors.LogMaxDaysOverflow]: {
    key: 'ERROR_LOG_MAX_DAYS_OVERFLOW',
  },
  [ApiErrors.LogConstMissing]: {
    key: 'ERROR_LOG_CONST_MISSING',
  },
  [ApiErrors.LogFormatInvalid]: {
    key: 'ERROR_LOG_FORMAT_INVALID',
  },
  [ApiErrors.StatsInitDatetime]: {
    key: 'ERROR_STATS_INIT_DATETIME',
  },
  [ApiErrors.StatsQueryFailed]: {
    key: 'ERROR_STATS_QUERY_FAILED',
  },
  [ApiErrors.StatsIntervalFailed]: {
    key: 'ERROR_STATS_INTERVAL_FAILED',
  },
  [ApiErrors.StatsMaxDaysOverflow]: {
    key: 'ERROR_STATS_MAX_DAYS_OVERFLOW',
  },
  [ApiErrors.StatsStartInvalid]: {
    key: 'ERROR_STATS_START_INVALID',
  },
  [ApiErrors.StatsEndInvalid]: {
    key: 'ERROR_STATS_END_INVALID',
  },
  [ApiErrors.EventGetNotFound]: {
    key: 'ERROR_EVENT_GET_NOT_FOUND',
  },
  [ApiErrors.EventFailedToAck]: {
    key: 'ERROR_EVENT_FAILED_TO_ACK',
  },
  [ApiErrors.FlushFailedToFlushAfterCreation]: {
    key: 'ERROR_FLUSH_FAILED_TO_FLUSH_AFTER_CREATION',
  },
  [ApiErrors.FlushFailedToWebhookAfterCreation]: {
    key: 'ERROR_FLUSH_FAILED_TO_WEBHOOK_AFTER_CREATION',
  },
  [ApiErrors.FlushFailedBeforeDelete]: {
    key: 'ERROR_FLUSH_FAILED_BEFORE_DELETE',
  },
  [ApiErrors.ServerStorageSwitchAuthFailed]: {
    key: 'ERROR_SERVER_STORAGE_SWITCH_AUTH_FAILED',
  },
  [ApiErrors.ServerConfigOrigInvalid]: {
    key: 'ERROR_SERVER_CONFIG_ORIG_INVALID',
  },
  [ApiErrors.FlushFailedAfterUpdate]: {
    key: 'ERROR_FLUSH_FAILED_AFTER_UPDATE',
  },
  [ApiErrors.ServerSwitchStorageFolderExistsCreate]: {
    key: 'ERROR_SERVER_SWITCH_STORAGE_FOLDER_EXISTS_CREATE',
  },
  [ApiErrors.ServerInvalidConfigExpires]: {
    key: 'ERROR_SERVER_INVALID_CONFIG_EXPIRES',
  },
  [ApiErrors.StoreConfigStore]: {
    key: 'ERROR_STORE_CONFIG_STORE',
  },
  [ApiErrors.ServerConfigErrorPath]: {
    key: 'ERROR_SERVER_CONFIG_ERROR_PATH',
  },
  [ApiErrors.UserPhoneCountryInvalid]: {
    key: 'ERROR_USER_PHONE_COUNTRY_INVALID',
  },
  [ApiErrors.UserChangeDisable2factorFirst]: {
    key: 'ERROR_USER_CHANGE_DISABLE_2FACTOR_FIRST',
  },
  [ApiErrors.UserPhoneFullInvalid]: {
    key: 'ERROR_USER_PHONE_FULL_INVALID',
  },
  [ApiErrors.TwoFactorAlreadyAdded]: {
    key: 'ERROR_2FACTOR_ALREADY_ADDED',
  },
  [ApiErrors.TwoFactorNoPhoneAdded]: {
    key: 'ERROR_2FACTOR_NO_PHONE_ADDED',
  },
  [ApiErrors.TwoFactorRemovalFailed]: {
    key: 'ERROR_2FACTOR_REMOVAL_FAILED',
  },
  [ApiErrors.TwoFactorVerificationNotEnabled]: {
    key: 'ERROR_2FACTOR_VERIFICATION_NOT_ENABLED',
  },
  [ApiErrors.TwoFactorVerifyUpdateFailed]: {
    key: 'ERROR_2FACTOR_VERIFY_UPDATE_FAILED',
  },
  [ApiErrors.TwoFactorNotEnabled]: {
    key: 'ERROR_2FACTOR_NOT_ENABLED',
  },
  [ApiErrors.TwoFactorInvalidToken]: {
    key: 'ERROR_2FACTOR_INVALID_TOKEN',
  },
  [ApiErrors.TwoFactorTokenFailed]: {
    key: 'ERROR_2FACTOR_TOKEN_FAILED',
  },
  [ApiErrors.AuthorizationScopeIncorrect]: {
    key: 'ERROR_AUTHORIZATION_SCOPE_INCORRECT',
  },
  [ApiErrors.BillingPlanInvalid]: {
    key: 'ERROR_BILLING_PLAN_INVALID',
  },
  [ApiErrors.BillingBillingInit]: {
    key: 'ERROR_BILLING_BILLING_INIT',
  },
  [ApiErrors.BillingTokenInvalid]: {
    key: 'ERROR_BILLING_TOKEN_INVALID',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.SubscriptionExists]: {
    key: 'ERROR_SUBSCRIPTION_EXISTS',
  },
  [ApiErrors.SubscriptionCreateFailed]: {
    key: 'ERROR_SUBSCRIPTION_CREATE_FAILED',
  },
  [ApiErrors.SubscriptionStateUnknown]: {
    key: 'ERROR_SUBSCRIPTION_STATE_UNKNOWN',
  },
  [ApiErrors.SubscriptionUpdateFailed]: {
    key: 'ERROR_SUBSCRIPTION_UPDATE_FAILED',
  },
  [ApiErrors.SubscriptionCancelMissing]: {
    key: 'ERROR_SUBSCRIPTION_CANCEL_MISSING',
  },
  [ApiErrors.SubscriptionCancelFailed]: {
    key: 'ERROR_SUBSCRIPTION_CANCEL_FAILED',
  },
  [ApiErrors.SubscriptionResetFailed]: {
    key: 'ERROR_SUBSCRIPTION_RESET_FAILED',
  },
  [ApiErrors.SubscriptionDetailsFailed]: {
    key: 'ERROR_SUBSCRIPTION_DETAILS_FAILED',
  },
  [ApiErrors.SubscriptionUpdateEmailFailed]: {
    key: 'ERROR_SUBSCRIPTION_UPDATE_EMAIL_FAILED',
  },
  [ApiErrors.SubscriptionAccountCloseCancelFailed]: {
    key: 'ERROR_SUBSCRIPTION_ACCOUNT_CLOSE_CANCEL_FAILED',
  },
  [ApiErrors.WebhookSignatureInvalidStripe]: {
    key: 'ERROR_WEBHOOK_SIGNATURE_INVALID_STRIPE',
  },
  [ApiErrors.WebhookSignatureInvalidStripeParts]: {
    key: 'ERROR_WEBHOOK_SIGNATURE_INVALID_STRIPE_PARTS',
  },
  [ApiErrors.WebhookSignatureInvalidStripeHmac]: {
    key: 'ERROR_WEBHOOK_SIGNATURE_INVALID_STRIPE_HMAC',
  },
  [ApiErrors.WebhookEnqueueFailedStripe]: {
    key: 'ERROR_WEBHOOK_ENQUEUE_FAILED_STRIPE',
  },
  [ApiErrors.SubscriptionOnlyTestModeAction]: {
    key: 'ERROR_SUBSCRIPTION_ONLY_TEST_MODE_ACTION',
  },
  [ApiErrors.PasswordCodeInvalid]: {
    key: 'ERROR_PASSWORD_CODE_INVALID',
  },
  [ApiErrors.PasswordCodeNotFoundOrExpired]: {
    key: 'ERROR_PASSWORD_CODE_NOT_FOUND_OR_EXPIRED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.PasswordCodeExpired]: {
    key: 'ERROR_PASSWORD_CODE_EXPIRED',
  },
  [ApiErrors.PasswordCodeUserMismatch]: {
    key: 'ERROR_PASSWORD_CODE_USER_MISMATCH',
  },
  [ApiErrors.PasswordCodeUserNotFound]: {
    key: 'ERROR_PASSWORD_CODE_USER_NOT_FOUND',
  },
  [ApiErrors.PasswordDontMatch]: {
    key: 'ERROR_PASSWORD_DONT_MATCH',
  },
  [ApiErrors.PasswordEncryptionFailed]: {
    key: 'ERROR_PASSWORD_ENCRYPTION_FAILED',
  },
  [ApiErrors.PasswordCommitFailed]: {
    key: 'ERROR_PASSWORD_COMMIT_FAILED',
  },
  [ApiErrors.PasswordGetJwtFailed]: {
    key: 'ERROR_PASSWORD_GET_JWT_FAILED',
  },
  [ApiErrors.PasswordAccountLockedNoChange]: {
    key: 'ERROR_PASSWORD_ACCOUNT_LOCKED_NO_CHANGE',
  },
  [ApiErrors.BillingPlanFree]: {
    key: 'ERROR_BILLING_PLAN_FREE',
  },
  [ApiErrors.ServerDisableAllFailed]: {
    key: 'ERROR_SERVER_DISABLE_ALL_FAILED',
  },
  [ApiErrors.AppStatusInit]: {
    key: 'ERROR_APP_STATUS_INIT',
  },
  [ApiErrors.AppStatusInvalidChecks]: {
    key: 'ERROR_APP_STATUS_INVALID_CHECKS',
  },
  [ApiErrors.AppStatusInvalidLastactive]: {
    key: 'ERROR_APP_STATUS_INVALID_LASTACTIVE',
  },
  [ApiErrors.SsoProviderUnknown]: {
    key: 'ERROR_SSO_PROVIDER_UNKNOWN',
  },
  [ApiErrors.SsoInvalidInput]: {
    key: 'ERROR_SSO_INVALID_INPUT',
  },
  [ApiErrors.ServerInvalidFilterMode]: {
    key: 'ERROR_SERVER_INVALID_FILTER_MODE',
  },
  [ApiErrors.ServerInvalidFilter]: {
    key: 'ERROR_SERVER_INVALID_FILTER',
  },
  [ApiErrors.ServerInvalidPassword]: {
    key: 'ERROR_SERVER_INVALID_PASSWORD',
  },
  [ApiErrors.ServerInvalidOption]: {
    key: 'ERROR_SERVER_INVALID_OPTION',
  },
  [ApiErrors.ServerNoChangesFound]: {
    key: 'ERROR_SERVER_NO_CHANGES_FOUND',
  },
  [ApiErrors.DomainsGenericInit]: {
    key: 'ERROR_DOMAINS_GENERIC_INIT',
  },
  [ApiErrors.DomainsProviderUseridSet]: {
    key: 'ERROR_DOMAINS_PROVIDER_USERID_SET',
  },
  [ApiErrors.DomainsNotReady]: {
    key: 'ERROR_DOMAINS_NOT_READY',
  },
  [ApiErrors.DomainsProviderUserGet]: {
    key: 'ERROR_DOMAINS_PROVIDER_USER_GET',
  },
  [ApiErrors.DomainsProviderInvalid]: {
    key: 'ERROR_DOMAINS_PROVIDER_INVALID',
  },
  [ApiErrors.DomainsGetProviderDetails]: {
    key: 'ERROR_DOMAINS_GET_PROVIDER_DETAILS',
  },
  [ApiErrors.AppsGenericInit]: {
    key: 'ERROR_APPS_GENERIC_INIT',
  },
  [ApiErrors.Oauth2SsoDenied]: {
    key: 'ERROR_OAUTH2_SSO_DENIED',
  },
  [ApiErrors.AppsProviderInvalidName]: {
    key: 'ERROR_APPS_PROVIDER_INVALID_NAME',
  },
  [ApiErrors.AppsInvalidConfigValue]: {
    key: 'ERROR_APPS_INVALID_CONFIG_VALUE',
  },
  [ApiErrors.AppsInvalidConfigType]: {
    key: 'ERROR_APPS_INVALID_CONFIG_TYPE',
  },
  [ApiErrors.DomainsProviderInit]: {
    key: 'ERROR_DOMAINS_PROVIDER_INIT',
  },
  [ApiErrors.DomainsConfigMissing]: {
    key: 'ERROR_DOMAINS_CONFIG_MISSING',
  },
  [ApiErrors.DomainsConfigFailure]: {
    key: 'ERROR_DOMAINS_CONFIG_FAILURE',
  },
  [ApiErrors.DomainsConfigStore]: {
    key: 'ERROR_DOMAINS_CONFIG_STORE',
  },
  [ApiErrors.DomainsDeleteFailed]: {
    key: 'ERROR_DOMAINS_DELETE_FAILED',
  },
  [ApiErrors.DomainsUserConfigsGet]: {
    key: 'ERROR_DOMAINS_USER_CONFIGS_GET',
  },
  [ApiErrors.DomainsProvidersGetList]: {
    key: 'ERROR_DOMAINS_PROVIDERS_GET_LIST',
  },
  [ApiErrors.DomainsBlacklistedName]: {
    key: 'ERROR_DOMAINS_BLACKLISTED_NAME',
  },
  [ApiErrors.DomainsInvalidName]: {
    key: 'ERROR_DOMAINS_INVALID_NAME',
  },
  [ApiErrors.DomainsConfigExists]: {
    key: 'ERROR_DOMAINS_CONFIG_EXISTS',
  },
  [ApiErrors.DomainsProviderDnsMissing]: {
    key: 'ERROR_DOMAINS_PROVIDER_DNS_MISSING',
  },
  [ApiErrors.DomainsRegistryCheckFailed]: {
    key: 'ERROR_DOMAINS_REGISTRY_CHECK_FAILED',
  },
  [ApiErrors.DomainsUnregRequiresDns]: {
    key: 'ERROR_DOMAINS_UNREG_REQUIRES_DNS',
  },
  [ApiErrors.DomainsProviderRegistryMissing]: {
    key: 'ERROR_DOMAINS_PROVIDER_REGISTRY_MISSING',
  },
  [ApiErrors.DomainsRegAlready]: {
    key: 'ERROR_DOMAINS_REG_ALREADY',
  },
  [ApiErrors.DomainsAutorenewInvalid]: {
    key: 'ERROR_DOMAINS_AUTORENEW_INVALID',
  },
  [ApiErrors.DomainsRegisterFailed]: {
    key: 'ERROR_DOMAINS_REGISTER_FAILED',
  },
  [ApiErrors.DomainsOrphanedDomain]: {
    key: 'ERROR_DOMAINS_ORPHANED_DOMAIN',
  },
  [ApiErrors.DomainsDeleteRegRejected]: {
    key: 'ERROR_DOMAINS_DELETE_REG_REJECTED',
  },
  [ApiErrors.DomainsZoneCreate]: {
    key: 'ERROR_DOMAINS_ZONE_CREATE',
  },
  [ApiErrors.DomainsZoneDelete]: {
    key: 'ERROR_DOMAINS_ZONE_DELETE',
  },
  [ApiErrors.DomainsZoneGet]: {
    key: 'ERROR_DOMAINS_ZONE_GET',
  },
  [ApiErrors.DomainsZoneMissingNs]: {
    key: 'ERROR_DOMAINS_ZONE_MISSING_NS',
  },
  [ApiErrors.DomainsNotPurchasable]: {
    key: 'ERROR_DOMAINS_NOT_PURCHASABLE',
  },
  [ApiErrors.DomainsZoneExists]: {
    key: 'ERROR_DOMAINS_ZONE_EXISTS',
  },
  [ApiErrors.DomainsProviderDisabled]: {
    key: 'ERROR_DOMAINS_PROVIDER_DISABLED',
  },
  [ApiErrors.DomainsConfigInvalid]: {
    key: 'ERROR_DOMAINS_CONFIG_INVALID',
  },
  [ApiErrors.BindingInvalidVanityName]: {
    key: 'ERROR_BINDING_INVALID_VANITY_NAME',
  },
  [ApiErrors.BindingInvalidOriginName]: {
    key: 'ERROR_BINDING_INVALID_ORIGIN_NAME',
  },
  [ApiErrors.BindingInvalidOriginDomain]: {
    key: 'ERROR_BINDING_INVALID_ORIGIN_DOMAIN',
  },
  [ApiErrors.BindingOriginVanityMatch]: {
    key: 'ERROR_BINDING_ORIGIN_VANITY_MATCH',
  },
  [ApiErrors.BindingOriginVanityFqdn]: {
    key: 'ERROR_BINDING_ORIGIN_VANITY_FQDN',
  },
  [ApiErrors.BindingOriginOriginFqdn]: {
    key: 'ERROR_BINDING_ORIGIN_ORIGIN_FQDN',
  },
  [ApiErrors.BindingServerOriginUnusable]: {
    key: 'ERROR_BINDING_SERVER_ORIGIN_UNUSABLE',
  },
  [ApiErrors.BindingConfigFailure]: {
    key: 'ERROR_BINDING_CONFIG_FAILURE',
  },
  [ApiErrors.BindingConfigStore]: {
    key: 'ERROR_BINDING_CONFIG_STORE',
  },
  [ApiErrors.BindingConfigExists]: {
    key: 'ERROR_BINDING_CONFIG_EXISTS',
  },
  [ApiErrors.DnsRecordCreate]: {
    key: 'ERROR_DNS_RECORD_CREATE',
  },
  [ApiErrors.BindingConfigMissing]: {
    key: 'ERROR_BINDING_CONFIG_MISSING',
  },
  [ApiErrors.BindingConfigInvalidType]: {
    key: 'ERROR_BINDING_CONFIG_INVALID_TYPE',
  },
  [ApiErrors.DnsRecordDelete]: {
    key: 'ERROR_DNS_RECORD_DELETE',
  },
  [ApiErrors.DnsRecordExternalCreate]: {
    key: 'ERROR_DNS_RECORD_EXTERNAL_CREATE',
  },
  [ApiErrors.BindingGetList]: {
    key: 'ERROR_BINDING_GET_LIST',
  },
  [ApiErrors.BindingLookup]: {
    key: 'ERROR_BINDING_LOOKUP',
  },
  [ApiErrors.BindingServerLoad]: {
    key: 'ERROR_BINDING_SERVER_LOAD',
  },
  [ApiErrors.BindingSslStatus]: {
    key: 'ERROR_BINDING_SSL_STATUS',
  },
  [ApiErrors.DomainActivate]: {
    key: 'ERROR_DOMAIN_ACTIVATE',
  },
  [ApiErrors.ServerFlushConfig]: {
    key: 'ERROR_SERVER_FLUSH_CONFIG',
  },
  [ApiErrors.DomainDnsNotInternal]: {
    key: 'ERROR_DOMAIN_DNS_NOT_INTERNAL',
  },
  [ApiErrors.DomainDnsNotSupported]: {
    key: 'ERROR_DOMAIN_DNS_NOT_SUPPORTED',
  },
  [ApiErrors.DomainDnsFilterInvalidType]: {
    key: 'ERROR_DOMAIN_DNS_FILTER_INVALID_TYPE',
  },
  [ApiErrors.DomainDnsFilterInvalidName]: {
    key: 'ERROR_DOMAIN_DNS_FILTER_INVALID_NAME',
  },
  [ApiErrors.DomainDnsInvalidType]: {
    key: 'ERROR_DOMAIN_DNS_INVALID_TYPE',
  },
  [ApiErrors.DomainDnsInvalidName]: {
    key: 'ERROR_DOMAIN_DNS_INVALID_NAME',
  },
  [ApiErrors.DomainDnsInvalidContent]: {
    key: 'ERROR_DOMAIN_DNS_INVALID_CONTENT',
  },
  [ApiErrors.DomainDnsInvalidTtl]: {
    key: 'ERROR_DOMAIN_DNS_INVALID_TTL',
  },
  [ApiErrors.DomainDnsInvalidPriority]: {
    key: 'ERROR_DOMAIN_DNS_INVALID_PRIORITY',
  },
  [ApiErrors.DomainDnsPriorityNotSupported]: {
    key: 'ERROR_DOMAIN_DNS_PRIORITY_NOT_SUPPORTED',
  },
  [ApiErrors.DomainDnsRecordLocked]: {
    key: 'ERROR_DOMAIN_DNS_RECORD_LOCKED',
  },
  [ApiErrors.DomainsPurchaseFailed]: {
    key: 'ERROR_DOMAINS_PURCHASE_FAILED',
  },
  [ApiErrors.StripeRefundFailed]: {
    key: 'ERROR_STRIPE_REFUND_FAILED',
  },
  [ApiErrors.UserFraudRisk]: {
    key: 'ERROR_USER_FRAUD_RISK',
  },
  [ApiErrors.BillingFeatureServerPasswords]: {
    key: 'ERROR_BILLING_FEATURE_SERVER_PASSWORDS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.BillingFeatureCustomErrors]: {
    key: 'ERROR_BILLING_FEATURE_CUSTOM_ERRORS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.BillingFeatureRawLogs]: {
    key: 'ERROR_BILLING_FEATURE_RAW_LOGS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },

  [ApiErrors.BillingLimitsCustomDomains]: {
    key: 'ERROR_BILLING_LIMITS_CUSTOM_DOMAINS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.BillingLimitsSslBindings]: {
    key: 'ERROR_BILLING_LIMITS_SSL_BINDINGS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.BillingLimitsServers]: {
    key: 'ERROR_BILLING_LIMITS_SERVERS',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.DomainDnsQueueInsert]: {
    key: 'ERROR_DOMAIN_DNS_QUEUE_INSERT',
  },
  [ApiErrors.DomainBindingInsert]: {
    key: 'ERROR_DOMAIN_BINDING_INSERT',
  },
  [ApiErrors.EnhanceCalm]: {
    key: 'ERROR_ENHANCE_CALM',
    flowAction: ErrorFlowActions.NoAction,
    friendlyMessage: 'Slow down a bit! Please try again in a moment.',
  },
  [ApiErrors.ServerSwitchStorageInitFailed]: {
    key: 'ERROR_SERVER_SWITCH_STORAGE_INIT_FAILED',
  },
  [ApiErrors.ServerSwitchStorageSetFailed]: {
    key: 'ERROR_SERVER_SWITCH_STORAGE_SET_FAILED',
  },
  [ApiErrors.ServerInvalidStorageBranch]: {
    key: 'ERROR_SERVER_INVALID_STORAGE_BRANCH',
  },
  [ApiErrors.ServerInvalidStorageRoot]: {
    key: 'ERROR_SERVER_INVALID_STORAGE_ROOT',
  },
  [ApiErrors.StorageReposGet]: {
    key: 'ERROR_STORAGE_REPOS_GET',
  },
  [ApiErrors.StorageBranchesGet]: {
    key: 'ERROR_STORAGE_BRANCHES_GET',
  },
  [ApiErrors.StorageBranchNotSet]: {
    key: 'ERROR_STORAGE_BRANCH_NOT_SET',
  },
  [ApiErrors.StorageRepoNotSet]: {
    key: 'ERROR_STORAGE_REPO_NOT_SET',
  },
  [ApiErrors.UserInit]: {
    key: 'ERROR_USER_INIT',
  },
  [ApiErrors.StoreFailed]: {
    key: 'ERROR_STORE_FAILED',
  },
  [ApiErrors.DomainConfigStore]: {
    key: 'ERROR_DOMAIN_CONFIG_STORE',
  },
  [ApiErrors.DomainInvalidConfig]: {
    key: 'ERROR_DOMAIN_INVALID_CONFIG',
  },
  [ApiErrors.DomainInvalidAutorenewConfig]: {
    key: 'ERROR_DOMAIN_INVALID_AUTORENEW_CONFIG',
  },
  [ApiErrors.DomainConfigUpdateFailure]: {
    key: 'ERROR_DOMAIN_CONFIG_UPDATE_FAILURE',
  },
  [ApiErrors.ServerRedirectPrimaryInvalid]: {
    key: 'ERROR_SERVER_REDIRECT_PRIMARY_INVALID',
  },
  [ApiErrors.UserTosInvalid]: {
    key: 'ERROR_USER_TOS_INVALID',
  },
  [ApiErrors.UserTosDecline]: {
    key: 'ERROR_USER_TOS_DECLINE',
  },
  [ApiErrors.UserNothingToCreate]: {
    key: 'ERROR_USER_NOTHING_TO_CREATE',
  },
  [ApiErrors.FlushFailedBeforeUndelete]: {
    key: 'ERROR_FLUSH_FAILED_BEFORE_UNDELETE',
  },
  [ApiErrors.ServerUndeleteFailed]: {
    key: 'ERROR_SERVER_UNDELETE_FAILED',
  },
  [ApiErrors.CustomerUpdateFailed]: {
    key: 'ERROR_CUSTOMER_UPDATE_FAILED',
  },
  [ApiErrors.ServerInvalidStorageNotVcs]: {
    key: 'ERROR_SERVER_INVALID_STORAGE_NOT_VCS',
  },
  [ApiErrors.CustomerGetFailed]: {
    key: 'ERROR_CUSTOMER_GET_FAILED',
  },
  [ApiErrors.CustomerGetCorrupted]: {
    key: 'ERROR_CUSTOMER_GET_CORRUPTED',
  },
  [ApiErrors.PlanInit]: {
    key: 'ERROR_PLAN_INIT',
  },
  [ApiErrors.PaymentUpdateFailed]: {
    key: 'ERROR_PAYMENT_UPDATE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.NotACustomer]: {
    key: 'ERROR_NOT_A_CUSTOMER',
  },
  [ApiErrors.CustomerNoInvoicesFound]: {
    key: 'ERROR_CUSTOMER_NO_INVOICES_FOUND',
  },
  [ApiErrors.DeleteAllStorageProviders]: {
    key: 'ERROR_DELETE_ALL_STORAGE_PROVIDERS',
  },
  [ApiErrors.DeleteAStorageProvider]: {
    key: 'ERROR_DELETE_A_STORAGE_PROVIDER',
  },
  [ApiErrors.AssetInvalidScheme]: {
    key: 'ERROR_ASSET_INVALID_SCHEME',
  },
  [ApiErrors.AssetInvalidMetadata]: {
    key: 'ERROR_ASSET_INVALID_METADATA',
  },
  [ApiErrors.AssetsInsertFailure]: {
    key: 'ERROR_ASSETS_INSERT_FAILURE',
  },
  [ApiErrors.AssetUploadMissing]: {
    key: 'ERROR_ASSET_UPLOAD_MISSING',
  },
  [ApiErrors.AssetImageOutputSetFailure]: {
    key: 'ERROR_ASSET_IMAGE_OUTPUT_SET_FAILURE',
  },
  [ApiErrors.AssetLoadImageFailure]: {
    key: 'ERROR_ASSET_LOAD_IMAGE_FAILURE',
  },
  [ApiErrors.AssetMissingFile]: {
    key: 'ERROR_ASSET_MISSING_FILE',
  },
  [ApiErrors.AssetsFailedToStore]: {
    key: 'ERROR_ASSETS_FAILED_TO_STORE',
  },
  [ApiErrors.AssetsUploadFailed]: {
    key: 'ERROR_ASSETS_UPLOAD_FAILED',
  },
  [ApiErrors.AssetsInvalidId]: {
    key: 'ERROR_ASSETS_INVALID_ID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.AssetDeleteFailure]: {
    key: 'ERROR_ASSET_DELETE_FAILURE',
  },
  [ApiErrors.AssetsGetFailed]: {
    key: 'ERROR_ASSETS_GET_FAILED',
  },
  [ApiErrors.StoragePairNotLoggedIn]: {
    key: 'ERROR_STORAGE_PAIR_NOT_LOGGED_IN',
  },
  [ApiErrors.CodePassEncryptFailure]: {
    key: 'ERROR_CODE_PASS_ENCRYPT_FAILURE',
  },
  [ApiErrors.AssetsRotateFail]: {
    key: 'ERROR_ASSETS_ROTATE_FAIL',
  },
  [ApiErrors.AssetsCropFail]: {
    key: 'ERROR_ASSETS_CROP_FAIL',
  },
  [ApiErrors.AssetsResizeFail]: {
    key: 'ERROR_ASSETS_RESIZE_FAIL',
  },
  [ApiErrors.AssetOutput]: {
    key: 'ERROR_ASSET_OUTPUT',
  },
  [ApiErrors.AssetInvalidType]: {
    key: 'ERROR_ASSET_INVALID_TYPE',
  },
  [ApiErrors.RequestExpiresInvalid]: {
    key: 'ERROR_REQUEST_EXPIRES_INVALID',
  },
  [ApiErrors.ServerInvalidPath]: {
    key: 'ERROR_SERVER_INVALID_PATH',
  },
  [ApiErrors.ServerNodeFiltered]: {
    key: 'ERROR_SERVER_NODE_FILTERED',
  },
  [ApiErrors.ServerUnsupportedContent]: {
    key: 'ERROR_SERVER_UNSUPPORTED_CONTENT',
  },
  [ApiErrors.VirusFound]: {
    key: 'ERROR_VIRUS_FOUND',
  },
  [ApiErrors.FileTooBig]: {
    key: 'ERROR_FILE_TOO_BIG',
  },
  [ApiErrors.InvalidUrlForConversion]: {
    key: 'ERROR_INVALID_URL_FOR_CONVERSION',
  },
  [ApiErrors.ConversionSizeTooSmall]: {
    key: 'ERROR_CONVERSION_SIZE_TOO_SMALL',
  },
  [ApiErrors.TransferFailed]: {
    key: 'ERROR_TRANSFER_FAILED',
  },
  [ApiErrors.ImageAssetProcessing]: {
    key: 'ERROR_IMAGE_ASSET_PROCESSING',
  },
  [ApiErrors.AssetImageDisabled]: {
    key: 'ERROR_ASSET_IMAGE_DISABLED',
  },
  [ApiErrors.ImageResizeDisabled]: {
    key: 'ERROR_IMAGE_RESIZE_DISABLED',
  },
  [ApiErrors.ServerHtmlRenderDenied]: {
    key: 'ERROR_SERVER_HTML_RENDER_DENIED',
  },
  [ApiErrors.ServerIndexFilesDenied]: {
    key: 'ERROR_SERVER_INDEX_FILES_DENIED',
  },
  [ApiErrors.UploadObjectInit]: {
    key: 'ERROR_UPLOAD_OBJECT_INIT',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadFailedToInsert]: {
    key: 'ERROR_UPLOAD_FAILED_TO_INSERT',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadTooLargeForAcct]: {
    key: 'ERROR_UPLOAD_TOO_LARGE_FOR_ACCT',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadTooManyChunksForAcct]: {
    key: 'ERROR_UPLOAD_TOO_MANY_CHUNKS_FOR_ACCT',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadTooManySessions]: {
    key: 'ERROR_UPLOAD_TOO_MANY_SESSIONS',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkSizeSmall]: {
    key: 'ERROR_UPLOAD_CHUNK_SIZE_SMALL',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkSizeBig]: {
    key: 'ERROR_UPLOAD_CHUNK_SIZE_BIG',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadIdInvalid]: {
    key: 'ERROR_UPLOAD_ID_INVALID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadIdNotFound]: {
    key: 'ERROR_UPLOAD_ID_NOT_FOUND',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadDeleteFailed]: {
    key: 'ERROR_UPLOAD_DELETE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkSize]: {
    key: 'ERROR_UPLOAD_CHUNK_SIZE',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkHashFailed]: {
    key: 'ERROR_UPLOAD_CHUNK_HASH_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkStoreFailed]: {
    key: 'ERROR_UPLOAD_CHUNK_STORE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkOrderInvalid]: {
    key: 'ERROR_UPLOAD_CHUNK_ORDER_INVALID',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadDeleteChunkFailed]: {
    key: 'ERROR_UPLOAD_DELETE_CHUNK_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UserCreate]: {
    key: 'ERROR_USER_CREATE',
  },
  [ApiErrors.BillingCreateFreeCustomer]: {
    key: 'ERROR_BILLING_CREATE_FREE_CUSTOMER',
  },
  [ApiErrors.CheckUserInsert]: {
    key: 'ERROR_CHECK_USER_INSERT',
  },
  [ApiErrors.UploadSetStateFailed]: {
    key: 'ERROR_UPLOAD_SET_STATE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadNoChunks]: {
    key: 'ERROR_UPLOAD_NO_CHUNKS',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadAssembleMissingChunks]: {
    key: 'ERROR_UPLOAD_ASSEMBLE_MISSING_CHUNKS',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadAssembleEnqueueFailed]: {
    key: 'ERROR_UPLOAD_ASSEMBLE_ENQUEUE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.BillingCustomerMissing]: {
    key: 'ERROR_BILLING_CUSTOMER_MISSING',
  },
  [ApiErrors.UserTrialEndFailed]: {
    key: 'ERROR_USER_TRIAL_END_FAILED',
  },
  [ApiErrors.OauthFallthrough]: {
    key: 'ERROR_OAUTH_FALLTHROUGH',
  },
  [ApiErrors.CookiesMissing]: {
    key: 'ERROR_COOKIES_MISSING',
  },
  [ApiErrors.WebhookStripeBody]: {
    key: 'ERROR_WEBHOOK_STRIPE_BODY',
  },
  [ApiErrors.CreateCheckoutSession]: {
    key: 'ERROR_CREATE_CHECKOUT_SESSION',
  },
  [ApiErrors.InvalidUrl]: {
    key: 'ERROR_INVALID_URL',
  },
  [ApiErrors.VaultFailure]: {
    key: 'ERROR_VAULT_FAILURE',
  },
  [ApiErrors.OrgDomainInvalid]: {
    key: 'ERROR_ORG_DOMAIN_INVALID',
  },
  [ApiErrors.OrgCreate]: {
    key: 'ERROR_ORG_CREATE',
  },
  [ApiErrors.OrgConfigInvalid]: {
    key: 'ERROR_ORG_CONFIG_INVALID',
  },
  [ApiErrors.OrgNotAuthorized]: {
    key: 'ERROR_ORG_NOT_AUTHORIZED',
    flowAction: ErrorFlowActions.Toast,
  },
  [ApiErrors.OrgNothingToUpdate]: {
    key: 'ERROR_ORG_NOTHING_TO_UPDATE',
  },
  [ApiErrors.OrgCommitChanges]: {
    key: 'ERROR_ORG_COMMIT_CHANGES',
  },
  [ApiErrors.OrgDomainMismatch]: {
    key: 'ERROR_ORG_DOMAIN_MISMATCH',
  },
  [ApiErrors.SubscriptionOrgCloseCancelFailed]: {
    key: 'ERROR_SUBSCRIPTION_ORG_CLOSE_CANCEL_FAILED',
  },
  [ApiErrors.WsInvalidConfigName]: {
    key: 'ERROR_WS_INVALID_CONFIG_NAME',
  },
  [ApiErrors.WorkspaceConfigNameInUse]: {
    key: 'ERROR_WORKSPACE_CONFIG_NAME_IN_USE',
  },
  [ApiErrors.OrgConfigNameInUse]: {
    key: 'ERROR_ORG_CONFIG_NAME_IN_USE',
  },
  [ApiErrors.WsConfigInvalid]: {
    key: 'ERROR_WS_CONFIG_INVALID',
  },
  [ApiErrors.WsCreate]: {
    key: 'ERROR_WS_CREATE',
  },
  [ApiErrors.WsCommitChanges]: {
    key: 'ERROR_WS_COMMIT_CHANGES',
  },
  [ApiErrors.WsNothingToUpdate]: {
    key: 'ERROR_WS_NOTHING_TO_UPDATE',
  },
  [ApiErrors.WsNameMismatch]: {
    key: 'ERROR_WS_NAME_MISMATCH',
  },
  [ApiErrors.ShareInvalidConfigName]: {
    key: 'ERROR_SHARE_INVALID_CONFIG_NAME',
  },
  [ApiErrors.ShareConfigNameInUse]: {
    key: 'ERROR_SHARE_CONFIG_NAME_IN_USE',
  },
  [ApiErrors.ShareConfigInvalid]: {
    key: 'ERROR_SHARE_CONFIG_INVALID',
    flowAction: ErrorFlowActions.Dialog,
  },
  [ApiErrors.ShareCreate]: {
    key: 'ERROR_SHARE_CREATE',
  },
  [ApiErrors.ShareCommitChanges]: {
    key: 'ERROR_SHARE_COMMIT_CHANGES',
  },
  [ApiErrors.ShareInvalidExpires]: {
    key: 'ERROR_SHARE_INVALID_EXPIRES',
  },
  [ApiErrors.ShareNothingToUpdate]: {
    key: 'ERROR_SHARE_NOTHING_TO_UPDATE',
  },
  [ApiErrors.ShareNameMismatch]: {
    key: 'ERROR_SHARE_NAME_MISMATCH',
  },
  [ApiErrors.ProfileInvalidName]: {
    key: 'ERROR_PROFILE_INVALID_NAME',
  },
  [ApiErrors.ProfileNotFound]: {
    key: 'ERROR_PROFILE_NOT_FOUND',
  },
  [ApiErrors.ProfileClosed]: {
    key: 'ERROR_PROFILE_CLOSED',
  },
  [ApiErrors.ProfileLocked]: {
    key: 'ERROR_PROFILE_LOCKED',
  },
  [ApiErrors.ProfileSuspended]: {
    key: 'ERROR_PROFILE_SUSPENDED',
  },
  [ApiErrors.ProfileDmca]: {
    key: 'ERROR_PROFILE_DMCA',
  },
  [ApiErrors.ProfileInsufficientPermissions]: {
    key: 'ERROR_PROFILE_INSUFFICIENT_PERMISSIONS',
  },
  [ApiErrors.UserNotValidated]: {
    key: 'ERROR_USER_NOT_VALIDATED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.MembershipExists]: {
    key: 'ERROR_MEMBERSHIP_EXISTS',
  },
  [ApiErrors.MembershipPermissionDenied]: {
    key: 'ERROR_MEMBERSHIP_PERMISSION_DENIED',
  },
  [ApiErrors.MembershipInviteInvalidEmail]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_EMAIL',
  },
  [ApiErrors.ExpiresInvalid]: {
    key: 'ERROR_EXPIRES_INVALID',
  },
  [ApiErrors.MembershipCommitChanges]: {
    key: 'ERROR_MEMBERSHIP_COMMIT_CHANGES',
  },
  [ApiErrors.MembershipUserSame]: {
    key: 'ERROR_MEMBERSHIP_USER_SAME',
  },
  [ApiErrors.MembershipNotExists]: {
    key: 'ERROR_MEMBERSHIP_NOT_EXISTS',
  },
  [ApiErrors.MembershipDeleteFailed]: {
    key: 'ERROR_MEMBERSHIP_DELETE_FAILED',
  },
  [ApiErrors.ProfileTypeInvalid]: {
    key: 'ERROR_PROFILE_TYPE_INVALID',
  },
  [ApiErrors.ProfileNotLoaded]: {
    key: 'ERROR_PROFILE_NOT_LOADED',
  },
  [ApiErrors.ProfilePermissionValidationError]: {
    key: 'ERROR_PROFILE_PERMISSION_VALIDATION_ERROR',
  },
  [ApiErrors.MembershipNoChanges]: {
    key: 'ERROR_MEMBERSHIP_NO_CHANGES',
  },
  [ApiErrors.MembershipOwnerSame]: {
    key: 'ERROR_MEMBERSHIP_OWNER_SAME',
  },
  [ApiErrors.MembershipDomainDisabled]: {
    key: 'ERROR_MEMBERSHIP_DOMAIN_DISABLED',
  },
  [ApiErrors.MembershipDomainDenied]: {
    key: 'ERROR_MEMBERSHIP_DOMAIN_DENIED',
  },
  [ApiErrors.MembershipInviteInvalidId]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_ID',
  },
  [ApiErrors.MembershipJoinDenied]: {
    key: 'ERROR_MEMBERSHIP_JOIN_DENIED',
  },
  [ApiErrors.UpdatePermissionDenied]: {
    key: 'ERROR_UPDATE_PERMISSION_DENIED',
  },
  [ApiErrors.MembershipRemovalOnlyOwner]: {
    key: 'ERROR_MEMBERSHIP_REMOVAL_ONLY_OWNER',
  },
  [ApiErrors.ChildCreationFailed]: {
    key: 'ERROR_CHILD_CREATION_FAILED',
  },
  [ApiErrors.AuthThrottleKeyInvalid]: {
    key: 'ERROR_AUTH_THROTTLE_KEY_INVALID',
  },
  [ApiErrors.InvalidProfileIdInput]: {
    key: 'ERROR_INVALID_PROFILE_ID_INPUT',
  },
  [ApiErrors.MembershipInviteAddFailed]: {
    key: 'ERROR_MEMBERSHIP_INVITE_ADD_FAILED',
  },
  [ApiErrors.MembershipInviteInvalidKey]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_KEY',
  },
  [ApiErrors.MembershipInviteInvalidAction]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_ACTION',
  },
  [ApiErrors.MembershipInviteUseFailed]: {
    key: 'ERROR_MEMBERSHIP_INVITE_USE_FAILED',
  },
  [ApiErrors.MembershipInviteInvalidMessage]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_MESSAGE',
  },
  [ApiErrors.MembershipInviteGetInvitationFailed]: {
    key: 'ERROR_MEMBERSHIP_INVITE_GET_INVITATION_FAILED',
  },
  [ApiErrors.MembershipInviteInvalidState]: {
    key: 'ERROR_MEMBERSHIP_INVITE_INVALID_STATE',
  },
  [ApiErrors.UploadOrgDenied]: {
    key: 'ERROR_UPLOAD_ORG_DENIED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadGetChunksFailed]: {
    key: 'ERROR_UPLOAD_GET_CHUNKS_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadChunkMissing]: {
    key: 'ERROR_UPLOAD_CHUNK_MISSING',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.OrgNotSubscribed]: {
    key: 'ERROR_ORG_NOT_SUBSCRIBED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadAssembleFailed]: {
    key: 'ERROR_UPLOAD_ASSEMBLE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadTooLarge]: {
    key: 'ERROR_UPLOAD_TOO_LARGE',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadCompleteFailed]: {
    key: 'ERROR_UPLOAD_COMPLETE_FAILED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.PaymentIntentCreateFailed]: {
    key: 'ERROR_PAYMENT_INTENT_CREATE_FAILED',
  },
  [ApiErrors.OrgCloseVaultFailed]: {
    key: 'ERROR_ORG_CLOSE_VAULT_FAILED',
  },
  [ApiErrors.VaultCreate]: {
    key: 'ERROR_VAULT_CREATE',
  },
  [ApiErrors.InstanceCreate]: {
    key: 'ERROR_INSTANCE_CREATE',
  },
  [ApiErrors.BillingPaymentRequired]: {
    key: 'ERROR_BILLING_PAYMENT_REQUIRED',
  },
  [ApiErrors.BillingPaymentMethodInvalid]: {
    key: 'ERROR_BILLING_PAYMENT_METHOD_INVALID',
  },
  [ApiErrors.MgmtConsoleMissingUser]: {
    key: 'ERROR_MGMT_CONSOLE_MISSING_USER',
  },
  [ApiErrors.MgmtConsoleLoginFailure]: {
    key: 'ERROR_MGMT_CONSOLE_LOGIN_FAILURE',
  },
  [ApiErrors.InvalidTransformation]: {
    key: 'ERROR_INVALID_TRANSFORMATION',
  },
  [ApiErrors.UnableToRender]: {
    key: 'ERROR_UNABLE_TO_RENDER',
  },
  [ApiErrors.UnableToRetrievePreview]: {
    key: 'ERROR_UNABLE_TO_RETRIEVE_PREVIEW',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.UploadFileNotCompleted]: {
    key: 'UPLOAD_FILE_NOT_COMPLETED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.NotAllowedUpdateShare]: {
    key: 'ERROR_NOT_ALLOWED_UPDATE_SHARE',
  },
  [ApiErrors.CreateShareFieldType]: {
    key: 'CREATE_SHARE_FIELD_TYPE',
    flowAction: ErrorFlowActions.Dialog,
  },
  [ApiErrors.CreateShareFieldMissing]: {
    key: 'CREATE_SHARE_FIELD_MISSING',
    flowAction: ErrorFlowActions.Dialog,
  },
  [ApiErrors.UnexpectedError]: {
    key: 'ERROR_UNEXPECTED_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.RequestAborted]: {
    key: 'REQUEST_ABORTED',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.ShareCannotViewMembers]: {
    key: 'SHARE_CANNOT_VIEW_MEMBERS',
    flowAction: ErrorFlowActions.NoAction,
  },
  [ApiErrors.CorsError]: {
    key: 'CORS_ERROR',
    flowAction: ErrorFlowActions.PageError,
    allowRetry: true,
  },
  [ApiErrors.UnknownError]: {
    key: 'UNKNOWN_ERROR',
    flowAction: ErrorFlowActions.Toast,
  },
};
