import {t} from '@lingui/macro';
import type {ShareMemberListInvitationItem} from 'fast-sdk/src/api/share/consts';
import AddEmailTextarea from 'interface/base/AddEmailTextarea';
import {Loading} from 'interface/common/Loading';
import {ModalSection} from 'interface/common/Modal';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {ProfileMemberList} from 'interface/stacks/app/ProfileMemberList';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import type {Share} from 'store/slices/shared/types';
import * as user from 'store/slices/user';
import {ShareMembersView} from './ShareModal';

interface ShareSettingsMembersProps {
  share: Share;
  emails: Array<string>;
  setEmails: (emails: Array<string>) => void;
  canAddRecipients?: boolean;
  currentView: ShareMembersView;
  invitations: Array<ShareMemberListInvitationItem>;
  loadingInvitations: boolean;
  fetchInvitations: () => void;
}

export default function ShareMembers({
  share,
  emails,
  setEmails,
  canAddRecipients,
  currentView,
  invitations,
  loadingInvitations,
  fetchInvitations,
}: ShareSettingsMembersProps) {
  const {subdomain} = useSubDomain();

  const currentUser = useSelector(user.selectors.getUserDetails);

  const members = [...(share?.members ?? [])];

  const hasMembersOrInvites = members?.length > 0 || invitations?.length > 0;

  return (
    <View style={styles.root}>
      {canAddRecipients && (
        <View>
          <ModalSection title={t`Send invitations to`} />
          <AddEmailTextarea
            emails={emails}
            setEmails={setEmails}
            customRootStyle={{marginBottom: 10}}
          />
        </View>
      )}
      {currentView === ShareMembersView.ShareSettings && (
        <View style={styles.membersContainer}>
          <ModalSection title={t`Members`} />
          {loadingInvitations ? (
            <Loading size="small" text={t`Loading Recipients...`} centered />
          ) : (
            <View
              style={[
                styles.membersList,
                hasMembersOrInvites && {minHeight: 120},
              ]}>
              <ProfileMemberList
                members={members}
                invites={invitations}
                profileId={share?.id}
                subdomain={subdomain}
                reload={fetchInvitations}
                type="share"
                emptyMessage={t`No recipients found`}
                showDotsMenu
                selfId={currentUser?.id}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    gap: 21,
  },
  membersContainer: {
    flexDirection: 'column',
    zIndex: -1,
  },
  membersList: {
    maxHeight: '33vh',
    flex: 1,
  },
});
