import {useFlag} from '@unleash/proxy-client-react';
import {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {RecyclerListView} from 'recyclerlistview/web';
import {FileListItem} from './FileListItem';
import {FileSummaryTabs} from './FolderHeaderSummary';
import {useFileList} from './hooks/useFileList';

import type {MultiplayerState} from 'interface/multiplayer/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FileListItemLayout} from './FileListItem';

export interface FileListProps {
  view: string[];
  items: {[id: string]: FilesItem};
  layout?: FileListItemLayout;
  root?: string[];
  share?: Share;
  shareView?: boolean;
  shareds?: boolean;
  archivedShares?: boolean;
  recents?: boolean;
  onOpen?: (item: FilesItem) => void;
  renderEmpty?: () => JSX.Element;
  renderLoading?: () => JSX.Element;
  multiplayer?: MultiplayerState;
  options?: {
    hideFolders?: boolean;
    hideFiles?: boolean;
    hideShares?: boolean;
    hideGutter?: boolean;
    linkParent?: boolean;
    disableOpen?: boolean;
    disableDrag?: boolean;
    disableFocus?: boolean;
    disableSelect?: boolean;
    disableDetails?: boolean;
    disableActions?: boolean;
    disablePullToRefresh?: boolean;
    overrideWorkspaceId?: string;
    useWindowScroll?: boolean;
    externalSelection?: string[];
  };
}

export function FileList(props: FileListProps) {
  const list = useFileList(props);
  const hasCursors = useFlag('multiplayer-cursors');

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const renderItem = useCallback(
    (type: number, id: string, index: number, ext: typeof list.extended) => {
      if (list.refResize.current) {
        const selected =
          (list.hasSelect || list.hasExternalSelection) &&
          ext.selection &&
          ext.selection.includes(id);
        const dragging =
          list.hasDrag && ext.dragging && ext.dragging.includes(id);
        const focused = list.hasFocus && ext.focused === id;
        const members = ext.members;
        const item = ext.items[id];
        const prevId = list.itemList[index - 1];
        const nextId = list.itemList[index + 1];
        const isPrevSelected =
          prevId &&
          (ext.selection?.includes(prevId) ||
            !!members?.find(e => e.selection?.includes(prevId)));
        const isNextSelected =
          nextId &&
          (ext.selection?.includes(nextId) ||
            !!members?.find(e => e.selection?.includes(nextId)));
        return (
          <FileListItem
            item={item}
            layout={type}
            workspaceId={list.workspaceTarget}
            members={members}
            highlighted={ext.highlight.id === id}
            focused={focused}
            selected={selected}
            dragging={dragging}
            isPremium={list.isPremium}
            isPrevSelected={isPrevSelected}
            isNextSelected={isNextSelected}
            linkParent={props.options?.linkParent}
            multiSelect={ext.selection.length > 0}
            showSummary={ext.summaryType === FileSummaryTabs.Summary}
            disableDetails={props.options?.disableDetails}
            disableActions={props.options?.disableActions}
            tooltipPos={index < 2 ? 'left' : 'top'}
            shareView={props.shareView}
            share={props.share}
            shareds={props.shareds}
            recents={props.recents}
            archivedShares={props.archivedShares}
            onOpen={list.hasOpen ? list.open : list.noop}
            onFocus={list.hasFocus ? list.focus : list.noop}
            onSelect={list.hasSelect ? list.select : list.open}
          />
        );
      }
    },
    [
      list.items,
      list.focus,
      list.select,
      list.itemList,
      list.listWidth,
      list.hasGridView,
      list.workspaceTarget,
    ],
  );

  if (list.isLoading && props.renderLoading) return props.renderLoading();
  if (list.isEmpty && props.renderEmpty) return props.renderEmpty();

  return !list.isEmpty ? (
    <View style={styles.expand}>
      <View
        testID="file-list-view"
        style={[styles.expand, {paddingLeft: list.listGutter}]}
        removeClippedSubviews={false}
        onPointerLeave={hasCursors ? list.syncMouseIdle : undefined}
        onPointerMove={hasCursors ? list.syncMouse : undefined}
        onLayout={list.resize}>
        {(props.options?.useWindowScroll ||
          (list.listWidth > 0 && list.listHeight > 0)) && (
          <RecyclerListView
            ref={list.refList}
            key={`file-list-view-${list.listWidth}`}
            onScroll={list.syncScrollPos}
            rowRenderer={renderItem}
            renderFooter={renderFooter}
            layoutProvider={list.listLayout}
            scrollThrottle={16}
            style={{scrollbarWidth: 'none'}}
            dataProvider={list.listData}
            extendedState={list.extended}
            useWindowScroll={props.options?.useWindowScroll}
            renderAheadOffset={screen.height / 2}
          />
        )}
      </View>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  expand: {
    flex: 1,
  },
  footer: {
    height: 10,
  },
});
