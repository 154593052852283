import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {ColumnManagerInfoKeys, PageType, type Store} from './types';

const generateColumnsInitialValue = (initialValue: boolean | null) => ({
  [ColumnManagerInfoKeys.WorkspaceBrowser]: initialValue,
  [ColumnManagerInfoKeys.FoldersAndFiles]: initialValue,
  [ColumnManagerInfoKeys.InfoPanel]: initialValue,
  [ColumnManagerInfoKeys.ShareConfiguration]: initialValue,
  [ColumnManagerInfoKeys.AIChat]: initialValue,
});

export const initialState: Store = {
  loaded: false,
  active: false,
  revision: 0,
  domainOrigin: 'fast.io',
  stopRedirect: false,
  currentJoinInvitation: undefined,
  forceLogout: false,
  redirectToRenewSubscription: false,
  renewSubscriptionSubdomain: undefined,
  userPermission: undefined,
  currentPage: {
    type: PageType.Unknown,
    workspace: '',
    shareName: '',
    shareId: '',
    folderId: '',
    fileId: '',
    settings: '',
    pageName: '',
  },
  columnInfo: generateColumnsInitialValue(true),
  columnInfoOverride: generateColumnsInitialValue(null),
  columnVisibility: generateColumnsInitialValue(true),
  endpointCallsLimit: {},
  redirectTo: undefined,
};
const sliceName = 'app';

const resetColumnManagerInfoTransform = {
  in: state => state,
  out: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        columnInfoOverride: initialState.columnInfoOverride,
      };
    }
    return state;
  },
};

export default createSlice({name: sliceName, reducers, initialState});
export {resetColumnManagerInfoTransform, selectors};
