import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Requests} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {GO_SUBDOMAIN} from 'utils/fast/constants';

export const Logout = () => {
  const navigate = useNavigate();
  const {subdomain} = useSubDomain();
  const {logout} = useLogout();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const navigateToSignIn = () =>
    navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`, {replace: true});

  useEffect(() => {
    if (isLoggedIn) {
      if (subdomain === GO_SUBDOMAIN) {
        logout({navigateTo: NavigateTo.NONE, showLoader: false});
        Requests.setAuthToken(null);
      }
    }
  }, [subdomain, isLoggedIn]);

  return (
    <View style={styles.root}>
      <View style={styles.wrapper}>
        <Text selectable style={styles.title}>
          <Trans>Successfully signed out</Trans>
        </Text>
        <Text selectable style={styles.content}>
          <Trans>
            You signed out of your Fast.io account. All private session data has
            been cleared for your security.
          </Trans>
        </Text>
        <View style={styles.buttonContainer}>
          <Button
            type="Primary"
            label={t`Sign back in`}
            customTextStyle={styles.buttonText}
            customRootStyle={styles.button}
            onPress={navigateToSignIn}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    maxWidth: 500,
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
    marginBottom: 14,
  },
  content: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    textAlign: 'center',
    marginBottom: 42,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    paddingVertical: 12,
    height: 48,
    borderRadius: 5,
    width: '100%',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
