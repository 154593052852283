import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  items: {},
  membersMap: {},
};

export default createSlice({name: 'organization', reducers, initialState});
export {selectors};
