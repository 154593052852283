// @ts-ignore
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import {useHover} from 'interface/common/hooks/useHover';
import {useDragDropTarget} from 'interface/stacks/workspace/storage/hooks/useDragDropTarget';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {useRef} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {GestureResponderEvent} from 'react-native';

export interface FolderBreadcrumbsItemProps {
  id: string;
  name: string;
  root?: string;
  state: 'Default' | 'Hover' | 'Current';
  workspaceId?: string;
  members?: MultiplayerMember[];
  isLast?: boolean;
  isShared?: boolean;
  onPress: (event: GestureResponderEvent) => void;
  onPressRoot?: (event: GestureResponderEvent) => void;
}

export function FolderBreadcrumbsItem(props: FolderBreadcrumbsItemProps) {
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const ref = useRef<View>(null);
  const classes = {
    root: [
      styles.root,
      props.root && styles.rootBadge,
      (props.state === 'Hover' || isHover) && styles.rootHover,
    ],
  };

  const {uploader} = useWorkspaceContext();
  const {isDropping} = useDragDropTarget({
    ref,
    targetNs: 'workspace',
    targetId: props.root ? 'root' : props.id,
    targetName: props.name,
    instanceNs: 'workspace',
    instanceId: props.workspaceId,
    uploader,
  });

  const dots = props?.members
    ?.filter(member => member.currentItemId === props.id)
    ?.map(member => (
      <View
        key={member.clientId}
        style={[styles.dot, {backgroundColor: member.userColor}]}
      />
    ));

  return (
    <Pressable
      ref={!props.isLast ? ref : undefined}
      style={classes.root}
      onHoverIn={!props.isShared ? onHoverIn : undefined}
      onHoverOut={!props.isShared ? onHoverOut : undefined}
      onPress={!props.isShared ? props.onPress : undefined}
      disabled={props.isShared}>
      <View style={styles.nameInfo}>
        {props.root && (
          <WorkspaceBadge id={props.root} size={32} style={{borderRadius: 8}} />
        )}
        <Typography
          size="md"
          variant="regular"
          color={
            props.root || props.isLast
              ? theme.colors.neutral.$2Base
              : theme.colors.neutral.$5
          }>
          {props.name}
        </Typography>
        {!props.root && props.isLast && !props.isShared && (
          <Icon name={'lucide:chevron-down'} color={theme.colors.neutral.$7} />
        )}
        {props.root && (
          <Button
            onPress={props.onPressRoot}
            aria-label="Open Workspace Menu"
            variant="text"
            endEnhancer={
              <Icon
                name={'lucide:ellipsis'}
                color={theme.colors.neutral.$2Base}
              />
            }
            overrides={{
              Button: {
                style: {
                  opacity: 0.5,
                  paddingHorizontal: 6,
                },
              },
            }}
          />
        )}
      </View>
      <View
        style={[styles.membersPlaceholder, props.isLast && {marginLeft: -24}]}>
        <View style={styles.members}>
          {dots?.length > 0 ? dots : <View style={styles.dot} />}
        </View>
      </View>
      {isDropping && <View pointerEvents="none" style={styles.drop} />}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 8,
  },
  rootBadge: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  rootHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  nameInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  name: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$2Base,
  },
  nameHover: {
    fontWeight: '400',
  },
  nameDefault: {
    fontWeight: '400',
    color: theme.colors.neutral.$5,
  },
  info: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$2Base,
  },
  membersPlaceholder: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  members: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  dot: {
    display: 'flex',
    flexDirection: 'column',
    width: 7,
    height: 7,
    borderRadius: 99999,
  },
  shareIcon: {
    width: 20,
    height: 20,
  },
  drop: {
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: -1,
    left: -1,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.colors.brand.$4Base,
    overflow: 'visible',
  },
});
