import type {Member} from '../organization/consts';

export type StorageNamespace = 'workspace' | 'share' | 'quickshare';

export type StorageListResponse = {
  nodes: {
    count: number;
    items: Array<StorageItem>;
  };
};

export type StorageSearchResponse = {
  files: {
    [nodeId: string]: {
      name: string;
      parent_id: string;
      type: 'folder' | 'file' | 'link';
    };
  };
};

export type StorageItemResponse = {
  node: StorageItem;
};

export type StorageRequestReadResponse = {
  token: string;
};

export type StorageJobResponse = {
  job: string | null;
};

export enum StorageItemType {
  Link = 'link',
  File = 'file',
  Folder = 'folder',
}

export type StorageItem = {
  // General data
  id: string;
  type: StorageItemType;
  name: string;
  parent: string;
  version: string;
  created: string;
  modified: string;
  restricted: boolean;
  locked: boolean;
  dmca: boolean;
  virus: {status: string; reason: string};
  // Optional file data
  size?: number;
  hash?: string;
  user?: string;
  users?: string[];
  mimetype?: string;
  mimecategory?: string;
  hash_type?: string;
  keyvalue?: Array<{[key: string]: unknown}>;
  deleted?: string;
  deleted_from?: string;
  expires?: string;
  closed?: boolean;
  previews: {
    thumbnail: FilePreview;
    image: FilePreview;
    hlsstream?: FilePreview;
    pdf?: FilePreview;
  };
  ai?: {
    attach: boolean;
    state: 'pending' | 'in_progress' | 'ready' | 'failed' | 'disabled';
  };
  summary?: null | {
    long: string;
    short: string;
    title: string;
  };
  file_attributes?: FileAttributes;
  target?: TargetType;
  target_id?: string;
  origin?: FileOrigin;
};

export type FileOrigin = {
  type: 'create' | 'upload' | 'copy' | 'unknown';
  creator: string;
  upload_session_id?: string;
};

export enum FilePreviewState {
  UNKNOWN = 'unknown',
  NOT_POSSIBLE = 'not possible',
  NOT_GENERATED = 'not generated',
  ERROR = 'error',
  IN_PROGRESS = 'in progress',
  READY = 'ready',
  EMPTY = 'empty',
}

export type FilePreview = {
  state: FilePreviewState;
};

export type FileExifMetadata = {
  FileType: string;
  MIMEType: string;
  Title: string;
  Subject: string;
  Author: string;
  Keywords: string;
  Template: string;
  ImageHeight: number;
  ImageWidth: number;
  LastModifiedBy: string;
  CreateDate: string;
  Rotation: number;
};

type FileAttributes = {
  exif_metadata: FileExifMetadata;
};

export enum FileTransformTypes {
  Image = 'image',
}

export enum FileTransformKeys {
  Size = 'size',
  OutputFormat = 'output-format',
}

export enum FileThumbnailSizes {
  /** Local, maps to "IconSmall" for the API */
  Tiny = 'IconTiny',
  Small = 'IconSmall',
  Medium = 'IconMedium',
  Preview = 'Preview',
}

export enum FileOutputFormats {
  Png = 'png',
}

export type FileTransformValues = FileOutputFormats | FileThumbnailSizes;

export enum PreviewType {
  THUMBNAIL = 'thumbnail',
  IMAGE = 'image',
  HLSSTREAM = 'hlsstream',
  PDF = 'pdf',
  SPREADSHEET = 'spreadsheet',
}

export type PreAuthorizeResponse = {
  downloadToken: string;
  path: string;
  primaryFilename: string;
};

export enum LinkType {
  Share = 'share',
}

export enum TargetType {
  Share = 'share',
}

export type QuickShareListResponse = {
  quickshares: QuickShareItem[];
};

export type QuickShareResponse = {
  quickshare: QuickShareItem;
};

export type QuickShareItem = {
  id: string;
  node: StorageItem;
  creator_uid: Member;
  limit_exceeded: boolean;
  expires: string;
  created: string;
};
