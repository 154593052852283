import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import useFetchShareDetails from 'interface/stacks/share/hooks/useFetchShareDetails';
import type {ShareFilesNavigation} from 'interface/stacks/share/hooks/useShareFilesNavigation';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {EntityType} from 'store/slices/activity/types';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';
import useSyncChatData from './useSyncChatData';
import useSyncStorageData from './useSyncStorageData';

interface UseSyncShareDataOptions {
  share: ShareItem;
  shareFilesNavigation: ShareFilesNavigation;
}

const useSyncShareData = ({
  share,
  shareFilesNavigation,
}: UseSyncShareDataOptions) => {
  const dispatch = useDispatch();

  const shareActivities =
    useSelector(activity.selectors.getShareActivities(share?.id))?.activities ??
    {};

  const shareDetailsActivity = shareActivities[ActivityTypes.DETAILS];

  const {fetchShareDetails} = useFetchShareDetails();

  const updateEntityLastClientUpdate = useCallback(
    (shareId: string, activityType: string) => {
      const lastClientUpdate = getCurrentUTCDateTime();
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [shareId],
          activities: [activityType],
          entityType: EntityType.Share,
          lastClientUpdate,
        }),
      );
    },
    [],
  );

  const onShareChange = useCallback(() => {
    if (share?.id) {
      updateEntityLastClientUpdate(share.id, ActivityTypes.DETAILS);
    }
  }, []);

  useEffect(() => {
    onShareChange();
  }, [share]);

  useEffect(() => {
    if (shareDetailsActivity) {
      const {lastServerUpdate, lastClientUpdate} = shareDetailsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchShareDetails(share.parent_workspace_folder, share.id);
      }
    }
  }, [shareDetailsActivity]);

  useSyncStorageData({
    namespace: 'share',
    profileId: share.id,
    profileName: share.id,
    folderKey: shareFilesNavigation.folderKey,
    activities: shareActivities,
  });

  useSyncChatData({
    namespace: 'share',
    profileId: share?.id,
    profileName: share?.custom_name,
    activities: shareActivities,
  });
};

export default useSyncShareData;
