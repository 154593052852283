import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as organization from 'store/slices/organization';

// Promise cache outside the hook to be shared across all instances
const fetchPromiseCache: Record<string, Promise<any>> = {};

export function useGetOrganizationDetailsCached(orgId: string) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const org = useSelector(organization.selectors.getOrganization(orgId));

  useEffect(() => {
    let mounted = true;

    const fetchOrganization = async () => {
      if (org || !orgId) return;

      try {
        setIsLoading(true);
        setError(null);

        if (!fetchPromiseCache[orgId]) {
          fetchPromiseCache[orgId] = api.organization
            .getOrganizationPublicDetails(orgId)
            .then(response => {
              dispatch(
                organization.default.actions.addOrganization({
                  orgId: orgId,
                  org: response.org,
                }),
              );
              return response;
            })
            .finally(() => {
              delete fetchPromiseCache[orgId];
            });
        }

        await fetchPromiseCache[orgId];
      } catch (err) {
        if (mounted) {
          setError(
            err instanceof Error
              ? err
              : new Error('Failed to fetch organization'),
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganization();

    return () => {
      mounted = false;
    };
  }, [orgId, org]);

  return {
    org,
    isLoading,
    error,
  };
}
