import {Loading} from 'interface/common/Loading';
import {useFocus} from 'interface/common/hooks/useFocus';
import {useHover} from 'interface/common/hooks/useHover';
import {useMouseDown} from 'interface/common/hooks/useMouseDown';
import {Fragment, forwardRef} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import Typography from '../Typography';
import {SIZES, TEXT_SIZES, TEXT_VARIANTS, VARIANTS} from './consts';
import {getButtonState} from './utils';

import type {PropsWithChildren} from 'react';
import type {GestureResponderEvent, MouseEvent, ViewStyle} from 'react-native';
import {SIZE, VARIANT} from './consts';
import type {ButtonOverrides} from './types';

type Props = {
  size?: keyof typeof SIZE;
  variant?: keyof typeof VARIANT;
  endEnhancer?: React.ReactNode | React.ComponentType<any>;
  startEnhancer?: React.ReactNode | React.ComponentType<any>;
  overrides?: ButtonOverrides;
  disabled?: boolean;
  loading?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  onHoverInFn?: (event: MouseEvent) => void;
  onHoverOutFn?: (event: MouseEvent) => void;
};

export const Button = forwardRef<View, PropsWithChildren<Props>>(
  (
    {
      children,
      size = SIZE.md,
      variant = VARIANT.primary,
      overrides,
      startEnhancer,
      endEnhancer,
      disabled,
      loading,
      onPress,
      onHoverInFn,
      onHoverOutFn,
    },
    ref,
  ) => {
    const {isHover: hovered, onHoverIn, onHoverOut} = useHover();
    const {mouseDown, onMouseDown, onMouseUp} = useMouseDown();
    const {focused, onFocus, onBlur} = useFocus();
    const state = getButtonState({
      hovered,
      focused,
      disabled,
    });

    const sizeStyles = SIZES[size];
    const variantStyles = VARIANTS[variant][state].light;

    const textProps = TEXT_SIZES[size];
    const textStyles = TEXT_VARIANTS[variant][state].light;

    const buttonStyles: ViewStyle = {
      ...variantStyles,
      ...sizeStyles,
      ...styles[state],
    };

    const handleHoverIn = (event: MouseEvent) => {
      onHoverInFn?.(event);
      onHoverIn();
    };

    const handleHoverOut = (event: MouseEvent) => {
      onHoverOutFn?.(event);
      onHoverOut();
    };

    return (
      <Pressable
        ref={ref}
        role="button"
        style={[styles.root, buttonStyles, overrides?.Button?.style]}
        onPress={loading || disabled ? undefined : onPress}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onFocus={() => !mouseDown && onFocus()}
        onBlur={onBlur}
        // @ts-ignore
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onPressOut={onMouseUp}
        disabled={disabled}>
        {loading ? (
          <Loading
            size="small"
            customStyle={overrides?.Loading?.style}
            customColor={overrides?.Loading?.style?.color}
          />
        ) : (
          <Fragment>
            {startEnhancer && (
              <View style={overrides?.StartEnhancer?.style}>
                {startEnhancer}
              </View>
            )}
            {typeof children === 'string' ? (
              <Typography
                variant={textProps.variant}
                size={textProps.size}
                overrides={[textStyles, overrides?.Text?.style]}>
                {children}
              </Typography>
            ) : (
              (children ?? null)
            )}
            {endEnhancer && (
              <View style={overrides?.EndEnhancer?.style}>{endEnhancer}</View>
            )}
          </Fragment>
        )}
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    gap: 6,
    // @ts-ignore
    outlineWidth: 0,
  },
  focus: {
    // @ts-ignore
    boxShadow: '0px 0px 0px 1px #fff inset, 0px 0px 0px 2px #002070',
  },
});
