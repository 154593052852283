import {api} from 'fast-sdk';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as activity from 'store/slices/activity';
import * as aichat from 'store/slices/aichat';

export const useFetchChatList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const fetchChatList = async () => {
    setIsLoading(true);
    if (!namespace || !instanceId || !instanceName) {
      return;
    }
    try {
      const response = await api.workspaceAichat.getChatList(
        namespace,
        instanceName,
      );
      if (response.result) {
        const chats = response.chats?.items ?? [];
        dispatch(
          aichat.default.actions.setChats({
            namespace,
            instanceId,
            chats: chats,
          }),
        );
        dispatch(
          activity.default.actions.updateAllAiChatActivity({
            namespace,
            instanceId,
          }),
        );
      }
    } catch (error) {
      dispatch(
        aichat.default.actions.setChats({namespace, instanceId, chats: []}),
      );
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, error, fetchChatList};
};
