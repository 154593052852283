import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import useDebounce from 'interface/common/hooks/useDebounce';
import {useRef} from 'react';
import {StyleSheet, Text, TextInput, View} from 'react-native';
import {useDispatch} from 'react-redux';
import workspace from 'store/slices/workspace';

const DEBOUNCE_SEARCH_TIME = 300;

export interface SearchInputProps {
  value: string;
  close: () => void;
  onChange: (text: string) => void;
  onSubmit: (text: string) => void;
  instanceId: string;
  instanceName: string;
  autoSearch?: boolean;
  firstItemRef?: React.RefObject<View>;
}

export function SearchInput(props: SearchInputProps) {
  const autoSearch = useDebounce(props.onSubmit, DEBOUNCE_SEARCH_TIME);
  const inputRef = useRef<TextInput>(null);
  const dispatch = useDispatch();
  return (
    <View style={styles.root}>
      <Icon name="lucide:search" size={20} color={theme.colors.neutral.$700} />
      <View style={styles.contents}>
        <TextInput
          ref={inputRef}
          style={[
            styles.input,
            {
              // @ts-ignore
              outline: 'none',
            },
          ]}
          autoFocus
          selectTextOnFocus
          value={props.value}
          placeholder={`Search ${props.instanceName} for folders and files`}
          placeholderTextColor={theme.colors.neutral.$400}
          onChangeText={text => {
            props.onChange(text);
            if (props.autoSearch && text.length >= 3) autoSearch(text);
          }}
          onSubmitEditing={() => {
            if (!props.value) return;
            props.onSubmit(props.value);
            dispatch(
              workspace.actions.logRecentSearch({
                id: props.instanceId,
                query: props.value,
                timestamp: Date.now(),
              }),
            );
            setTimeout(() => {
              // Focus first search result
              if (props.firstItemRef?.current) {
                props.firstItemRef.current.focus();
              } else {
                // Focus search input
                inputRef.current?.focus();
              }
            }, 50);
          }}
          onKeyPress={e => {
            if (
              (e.nativeEvent.key === 'k' &&
                (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey)) ||
              e.nativeEvent.key === 'Escape' ||
              (e.nativeEvent.key === 'Backspace' && props.value.length === 0)
            ) {
              props.close();
              return;
            }
          }}
        />
      </View>
      <View style={styles.kbd}>
        <Text style={styles.cmd}>{'⌘'}</Text>
        <Text style={styles.k}>{'K'}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 56,
    width: '100%',
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
  },
  contents: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 10,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
  },
  input: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22,
  },
  kbd: {
    flexDirection: 'row',
    minWidth: 32,
    paddingTop: 2,
    paddingLeft: 6,
    paddingBottom: 2,
    paddingRight: 6,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.colors.neutral.$0,
    boxShadow:
      '0px 1px 3px 0px rgba(28, 27, 34, 0.05), 0px 2px 8px 0px rgba(28, 27, 34, 0.05)',
  },
  cmd: {
    color: theme.colors.neutral.$500,
    textAlign: 'center',
    fontFamily: 'Roboto sans-serif',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
  },
  k: {
    color: theme.colors.neutral.$500,
    textAlign: 'center',
    marginTop: -1 /* fix alignment */,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
  },
});
