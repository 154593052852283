import {Trans} from '@lingui/macro';
import {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {getContrast} from 'utils/common/color';

import files from 'store/slices/files';

export interface WorkspaceFrameProps {
  mode: 'follow' | 'upload';
  color: string;
  name?: string;
  hideBadge?: boolean;
}

export function WorkspaceFrame(props: WorkspaceFrameProps) {
  const contrast = getContrast(props.color);
  const [badgeHidden, setBadgeHidden] = useState(false);
  const classes = {
    label: [
      styles.label,
      {backgroundColor: props.color},
      {transition: 'transform 0.1s ease-in'},
      (props.hideBadge || badgeHidden) && styles.labelHidden,
    ],
    labelText: [
      styles.labelText,
      {color: contrast === 'black' ? '#000' : '#fff'},
    ],
    outline: [styles.outline, {borderColor: props.color}],
  };

  // On any mouse click or scroll event, exit follow mode
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.mode !== 'follow') return;
    const exit = () => dispatch(files.actions.follow({id: null}));
    window.addEventListener('mouseup', exit);
    window.addEventListener('wheel', exit);
    return () => {
      window.removeEventListener('mouseup', exit);
      window.removeEventListener('wheel', exit);
    };
  }, [props.mode, dispatch]);

  return (
    <>
      <View
        style={styles.badge}
        pointerEvents="box-only"
        onPointerLeave={() => setBadgeHidden(false)}
        onPointerEnter={() => setBadgeHidden(true)}>
        {props.name && (
          <View style={classes.label}>
            <Text style={classes.labelText}>
              <Trans>{`Following ${props.name}`}</Trans>
            </Text>
          </View>
        )}
      </View>
      <View style={classes.outline} pointerEvents="none" />
    </>
  );
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    top: 12,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderStyle: 'solid',
    backgroundColor: '#000000',
    flexDirection: 'row',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 3,
  },
  labelHidden: {
    transform: [{translateY: -32}],
  },
  labelText: {
    color: '#000',
    textAlign: 'center',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 20,
  },
  outline: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderWidth: 5,
    borderRadius: 6,
  },
});
