import {t} from '@lingui/macro';
import theme from 'config/theme';
import {
  DEFAULT_INVITE_SHARE_MESSAGE,
  DEFAULT_INVITE_WORKSPACE_MESSAGE,
} from 'constants/invites';
import events from 'extensions/events';
import {Popup} from 'extensions/viewport/Popup';
import {PopupMenu} from 'extensions/viewport/PopupMenu';
import {api} from 'fast-sdk';
import React, {useCallback, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {ProfileMemberItemInvite} from './ProfileMemberItemInvite';
import type {ProfileInvite, ProfileMember} from './profile.types';

export interface ProfileMemberListInviteProps {
  type: 'workspace' | 'share';
  profileId: string;
  invite?: ProfileInvite;
  self?: ProfileMember;
  isLast?: boolean;
  reload?: () => void;
  subdomain?: string;
  showDotsMenu?: boolean;
  canUpdatePermissions?: boolean;
}

export function ProfileMemberListInvite(props: ProfileMemberListInviteProps) {
  const [hasMenu, setHasMenu] = useState(false);
  const [access, setAccess] = useState('viewer');

  const handleProfileAction = useCallback(
    async (command: string) => {
      switch (command) {
        case 'viewer':
        case 'editor':
        case 'admin':
        case 'owner':
          setAccess(command);
          break;
        case 'resend':
          if (props.type === 'workspace')
            await api.workspace.addMember(
              props.profileId,
              props.invite.invitee_email,
              'view',
              DEFAULT_INVITE_WORKSPACE_MESSAGE,
            );
          else if (props.type === 'share')
            await api.share.addShareMember(
              props.profileId,
              props.invite.invitee_email,
              'view',
              DEFAULT_INVITE_SHARE_MESSAGE,
            );
          break;
        case 'delete':
          if (props.type === 'workspace')
            await api.workspace.deleteWorkspaceInvitation(
              props.profileId,
              props.invite.id,
            );
          else if (props.type === 'share')
            await api.share.deleteShareInvitation(
              props.profileId,
              props.invite.id,
            );
          break;
      }
      props.reload?.();
      setHasMenu(false);
    },
    [props.profileId, props.invite, props.type, props.reload],
  );

  return (
    <View style={[styles.root, props.isLast && styles.rootLast]}>
      <View style={styles.profile}>
        <ProfileMemberItemInvite
          id={props.invite.id}
          email={props.invite.invitee_email}
        />
      </View>
      <View style={styles.status}>
        {props.invite.state && (
          <Text
            style={[
              styles.badge,
              props.invite.state === 'external' && styles.badgeExternal,
              props.invite.state === 'success' && styles.badgeSuccess,
            ]}>
            {`${props.invite.state.charAt(0).toUpperCase()}${props.invite.state.slice(1)}`}
          </Text>
        )}
      </View>
      <Popup
        triggerElement={
          <Pressable
            onPress={() => {
              if (!props.showDotsMenu && !props.canUpdatePermissions) {
                return;
              }
              // Hack to workaround closing other popups
              events.simulateKey('down', {
                key: 'Escape',
                code: 'Escape',
                original: null,
                flags: {},
              });
              setHasMenu(!hasMenu);
            }}
            style={e => [
              styles.menu,
              // @ts-ignore
              (e.hovered || hasMenu) && styles.menuActive,
            ]}>
            {props.showDotsMenu ? (
              <MCIcon
                name="dots-horizontal"
                size={20}
                color={theme.colors.neutral.$5}
              />
            ) : (
              <>
                <Text style={styles.menuLabel}>
                  {access.charAt(0).toUpperCase() + access.slice(1)}
                </Text>
                {props.canUpdatePermissions && (
                  <MCIcon
                    name="chevron-down"
                    size={18}
                    color={theme.colors.neutral.$5}
                  />
                )}
              </>
            )}
          </Pressable>
        }
        placement="bottom-end"
        isOpen={hasMenu}
        close={() => setHasMenu(false)}>
        <PopupMenu
          autoClose
          close={() => setHasMenu(false)}
          onSelectItem={handleProfileAction}
          menuItems={[
            {
              command: 'resend',
              text: t`Resend Invite`,
              icon: (
                <Icon name="email" size={20} color={theme.colors.neutral.$6} />
              ),
            },
            {
              command: 'divider',
              text: '-',
            },
            {
              command: 'delete',
              text:
                props.type === 'workspace'
                  ? t`Remove from Workspace`
                  : t`Remove from Share`,
              icon: (
                <MCIcon
                  name="account-remove"
                  size={20}
                  color={theme.colors.danger.$35Hover}
                />
              ),
              destructive: true,
            },
          ].filter(e => !!e)}
        />
      </Popup>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 14,
    height: 54,
    borderTopWidth: 1,
    borderColor: theme.colors.neutral.$12,
  },
  rootLast: {
    borderBottomWidth: 1,
  },
  profile: {
    flex: 1,
    height: 54,
    justifyContent: 'center',
  },
  status: {
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    paddingVertical: 1,
    paddingHorizontal: 8,
    borderRadius: 99,
    backgroundColor: theme.colors.neutral.$11,
    color: theme.colors.neutral.$2Base,
  },
  badgeExternal: {
    backgroundColor: theme.colors.caution.$5,
  },
  badgeSuccess: {
    backgroundColor: theme.colors.success.$6,
  },
  menuWrapper: {
    position: 'relative',
  },
  menu: {
    flexGrow: 0,
    minWidth: 36,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuActive: {
    backgroundColor: theme.colors.neutral.$13,
  },
  menuLabel: {
    fontSize: 14,
    color: theme.colors.neutral.$3,
    fontWeight: '400',
    lineHeight: 24,
    marginRight: 8,
  },
});
