import {LOCALHOST_DOMAINS} from 'fast-sdk/src/config';
import {useEffect, useState} from 'react';
import {
  getEnvironmentDomain,
  getEnvironmentProtocol,
} from 'utils/common/version';

export const getHostData = async () => {
  const domain = await getEnvironmentDomain();
  const protocol = await getEnvironmentProtocol();

  const domains = domain.split('.');

  let mainDomain = '';
  if (
    LOCALHOST_DOMAINS.some(domain => domain.includes(domain)) ||
    domains.length === 1
  ) {
    const [main] = domains.slice(-1);
    mainDomain = main;
  }
  const [top, second] = domains.reverse();
  mainDomain = [second, top].join('.');

  return {domain, protocol, mainDomain};
};

const useHost = () => {
  const [domain, setDomain] = useState('');
  const [mainDomain, setMainDomain] = useState('');
  const [protocol, setProtocol] = useState('');

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getHost = async () => {
      const {domain, protocol, mainDomain} = await getHostData();
      setDomain(domain);
      setProtocol(protocol);
      setMainDomain(mainDomain);
    };

    getHost().finally(() => setLoaded(true));
  }, []);

  return {domain, protocol, mainDomain, loaded};
};

export default useHost;
