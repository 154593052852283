import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useEffect, useRef, useState} from 'react';
import {
  TextInput as Input,
  type TextInputProps as InputProps,
  StyleSheet,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

interface TextInputProps {
  label?: string;
  value?: string;
  defaultValue?: string;
  placeHolder?: string;
  disabled?: boolean;
  customStyle?: TextStyle | Array<TextStyle>;
  setInputValue?: (inp: string) => void;
  setBlur?: (inp: boolean) => void;
  customProps?: InputProps;
  customRootStyle?: ViewStyle | Array<ViewStyle>;
  customLabelStyle?: TextStyle;
}

export function TextInput(props: TextInputProps) {
  const inputRef = useRef();
  const randomId = (Math.random() * 1000000).toString();
  const [focus, setFocus] = useState<boolean>(false);
  const [inputStyle, setInputStyle] = useState<{
    borderBottomWidth: number;
    backgroundColor: string;
  }>();
  const textColor = theme.colors.neutral.$2Base;

  useEffect(() => {
    if (props.value) {
      if (focus) {
        setInputStyle(styles.focusedInput);
      } else {
        setInputStyle(styles.blurInputWithContent);
      }
    } else {
      if (focus) {
        setInputStyle(styles.focusedInput);
      } else {
        setInputStyle(styles.blurInputWithContent);
      }
    }
  }, [props.value, focus]);

  const showCharacterCount = props.customProps?.maxLength;

  return (
    <View style={[styles.root, props.customRootStyle]}>
      {props.label && (
        <Typography
          size="xs"
          overrides={{...styles.label, ...props.customLabelStyle}}
          color={textColor}>
          {props.label}
        </Typography>
      )}
      <Input
        ref={inputRef}
        nativeID={randomId}
        style={[
          styles.input,
          {color: theme.colors.neutral.$2Base},
          inputStyle,
          props.customStyle,
        ]}
        value={props.value ? props.value : ''}
        defaultValue={props.defaultValue}
        placeholder={props.placeHolder}
        placeholderTextColor={theme.colors.neutral.$6}
        editable={!props.disabled}
        onChangeText={props.setInputValue}
        aria-label={props.label}
        {...props.customProps}
        onBlur={() => {
          setFocus(false);
          if (props.setBlur) props.setBlur(true);
        }}
        onFocus={() => {
          setFocus(true);
          if (props.setBlur) props.setBlur(false);
        }}
      />
      {showCharacterCount && (
        <Typography size="xs" overrides={{alignSelf: 'flex-end'}}>
          {props.value?.length ?? 0}/{props.customProps?.maxLength}
        </Typography>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
  },
  blurInput: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.brand.$4Base,
    backgroundColor: theme.colors.neutral.$13,
  },
  focusedInput: {
    outlineColor: 'transparent',
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.brand.$4Base,
    backgroundColor: theme.colors.neutral.$13,
  },
  blurInputWithContent: {
    borderBottomWidth: 0,
    backgroundColor: theme.colors.neutral.$13,
  },
  input: {
    padding: 10,
    outlineStyle: 'none',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  label: {
    marginBottom: 6,
  },
});
