import theme from 'config/theme';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import {StyleSheet, View} from 'react-native';
import {createInitials} from 'utils/common/data';
import Typography from '../Typography';
interface Props {
  userId: string;
}

export function UserChip({userId}: Props) {
  const {user} = useGetUserDetailsCached(userId);

  if (!user) {
    return null;
  }
  const name = `${user.first_name} ${user.last_name}`;
  const initials = createInitials(name);

  return (
    <View style={styles.container}>
      <MemberAvatar initials={initials} memberId={userId} size={-2} />
      <Typography size="xs" color={theme.colors.neutral.$2Base}>
        {name}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$11,
    paddingEnd: 8,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  image: {
    width: 16,
    height: 16,
  },
});
