import {api} from 'fast-sdk';
import {useGetNamespaceContextInModal} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as aichat from 'store/slices/aichat';

export const usePublishChat = () => {
  const dispatch = useDispatch();
  const {namespace, instanceId, instanceName} = useGetNamespaceContextInModal();
  const [isLoading, setIsLoading] = useState(false);

  const publishChat = async (chatId: string) => {
    setIsLoading(true);
    try {
      const publishChatResponse = await api.workspaceAichat.publishChat(
        namespace,
        instanceName,
        chatId,
      );
      if (publishChatResponse.result) {
        dispatch(
          aichat.default.actions.publishChat({namespace, instanceId, chatId}),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, publishChat};
};
