import theme from 'config/theme';
import {Loading} from 'interface/common/Loading';
import {type PropsWithChildren, forwardRef, useCallback, useState} from 'react';
import {
  type GestureResponderEvent,
  type MouseEvent,
  Pressable,
  type PressableProps,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

export type ButtonType =
  | 'Primary'
  | 'Secondary'
  | 'Secondary_thin'
  | 'Text'
  | 'Text_Secondary'
  | 'Success'
  | 'Caution'
  | 'Danger';

type FontStyle =
  | 'All Caps, Small'
  | 'All Caps, Medium'
  | 'All Caps, Large'
  | 'Normal, Small'
  | 'Normal, Medium'
  | 'Normal, Large'
  | '3XL';

export interface ButtonProps {
  type: ButtonType;
  fontStyle?: FontStyle;
  label?: string;
  disabled?: boolean;
  ariaLabel?: string;
  onPress: (e?: GestureResponderEvent) => void;
  loading?: boolean;
  rIcon?: JSX.Element;
  lIcon?: JSX.Element;
  customRootStyle?: ViewStyle | Array<ViewStyle>;
  customTextStyle?: TextStyle | Array<TextStyle>;
  customLoadingStyle?: ViewStyle;
  customLoadingColor?: string;
  customLeftIconStyle?: TextStyle;
  customRightIconStyle?: TextStyle;
  customProps?: PressableProps;
  onHoverIn?: (event: MouseEvent) => void;
  onHoverOut?: (event: MouseEvent) => void;
  singleLine?: boolean;
}

export const getBaseStyle = (fontStyle?: FontStyle) => {
  const button: ViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
  };

  const text = {
    fontWeight: '400',
  };

  switch (fontStyle) {
    case 'Normal, Small':
      return {
        button: {
          ...button,
          paddingVertical: 6,
          paddingHorizontal: 12,
          borderRadius: 3,
        },
        text: {
          ...text,
          fontSize: 12,
          lineHeight: 1.5,
        },
      };
    case 'Normal, Medium':
      return {
        button: {
          ...button,
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 4,
        },
        text: {
          ...text,
          fontSize: 13,
          lineHeight: 1.5,
        },
      };
    case 'Normal, Large':
      return {
        button: {
          ...button,
          paddingVertical: 12,
          paddingHorizontal: 14,
          borderRadius: 5,
        },
        text: {
          ...text,
          fontSize: 14,
          lineHeight: 1.5,
        },
      };
    case 'All Caps, Small':
      return {
        button: {
          ...button,
          paddingVertical: 6,
          paddingHorizontal: 12,
          borderRadius: 3,
        },
        text: {
          ...text,
          textTransform: 'uppercase',
          fontSize: 11,
          lineHeight: 1.5,
        },
      };
    case 'All Caps, Medium':
      return {
        button: {
          ...button,
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 4,
        },
        text: {
          ...text,
          textTransform: 'uppercase',
          fontSize: 12,
          lineHeight: 1.5,
        },
      };
    case 'All Caps, Large':
      return {
        button: {
          ...button,
          paddingVertical: 12,
          paddingHorizontal: 14,
          borderRadius: 5,
        },
        text: {
          ...text,
          textTransform: 'uppercase',
          fontSize: 13,
          lineHeight: 1.5,
        },
      };
    case '3XL':
      return {
        button: {
          ...button,
          paddingVertical: 12,
          paddingHorizontal: 14,
          borderRadius: 5,
        },
        text: {
          ...text,
          fontSize: 21,
          lineHeight: 1.6,
        },
      };
    default:
      return {
        button: {
          ...button,
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 4,
        },
        text: {
          ...text,
          fontSize: 13,
          lineHeight: 1.5,
        },
      };
  }
};

/** @deprecated Use `interface/base/Button` instead. */
export const Button = forwardRef<View, PropsWithChildren<ButtonProps>>(
  (props, ref) => {
    const [mouseDown, setMouseDown] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const getRootStyle = useCallback(
      (type: ButtonType) => {
        const rootStyle = [];
        switch (type) {
          case 'Primary':
            rootStyle.push(styles.primaryRoot);
            if (hovered) rootStyle.push(styles.primaryRootHover);
            if (focused) rootStyle.push(styles.primaryRootFocused);
            break;
          case 'Secondary':
            rootStyle.push(styles.secondaryRoot);
            if (hovered) rootStyle.push(styles.secondaryRootHover);
            if (focused) rootStyle.push(styles.secondaryRootFocused);
            break;
          case 'Secondary_thin':
            rootStyle.push(styles.secondaryThinRoot);
            if (hovered) rootStyle.push(styles.secondaryThinRootHover);
            if (focused) rootStyle.push(styles.secondaryThinRootFocused);
            break;
          case 'Text':
            rootStyle.push(styles.textRoot);
            if (hovered) rootStyle.push(styles.textRootHover);
            if (focused) rootStyle.push(styles.textRootFocused);
            break;
          case 'Text_Secondary':
            rootStyle.push(styles.textSecondaryRoot);
            if (hovered) rootStyle.push(styles.textSecondaryRootHover);
            if (focused) rootStyle.push(styles.textSecondaryRootFocused);
            break;
          case 'Success':
            rootStyle.push(styles.successRoot);
            if (hovered) rootStyle.push(styles.successRootHover);
            if (focused) rootStyle.push(styles.successRootFocused);
            break;
          case 'Caution':
            rootStyle.push(styles.cautionRoot);
            if (hovered) rootStyle.push(styles.cautionRootHover);
            if (focused) rootStyle.push(styles.cautionRootFocused);
            break;
          case 'Danger':
            rootStyle.push(styles.dangerRoot);
            if (hovered) rootStyle.push(styles.dangerRootHover);
            if (focused) rootStyle.push(styles.dangerRootFocused);
            break;
        }
        return rootStyle;
      },
      [hovered, focused],
    );

    const getTextStyle = useCallback(
      (type: ButtonType) => {
        const textStyle = [];
        switch (type) {
          case 'Primary':
            textStyle.push(styles.primaryText);
            break;
          case 'Secondary':
            textStyle.push(styles.secondaryText);
            if (hovered) textStyle.push(styles.secondaryTextHover);
            if (focused) textStyle.push(styles.secondaryTextFocused);
            break;
          case 'Secondary_thin':
            textStyle.push(styles.secondaryThinText);
            if (hovered) textStyle.push(styles.secondaryThinTextHover);
            if (focused) textStyle.push(styles.secondaryThinTextFocused);
            break;
          case 'Text':
            textStyle.push(styles.textText);
            if (hovered) textStyle.push(styles.textTextHover);
            if (focused) textStyle.push(styles.textTextFocused);
            break;
          case 'Text_Secondary':
            textStyle.push(styles.textSecondaryText);
            if (hovered) textStyle.push(styles.textSecondaryTextHover);
            break;
          case 'Success':
            textStyle.push(styles.successText);
            break;
          case 'Caution':
            textStyle.push(styles.cautionText);
            break;
          case 'Danger':
            textStyle.push(styles.dangerText);
            break;
        }

        return textStyle;
      },
      [hovered, focused],
    );

    return (
      <Pressable
        ref={ref}
        role="button"
        style={[
          [
            getBaseStyle(props.fontStyle).button,
            ...getRootStyle(props.type),
            styles.root,
          ],
          props.customRootStyle,
          props.disabled && styles.disabled,
        ]}
        // This attribute is just for web version and because of that we need
        // to ignore it in the typscript with the following command
        // @ts-ignore
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
        onPressOut={() => setMouseDown(false)}
        onPress={!props.loading && !props.disabled && props.onPress}
        disabled={props.disabled || props.loading}
        aria-label={props.ariaLabel}
        onHoverIn={e => {
          if (props.onHoverIn) {
            props.onHoverIn(e);
          }
          setHovered(true);
        }}
        onHoverOut={e => {
          if (props.onHoverOut) {
            props.onHoverOut(e);
          }
          setHovered(false);
        }}
        onFocus={() => !mouseDown && setFocused(true)}
        onBlur={() => setFocused(false)}
        {...props.customProps}>
        {!props.loading && props.lIcon && (
          <View style={[styles.leftIcon, props.customLeftIconStyle]}>
            {props.lIcon}
          </View>
        )}
        {!props.children && !props.loading && props.label ? (
          <Text
            style={[
              getBaseStyle(props.fontStyle).text,
              ...getTextStyle(props.type),
              props.customTextStyle,
            ]}
            numberOfLines={props.singleLine ? 1 : undefined}>
            {props.label}
          </Text>
        ) : (
          props.children
        )}
        {!props.loading && props.rIcon && (
          <View style={[styles.rightIcon, props.customRightIconStyle]}>
            {props.rIcon}
          </View>
        )}
        {props.loading && (
          <Loading
            size="small"
            customStyle={props.customLoadingStyle}
            customColor={
              props.customLoadingColor ?? theme.colors.neutral.$white
            }
          />
        )}
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    outlineWidth: 0,
  },
  leftIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 20,
    height: 24,
  },
  rightIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 20,
    height: 24,
  },
  disabled: {
    opacity: 0.5,
  },
  // Primary Root Styles
  primaryRootHover: {
    backgroundColor: theme.colors.brand.$35Hover,
  },
  primaryRootFocused: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$white} inset, 0px 0px 0px 2px ${theme.colors.brand.$2}`,
  },
  primaryRoot: {
    backgroundColor: theme.colors.brand.$4Base,
  },

  // Secondary Root Styles
  secondaryRoot: {
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
  },
  secondaryRootHover: {
    backgroundColor: theme.colors.neutral.$13,
    borderColor: theme.colors.neutral.$9,
  },
  secondaryRootFocused: {
    borderColor: theme.colors.brand.$2,
  },
  // Secondary Thin Root Styles
  secondaryThinRoot: {
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
  },
  secondaryThinRootHover: {
    backgroundColor: theme.colors.neutral.$13,
    borderColor: theme.colors.neutral.$9,
  },
  secondaryThinRootFocused: {
    borderWidth: 2,
    borderColor: theme.colors.brand.$2,
  },
  // Text Root Styles
  textRoot: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  textRootHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  textRootFocused: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px 0px 0px 2px ${theme.colors.brand.$2}`,
  },
  // Text Secondary Root Styles
  textSecondaryRoot: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  textSecondaryRootHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  textSecondaryRootFocused: {
    borderWidth: 2,
    borderColor: theme.colors.brand.$2,
  },
  // Success Root Styles
  successRoot: {
    backgroundColor: theme.colors.success.$4Base,
    borderWidth: 0,
  },
  successRootHover: {
    backgroundColor: theme.colors.success.$35Hover,
  },
  successRootFocused: {
    borderWidth: 2,
    borderColor: theme.colors.brand.$2,
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: '0px 0px 0px 4px #FFF inset',
  },
  // Caution Root Styles
  cautionRoot: {
    backgroundColor: theme.colors.caution.$4Base,
    borderWidth: 0,
  },
  cautionRootHover: {
    backgroundColor: theme.colors.caution.$35Hover,
  },
  cautionRootFocused: {
    borderWidth: 2,
    borderColor: theme.colors.brand.$2,
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: '0px 0px 0px 4px #FFF inset',
  },

  // Danger Root Styles
  dangerRoot: {
    backgroundColor: theme.colors.danger.$4Base,
    borderWidth: 0,
  },
  dangerRootHover: {
    backgroundColor: theme.colors.danger.$35Hover,
  },
  dangerRootFocused: {
    borderWidth: 2,
    borderColor: theme.colors.brand.$2,
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: '0px 0px 0px 4px #FFF inset',
  },

  // Primary Text Styles
  primaryText: {
    color: theme.colors.neutral.$white,
  },

  // Secondary Text Styles
  secondaryText: {
    color: theme.colors.neutral.$4,
  },
  secondaryTextHover: {
    color: theme.colors.neutral.$2Base,
  },
  secondaryTextFocused: {
    color: theme.colors.neutral.$2Base,
  },

  // Secondary Thin Text Styles
  secondaryThinText: {
    color: theme.colors.neutral.$4,
  },
  secondaryThinTextHover: {
    color: theme.colors.neutral.$2Base,
  },
  secondaryThinTextFocused: {
    color: theme.colors.neutral.$2Base,
  },

  // Text Text Styles
  textText: {
    color: theme.colors.brand.$4Base,
  },
  textTextHover: {
    color: theme.colors.brand.$3,
  },
  textTextFocused: {
    color: theme.colors.brand.$3,
  },

  // Text Secondary Text Styles
  textSecondaryText: {
    color: theme.colors.neutral.$4,
  },
  textSecondaryTextHover: {
    color: theme.colors.neutral.$2Base,
  },

  // Success Text Styles
  successText: {
    color: theme.colors.neutral.$2Base,
  },

  // Caution Text Styles
  cautionText: {
    color: theme.colors.neutral.$2Base,
  },

  // Danger Text Styles
  dangerText: {
    color: theme.colors.neutral.$2Base,
  },
});
