import {api} from 'fast-sdk';
import {useCallback, useEffect, useState} from 'react';

import type {Member} from 'store/slices/settings/types';

type Options = {
  enabled?: boolean;
};

export const useWorkspaceMembers = (
  workspaceId: string,
  options: Options = {enabled: true},
) => {
  const [isLoading, setIsLoading] = useState(options.enabled);
  const [isError, setIsError] = useState(false);
  const [members, setMembers] = useState<Member[]>();

  const fetchWorkspaceMembers = useCallback(async () => {
    if (!workspaceId || !workspaceId.startsWith('4')) return;
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await api.workspace.getWorkspaceMembers(workspaceId);
      if (response.result) {
        const memberList: Array<Member> = [];
        for (const member of response.users) {
          memberList.push({
            authentication: member.permissions,
            firstName: member.first_name,
            lastName: member.last_name,
            email: member.email_address,
            profilePic: member.profile_pic,
            dateJoined: new Date(),
            id: member.id,
          });
        }
        setMembers(memberList);
        setIsError(false);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (options.enabled) fetchWorkspaceMembers();
  }, [options.enabled, fetchWorkspaceMembers]);

  return {
    isLoading,
    isError,
    members,
  };
};
