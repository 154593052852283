import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {FilesItem} from 'store/slices/files/types';
import {AiChatFilterSection} from './AiChatFilterSection';

export function AiChatFilterAttachSection() {
  const dispatch = useDispatch();
  const attachFiles = useSelector(aichat.selectors.getAttachFiles());
  const fileList = Object.values(attachFiles);
  const hasAttachFiles = fileList.length > 0;

  const handleRemoveFile = (file: FilesItem) => {
    dispatch(aichat.default.actions.removeAttachFile({fileId: file.id}));
  };

  return (
    hasAttachFiles && (
      <AiChatFilterSection
        files={fileList}
        onRemove={handleRemoveFile}
        type="attachment"
      />
    )
  );
}
