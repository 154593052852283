import {Fragment, useState} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useIntercom} from 'react-use-intercom';

// @ts-ignore
import svgIntercomLogo from 'assets/svgs/logos/Intercom.svg';
import theme from 'config/theme';
import {useParams} from 'extensions/navigation';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Avatar} from 'interface/common/Avatar';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';

import useLegalNavigation from '../hooks/useLegalNavigation';
import useShowUpdateNotice from '../hooks/useShowUpdateNotice';
import {AccountDropdown} from '../layout/AccountDropdown';
import AppNewVersionNotice from '../layout/AppNewVersionNotice';
import {HeaderOrgButton} from './HeaderOrgButton';

import {ROUTES} from 'constants/routes';
import {AppTabs} from 'interface/stacks/profile-membership/types';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';

const commonOverrides = {
  Button: {
    style: {
      width: 32,
      paddingVertical: 0,
    },
  },
};

const getIcon = (name: string) => {
  return <Icon name={name} color={'#515662'} size={22} />;
};

export default function AppUserBar() {
  const {workspaceId} = useParams<{workspaceId: string}>();
  const {navigateToHelp} = useLegalNavigation();
  const {showUpdateAlert} = useShowUpdateNotice();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const organization = useSelector(user.selectors.getSelectedOrganization);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const profilePic = useSelector(user.selectors.getUserProfilePic);
  const orgLogo = useSelector(settings.selectors.getOrganizationLogo);
  const userColor = userDetails?.color || getHashColor(userDetails?.id);
  const userInitials = createInitials(
    `${userDetails?.first_name} ${userDetails?.last_name}`,
  );

  const intercom = useIntercom();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleSearch = () => {
    dispatch(workspace.default.actions.toggleSearch());
  };

  const toggleIntercom = () => {
    intercom.isOpen ? intercom.hide() : intercom.show();
  };

  const openUserMenu = () => {
    intercom.hide();
    setIsUserMenuOpen(true);
  };

  const closeUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  const navigateToHome = () => {
    const isInShared = window.location.pathname.includes(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SHARED}/`,
    );
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${isInShared ? AppTabs.Shares : AppTabs.Workspaces}`,
    );
  };

  return (
    <Fragment>
      <View style={styles.root}>
        <View style={styles.top}>
          {organization ? (
            <HeaderOrgButton organization={organization} orgLogo={orgLogo} />
          ) : (
            <Button
              aria-label="Home"
              variant="text"
              onPress={navigateToHome}
              endEnhancer={getIcon('lucide:house')}
              overrides={{
                Button: {
                  style: {
                    ...commonOverrides.Button.style,
                    backgroundColor: theme.colors.neutral.$175,
                  },
                },
              }}
            />
          )}
          <Pressable
            style={{
              // @ts-ignore
              cursor: 'default',
            }}>
            {({hovered}) => (
              <Fragment>
                <Button
                  disabled={!workspaceId}
                  aria-label="Search"
                  variant="text"
                  onPress={toggleSearch}
                  endEnhancer={getIcon('lucide:search')}
                  overrides={{
                    Button: {
                      style: {
                        ...commonOverrides.Button.style,
                        opacity: workspaceId ? 1 : 0.5,
                      },
                    },
                  }}
                />
                {hovered && (
                  <View style={styles.searchTooltip}>
                    <Typography size="xs" color={theme.colors.neutral.$white}>
                      Enter a Workspace or Share to search.
                    </Typography>
                  </View>
                )}
              </Fragment>
            )}
          </Pressable>
          <Button
            aria-label="Notifications"
            variant="text"
            onPress={() => {}}
            endEnhancer={getIcon('lucide:bell')}
            overrides={commonOverrides}
          />
        </View>
        <View style={styles.bottom}>
          <Button
            aria-label="Intercom"
            variant="text"
            onPress={toggleIntercom}
            endEnhancer={
              <Image source={svgIntercomLogo} style={styles.intercomLogo} />
            }
            overrides={commonOverrides}
          />
          <Button
            aria-label="Help"
            variant="text"
            onPress={navigateToHelp}
            endEnhancer={getIcon('lucide:circle-help')}
            overrides={commonOverrides}
          />
          <Popup
            isOpen={isUserMenuOpen}
            close={closeUserMenu}
            placement="right-start"
            offset={16}
            triggerElement={
              <Button
                aria-label="Account"
                variant="text"
                onPress={openUserMenu}
                overrides={commonOverrides}
                endEnhancer={
                  <Avatar
                    color={userColor}
                    initials={userInitials}
                    photo={profilePic}
                    hideBorder
                    size={1}
                    shapeKind="rounded-square"
                    imageStyle={styles.avatar}
                    innerStyle={styles.avatar}
                  />
                }
              />
            }>
            <AccountDropdown close={closeUserMenu} />
          </Popup>
        </View>
      </View>
      {showUpdateAlert && (
        <View style={styles.versionNotice}>
          <AppNewVersionNotice />
        </View>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    zIndex: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 64,
    height: '100%',
    paddingBottom: 16,
    paddingHorizontal: 16,
    paddingTop: 8,
    backgroundColor: theme.colors.neutral.$100,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 6,
  },
  top: {
    gap: 24,
  },
  bottom: {
    gap: 24,
  },
  versionNotice: {
    position: 'absolute',
    bottom: 10,
    left: 80,
    zIndex: 2,
    width: 240,
  },
  intercomLogo: {
    width: 22,
    height: 22,
  },
  searchTooltip: {
    position: 'absolute',
    zIndex: 3,
    top: -20,
    left: 40,
    width: 140,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
  },
});
