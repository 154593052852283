import {Fragment} from 'react';
import type {Share} from 'store/slices/shared/types';
import useShareActions, {
  UpdateMode,
} from '../../../shared/dialogs/SharedCustomizeDialog/hooks/useShareActions';
import useUpdateShare from '../../../shared/hooks/useUpdateShare';
import AddPeople from './AddPeople';
import Permissions from './Permissions';
// import ShareDetails from './ShareDetails';
import ShareLinks from './ShareLinks';
import ShareMainInfo from './ShareMainInfo';
import ShareOptions from './ShareOptions';

interface Props {
  share: Share;
}

export default function ShareContent({share}: Props) {
  const {shareState, isContentLoading} = useShareActions({
    workspaceFolderName: share?.parent_workspace_folder,
    shareId: share?.id,
    folderParentId: share.share_link?.graft_point,
    updateMode: UpdateMode.Edit,
  });

  const {updateShare} = useUpdateShare({
    share,
  });

  return (
    !isContentLoading && (
      <Fragment>
        {/* <ShareDetails
          share={share}
          shareState={shareState}
          updateShare={updateShare}
        /> */}
        <ShareMainInfo
          share={share}
          shareState={shareState}
          updateShare={updateShare}
        />
        <AddPeople share={share} />
        <Permissions share={share} updateShare={updateShare} />
        <ShareOptions shareState={shareState} updateShare={updateShare} />
        <ShareLinks shareState={shareState} updateShare={updateShare} />
      </Fragment>
    )
  );
}
