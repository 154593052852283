import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

export function AiChatMessageSkeleton() {
  const items = Array.from({length: 3}, (_, index) => (
    <>
      <AiChatMessageQuestionSkeleton />
      {/* <AiChatMessageAnswerSkeleton /> */}
    </>
  ));
  return <>{items}</>;
}

function AiChatMessageQuestionSkeleton() {
  return (
    <View style={questionStyles.container}>
      <Skeleton width={30} height={30} style={questionStyles.avatarContainer} />
      <View style={questionStyles.messageContainer}>
        <View style={questionStyles.header}>
          <Skeleton width={80} height={20} />
          <Skeleton width={60} height={20} />
        </View>
        <Skeleton width={130} height={20} />
      </View>
    </View>
  );
}

const questionStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 12,
  },
  avatarContainer: {
    width: 32,
    height: 32,
    marginVertical: 4,
  },
  messageContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 8,
    borderRadius: 12,
    backgroundColor: theme.colors.neutral.$75,
  },
  header: {
    flexDirection: 'row',
    gap: 12,
  },
});
