import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useNavigateToSubdomain} from 'interface/common/hooks/navigator/useNavigateToSubdomain';
import {useGetOrganizationDetailsCached} from 'interface/common/hooks/useGetOrganizationDetailsCached';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import * as workspace from 'store/slices/workspace';
import OrganizationHeaderSkeleton from '../../skeleton/OrganizationHeaderSkeleton';
import OrganizationHeader from '../organization/OrganizationHeader';
import SharesTable from '../shares/SharesTable';
import WorkspaceCards from '../workspaces/WorkspaceCards';

interface Props {
  orgDomain: string;
  isSharesLoading: boolean;
  isWorkspaces?: boolean;
}

const ExternalOrgSection = ({
  orgDomain,
  isSharesLoading,
  isWorkspaces = true,
}: Props) => {
  const {navigateToSubdomain} = useNavigateToSubdomain();

  const {generateShareUrl} = useGenerateShareUrl();
  const {org, isLoading} = useGetOrganizationDetailsCached(orgDomain);

  const workspaces = useSelector(
    workspace.selectors.getOrganizationWorkspaces(orgDomain),
  );
  const joinedWorkspaces = useSelector(
    workspace.selectors.getAllJoinedWorkspaces,
  );
  const shares = useSelector(shared.selectors.getOrganizationShares(org?.id));

  const currentWorkspaces = workspaces.filter(workspace =>
    joinedWorkspaces.some(w => w.id === workspace.id),
  );

  const onWorkspacePress = (workspace: WorkspaceListDetail) => {
    const workspacePath = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;
    navigateToSubdomain(org?.domain, workspacePath);
  };

  const onSharePress = (share: Share) => {
    navigateToSubdomain(org?.domain, generateShareUrl(share, false));
  };

  return (
    <View style={styles.root}>
      {isLoading || !org ? (
        <OrganizationHeaderSkeleton />
      ) : (
        <OrganizationHeader
          organization={org}
          workspacesCount={currentWorkspaces.length}
          sharesCount={shares.length}
          isWorkspaces={isWorkspaces}
          isExternal
        />
      )}
      {isWorkspaces ? (
        <WorkspaceCards
          workspaces={currentWorkspaces}
          onWorkspacePress={onWorkspacePress}
        />
      ) : (
        <SharesTable
          shares={shares}
          isSharesLoading={isSharesLoading}
          onSharePress={onSharePress}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {},
});

export default ExternalOrgSection;
