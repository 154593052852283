import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet, View} from 'react-native';

interface CitationPage {
  node_id: string;
  version_id?: string;
  page?: number;
  snippet?: string;
  hash?: string;
}

interface Props {
  citationPage: CitationPage;
  isOpen: boolean;
  onPressed: () => void;
  onViewFile: () => void;
  onCopy: (snippet: string) => void;
}

export function AiChatCitationModalItem({
  citationPage,
  isOpen,
  onPressed,
  onViewFile,
  onCopy,
}: Props) {
  const iconName = !isOpen ? 'chevron-left' : 'chevron-down';
  const pageNumber = `Page ${citationPage.page}`;
  const description =
    citationPage.snippet ??
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.';

  return (
    <View style={styles.container}>
      <Pressable style={styles.header} onPress={onPressed}>
        <Typography
          variant="medium"
          size="md"
          color={theme.colors.neutral.$700}>
          {pageNumber}
        </Typography>
        <Icon
          name={`lucide:${iconName}`}
          size={18}
          color={theme.colors.neutral.$700}
        />
      </Pressable>
      {isOpen && (
        <Typography
          variant="regular"
          size="md"
          color={theme.colors.neutral.$700}>
          {description}
        </Typography>
      )}
      {isOpen && (
        <View style={styles.buttons}>
          <Pressable style={styles.button} onPress={() => onCopy(description)}>
            <Icon
              name="lucide:copy"
              size={18}
              color={theme.colors.neutral.$700}
            />
          </Pressable>
          <Pressable style={styles.button} onPress={onViewFile}>
            <Icon
              name="lucide:eye"
              size={18}
              color={theme.colors.neutral.$700}
            />
          </Pressable>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginHorizontal: 24,
    paddingVertical: 12,
    marginTop: 2,
    marginBottom: 6,
    borderRadius: 16,
    gap: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.colors.neutral.$0,
    boxShadow:
      '0px 1px 3px rgba(28, 27, 34, 0.05), 0px 2px 8px rgba(28, 27, 34, 0.05)',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
  },
  button: {
    padding: 2,
  },
});
