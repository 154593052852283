import theme from 'config/theme';
import type {ShareType} from 'fast-sdk/src/api/share/consts';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Skeleton from 'interface/common/Skeleton';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Image,
  type ImageResizeMode,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {FileType, getFileTypeFromMime, typeToIcon} from 'utils/fast/files';
import useFileThumbnailSource, {
  FILES_PREVIEW_SUPPORT,
} from '../hooks/useFileThumbnailSource';

export const TINY_THUMBNAIL_SIZE = 18;
export const SMALL_THUMBNAIL_SIZE = 36;
export const MEDIUM_THUMBNAIL_SIZE = 182;

export const THUMBNAIL_SIZE = {
  [FileThumbnailSizes.Tiny]: 18,
  [FileThumbnailSizes.Small]: 28,
  [FileThumbnailSizes.Medium]: 40,
  [FileThumbnailSizes.Preview]: 84,
};

interface FileThumbnailProps {
  item: FilesItem;
  size: FileThumbnailSizes;
  uploading?: boolean;
  shareType?: ShareType;
  options?: {
    backgroundColor?: string;
    previewResizeMode?: ImageResizeMode;
    imageResizeMode?: ImageResizeMode;
    imageWidth?: number;
    imageHeight?: number;
    transform?: any;
    transformOrigin?: string;
    containerStyle?: StyleProp<ViewStyle>;
    imageContainerStyle?: StyleProp<ViewStyle>;
  };
}

export default function FileThumbnail({
  item,
  size,
  uploading,
  shareType,
  options,
}: FileThumbnailProps) {
  const dispatch = useDispatch();
  const [isInView, setIsInView] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const ref = useRef(null);

  const fileType = useMemo(() => getFileTypeFromMime(item), [item]);
  const fileCacheKey = useMemo(
    () => `${item.id}-${size === FileThumbnailSizes.Tiny ? 'IconSmall' : size}`,
    [item.id, size],
  );

  const thumbnailUrl = useSelector(
    files.selectors.selectThumbnail(fileCacheKey),
  );
  const updatedItem = useSelector(files.selectors.getItem(item.id));

  const {fetchFileThumbnail} = useFileThumbnailSource();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {threshold: 0},
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const loadThumbnail = async () => {
      const isFolder = fileType === FileType.Folder;
      if (
        !thumbnailUrl &&
        isInView &&
        !isFolder &&
        FILES_PREVIEW_SUPPORT.includes(fileType) &&
        updatedItem
      ) {
        const newThumbnail = await fetchFileThumbnail(
          updatedItem,
          size === FileThumbnailSizes.Tiny ? FileThumbnailSizes.Small : size,
          fileType,
        );
        if (newThumbnail) {
          dispatch(
            files.default.actions.setThumbnail({
              thumbnailKey: fileCacheKey,
              source: newThumbnail,
            }),
          );
        }
      }
    };

    if (isInView) {
      loadThumbnail();
    }
  }, [
    isInView,
    updatedItem?.id,
    updatedItem?.previews?.image?.state,
    size,
    thumbnailUrl,
  ]);

  const containerStyle = useMemo(
    () => [
      styles.container,
      styles[`container${size}`],
      uploading && styles.uploading,
      thumbnailUrl && styles.containerLoaded,
      options?.containerStyle,
    ],
    [size, thumbnailUrl, uploading, options?.containerStyle],
  );

  const imageStyle = useMemo(
    () => [
      styles.image,
      styles[`image${size}`],
      options?.previewResizeMode && {resizeMode: options?.previewResizeMode},
      options?.backgroundColor && {backgroundColor: options.backgroundColor},
      thumbnailUrl && styles.imageLoaded,
    ],
    [size, options?.backgroundColor, options?.previewResizeMode, thumbnailUrl],
  );

  const typeIconSource = useMemo(
    () => ({uri: typeToIcon(fileType)}),
    [fileType],
  );

  const skeletonSize =
    size === FileThumbnailSizes.Tiny
      ? TINY_THUMBNAIL_SIZE
      : size === FileThumbnailSizes.Small
        ? SMALL_THUMBNAIL_SIZE
        : size === FileThumbnailSizes.Medium
          ? MEDIUM_THUMBNAIL_SIZE
          : MEDIUM_THUMBNAIL_SIZE;

  return (
    <View
      ref={ref}
      style={[containerStyle, imageLoaded && options?.imageContainerStyle]}>
      {uploading ? (
        <Skeleton height={skeletonSize} width={skeletonSize} />
      ) : shareType ? (
        <ShareTypeBadge shareType={shareType} size={THUMBNAIL_SIZE[size]} />
      ) : (
        !imageLoaded && (
          <Image
            style={[imageStyle, styles[`fallback${size}`]]}
            source={typeIconSource}
            resizeMode="center"
          />
        )
      )}
      {thumbnailUrl && (
        <Image
          style={[
            imageStyle,
            styles.thumbnailImage,
            options?.imageWidth && {width: options.imageWidth},
            options?.imageHeight && {height: options.imageHeight},
            options?.transform && {transform: options.transform},
            options?.transformOrigin && {
              // @ts-ignore
              transformOrigin: options.transformOrigin,
            },
          ]}
          source={{
            uri: thumbnailUrl,
          }}
          onLoad={() => {
            setImageLoaded(true);
          }}
          resizeMode={options?.imageResizeMode}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  uploading: {
    opacity: 0.5,
  },
  image: {
    resizeMode: 'contain',
  },
  containerLoaded: {
    padding: 0,
  },
  imageLoaded: {
    height: '100%',
    width: '100%',
  },
  thumbnailImage: {
    ...StyleSheet.absoluteFillObject,
  },
  [`container${FileThumbnailSizes.Tiny}`]: {
    width: TINY_THUMBNAIL_SIZE,
    height: TINY_THUMBNAIL_SIZE,
  },
  [`image${FileThumbnailSizes.Tiny}`]: {
    width: THUMBNAIL_SIZE[FileThumbnailSizes.Tiny],
    height: THUMBNAIL_SIZE[FileThumbnailSizes.Tiny],
  },
  [`container${FileThumbnailSizes.Small}`]: {
    width: SMALL_THUMBNAIL_SIZE,
    height: SMALL_THUMBNAIL_SIZE,
    padding: 5,
  },
  [`image${FileThumbnailSizes.Small}`]: {
    width: THUMBNAIL_SIZE[FileThumbnailSizes.Small],
    height: THUMBNAIL_SIZE[FileThumbnailSizes.Small],
  },
  [`container${FileThumbnailSizes.Medium}`]: {
    width: MEDIUM_THUMBNAIL_SIZE,
    height: MEDIUM_THUMBNAIL_SIZE,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$15,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$border,
  },
  [`image${FileThumbnailSizes.Medium}`]: {
    width: THUMBNAIL_SIZE[FileThumbnailSizes.Medium],
    height: THUMBNAIL_SIZE[FileThumbnailSizes.Medium],
  },
  [`container${FileThumbnailSizes.Preview}`]: {
    width: '100%',
    height: '100%',
  },
  [`image${FileThumbnailSizes.Preview}`]: {
    width: THUMBNAIL_SIZE[FileThumbnailSizes.Preview],
    height: THUMBNAIL_SIZE[FileThumbnailSizes.Preview],
    resizeMode: 'center',
  },
  [`fallback${FileThumbnailSizes.Preview}`]: {
    tintColor: theme.colors.neutral.$6,
  },
});
