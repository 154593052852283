import type {PayloadAction} from '@reduxjs/toolkit';
import type {UploadStatus} from 'fast-sdk';
import type {
  StorageItem,
  StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';
import type {WorkspaceInfoPanelTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import type {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import type {FileSummaryTabs} from 'interface/stacks/workspace/storage/FolderHeaderSummary';
import type {Member} from 'store/slices/settings/types';
import type {QuickShare} from '../quick-share/types';

export type Store = {
  items: {
    [id: string]: FilesItem;
  };
  share: {
    [id: string]: {[id: string]: FilesView};
  };
  workspace: {
    [id: string]: {[id: string]: FilesView};
  };
  sort: FilesSort;
  filter: FilesFilter;
  layout: FileListItemLayout;
  selection: string[];
  focusedId: string;
  focusedParentId: string;
  focusedType: WorkspaceInfoPanelTab | undefined;
  fileSummaryType: FileSummaryTabs | undefined;
  following?: string | null;
  search?: string;
  query?: string;
  drag?: string[];
  drop?: string;
  pick?: FilesPick;
  previewProgress?: number;
  preview?: {
    id: string;
    name: string;
    jobId?: number;
  };
  loading?: {
    folderId: string;
    instanceId: string;
  };
  thumbnails: Record<string, string>;
  skipClearFocus: boolean;
};

export type Actions = {
  Loading: PayloadAction<
    | {
        folderId: string;
        instanceId: string;
      }
    | undefined
  >;
  UpdateFolder: PayloadAction<{
    id: string;
    list: StorageItem[];
    instanceId: string;
    instanceNs: StorageNamespace;
    folderDetails?: StorageItem;
  }>;
  UpdateFile: PayloadAction<{
    file: StorageItem;
    instanceId: string;
    instanceNs: StorageNamespace;
  }>;
  Fail: PayloadAction<{message: string; notify: boolean}>;
  Move: PayloadAction<{ids: string[]; destination: string}>;
  Copy: PayloadAction<{ids: string[]; destination: string}>;
  Update: PayloadAction<{id: string; name?: string}>;
  Create: PayloadAction<{name: string; id: string}>;
  Password: PayloadAction<{id: string; password: string}>;
  Search: PayloadAction<{
    workspaceId: string;
    id?: string;
    query?: string;
    results?: object;
  }>;
  Follow: PayloadAction<{id: string}>;
  Select: PayloadAction<{
    ids: string[];
    noToggle?: boolean;
    itemList?: string[];
    focused?: string;
    fromChip?: boolean;
  }>;
  Browse: PayloadAction<{id: string}>;
  Focus: PayloadAction<{id: string}>;
  FocusParent: PayloadAction<{id: string}>;
  SkipClearFocus: PayloadAction<{skip: boolean}>;
  FocusType: PayloadAction<{type: WorkspaceInfoPanelTab}>;
  FileSummaryType: PayloadAction<{type: FileSummaryTabs}>;
  Drag: PayloadAction<{ids?: string[]}>;
  Purge: PayloadAction<{ids: string[]}>;
  Archive: PayloadAction<{ids: string[]}>;
  Restore: PayloadAction<{ids: string[]}>;
  Refresh: PayloadAction<{ids: string[]}>;
  Sync: PayloadAction<{ids: string[]}>;
  Picking: PayloadAction<FilesPick>;
  Sorting: PayloadAction<Store['sort']>;
  Filtering: PayloadAction<Store['filter']>;
  Layout: PayloadAction;
  Preview: PayloadAction<{id: string; name: string}>;
  PreviewJob: PayloadAction<{jobId: number}>;
  PreviewProgress: PayloadAction<{progress: number}>;
  SetThumbnail: PayloadAction<{thumbnailKey: string; source: string}>;
  Reset: PayloadAction;
};

// HELPERS

export type FilesView = string[];

export type FilesItem = StorageItem & {
  state: FilesItemState;
  shareId?: string;
  workspaceId?: string;
  comments: Array<FilesComment>;
  hierarchy: string[][];
  upload?: {
    bytes: number;
    member: string;
    status: UploadStatus;
  };
  quickShare?: QuickShare;
};

export type FilesFilter = {
  type:
    | 'all'
    | 'folders'
    | 'files'
    | 'images'
    | 'video'
    | 'audio'
    | 'document'
    | 'spreadsheet'
    | 'presentation'
    | 'development'
    | 'public'
    | 'private';
};

export type FilesSort = {
  category: 'name' | 'date' | 'size' | 'downloads';
  order: 'asc' | 'desc';
};

export type FilesComment = {
  sender: Member;
  createdAt: string;
  content: string;
  reply: Array<FilesComment>;
  seen?: boolean;
};

export type FilesPick = {
  action: 'copy' | 'move' | 'restore' | 'transfer';
  destination: string;
  ids: string[];
};

export enum FilesItemState {
  isNormal = 0,
  isLoading = 1,
  isMoving = 2,
  isCopying = 3,
  isDeleting = 4,
  isRestoring = 5,
  isPurging = 6,
  isUpdating = 7,
  isHashing = 8,
  isUploading = 9,
  isVerifying = 10,
}

export enum FilesItemFlag {
  isReserved = 0,
  isOwned = 1,
  isViewable = 2,
  isReported = 3,
  isEditable = 4,
  isCopyright = 5,
  isRestricted = 6,
  isWordFiltered = 7,
  isInfected = 8,
}

export enum FilesErrors {
  ApiInternal = 100,
  UserError = 101,
  MissingKey = 102,
  InvalidKey = 103,
  MissingToken = 104,
  InvalidToken = 105,
  InvalidQuickkey = 110,
  MissingQuickkey = 111,
  InvalidFolderkey = 112,
  MissingFolderkey = 113,
  AccessDenied = 114,
  FolderPathConflict = 115,
  InvalidDate = 116,
  MissingFoldername = 117,
  InvalidFilename = 118,
  ZipOwnerNotPro = 149,
  ZipTotalSizeTooBig = 153,
  ZipOwnerInsufficientBandwidth = 155,
  StorageLimitExceeded = 162,
  FolderDepthLimit = 167,
  NotPremiumUser = 173,
  InvalidUrl = 174,
  InvalidUploadKey = 175,
  BandwidthError = 183,
  NotLoggedIn = 188,
  CopyLimitExceeded = 207,
  AsyncJobInProgress = 208,
}
