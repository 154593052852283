import {useLingui} from '@lingui/react';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {AppUserAvatar} from 'interface/stacks/app/AppUserAvatar';
import {ProgressCircle} from 'interface/stacks/uploads/base/ProgressCircle';
import {UploadButton} from 'interface/stacks/uploads/base/UploadButton';
import * as status from 'interface/stacks/uploads/utils/status';
import {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import IconC from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {bytesize, truncate} from 'utils/common/data';

import type {Upload} from 'fast-sdk';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';

const SHOW_PROGRESS = false;

export interface UploadsItemProps {
  uploader: UploaderState;
  member: MultiplayerMember;
  item: Upload;
}

export function UploadsItem(props: UploadsItemProps) {
  const [hovered, setHovered] = useState(false);
  const {item} = props;

  useLingui();

  return (
    <Pressable
      style={styles.root}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}>
      <View style={styles.left}>
        <AppUserAvatar />
        <View style={styles.info}>
          <Typography
            numberOfLines={1}
            size="md"
            color={theme.colors.neutral.$14}>
            {truncate(item.metadata.name, 25)}
          </Typography>
          <View style={styles.status}>
            {SHOW_PROGRESS && status.isTransferring(item.status) ? (
              <>
                <Typography size="xs" color={theme.colors.neutral.$8}>
                  {`${bytesize(item.bytes)} / ${bytesize(item.metadata.size)}`}
                </Typography>
              </>
            ) : (
              <Typography
                numberOfLines={2}
                ellipsizeMode="tail"
                size="xs"
                color={
                  status.isFailure(item.status)
                    ? theme.colors.danger.$4Base
                    : theme.colors.neutral.$8
                }>
                {status.isFailure(item.status)
                  ? item.error
                  : status.toString(item.status)}
              </Typography>
            )}
          </View>
        </View>
      </View>
      {hovered ? (
        <View style={styles.actions}>
          {status.isTransferring(item.status) && (
            <UploadButton
              onPress={() => {
                props.uploader.pause(item.metadata.uuid);
              }}
              onHoverIn={() => setHovered(true)}>
              <IconC name="pause" size={20} color={theme.colors.neutral.$8} />
            </UploadButton>
          )}
          {status.isPaused(item.status) && (
            <UploadButton
              onPress={() => {
                props.uploader.resume(item.metadata.uuid);
              }}
              onHoverIn={() => setHovered(true)}>
              <IconC name="play" size={20} color={theme.colors.neutral.$8} />
            </UploadButton>
          )}
          {status.isRestartable(item.status) && (
            <UploadButton
              onPress={() => {
                props.uploader.retry(item.metadata.uuid);
              }}
              onHoverIn={() => setHovered(true)}>
              <IconC name="cached" size={20} color={theme.colors.neutral.$8} />
            </UploadButton>
          )}
          <UploadButton
            onPress={() => {
              props.uploader.cancel(item.metadata.uuid);
            }}
            onHoverIn={() => setHovered(true)}>
            <Icon name="cancel" size={20} color={theme.colors.neutral.$8} />
          </UploadButton>
        </View>
      ) : (
        <View style={styles.icon}>
          {status.isQueued(item.status) && (
            <IconC name="clock" size={20} color={theme.colors.neutral.$8} />
          )}
          {status.isComplete(item.status) && (
            <IconC name="check" size={20} color={theme.colors.success.$4Base} />
          )}
          {status.isAssembling(item.status) && (
            <IconC name="check" size={20} color={theme.colors.neutral.$8} />
          )}
          {status.isFailure(item.status) && (
            <Icon name="warning" size={20} color={theme.colors.danger.$4Base} />
          )}
          {status.isTransferring(item.status) && (
            <ProgressCircle
              progress={item.bytes / item.metadata.size}
              innerFill={theme.colors.neutral.$1}
              outerFill="rgba(255,255,255,0.2)"
            />
          )}
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    cursor: 'default',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 6,
    borderColor: theme.colors.neutral.$25,
    borderTopWidth: 1,
  },
  left: {
    flex: 1,
    marginLeft: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  status: {
    flexDirection: 'row',
    gap: 6,
  },
  info: {
    flex: 1,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  },
  icon: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
});
