import {colors} from 'config/styles';
import theme from 'config/theme';
import Footer from 'interface/stacks/app/Footer';
import LogoHeader from 'interface/stacks/onboard/base/LogoHeader';
import {StyleSheet, View, useWindowDimensions} from 'react-native';

type Props = {
  showLayout?: boolean;
  children: React.ReactNode;
};

export function ShareNotAvailableLayout({showLayout, children}: Props) {
  const screen = useWindowDimensions();

  const isSmallScreen = screen.width < 790;
  const lightColors = colors.mainLightColors;

  const classes = {
    root: [
      styles.root,
      isSmallScreen && styles.rootSmall,
      {backgroundColor: theme.colors.neutral.$white},
    ],
    text: [{color: lightColors.text}, styles.text],
    content: [styles.content, isSmallScreen && styles.content],
  };

  return (
    <View style={classes.root}>
      {showLayout && (
        <View style={styles.header}>
          <LogoHeader />
        </View>
      )}
      <View style={classes.content}>
        {children}
        {showLayout && <Footer />}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  rootSmall: {
    flexDirection: 'column',
  },
  logo: {
    width: 120,
    height: 24,
    margin: 0,
    zIndex: 5,
  },
  text: {
    fontWeight: '400',
    fontSize: 13,
    lineHeight: 21,
    marginLeft: 10,
    color: '#ffffff',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
    zIndex: 10,
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'calc(100%-200px)',
    width: '100%',
    flexGrow: 1,
  },
  contentSmall: {
    width: '100%',
    paddingLeft: 0,
  },
});
