import theme from 'config/theme';
import {getBaseUrl} from 'fast-sdk/src/requests/utils';
import Markdown from 'interface/base/Markdown';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import {useGenericSSE} from '../../hooks/useGenericSSE';

interface Props {
  chatId: string;
  messageId: string;
}

export function AiChatStreamingResponseItem({chatId, messageId}: Props) {
  const dispatch = useDispatch();
  const {namespace, instanceId, instanceName} = useGetNamespaceContext();
  const {events, isConnected, error, hasCompleted, startStream} = useGenericSSE(
    getBaseUrl(
      `/${namespace}/${instanceName}/ai/chat/${chatId}/message/${messageId}/read`,
    ),
  );
  const response = `${events.join('')}`;

  useEffect(() => {
    startStream();
  }, []);

  return <Markdown style={markdownStyles}>{response}</Markdown>;
}

const markdownStyles = StyleSheet.create({
  body: {
    color: theme.colors.neutral.$700,
    fontSize: 14,
    lineHeight: 24,
    paddingStart: 40,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 8,
  },
  buttons: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
