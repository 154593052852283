import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import AddEmailTextarea from 'interface/base/AddEmailTextarea';
import {Button} from 'interface/common/Button';
import useOrgInvitation from 'interface/common/hooks/useOrgInvitation';
import usePermissionsPicker from 'interface/common/hooks/usePermissionsPicker';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialIcons';
import type {Organization} from 'store/slices/user/types';
import {PageSubTitle} from '../../base/PageSubTitle';

interface InviteMembersModalProps {
  organization: Organization;
  onSubmit: () => void;
}

export function InviteMembersModal({
  organization,
  onSubmit,
}: InviteMembersModalProps) {
  const {close} = useModal();
  const toast = useToast();

  const [emails, setEmails] = useState<Array<string>>([]);

  const {loading, sendInvitations} = useOrgInvitation(
    organization?.domain,
    organization?.name,
  );
  const {permission, PickerComponent} = usePermissionsPicker({
    initialPermission: 'member',
    disabled: loading,
  });

  const sendEmails = async () => {
    const {successEmails, failedEmails} = await sendInvitations(
      emails,
      permission,
    );

    if (successEmails.length) {
      toast.show(
        t`Invited ${successEmails.length} ${successEmails.length > 1 ? 'members' : 'member'} to ${organization?.name} organization.`,
        {type: 'success'},
      );
    }

    if (failedEmails.length) {
      toast.show(
        t`The emails: ${failedEmails.join(', ')} are not added to ${organization?.name}, please try again.`,
        {type: 'danger'},
      );
    }
    onSubmit();
    close();
  };

  return (
    <View style={styles.root}>
      <Button
        lIcon={
          <Icon
            name="close"
            size={20}
            onPress={close}
            color={theme.colors.neutral.$6}
          />
        }
        onPress={close}
        type="Text"
        customRootStyle={styles.closeContainer}
      />
      <PageSubTitle
        customStyle={{marginBottom: 21}}
        text={`Invite people to ${organization?.name} organization.`}
      />
      <AddEmailTextarea
        emails={emails}
        setEmails={setEmails}
        customRootStyle={{marginBottom: 10}}
        subdomain={organization?.domain}
      />
      <View style={styles.buttonContainer}>
        {PickerComponent}
        <Button
          type="Primary"
          label={t`Send Invites`}
          onPress={sendEmails}
          customRootStyle={{height: 36}}
          disabled={loading || !emails.length}
          loading={loading}
          customLoadingColor={theme.colors.neutral.$white}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    width: 550,
    backgroundColor: 'white',
    margin: 'auto',
    borderRadius: 7,
  },
  closeContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    margin: 8,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
});
