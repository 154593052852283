import {t} from '@lingui/macro';
import {ShareType} from 'fast-sdk/src/api/share/consts';
import {Loading} from 'interface/common/Loading';
import Modal, {FooterActions} from 'interface/common/Modal';
import {TabList} from 'interface/common/TabList';
import {useFormSubmit} from 'interface/common/hooks/useFormSubmit';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {ShareTypeLabel} from 'utils/fast/shares';
import useShareActions, {UpdateMode} from './hooks/useShareActions';
import {ShareDesign} from './tabs/ShareDesign';
import {ShareDetails} from './tabs/ShareDetails';

enum ShareTab {
  Details = 'Details',
  Design = 'Design',
}

const SHARE_DETAILS_TABS = [ShareTab.Details, ShareTab.Design];

interface SharedCustomizeDialogProps {
  workspaceFolderName: string;
  shareId?: string;
  folderParentId?: string;
  close: () => void;
  items?: string[];
  shareType?: ShareType;
}

export function SharedCustomizeDialog({
  workspaceFolderName,
  folderParentId,
  shareId,
  close,
  items,
  shareType,
}: SharedCustomizeDialogProps) {
  const [activeTab, setActiveTab] = useState<ShareTab>(ShareTab.Details);

  const updateMode = shareId ? UpdateMode.Edit : UpdateMode.Create;

  const {
    shareState,
    handleCreateShare,
    handleUpdateShare,
    isSaveLoading,
    isFormInvalid,
    isContentLoading,
  } = useShareActions({
    workspaceFolderName,
    shareId,
    folderParentId,
    done: close,
    updateMode,
    items,
    shareType,
  });

  const onTabChange = (newActiveTab: ShareTab) => {
    setActiveTab(newActiveTab);
    if (newActiveTab === ShareTab.Details) {
      shareState.setCurrentFormLink(undefined);
    }
  };

  const isCreate = updateMode === UpdateMode.Create;
  const showFooterActions = !shareState.currentFormLink;

  useFormSubmit(
    isCreate ? handleCreateShare : handleUpdateShare,
    isFormInvalid,
  );

  return (
    <Modal
      title={
        isCreate
          ? `Create Shared Folder - ${ShareTypeLabel[shareType ?? ShareType.Exchange]}`
          : 'Customize Shared Folder'
      }
      footer={
        showFooterActions && (
          <FooterActions
            actionButtonLabel={t`Done`}
            onSave={isCreate ? handleCreateShare : handleUpdateShare}
            isFormInvalid={isFormInvalid}
            onCancel={close}
            isActionLoading={isSaveLoading}
          />
        )
      }
      close={close}>
      <TabList
        tabs={SHARE_DETAILS_TABS.map(tab => ({key: tab, label: tab}))}
        activeTab={activeTab}
        setActiveTab={onTabChange}
        rootStyle={styles.tabList}
        tabStyle={styles.tabItem}
      />
      <View style={styles.tabsBody}>
        {isContentLoading ? (
          <Loading centered />
        ) : activeTab === ShareTab.Details ? (
          <ShareDetails shareState={shareState} />
        ) : (
          <ShareDesign shareState={shareState} />
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  tabsBody: {
    paddingTop: 20,
    minHeight: 200,
  },
  tabList: {
    paddingHorizontal: 0,
  },
  tabItem: {
    paddingVertical: 6,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 0,
  },
});
