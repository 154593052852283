import {useParams} from 'extensions/navigation';
import {useSelector} from 'react-redux';
import {selectors as sharedSelectors} from 'store/slices/shared';

export function useGetCurrentShareMap() {
  const {sharedCustomName} = useParams();
  const share = useSelector(
    sharedSelectors.getSharedByCustomName(sharedCustomName),
  );

  return share;
}
