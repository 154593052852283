import theme from 'config/theme';
import {ROUTES, WORKSPACE_STORAGE} from 'constants/routes';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useGetFileDetailsCached} from 'interface/common/hooks/useGetFileDetailsCached';
import {Image, Pressable, StyleSheet, Text} from 'react-native';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import * as files from 'store/slices/files';
import {typeToIcon} from 'utils/fast/files';

interface Props {
  fileId: string;
  instanceId: string;
  instanceNs: StorageNamespace;
}

// instanceId is the workspaceFolderName
export function FolderChip({fileId, instanceId, instanceNs}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {item, parentItemId, fileType, itemName} = useGetFileDetailsCached(
    fileId,
    instanceId,
    instanceNs,
  );

  const handlePress = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${item.workspaceId}/${WORKSPACE_STORAGE}/${parentItemId}`,
    );
    dispatch(
      files.default.actions.select({
        ids: [item.id],
        focused: item.id,
        fromChip: true,
      }),
    );
  };

  return (
    <Pressable style={styles.container} onPress={handlePress}>
      {fileType && (
        <Image
          style={styles.image}
          source={{uri: typeToIcon(fileType)}}
          resizeMode="center"
        />
      )}
      {itemName && <Text style={styles.label}>{itemName}</Text>}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$11,
    paddingHorizontal: 8,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  label: {
    fontSize: 12,
    color: theme.colors.neutral.$2Base,
    lineHeight: 20,
  },
  image: {
    width: 16,
    height: 16,
  },
});
