import {getOrganizationMembersDetails} from 'interface/stacks/auth/consts';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import type {Organization} from 'store/slices/user/types';

export function useOrgMembers(organization?: Organization) {
  const dispatch = useDispatch();

  const members = useSelector(
    user.selectors.getMembersByOrganization(organization?.id),
  );

  const [loading, setLoading] = useState(false);

  const getMembers = async (domain: string, id: string) => {
    setLoading(true);
    try {
      const members = await getOrganizationMembersDetails(domain);
      dispatch(
        user.default.actions.setMembersByOrganization({
          organizationId: id,
          members,
        }),
      );
      return members;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organization) {
      getMembers(organization?.domain, organization?.id);
    }
  }, [organization?.id]);

  return {members, loading, getMembers};
}
