import {ROUTES} from 'constants/routes';
import device from 'extensions/device';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Transfers} from 'fast-sdk';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {ShareModal} from 'interface/stacks/share/components/ShareModal';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {createRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useOutletContext} from 'react-router';
import {slices} from 'store';
import {isTouch} from 'utils/common/platform';
import {download} from 'utils/fast/storage';
import {WorkspaceInfoPanelTab} from '../../layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import {SharedCustomizeDialog} from '../../shared/dialogs/SharedCustomizeDialog';
import {MainDialog} from '../quick-share/MainDialog';
import {useContextMenu} from './useContextMenu';
import useGetFileInformation from './useGetFileInformation';
import {useModalPurge} from './useModalPurge';
import {useModalRename} from './useModalRename';
import {useModalTrash} from './useModalTrash';
import {useWorkspaceContext} from './useWorkspaceContext';

import * as files from 'store/slices/files';
import * as effects from './files';

import useNavigateToShare from 'interface/stacks/share/hooks/useNavigateToShare';
import {useDragDropItem} from 'interface/stacks/workspace/storage/hooks/useDragDropItem';

import type {ShareFilesNavigation} from 'interface/stacks/share/hooks/useShareFilesNavigation';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {FileListItemProps} from 'interface/stacks/workspace/storage/FileListItem';
import type {MouseEvent} from 'react';
import type {GestureResponderEvent, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

import useArchiveShares from '../../shared/hooks/useArchiveShares';
import useDeleteShares from '../../shared/hooks/useDeleteShares';
import useUnarchiveShare from '../../shared/hooks/useUnarchiveShare';

export function useFileListItem(
  ref: React.RefObject<View>,
  props: FileListItemProps & {skipGetFile?: boolean},
) {
  const {
    item,
    shareds,
    members,
    layout,
    workspaceId,
    highlighted,
    archivedShares,
    disableActions,
    shareView,
    skipGetFile = false,
  } = props;
  const {workspace, uploader} = useWorkspaceContext();
  const contextShare =
    useOutletContext<[Share, UploaderState, ShareFilesNavigation]>();

  const {navigateToShare} = useNavigateToShare();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useModal();
  const popup = usePopup();

  const selection = useSelector(files.selectors.getSelection);
  const {isDropping} = useDragDropItem({
    ref,
    target: item,
    instanceNs: contextShare?.length ? 'share' : 'workspace',
    instanceId: contextShare?.length ? contextShare[0].id : workspace?.id,
    uploader: contextShare?.length ? contextShare[1] : uploader,
    selection,
  });

  const isUpload = Boolean(item.upload);
  const isDeleted = Boolean(item.deleted);

  const {file, loading} = useGetFileInformation({
    instanceId: workspaceId,
    // @ts-expect-error
    fileId: shareds ? item.share_link?.id : item.id,
    isUpload,
    skip: skipGetFile,
  });

  const fileNode = isUpload ? item : (file ?? item);
  const fileLoading = Boolean(loading || !fileNode);

  const isRow = layout <= FileListItemLayout.ListNormal;
  const isCell = layout >= FileListItemLayout.GridSimple;
  const isLink = fileNode?.type === StorageItemType.Link;
  const isFile = fileNode?.type === StorageItemType.File;
  const isFolder = fileNode?.type === StorageItemType.Folder;
  const sharedId = fileNode?.target_id ?? (skipGetFile ? item?.id : undefined);

  const {share} = useGetShareDetails({
    workspaceFolderName: workspaceId,
    shareId: sharedId,
  });

  const shareTarget = share ?? props.share;
  const canShare = !isLink && !shareTarget;

  const membersSelected = fileNode
    ? members?.filter(m => m.selection?.includes(fileNode?.id))
    : [];

  const hasOutline =
    (isDropping && !props.dragging) ||
    (highlighted && selection.length === 0) ||
    props.selected ||
    membersSelected?.length > 0;

  // File Actions

  const moveTo = useCallback(
    effects.pick(dispatch, {
      action: 'move',
      destination: 'root',
      ids:
        selection.length > 0 ? selection.map(select => select) : [fileNode?.id],
    }),
    [fileNode, selection],
  );

  const copyTo = useCallback(
    effects.pick(dispatch, {
      action: 'copy',
      destination: 'root',
      ids:
        selection.length > 0 ? selection.map(select => select) : [fileNode?.id],
    }),
    [fileNode, selection],
  );

  const restoreTo = useCallback(
    effects.pick(dispatch, {
      action: 'restore',
      destination: 'root',
      ids:
        selection.length > 0 ? selection.map(select => select) : [fileNode?.id],
    }),
    [fileNode, selection],
  );

  const transferTo = useCallback(
    effects.pick(dispatch, {
      action: 'transfer',
      destination: 'root',
      ids:
        selection.length > 0 ? selection.map(select => select) : [fileNode?.id],
    }),
    [fileNode, selection],
  );

  const uploadTo = useCallback(async () => {
    const files = await Transfers.pickFiles();
    const targetId = shareTarget ? shareTarget.id : workspace.id;
    uploader.instance.addFiles(files, fileNode?.id, targetId);
  }, [fileNode, uploader, workspace, workspaceId, shareTarget]);

  const renameModal = useModalRename(
    fileNode ? fileNode?.id : '',
    fileNode ? fileNode?.name : '',
    fileNode ? fileNode?.type : '',
    shareTarget ? shareTarget.id : workspaceId,
    shareTarget ? 'share' : 'workspace',
  );

  const trashModal = useModalTrash(
    selection.length > 0
      ? selection.map(select => select)
      : [fileNode ? fileNode?.id : ''],
    shareTarget ? shareTarget.id : workspaceId,
    shareTarget ? 'share' : 'workspace',
  );

  const purgeModal = useModalPurge(
    selection.length > 0
      ? selection.map(select => select)
      : [fileNode ? fileNode?.id : ''],
    shareTarget ? shareTarget.id : workspaceId,
    shareTarget ? 'share' : 'workspace',
    fileNode ? fileNode?.name : undefined,
  );

  const {archiveShares, archiveSharesByFileId} = useArchiveShares();
  const {unarchiveShare} = useUnarchiveShare();
  const {deleteShares} = useDeleteShares();

  const downloadItem = useCallback(() => {
    download(
      fileNode?.id,
      shareTarget ? shareTarget.id : workspaceId,
      shareTarget ? 'share' : 'workspace',
    );
  }, [fileNode?.id, shareTarget?.id, workspaceId]);

  const previewShare = useCallback(() => {
    if (!share) return;
    navigateToShare(share);
  }, [sharedId, workspaceId, share]);

  const archiveSharesOnPress = useCallback(() => {
    if (selection.length > 0) {
      archiveSharesByFileId(selection);
    } else if (share) {
      archiveShares([share]);
    }
  }, [sharedId, workspaceId, share, selection]);

  const unarchiveShareOnPress = useCallback(() => {
    if (!share) return;
    unarchiveShare(share);
  }, [sharedId, workspaceId, share]);

  const deleteShareOnPress = useCallback(() => {
    if (!share) return;

    deleteShares([share]);
  }, [sharedId, workspaceId, share]);

  const createShareOnPress = useCallback(() => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspaceId}
        folderParentId={fileNode?.parent}
        close={modal.close}
        items={
          selection.length > 0
            ? selection.map(select => select)
            : [fileNode?.id]
        }
      />,
    );
  }, [workspaceId, fileNode, selection]);

  const customizeShare = useCallback(() => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspaceId}
        shareId={sharedId}
        close={modal.close}
      />,
    );
  }, [workspaceId, sharedId]);

  const openShare = useCallback(() => {
    modal.open(
      <ShareModal
        workspaceFolderName={workspaceId}
        shareId={sharedId}
        done={modal.close}
      />,
    );
  }, [workspaceId, sharedId]);

  const openComments = useCallback(() => {
    dispatch(slices.files.actions.focus({id: fileNode ? fileNode?.id : ''}));
    dispatch(
      slices.files.actions.focusType({type: WorkspaceInfoPanelTab.Comments}),
    );
  }, [fileNode]);

  const viewFile = useCallback(() => {
    navigate(
      `/${shareView ? 'share' : 'workspace'}/${shareView ? shareTarget?.id : workspaceId}/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/${fileNode?.id}`,
    );
  }, [fileNode?.id, shareView, shareTarget?.id, workspaceId]);

  const headerText = fileNode ? fileNode?.name : '';
  const menuFiles = useContextMenu(
    archivedShares || shareTarget?.archived
      ? {
          headerText,
          unarchiveShareOnPress,
          deleteShareOnPress,
        }
      : isDeleted
        ? {
            headerText,
            restoreFromTrashOnPress: restoreTo,
            purgeFromTrashOnPress: () => purgeModal.open(),
          }
        : {
            headerText,
            addToExistingShareOnPress: transferTo,
            createShareOnPress: canShare ? createShareOnPress : undefined,
            copyToOnPress: !isLink ? copyTo : undefined,
            downloadOnPress: isFile ? downloadItem : undefined,
            moveToOnPress: moveTo,
            moveToTrashOnPress: !isLink ? () => trashModal.open() : undefined,
            quickShareOnPress: () => modal.open(<MainDialog file={fileNode} />),
            commentsOnPress: openComments,
            renameOnPress: () => renameModal.open(),
            uploadHereOnPress: isFolder ? uploadTo : undefined,
            viewOnPress: isFile ? viewFile : undefined,
            previewShareOnPress: isLink ? previewShare : undefined,
            openCustomizeShareOnPress: isLink ? customizeShare : undefined,
            openShareDialogOnPress: isLink ? openShare : undefined,
            archiveSharesOnPress: isLink ? archiveSharesOnPress : undefined,
          },
  );

  const menuAnchorRef = createRef<any>();

  // User Interactions
  const menu = useCallback(
    (e: MouseEvent | GestureResponderEvent, ref?: React.RefObject<any>) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (isUpload) return;
      if (popup) {
        popup.setPlacement('bottom-end');
        if (ref) {
          popup.setRef(ref);
          popup.setPosition(undefined);
        } else {
          if (e?.nativeEvent) {
            popup.setPosition({
              x: e.nativeEvent.pageX,
              y: e.nativeEvent.pageY,
            });
          }
          popup.setRef(menuAnchorRef);
        }
      }
      menuFiles.open();
    },
    [menuFiles, menuAnchorRef],
  );

  const open = useCallback(() => {
    if (isUpload) return;
    if (isLink && !disableActions) {
      previewShare();
    } else {
      props?.onOpen(fileNode, share);
    }
  }, [fileNode, props.onOpen, share, disableActions]);

  const focus = useCallback(
    (openDetails: boolean, event?: GestureResponderEvent) => {
      props?.onFocus(fileNode, openDetails, event);
    },
    [fileNode, props.onFocus],
  );

  const select = useCallback(
    (event?: GestureResponderEvent) => {
      if (isUpload) return;
      props?.onSelect(fileNode, event);
    },
    [fileNode, props.onSelect],
  );

  const longPress = useCallback(
    e => {
      if (!isTouch()) return open();
      device.triggerHapticFeedback('impactLight');
      menu(e);
    },
    [menu, open],
  );

  return {
    isRow,
    isCell,
    isLink,
    isFile,
    isFolder,
    isUpload,
    isDropping,
    fileNode,
    fileLoading,
    sharedId,
    shareTarget,
    membersSelected,
    hasOutline,
    downloadItem,
    previewShare,
    createShareOnPress,
    customizeShare,
    openShare,
    viewFile,
    uploadTo,
    renameModal,
    trashModal,
    menuFiles,
    menuAnchorRef,
    menu,
    longPress,
    open,
    focus,
    select,
  };
}
