import {TextInput} from 'interface/common/TextInput';
import type {ViewStyle} from 'react-native';

interface Props {
  text: string;
  onChangeText: (text: string) => void;
  label?: string;
  numberOfLines?: number;
  customRootStyle?: ViewStyle;
  placeholder?: string;
  onBlur?: () => void;
  minHeight?: number;
  maxLength?: number;
  customInputStyle?: ViewStyle;
  disabled?: boolean;
}

export const TextArea = ({
  text,
  onChangeText,
  label,
  numberOfLines,
  placeholder,
  customRootStyle,
  customInputStyle,
  onBlur,
  minHeight = 160,
  maxLength = 1000,
  disabled,
}: Props) => {
  return (
    <TextInput
      value={text}
      setInputValue={onChangeText}
      placeHolder={placeholder}
      label={label}
      customProps={{
        multiline: true,
        numberOfLines,
        autoComplete: 'off',
        maxLength,
      }}
      customRootStyle={customRootStyle}
      customStyle={{minHeight, ...customInputStyle}}
      setBlur={onBlur}
      disabled={disabled}
    />
  );
};
