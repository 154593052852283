import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import {useMemo} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as workspace from 'store/slices/workspace';
import {useWorkspaceJoinMember} from '../hooks/useWorkspaceJoinMember';
import {WorkspaceCard} from './WorkspaceCard';

export function WorkspaceCardList({
  workspaces,
}: {workspaces: WorkspaceListDetail[]}) {
  const {onJoinButtonPress} = useWorkspaceJoinMember();

  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  const sort =
    useSelector(workspace.selectors.getWorkspaceSort) ?? workspace.defaultSort;

  const sortedWorkspaces = useMemo(() => {
    return workspaces.sort((a, b) => {
      if (sort.category === 'name') {
        return sort.order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });
  }, [workspaces, sort]);

  return (
    <ScrollView contentContainerStyle={styles.content}>
      {sortedWorkspaces.map(workspace => (
        <WorkspaceCard
          key={workspace.id}
          workspace={workspace}
          onJoinButtonPress={onJoinButtonPress}
          showArchivedButtons={isAdminOrOwner}
        />
      ))}
      {sortedWorkspaces.length === 0 && (
        <NoResultsAvailable message="No workspaces available to display." />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  content: {
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 15,
    gap: 10,
  },
});
