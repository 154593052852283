import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet, View} from 'react-native';

import type {PressableProps} from 'react-native';

export interface MenuItemProps extends PressableProps {
  icon?: React.ReactNode;
  label?: string;
  detailsText?: string;
  detailsIcon?: React.ReactNode;
  itemRef?: React.RefObject<View>;
  isEnabled?: boolean;
}

export function MenuItem(props: MenuItemProps) {
  return (
    <Pressable
      ref={props.itemRef}
      style={({hovered}) => [
        styles.root,
        hovered && styles.hover,
        !props.isEnabled && styles.disabled,
      ]}
      disabled={!props.isEnabled}
      {...props}>
      {({hovered}) => (
        <>
          {props.icon}
          <View style={styles.info}>
            {props.label && (
              <View style={styles.label}>
                <Typography
                  variant="medium"
                  size="sm"
                  color={theme.colors.neutral.$700}>
                  {props.label}
                </Typography>
              </View>
            )}
            {(props.detailsText || props.detailsIcon) && (
              <View style={styles.details}>
                {props.detailsIcon}
                <View style={styles.detailsText}>
                  <Typography
                    variant="medium"
                    size="sm"
                    color={
                      hovered
                        ? theme.colors.neutral.$500
                        : theme.colors.neutral.$400
                    }>
                    {props.detailsText}
                  </Typography>
                </View>
              </View>
            )}
          </View>
        </>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    padding: 6,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$0,
  },
  hover: {
    backgroundColor: theme.colors.neutral.$75,
  },
  text: {
    overflow: 'hidden',
    color: theme.colors.neutral.$700,
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
  },
  info: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  label: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingLeft: 6,
    paddingBottom: 0,
    paddingRight: 6,
    alignItems: 'center',
  },
  detailsLabel: {
    overflow: 'hidden',
    color: theme.colors.neutral.$400,
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
  },
  detailsLabelHover: {
    color: theme.colors.neutral.$500,
  },
  details: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  detailsText: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingLeft: 6,
    paddingBottom: 0,
    paddingRight: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.4,
    userSelect: 'none',
    pointerEvents: 'none',
  },
});
