import {t} from '@lingui/macro';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import type {Member} from 'store/slices/settings/types';
import * as workspace from 'store/slices/workspace';
import WorkspacesCardsSkeleton, {
  WorkspaceCardSkeleton,
} from '../../skeleton/WorkspacesCardsSkeleton';
import AddItemCard from '../common/AddItemCard';
import WorkspaceCard from './WorkspaceCard';

interface WorkspacesCardsProps {
  workspaces: WorkspaceListDetail[];
  members?: Member[];
  isMembersLoading?: boolean;
  onNewWorkspacePress?: () => void;
  onWorkspacePress: (workspace: WorkspaceListDetail) => void;
  isReloadingWorkspaces?: boolean;
}

const WorkspacesCards = ({
  workspaces,
  members = [],
  isMembersLoading = false,
  onNewWorkspacePress,
  onWorkspacePress,
  isReloadingWorkspaces = false,
}: WorkspacesCardsProps) => {
  const workspacesLoading = useSelector(
    workspace.selectors.getWorkspacesLoading,
  );

  const workspacesLoaded = useSelector(workspace.selectors.getWorkspacesLoaded);

  const showCardsSkeleton =
    (workspacesLoading && !workspaces?.length) || !workspacesLoaded;

  if (showCardsSkeleton) {
    return <WorkspacesCardsSkeleton />;
  }

  const showFirstCardSkeleton =
    isReloadingWorkspaces || (workspaces.length === 0 && isMembersLoading);

  return (
    <View style={styles.grid}>
      {showFirstCardSkeleton && <WorkspaceCardSkeleton />}
      {workspaces.map((workspace, index) => (
        <WorkspaceCard
          key={`workspace-card-${index}`}
          workspace={workspace}
          onWorkspacePress={onWorkspacePress}
        />
      ))}
      {Boolean(members?.length) && onNewWorkspacePress && (
        <AddItemCard
          onPress={onNewWorkspacePress}
          label={t`Create New Workspace`}
          ariaLabel="Add New Workspace"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 24,
    gap: 16,
  },
});

export default WorkspacesCards;
