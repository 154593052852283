import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {getFileTypeFromMime, typeToIcon} from 'utils/fast/files';
import type {AiChatFilterType} from '../../../consts';

interface Props {
  item: FilesItem;
  type: AiChatFilterType;
  onRemove?: () => void;
}

export function AiChatChip({item, onRemove, type}: Props) {
  const showRemove = !!onRemove;
  const fileType = getFileTypeFromMime(item);
  const colorIcon =
    type === 'scope' ? theme.colors.brand.$1_400 : theme.colors.neutral.$700;
  const colorText =
    type === 'scope' ? theme.colors.brand.$1_500 : theme.colors.neutral.$700;
  return (
    <View style={styles.container}>
      <Image
        style={[styles.icon, {tintColor: colorIcon}]}
        source={{uri: typeToIcon(fileType)}}
        resizeMode="center"
      />
      <Typography
        size="xs"
        color={colorText}
        numberOfLines={1}
        overrides={styles.text}>
        {item.name}
      </Typography>
      {showRemove && (
        <Pressable onPress={onRemove}>
          <Icon name="lucide:x" size={16} color={colorText} />
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 99,
    height: 22,
    borderWidth: 1,
    borderColor: 'rgba(34, 40, 53, 0.15)',
    paddingVertical: 4,
    flexDirection: 'row',
    paddingStart: 8,
    paddingEnd: 4,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 200,
  },
  text: {
    paddingHorizontal: 6,
  },
  icon: {
    width: 16,
    height: 16,
  },
});
