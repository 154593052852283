import theme from 'config/theme';
import {Outlet, useParams} from 'extensions/navigation';
import {WorkspaceFrame} from 'interface/stacks/workspace/base/WorkspaceFrame';
import {useDragDropTarget} from 'interface/stacks/workspace/storage/hooks/useDragDropTarget';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import useGetShareDetails from '../hooks/useGetShareDetails';
import {useShareFilesNavigation} from '../hooks/useShareFilesNavigation';

export interface ShareMemberListItem {
  id: string;
  permissions: string;
}

export function ShareLayout() {
  const ref = useRef<View>(null);
  const {sharedCustomName} = useParams();
  const {uploader} = useWorkspaceContext();
  const {share} = useGetShareDetails({
    sharedCustomName,
  });
  const shareFilesNavigation = useShareFilesNavigation();
  const {isDropping} = useDragDropTarget({
    ref,
    targetNs: 'share',
    targetId: shareFilesNavigation.folderKey,
    targetName: share?.name,
    instanceNs: 'share',
    instanceId: share?.id,
    uploader,
    options: {
      isUploadOnly: true,
    },
  });

  if (!share) {
    return null;
  }

  return (
    <View ref={ref} style={styles.root}>
      <View style={styles.inner}>
        <Outlet context={[share, uploader, shareFilesNavigation]} />
      </View>
      {isDropping && (
        <WorkspaceFrame mode="upload" color={theme.colors.brand.$4Base} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  inner: {
    flex: 1,
  },
});
