import {Popup} from 'extensions/viewport/Popup';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {FilesItem} from 'store/slices/files/types';
import type {AiChatFilterType} from '../../../consts';
import {AiChatSearch} from './AiChatSearch';

interface Props {
  trigger: (onPress: () => void) => React.ReactNode;
  type: AiChatFilterType;
}

const MAX_ATTACH_FILES = 5;

export function AiChatSearchPopup({trigger, type}: Props) {
  const dispatch = useDispatch();
  const [hasMenu, setHasMenu] = useState(false);
  const toast = useToast();

  const {namespace, instanceId, instanceName, name, workspaceObj} =
    useGetNamespaceContext();

  const filterList = useSelector(
    type === 'scope'
      ? aichat.selectors.getScopeFiles()
      : aichat.selectors.getAttachFiles(),
  );
  const fileList = Object.values(filterList);

  const handleAdd = (file: FilesItem) => {
    if (type === 'scope') {
      dispatch(aichat.default.actions.addScopeFile({file}));
    } else {
      if (fileList.length >= MAX_ATTACH_FILES) {
        toast.show('You can only attach up to 5 files');
        return;
      }
      dispatch(aichat.default.actions.addAttachFile({file}));
    }
  };

  const handleRemove = (file: FilesItem) => {
    if (type === 'scope') {
      dispatch(aichat.default.actions.removeScopeFile({fileId: file.id}));
    } else {
      dispatch(aichat.default.actions.removeAttachFile({fileId: file.id}));
    }
  };

  const filterForShowing = (item: FilesItem) => {
    return !filterList[item.id];
  };

  const filterForEnabling = (item: FilesItem) => {
    if (type === 'scope') {
      if (item.type === 'folder') {
        return true;
      }
      return item.ai?.state === 'ready';
    }
    return item.ai?.attach === true;
  };

  return (
    <Popup
      triggerElement={trigger(() => setHasMenu(true))}
      isOpen={hasMenu}
      placement="top-start"
      close={() => setHasMenu(false)}>
      {/* TODO: AICHAT: Handle for Shares */}
      <AiChatSearch
        files={fileList}
        onAdd={handleAdd}
        onRemove={handleRemove}
        instanceAlt={instanceName}
        instanceId={instanceName}
        instanceName={name}
        instanceNs={namespace}
        filterForShowing={filterForShowing}
        filterForEnabling={filterForEnabling}
      />
    </Popup>
  );
}
