import {Router} from 'extensions/navigation';
import SessionProvider from 'extensions/session/SessionProvider';
import {Navigator} from 'interface/Navigator';
import {history} from 'store';

export const NavigatorHistory = () => {
  return (
    <Router history={history}>
      <SessionProvider>
        <Navigator />
      </SessionProvider>
    </Router>
  );
};
