import {format} from 'date-fns';
import {api} from 'fast-sdk';
import {type Subsystem, SystemStatus} from 'fast-sdk/src/api/platform/consts';
import {useEffect, useMemo, useState} from 'react';

export enum Status {
  OPERATIONAL = 'operational',
  DEGRADED = 'degraded',
  DOWN = 'down',
}

export type StatusItem = {
  name: string;
  status: Status;
  statusText: string;
  updatedAt: string;
};

const isOperational = (status: SystemStatus) => {
  return status === SystemStatus.operational;
};

const isDegraded = (status: SystemStatus) => {
  return status === SystemStatus.degradedPerformance;
};

const isDown = (status: SystemStatus) => {
  return [
    SystemStatus.partialOutage,
    SystemStatus.majorOutage,
    SystemStatus.underMaintenance,
  ].includes(status);
};

const getSystemStatus = (systemStatus: SystemStatus): Status => {
  if (isOperational(systemStatus)) {
    return Status.OPERATIONAL;
  }

  if (isDegraded(systemStatus)) {
    return Status.DEGRADED;
  }

  return Status.DOWN;
};

const getSystemStatusText = (systemStatus: SystemStatus): string => {
  switch (systemStatus) {
    case SystemStatus.operational:
      return 'Operational';
    case SystemStatus.degradedPerformance:
      return 'Degraded Performance';
    case SystemStatus.partialOutage:
      return 'Partial Outage';
    case SystemStatus.majorOutage:
      return 'Major Outage';
    case SystemStatus.underMaintenance:
      return 'Under Maintenance';
  }
};

const TOP_LEVEL_NAME = {
  ai: 'AI',
  api: 'API',
  apps: 'Apps',
  notifications: 'Notifications',
  storage: 'Storage',
  transformations: 'Transformations',
  web: 'Web',
};

const isTopLevelAPIStatus = (name: string) => {
  const topLevelNames = Object.values(TOP_LEVEL_NAME);
  return topLevelNames.some(topLevelName => name.startsWith(topLevelName));
};

export default function useSystemStatus() {
  const [loading, setLoading] = useState(true);
  const [overallStatus, setOverallStatus] = useState<Status>(
    Status.OPERATIONAL,
  );
  const [statuses, setStatuses] = useState<Array<StatusItem>>([]);

  useEffect(() => {
    async function fetchStatus() {
      const response = await api.platform.systemStatus();

      if (response.result) {
        const {statuses} = response;
        const items: Array<StatusItem> = [];
        let allStatus: Status = Status.OPERATIONAL;
        for (const name in statuses) {
          if (!isTopLevelAPIStatus(name)) {
            continue;
          }

          const {status, updated_at} = statuses[name] as Subsystem;
          items.push({
            name,
            status: getSystemStatus(status),
            statusText: getSystemStatusText(status),
            updatedAt: format(
              new Date(`${updated_at} UTC`),
              'MMM. d, yyyy, h:mm a',
            ),
          });

          if (allStatus === Status.OPERATIONAL && isDegraded(status)) {
            allStatus = Status.DEGRADED;
            continue;
          }

          if (
            (allStatus === Status.OPERATIONAL ||
              allStatus === Status.DEGRADED) &&
            isDown(status)
          ) {
            allStatus = Status.DOWN;
          }
        }
        setStatuses(items);
        setOverallStatus(allStatus);
      }

      setLoading(false);
    }

    fetchStatus();
  }, []);

  const label = useMemo(() => {
    if (overallStatus === Status.OPERATIONAL) {
      return 'Operational';
    }

    if (overallStatus === Status.DEGRADED) {
      return 'Degraded Performance';
    }

    return 'Partial Outages';
  }, [overallStatus]);

  return {overallStatus, statuses, loading, label};
}
