import theme from 'config/theme';
import {Loading} from 'interface/common/Loading';
import {StyleSheet, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import {MemberTableRow} from './MemberTableRow';

import {InvitationStatuses} from 'fast-sdk/src/api/share/consts';
import Typography from 'interface/base/Typography';
import {useSelector} from 'react-redux';
import type {Invite, Member} from 'store/slices/settings/types';
import {selectors} from 'store/slices/user';

const tableHeads = ['Name', 'Permission', 'Added By', 'Date Joined', ''];

interface MembersTableProps {
  loading: boolean;
  members: Array<Member>;
  invites: Array<Invite>;
  onUpdate: () => void;
}

const getInviteInfo = (invites: Array<Invite>, email: string) => {
  const inviteInfo = invites.find(invite => invite.email === email);
  return inviteInfo
    ? {
        ...inviteInfo,
      }
    : {};
};

const mergeMembers = (members: Array<Member>, invites: Array<Invite>) => {
  const membersWithInvites = members.map(member => ({
    ...getInviteInfo(invites, member.email),
    ...member,
  }));

  const invitesNotAccepted = invites.filter(
    invite => invite.state === InvitationStatuses.Pending,
  );

  const memberWithoutDuplicates = membersWithInvites.filter(
    member => !invitesNotAccepted.some(invite => invite.email === member.email),
  );

  return [...memberWithoutDuplicates, ...invitesNotAccepted].sort((a, b) => {
    return a.email.localeCompare(b.email);
  });
};

export function MembersTable({
  members,
  invites,
  loading,
  onUpdate,
}: MembersTableProps) {
  const userDetails = useSelector(selectors.getUserDetails);
  const membersAndInvites = mergeMembers(members ?? [], invites ?? []);
  const currentMember = members?.find(item => item.id === userDetails?.id);

  return loading ? (
    <Loading />
  ) : (
    <View role="table" style={styles.root}>
      <View style={styles.header}>
        {tableHeads.map((TH, idx) => (
          <Typography
            key={TH}
            variant="bold"
            size="xs"
            color={theme.colors.neutral.$700}
            overrides={[
              idx === 0 && {
                width: '35%',
              },
              idx === 1 && {
                width: '15%',
              },
              idx === 2 && {
                width: '25%',
              },
              idx === 3 && {
                width: '15%',
              },
              idx === 4 && {
                width: '10%',
              },
            ]}>
            {TH}
          </Typography>
        ))}
      </View>
      {membersAndInvites?.map(member => (
        <MemberTableRow
          key={member.id}
          memberOrInvite={member}
          color={getHashColor(member.id)}
          currentMember={currentMember}
          onUpdate={onUpdate}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$14,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
  },
  tableContainer: {},
});
