import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {VARIANT as BUTTON_VARIANT, Button, SIZE} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {useOrgLogo} from 'interface/base/OrgLogo';
import {OrgLogoSize} from 'interface/base/OrgLogo/types';
import Typography, {VARIANT} from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import {useNavigateToGo} from 'interface/common/hooks/navigator/useNavigateToGo';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {UserBadge} from 'interface/stacks/auth/components/UserBadge';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';

interface ShareNotAvailableProps {
  isLoggedIn?: boolean;
}

export default function ShareNotAvailable({
  isLoggedIn = false,
}: ShareNotAvailableProps) {
  const {subdomain} = useSubDomain();
  const {logout} = useLogout();
  const {navigateToGo} = useNavigateToGo();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const userProfilePic = useSelector(user.selectors.getUserProfilePic);

  const {logo, org, loading} = useOrgLogo({
    orgId: subdomain,
    size: OrgLogoSize.ExtraLarge,
  });

  const handleSignIn = async () => {
    if (isLoggedIn) {
      await logout({navigateTo: NavigateTo.NONE});
    }

    navigateToGo(
      `${ROUTES.NOT_LOGGED_IN.SIGNIN}?redirectTo=${window.location.href}`,
    );
  };

  const handleSignUp = () => {
    navigateToGo(
      `${ROUTES.NOT_LOGGED_IN.SIGNUP}?redirectTo=${window.location.href}`,
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.card}>
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <Icon
              name="lucide:info"
              size={18}
              color={theme.colors.neutral.$700}
              opacity={0.5}
            />
            <Typography color={theme.colors.neutral.$500}>
              {t`${
                isLoggedIn
                  ? 'You don’t have permission to view this Share.'
                  : 'Please sign in below to view this Share.'
              }`}
            </Typography>
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.signInContainer}>
            <View style={styles.signInTextContainer}>
              <Typography size={'2xl'} color={theme.colors.neutral.$700}>
                {t`${isLoggedIn ? 'Signed in to' : 'Sign in to'}`}
              </Typography>
              {loading ? <Skeleton width={56} height={56} /> : logo}
              {loading ? (
                <Skeleton width={120} height={34} />
              ) : (
                <Typography
                  size={'2xl'}
                  variant={VARIANT['semi-bold']}
                  color={theme.colors.neutral.$700}>
                  {org?.name}
                </Typography>
              )}
            </View>
            {isLoggedIn && (
              <UserBadge
                id={userDetails.id}
                email={userDetails.email_address}
                imageSrc={userProfilePic}
                initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
              />
            )}
            <Typography
              color={theme.colors.neutral.$700}
              overrides={{textAlign: 'center'}}>
              {t`${
                isLoggedIn
                  ? 'This share can’t be accessed with your account. Please sign in with a different account or contact the Org Admin for help.'
                  : 'This Organization requires you to sign in to your Fastio account to access this Share.'
              }`}
            </Typography>
          </View>
          <Button size={SIZE.lg} onPress={handleSignIn}>
            {t`${isLoggedIn ? 'Try different account' : 'Sign in to Fastio'}`}
          </Button>
        </View>
      </View>
      {!isLoggedIn && (
        <View style={styles.footer}>
          <Typography size="sm" color={theme.colors.neutral.$400}>
            {t`Don't have an account?`}
          </Typography>
          <Button
            variant={BUTTON_VARIANT.text}
            size={SIZE.sm}
            onPress={handleSignUp}>
            {t`Create Account`}
          </Button>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    borderRadius: 16,
    width: '100%',
    maxWidth: 500,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    marginBottom: 20,
  },
  header: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundImage: 'linear-gradient(90deg, #F0F6FF 0%, #EBEBFF 100%)',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 16,
    paddingHorizontal: 8,
    zIndex: -1,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  signInContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    marginBottom: 32,
  },
  signInTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
  content: {
    padding: 32,
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  },
});
