import type {ApiError} from './types';

export enum ApiErrors {
  ApiUserTokenBasicInvalid = 10001,
  RequestTypeInvalid = 10003,
  ApiUserTokenUserInvalid = 10004,
  ApiUserTokenPassInvalid = 10005,
  UserNotFound = 10006,
  PassNotSet = 10007,
  CredentialsInvalid = 10008,
  JwtCreation = 10009,
  Disabled = 10010,
  AuthorizationInvalid = 10011,
  InitTokenObject = 10012,
  TokenUserSet = 10014,
  TokenTooManyKeys = 10015,
  TokenMemoInvalid = 10016,
  TokenCreate = 10017,
  TokenStore = 10018,
  TokenInvalid = 10019,
  TokenMissing = 10020,
  TokenDelete = 10021,
  EmailInvalid = 10022,
  UserMiscompare = 10023,
  UserNotLoggedIn = 10024,
  UserEmailInvalid = 10025,
  UserPassInvalid = 10026,
  UserFirstNameInvalid = 10027,
  UserPhoneNumberInvalid = 10029,
  UserNothingToUpdate = 10030,
  UserPassEncodeFailure = 10031,
  UserCommitChanges = 10032,
  UserCodeInvalid = 10033,
  UserCodeCreate = 10035,
  UserEmailAlreadyVerified = 10037,
  StorageProviderGenericInit = 10038,
  SsoProviderInvalid = 10041,
  ProviderInvalid = 10043,
  ProviderDeleteFailed = 10044,
  ServerConfigExists = 10045,
  CdnNotReady = 10076,
  CdnProviderGenericInit = 10064,
  CdnGetProviderList = 10065,
  ServerInit = 10069,
  ServerUseridSet = 10070,
  DomainInvalidConfigName = 10071,
  DomainRestrictedServerName = 10072,
  DomainConfigNameInUse = 10073,
  ServerNotReady = 10074,
  ServerInvalidConfigValue = 10079,
  ServerAnalyticsProfileInvalid = 10081,
  ServerCdnProviderInvalid = 10082,
  ServerConfigFailure = 10083,
  ServerConfigStore = 10085,
  ServerConfigMissing = 10086,
  ServerDeleteFailed = 10087,
  ServerConfigInvalid = 10088,
  ServerUserConfigsGet = 10089,
  ServerDeletedNoop = 10090,
  ServerFolderExistsCreate = 10091,
  ServerInitStorage = 10092,
  ServerPathGet = 10096,
  StorageAuth = 10097,
  StorageInvalidResult = 10098,
  ServerLockedNoop = 10099,
  ServerCdnDeliveryFailed = 10100,
  ServerCdnDeliveryFailedInternal = 10101,
  EventIdInvalid = 10102,
  AccountClosed = 10103,
  AccountLocked = 10104,
  AccountSuspended = 10105,
  AccountDmca = 10106,
  LogInit = 10107,
  LogStartInvalid = 10108,
  LogEndInvalid = 10109,
  LogIntervalFailed = 10110,
  LogMaxDaysOverflow = 10111,
  LogConstMissing = 10112,
  LogFormatInvalid = 10113,
  StatsInitDatetime = 10114,
  StatsQueryFailed = 10115,
  StatsIntervalFailed = 10116,
  StatsMaxDaysOverflow = 10117,
  StatsStartInvalid = 10118,
  StatsEndInvalid = 10119,
  EventGetNotFound = 10124,
  EventFailedToAck = 10125,
  FlushFailedToFlushAfterCreation = 10126,
  FlushFailedToWebhookAfterCreation = 10136,
  FlushFailedBeforeDelete = 10146,
  ServerStorageSwitchAuthFailed = 10147,
  ServerConfigOrigInvalid = 10148,
  FlushFailedAfterUpdate = 10149,
  ServerSwitchStorageFolderExistsCreate = 10151,
  ServerInvalidConfigExpires = 10160,
  StoreConfigStore = 10161,
  ServerConfigErrorPath = 10162,
  UserPhoneCountryInvalid = 10163,
  UserChangeDisable2factorFirst = 10164,
  UserPhoneFullInvalid = 10165,
  TwoFactorAlreadyAdded = 10167,
  TwoFactorNoPhoneAdded = 10168,
  TwoFactorRemovalFailed = 10169,
  TwoFactorVerificationNotEnabled = 10170,
  TwoFactorVerifyUpdateFailed = 10171,
  TwoFactorNotEnabled = 10172,
  TwoFactorInvalidToken = 10173,
  TwoFactorTokenFailed = 10174,
  AuthorizationScopeIncorrect = 10175,
  BillingPlanInvalid = 10176,
  BillingBillingInit = 10177,
  BillingTokenInvalid = 10178,
  SubscriptionExists = 10179,
  SubscriptionCreateFailed = 10180,
  SubscriptionStateUnknown = 10182,
  SubscriptionUpdateFailed = 10183,
  SubscriptionCancelMissing = 10184,
  SubscriptionCancelFailed = 10185,
  SubscriptionResetFailed = 10186,
  SubscriptionDetailsFailed = 10188,
  SubscriptionUpdateEmailFailed = 10190,
  SubscriptionAccountCloseCancelFailed = 10191,
  WebhookSignatureInvalidStripe = 10192,
  WebhookSignatureInvalidStripeParts = 10193,
  WebhookSignatureInvalidStripeHmac = 10194,
  WebhookEnqueueFailedStripe = 10195,
  SubscriptionOnlyTestModeAction = 10196,
  PasswordCodeInvalid = 10197,
  PasswordCodeNotFoundOrExpired = 10198,
  PasswordCodeExpired = 10199,
  PasswordCodeUserMismatch = 10200,
  PasswordCodeUserNotFound = 10201,
  PasswordDontMatch = 10202,
  PasswordEncryptionFailed = 10203,
  PasswordCommitFailed = 10204,
  PasswordGetJwtFailed = 10205,
  PasswordAccountLockedNoChange = 10207,
  BillingPlanFree = 10215,
  ServerDisableAllFailed = 10217,
  AppStatusInit = 10220,
  AppStatusInvalidChecks = 10221,
  AppStatusInvalidLastactive = 10225,
  SsoProviderUnknown = 10226,
  SsoInvalidInput = 10230,
  ServerInvalidFilterMode = 10235,
  ServerInvalidFilter = 10236,
  ServerInvalidPassword = 10238,
  ServerInvalidOption = 10240,
  ServerNoChangesFound = 10241,
  DomainsGenericInit = 10244,
  DomainsProviderUseridSet = 10245,
  DomainsNotReady = 10246,
  DomainsProviderUserGet = 10247,
  DomainsProviderInvalid = 10249,
  DomainsGetProviderDetails = 10250,
  AppsGenericInit = 10254,
  Oauth2SsoDenied = 10260,
  AppsProviderInvalidName = 10267,
  AppsInvalidConfigValue = 10269,
  AppsInvalidConfigType = 10270,
  DomainsProviderInit = 10286,
  DomainsConfigMissing = 10287,
  DomainsConfigFailure = 10288,
  DomainsConfigStore = 10289,
  DomainsDeleteFailed = 10290,
  DomainsUserConfigsGet = 10291,
  DomainsProvidersGetList = 10292,
  DomainsBlacklistedName = 10294,
  DomainsInvalidName = 10295,
  DomainsConfigExists = 10296,
  DomainsProviderDnsMissing = 10297,
  DomainsRegistryCheckFailed = 10298,
  DomainsUnregRequiresDns = 10299,
  DomainsProviderRegistryMissing = 10300,
  DomainsRegAlready = 10301,
  DomainsAutorenewInvalid = 10302,
  DomainsRegisterFailed = 10303,
  DomainsOrphanedDomain = 10304,
  DomainsDeleteRegRejected = 10305,
  DomainsZoneCreate = 10306,
  DomainsZoneDelete = 10307,
  DomainsZoneGet = 10308,
  DomainsZoneMissingNs = 10309,
  DomainsNotPurchasable = 10310,
  DomainsZoneExists = 10311,
  DomainsProviderDisabled = 10312,
  DomainsConfigInvalid = 10313,
  BindingInvalidVanityName = 10314,
  BindingInvalidOriginName = 10315,
  BindingInvalidOriginDomain = 10316,
  BindingOriginVanityMatch = 10317,
  BindingOriginVanityFqdn = 10318,
  BindingOriginOriginFqdn = 10319,
  BindingServerOriginUnusable = 10320,
  BindingConfigFailure = 10321,
  BindingConfigStore = 10322,
  BindingConfigExists = 10323,
  DnsRecordCreate = 10324,
  BindingConfigMissing = 10325,
  BindingConfigInvalidType = 10326,
  DnsRecordDelete = 10328,
  DnsRecordExternalCreate = 10330,
  BindingGetList = 10332,
  BindingLookup = 10333,
  BindingServerLoad = 10334,
  BindingSslStatus = 10335,
  DomainActivate = 10336,
  ServerFlushConfig = 10337,
  DomainDnsNotInternal = 10338,
  DomainDnsNotSupported = 10339,
  DomainDnsFilterInvalidType = 10340,
  DomainDnsFilterInvalidName = 10341,
  DomainDnsInvalidType = 10342,
  DomainDnsInvalidName = 10343,
  DomainDnsInvalidContent = 10344,
  DomainDnsInvalidTtl = 10345,
  DomainDnsInvalidPriority = 10346,
  DomainDnsPriorityNotSupported = 10347,
  DomainDnsRecordLocked = 10348,
  DomainsPurchaseFailed = 10349,
  StripeRefundFailed = 10350,
  UserFraudRisk = 10354,
  BillingFeatureServerPasswords = 10356,
  BillingFeatureCustomErrors = 10358,
  BillingFeatureRawLogs = 10359,
  BillingLimitsCustomDomains = 10361,
  BillingLimitsSslBindings = 10362,
  BillingLimitsServers = 10363,
  DomainDnsQueueInsert = 10365,
  DomainBindingInsert = 10367,
  EnhanceCalm = 10368,
  ServerSwitchStorageInitFailed = 10370,
  ServerSwitchStorageSetFailed = 10371,
  ServerInvalidStorageBranch = 10372,
  ServerInvalidStorageRoot = 10374,
  StorageReposGet = 10377,
  StorageBranchesGet = 10379,
  StorageBranchNotSet = 10380,
  StorageRepoNotSet = 10381,
  UserInit = 10382,
  StoreFailed = 10384,
  DomainConfigStore = 10385,
  DomainInvalidConfig = 10386,
  DomainInvalidAutorenewConfig = 10387,
  DomainConfigUpdateFailure = 10388,
  ServerRedirectPrimaryInvalid = 10389,
  UserTosInvalid = 10394,
  UserTosDecline = 10395,
  UserNothingToCreate = 10396,
  FlushFailedBeforeUndelete = 10397,
  ServerUndeleteFailed = 10398,
  CustomerUpdateFailed = 10401,
  ServerInvalidStorageNotVcs = 10402,
  CustomerGetFailed = 10403,
  CustomerGetCorrupted = 10404,
  PlanInit = 10405,
  PaymentUpdateFailed = 10406,
  NotACustomer = 10408,
  CustomerNoInvoicesFound = 10409,
  DeleteAllStorageProviders = 10410,
  DeleteAStorageProvider = 10411,
  AssetInvalidScheme = 10415,
  AssetInvalidMetadata = 10416,
  AssetsInsertFailure = 10417,
  AssetUploadMissing = 10418,
  AssetImageOutputSetFailure = 10419,
  AssetLoadImageFailure = 10420,
  AssetMissingFile = 10421,
  AssetsFailedToStore = 10422,
  AssetsUploadFailed = 10423,
  AssetsInvalidId = 10424,
  AssetDeleteFailure = 10425,
  AssetsGetFailed = 10426,
  StoragePairNotLoggedIn = 10427,
  CodePassEncryptFailure = 10429,
  AssetsRotateFail = 10445,
  AssetsCropFail = 10446,
  AssetsResizeFail = 10447,
  AssetOutput = 10450,
  AssetInvalidType = 10452,
  RequestExpiresInvalid = 10454,
  ServerInvalidPath = 10455,
  ServerNodeFiltered = 10456,
  ServerUnsupportedContent = 10457,
  VirusFound = 10458,
  FileTooBig = 10459,
  InvalidUrlForConversion = 10460,
  ConversionSizeTooSmall = 10461,
  TransferFailed = 10463,
  ImageAssetProcessing = 10464,
  AssetImageDisabled = 10485,
  ImageResizeDisabled = 10486,
  ServerHtmlRenderDenied = 10487,
  ServerIndexFilesDenied = 10488,
  UploadObjectInit = 10489,
  UploadFailedToInsert = 10500,
  UploadTooLargeForAcct = 10501,
  UploadTooManyChunksForAcct = 10502,
  UploadTooManySessions = 10504,
  UploadChunkSizeSmall = 10505,
  UploadChunkSizeBig = 10506,
  UploadIdInvalid = 10507,
  UploadIdNotFound = 10508,
  UploadDeleteFailed = 10509,
  UploadChunkSize = 10511,
  UploadChunkHashFailed = 10512,
  UploadChunkStoreFailed = 10513,
  UploadChunkOrderInvalid = 10514,
  UploadDeleteChunkFailed = 10515,
  UserCreate = 10517,
  BillingCreateFreeCustomer = 10518,
  CheckUserInsert = 10519,
  UploadSetStateFailed = 10520,
  UploadNoChunks = 10521,
  UploadAssembleMissingChunks = 10522,
  UploadAssembleEnqueueFailed = 10523,
  BillingCustomerMissing = 10524,
  UserTrialEndFailed = 10527,
  OauthFallthrough = 10529,
  CookiesMissing = 10530,
  WebhookStripeBody = 10538,
  CreateCheckoutSession = 10539,
  InvalidUrl = 10540,
  VaultFailure = 10541,
  OrgDomainInvalid = 10542,
  OrgCreate = 10543,
  OrgConfigInvalid = 10544,
  OrgNotAuthorized = 10545,
  OrgNothingToUpdate = 10547,
  OrgCommitChanges = 10548,
  OrgDomainMismatch = 10549,
  SubscriptionOrgCloseCancelFailed = 10550,
  WsInvalidConfigName = 10555,
  WorkspaceConfigNameInUse = 10556,
  OrgConfigNameInUse = 10557,
  WsConfigInvalid = 10558,
  WsCreate = 10559,
  WsCommitChanges = 10561,
  WsNothingToUpdate = 10562,
  WsNameMismatch = 10563,
  ShareInvalidConfigName = 10564,
  ShareConfigNameInUse = 10565,
  ShareConfigInvalid = 10566,
  ShareCreate = 10567,
  ShareCommitChanges = 10568,
  ShareInvalidExpires = 10569,
  ShareNothingToUpdate = 10570,
  ShareNameMismatch = 10571,
  ProfileInvalidName = 10580,
  ProfileNotFound = 10581,
  ProfileClosed = 10582,
  ProfileLocked = 10583,
  ProfileSuspended = 10584,
  ProfileDmca = 10585,
  ProfileInsufficientPermissions = 10586,
  UserNotValidated = 10587,
  MembershipExists = 10599,
  MembershipPermissionDenied = 10601,
  MembershipInviteInvalidEmail = 10602,
  ExpiresInvalid = 10603,
  MembershipCommitChanges = 10604,
  MembershipUserSame = 10605,
  MembershipNotExists = 10606,
  MembershipDeleteFailed = 10607,
  ProfileTypeInvalid = 10610,
  ProfileNotLoaded = 10611,
  ProfilePermissionValidationError = 10612,
  MembershipNoChanges = 10613,
  MembershipOwnerSame = 10614,
  MembershipDomainDisabled = 10616,
  MembershipDomainDenied = 10617,
  MembershipInviteInvalidId = 10618,
  MembershipJoinDenied = 10619,
  UpdatePermissionDenied = 10620,
  MembershipRemovalOnlyOwner = 10621,
  ChildCreationFailed = 10622,
  AuthThrottleKeyInvalid = 10623,
  InvalidProfileIdInput = 10624,
  MembershipInviteAddFailed = 10625,
  MembershipInviteInvalidKey = 10626,
  MembershipInviteInvalidAction = 10627,
  MembershipInviteUseFailed = 10628,
  MembershipInviteInvalidMessage = 10629,
  MembershipInviteGetInvitationFailed = 10630,
  MembershipInviteInvalidState = 10631,
  UploadOrgDenied = 10633,
  UploadGetChunksFailed = 10634,
  UploadChunkMissing = 10635,
  OrgNotSubscribed = 10639,
  UploadAssembleFailed = 10640,
  UploadTooLarge = 10641,
  UploadCompleteFailed = 10642,
  PaymentIntentCreateFailed = 10660,
  OrgCloseVaultFailed = 10661,
  VaultCreate = 10662,
  InstanceCreate = 10663,
  BillingPaymentRequired = 10664,
  BillingPaymentMethodInvalid = 10665,
  MgmtConsoleMissingUser = 10666,
  MgmtConsoleLoginFailure = 10667,
  InvalidTransformation = 10668,
  UnableToRender = 10669,
  UnableToRetrievePreview = 143705,
  UploadFileNotCompleted = 165170,
  NotAllowedUpdateShare = 107670,
  CreateShareFieldType = 169650,
  CreateShareFieldMissing = 205516,
  UnexpectedError = 90211,
  RequestAborted = 90212,
  ShareCannotViewMembers = 161224,
  CorsError = 'cors',
  UnknownError = 99999,
}

export const matchHandleableError = (
  apiError: ApiError | undefined | null,
  lookupError: ApiErrors,
) => {
  return apiError?.code === lookupError;
};
