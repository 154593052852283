import {format, isToday, isYesterday} from 'date-fns';
import {bytesize} from 'utils/common/data';
import {parseServerDate} from 'utils/common/dates';

import type {FileExifMetadata} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

export const getFileExifMetadata = (
  file: FilesItem,
): Partial<FileExifMetadata> | undefined => {
  if (
    !file.file_attributes ||
    Array.isArray(file.file_attributes) ||
    !file.file_attributes.exif_metadata ||
    Array.isArray(file.file_attributes)
  ) {
    return undefined;
  }

  const created = parseServerDate(file.created);
  const createdFormatted = renderDate(created);
  return {
    // @ts-expect-error - Metadata augmentation
    UploadDate: createdFormatted,
    ...file.file_attributes.exif_metadata,
  };
};

export const formatExifMetadataItem = ([k, v]: [string, string | number]) => {
  let key = k;
  let val = v;
  switch (key) {
    case 'UploadDate':
      key = 'Date Uploaded';
      val = val.toString();
      break;
    case 'CreateDate':
      key = 'Date Created';
      val = renderDate(fixDate(val.toString()));
      break;
    case 'ISO':
      key = 'ISO';
      break;
    case 'ExifByteOrder':
      key = 'Exif Byte Order';
      break;
    case 'MIMEType':
      key = 'MIME Type';
      break;
    case 'ImageHeight':
      key = 'Resolution - Height';
      break;
    case 'ImageWidth':
      key = 'Resolution - Width';
      break;
    case 'PageCount':
      key = 'Page Count';
    default:
      // Convert to title case from pascal case
      key = key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());
  }
  return [key, val] satisfies [string, string | number];
};

export const renderDate = (date: Date) => {
  if (isToday(date)) return format(date, "'Today at' h:mm a");
  if (isYesterday(date)) return format(date, "'Yesterday at' h:mm a");
  return format(date, "MM/dd/yy 'at' h:mm a");
};

export const fixDate = (dateStr: string) => {
  return new Date(dateStr.replace(/(\d{4}):(\d{2}):(\d{2})\s/, '$1-$2-$3T'));
};
