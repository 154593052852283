import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet} from 'react-native';
import {NEW_PRIVATE_CHAT} from '../consts';
import AiChatPublishModal from './AiChatPublishModal';

interface Props {
  chatId: string;
}

export function PrivateChatButton({chatId}: Props) {
  const modal = useModal();
  const openModal = () => {
    if (chatId === NEW_PRIVATE_CHAT) return;
    modal.open(<AiChatPublishModal chatId={chatId} onClose={modal.close} />);
  };
  return (
    <HoveredView onPress={openModal} style={styles.container}>
      <Icon name="lucide:lock" color={theme.colors.neutral.$700} />

      <Typography size="xs" color={theme.colors.neutral.$700}>
        Private
      </Typography>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 4,
    height: 32,
    gap: 6,
    borderRadius: 6,
    alignItems: 'center',
  },
});
