import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

export function AiChatListSkeleton() {
  const items = Array.from({length: 3}, (_, index) => (
    <AiChatItemSkeleton key={index} />
  ));
  return (
    <>
      <Skeleton width={60} height={20} style={styles.groupTitle} />
      <View style={styles.chatList}>{items}</View>
    </>
  );
}

function AiChatItemSkeleton() {
  return (
    <View style={styles.container}>
      <Skeleton width={120} height={20} />
      <View style={styles.content}>
        <Skeleton width={30} height={30} style={styles.avatar} />
        <Skeleton width={45} height={20} />
        <Skeleton width={45} height={20} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 12,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$50,
    gap: 12,
  },
  content: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  avatar: {
    borderRadius: 12,
    paddingVertical: 4,
  },
  groupTitle: {
    marginVertical: 16,
  },
  chatList: {
    gap: 8,
  },
});
