import {t} from '@lingui/macro';
import {useModal} from 'extensions/viewport/useModal';
import {Prompt} from 'interface/common/Prompt';
import {useCallback} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import files from 'store/slices/files';
import {archive} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';

export function useModalTrash(
  ids: string[],
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const modal = useModal();
  const toast = useToast();
  const dispatch = useDispatch();

  const submit = useCallback(async () => {
    const {completes} = await archive(dispatch, ids, instanceId, instanceNs);
    if (completes.length === ids.length) {
      dispatch(files.actions.archiveFiles({ids}));
      toast.show('Moved items to trash');
    } else {
      toast.show(t`Failed to move items to trash`, {type: 'danger'});
    }
    modal.close();
  }, [ids, instanceId, instanceNs]);

  const open = useCallback(() => {
    modal.open(
      <Prompt
        title={t`Move to Trash`}
        text={t`Are you sure you want to move these items to Trash? You can always restore items from your trash if you change your mind.`}
        buttons={[
          {
            variant: 'secondary',
            text: t`Cancel`,
            onPress: modal.close,
          },
          {
            variant: 'primary',
            text: t`Done`,
            onPress: submit,
            isSubmit: true,
          },
        ]}
        onClose={modal.close}
      />,
    );
  }, [ids]);

  return {open, close: modal.close};
}
