import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {FilesData} from '../hooks/useFetchShareFiles';
import type {ShareFilesNavigation} from '../hooks/useShareFilesNavigation';

interface Props {
  shareFilesNavigation: ShareFilesNavigation;
  filesData: FilesData;
}

export function ShareFooter({shareFilesNavigation, filesData}: Props) {
  const {folderKey, setTrashView} = shareFilesNavigation;

  const onTrashOpen = () => {
    setTrashView(true);
  };

  const isTrashView = folderKey === WORKSPACE_TRASH;
  const filesCount = filesData?.filesIds?.length;
  const displayFooter = Boolean(filesCount) || !isTrashView;
  const showFilesCount = Boolean(filesCount);

  if (!displayFooter) {
    return null;
  }

  return (
    <View
      style={[styles.root, !showFilesCount && {justifyContent: 'flex-end'}]}>
      {showFilesCount && (
        <Typography
          size="xs"
          color={theme.colors.neutral.$2Base}
          variant="medium">
          <Trans>
            {filesCount} {filesCount === 1 ? 'item' : 'items'}
          </Trans>
        </Typography>
      )}
      {!isTrashView && (
        <Button
          onPress={onTrashOpen}
          variant="secondary"
          size="sm"
          startEnhancer={
            <Icon name="lucide:trash" color={theme.colors.neutral.$black} />
          }>
          <Trans>Trash</Trans>
        </Button>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$75,
  },
});
