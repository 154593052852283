import {useOrganizationPublicDetails} from 'interface/common/hooks/useOrganizationPublicDetails';
import type {Organization} from 'store/slices/user/types';
import OrgLogo from './component';
import {OrgLogoSize} from './types';

interface UseOrgLogoProps {
  orgId: string;
  orgDetails?: Organization;
  size?: OrgLogoSize;
  showBorder?: boolean;
}

export default function useOrgLogo({
  orgId,
  orgDetails,
  size = OrgLogoSize.Medium,
  showBorder = true,
}: UseOrgLogoProps) {
  const {org, loading} = useOrganizationPublicDetails(orgId, orgDetails);

  return {
    logo: org && <OrgLogo org={org} size={size} showBorder={showBorder} />,
    org,
    loading,
  };
}
