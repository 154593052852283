import theme from 'config/theme';
import {Avatar} from 'interface/common/Avatar';
import {Divider} from 'interface/common/Divider';
import {useHover} from 'interface/common/hooks/useHover';
import {Fragment} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {Icon} from '../Icon';
import type {UserInfo} from './AddEmailDropdown';

export function AddEmailItem(props: {
  user: UserInfo;
  searchTerm: string;
  onOptionSelected: (option: string) => void;
  isFocused: boolean;
}) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  const isInvite = props.user.isInvite;
  const renderContent = isInvite ? InviteContent : UserContent;

  return (
    <Fragment>
      {props.user.isInvite && <Divider />}
      <Pressable
        onPress={() => {
          props.onOptionSelected(props.user.email);
        }}
        style={{padding: 3}}
        // @ts-ignore
        onMouseEnter={onHoverIn}
        onMouseLeave={onHoverOut}>
        <View
          style={[
            styles.itemRow,
            (isHover || props.isFocused) && styles.rowHover,
          ]}>
          {renderContent(props.user.email, props.user.name, props.searchTerm)}
        </View>
      </Pressable>
    </Fragment>
  );
}

function highlightMatches(text: string, searchTerm: string) {
  if (!searchTerm) return <Text>{text}</Text>;

  const getParts = () => {
    try {
      return text.split(new RegExp(`(${searchTerm})`, 'gi'));
    } catch (e) {
      return [text];
    }
  };

  const parts = getParts();

  return (
    <Text>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <Text key={index} style={styles.boldText}>
            {part}
          </Text>
        ) : (
          part
        ),
      )}
    </Text>
  );
}

function InviteContent(email: string, searchTerm: string) {
  return (
    <Fragment>
      <Icon name="lucide:mail" size={24} color={theme.colors.neutral.$black} />
      <Text>Invite:</Text>
      <Text>{highlightMatches(email, searchTerm)}</Text>
    </Fragment>
  );
}

function UserContent(email: string, name: string, searchTerm: string) {
  return (
    <Fragment>
      <Avatar
        color={getHashColor(email)}
        initials={createInitials(name)}
        size={0}
      />
      <View style={styles.textContainer}>
        {highlightMatches(name, searchTerm)}
        {highlightMatches(email, searchTerm)}
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  itemRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
    marginVertical: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  rowHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  boldText: {
    fontWeight: 'bold',
  },
});
