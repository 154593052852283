import {t} from '@lingui/macro';
import {ROUTES} from 'constants/routes';
import {handleApiError} from 'errors';
import {useNavigate} from 'extensions/navigation';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {Loading} from 'interface/common/Loading';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {useProccessPendingInvitation} from 'interface/common/hooks/useProccessPendingInvitation';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as userSlice from 'store/slices/user';
import Auth from '../consts';

export function VerifyEmail() {
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();

  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);
  const userDetails = useSelector(userSlice.selectors.getUserDetails);

  const {handleInvitationFlow} = useProccessPendingInvitation();
  const {logout} = useLogout();

  useEffect(() => {
    async function getEmail() {
      if (userDetails?.email_address && userDetails.id) {
        return setEmail(userDetails.email_address);
      }

      const userEmail = await Auth.getUserEmail();

      if (userEmail) {
        return setEmail(userEmail);
      }

      toast.show(
        t`The verify email should happen in the same browser that the user logged in, please log in again`,
        {type: 'danger'},
      );
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    getEmail();
  }, [userDetails]);

  useEffect(() => {
    const tokenParam = searchparams.get('token');

    if (!tokenParam) {
      return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    setToken(tokenParam.split('-').join(''));
  }, [searchparams]);

  useEffect(() => {
    if (!token || !email) {
      return;
    }

    async function getUserDetails() {
      const {user, result} = await api.user.userDetails();
      if (!result) {
        return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }
      dispatch(userSlice.default.actions.setUserDetails({...user}));
    }

    async function validateEmail() {
      const {result, error} = await api.user.validateEmail({
        email_token: token,
        email,
      });

      if (!result) {
        if (error?.code === ApiErrors.UserMiscompare) {
          handleApiError(
            {
              code: error?.code,
              text: '',
            },
            null,
            null,
            {
              message: `Email Mismatch Detected: You're logged in with a different email (${email}) than the one you're trying to verify.`,
              title: 'verifying new user email',
            },
          );
          return await logout({navigateTo: NavigateTo.SIGNIN});
        }
        if (error?.code === ApiErrors.UserCodeInvalid) {
          return setTimeout(() => {
            navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
          }, 1500);
        }
        return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }

      await getUserDetails();

      if (invitation) {
        return handleInvitationFlow(invitation);
      }

      return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
    }

    validateEmail();
  }, [token, email]);

  return (
    <View style={styles.root}>
      <Loading />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});
