import {useModal} from 'extensions/viewport/useModal';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useCallback} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import AiChatMembersModal from './AiChatMembersModal';

interface Props {
  chatId: string;
  workspaceMembers: Member[];
}

function getChatMemberIds(chat: EntityChat) {
  // TODO: AICHAT: Remove when API is ready
  const creatorId = chat?.creator?.id;
  if (chat?.members.length === 0 && creatorId) {
    return [creatorId];
  }
  return chat?.members.map(member => member.id) ?? [];
}

export function AiChatMembers({chatId, workspaceMembers}: Props) {
  const modal = useModal();
  const {namespace, instanceId} = useGetNamespaceContext();
  const chat = useSelector(
    aichat.selectors.getChat(namespace, instanceId, chatId),
  );
  const chatMemberIds = getChatMemberIds(chat);

  const chatMembers =
    chatMemberIds
      ?.map(memberId =>
        workspaceMembers?.find(member => member.id === memberId),
      )
      ?.filter(member => !!member) ?? [];

  // TODO: get active members
  const activeMembers = [];

  const open = useCallback(() => {
    modal.open(
      <AiChatMembersModal
        members={chatMembers}
        activeMembers={activeMembers}
        onClose={modal.close}
        ownerId={chat?.creator.id}
      />,
    );
  }, []);

  return (
    <Pressable style={styles.container} onPress={open}>
      <MemberAvatarGroup
        members={chatMembers ?? []}
        loading={false}
        showCount={false}
        size={-1}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
});
