import theme from 'config/theme';
import type {Citation} from 'fast-sdk/src/api/workspace/aichat/consts';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {CitationItem} from './CitationsItem';

interface Props {
  citations: Citation[];
}

export function AiChatCitationsSection({citations}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!citations || citations.length === 0) {
    return null;
  }

  const referencesText =
    citations.length + (citations.length === 1 ? ' Reference' : ' References');

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.references}
        onPress={() => setIsExpanded(!isExpanded)}>
        <Typography
          variant="regular"
          size="sm"
          color={theme.colors.neutral.$700}
          overrides={styles.referenceText}>
          {referencesText}
        </Typography>
        <Icon
          name={isExpanded ? 'lucide:chevron-up' : 'lucide:chevron-down'}
          size={16}
          color={theme.colors.neutral.$300}
        />
      </Pressable>
      {isExpanded && (
        <View style={styles.citationsContainer}>
          {citations.map((citation, index) => (
            <>
              <CitationItem key={citation.node_id} citation={citation} />
              {index < citations.length - 1 && <View style={styles.divider} />}
            </>
          ))}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
    gap: 16,
  },
  citationsContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    marginStart: 40,
    borderColor: theme.colors.neutral.$100,
    borderRadius: 8,
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: theme.colors.neutral.$75,
    marginHorizontal: 8,
  },
  referenceText: {
    paddingHorizontal: 6,
  },
  references: {
    height: 24,
    borderRadius: 999,
    borderColor: 'rgba(34, 40, 53, 0.12)',
    borderWidth: 1,
    marginStart: 40,
    flexDirection: 'row',
    paddingHorizontal: 8,
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
});
