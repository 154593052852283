import {t} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {Picker} from 'extensions/viewport/Picker';
import {api} from 'fast-sdk';
import {TextArea} from 'interface/base/TextArea';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {Loading} from 'interface/common/Loading';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {PageTitle} from 'interface/stacks/settings/base/PageTitle';
import {timezoneOptions} from 'interface/stacks/settings/base/consts';
import {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import settings from 'store/slices/settings';
import {type SocialLink, SocialLinkType} from 'store/slices/settings/types';
import user from 'store/slices/user';
import type {Organization as OrganizationType} from 'store/slices/user/types';
import {AddLogo} from './AddLogo';
import {OrganizationLinks} from './OrganizationLinks';
import {SectionItem} from './SectionItem';

const validDomain = (domain: string) => {
  if (!domain) return true;
  const domainPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  return domainPattern.test(domain);
};

export function Organization() {
  const toast = useToast();
  const {subdomain: paramSubdomain} = useSubDomain();

  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<OrganizationType>();
  const [name, setName] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [subdomain, setSubdomain] = useState<string>('');
  const [emailDomain, setEmailDomains] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [emailDomainsChangeLoading, setEmailDomainsChangeLoading] =
    useState<boolean>(false);
  const [nameChangeLoading, setNameChangeLoading] = useState<boolean>(false);
  const [descriptionChangeLoading, setDescriptionChangeLoading] =
    useState<boolean>(false);

  const getOrgDetails = async () => {
    setPageLoading(true);
    try {
      const res = await api.organization.getOrganizationDetails(paramSubdomain);
      if (res?.result) {
        setCurrentOrganizationDetails(res.org);
        setName(res.org.name ? res.org.name : '');
        setSubdomain(res.org.domain ? res.org.domain : '');
        setEmailDomains(
          res.org.perm_auth_domains ? res.org.perm_auth_domains : '',
        );
        setDescription(res.org.description ? res.org.description : '');
        // TODO: this timezone is not mach with the timezoneOptions that we have
        // so we need a proceudre to map them to each other
        setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        const socials: Array<SocialLink> = [];
        if (res.org.facebook) {
          socials.push({
            type: SocialLinkType.Facebook,
            link: res.org.facebook,
          });
        }
        if (res.org.instagram) {
          socials.push({
            type: SocialLinkType.Instagram,
            link: res.org.instagram,
          });
        }
        if (res.org.twitter) {
          socials.push({
            type: SocialLinkType.Twitter,
            link: res.org.twitter,
          });
        }
        if (res.org.youtube) {
          socials.push({
            type: SocialLinkType.Youtube,
            link: res.org.youtube,
          });
        }
        if (res.org.homepage) {
          socials.push({
            type: SocialLinkType.Other,
            link: res.org.homepage,
          });
        }
        dispatch(settings.actions.setOrganizationSocialLinks({links: socials}));
      }
    } catch (err) {
      analytics.notify(err);
      toast.show(
        'Getting the organization faced error please try again later',
        {type: 'danger'},
      );
    }
    setPageLoading(false);
  };

  useEffect(() => {
    if (paramSubdomain) {
      getOrgDetails();
    }
  }, [paramSubdomain]);

  const changeOrgName = async () => {
    if (name !== currentOrganizationDetails.name) {
      if (name) {
        setNameChangeLoading(true);
        const res = await api.organization.updateOrganization(subdomain, {
          name,
        });
        if (res?.result) {
          const org = await api.organization.getOrganizationDetails(subdomain);
          if (org?.result) {
            dispatch(user.actions.setSelectedOrganization({org: org.org}));
            toast.show(t`Organization name is successfully changed`, {
              type: 'success',
            });
          }
        }
      } else {
        toast.show(t`Please fill the name`, {type: 'danger'});
      }
    }
    setNameChangeLoading(false);
  };

  const changeEmailDomain = async () => {
    if (emailDomain !== currentOrganizationDetails.perm_auth_domains) {
      if (validDomain(emailDomain)) {
        setEmailDomainsChangeLoading(true);
        const res = await api.organization.updateOrganization(subdomain, {
          perm_auth_domains: emailDomain,
        });
        if (res?.result) {
          const org = await api.organization.getOrganizationDetails(subdomain);
          if (org?.result) {
            dispatch(user.actions.setSelectedOrganization({org: org.org}));
            toast.show(
              t`Organization authorized email domain is successfully changed`,
              {
                type: 'success',
              },
            );
          }
        }
      } else {
        toast.show(t`Please enter a valid domain`, {type: 'danger'});
      }
    }
    setEmailDomainsChangeLoading(false);
  };

  const changeOrgDescription = async () => {
    if (description !== currentOrganizationDetails.description) {
      if (description) {
        setDescriptionChangeLoading(true);
        try {
          const res = await api.organization.updateOrganization(subdomain, {
            description,
          });
          if (res?.result) {
            const org =
              await api.organization.getOrganizationDetails(subdomain);
            if (org?.result) {
              dispatch(user.actions.setSelectedOrganization({org: org.org}));
              toast.show(t`Organization description is successfully changed`, {
                type: 'success',
              });
            }
          }
        } catch (err) {
          analytics.notify(err);
          toast.show(
            t`changing organization description faced error please try again later`,
            {type: 'danger'},
          );
        }
      }
    }
    setDescriptionChangeLoading(false);
  };

  const showTimeZone = useFlag('show-time-zone');

  return (
    <ScrollView style={styles.root}>
      {pageLoading ? (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      ) : null}
      <View>
        <PageTitle text={t`General Settings`} />
        <SectionItem
          title={t`Subdomain`}
          content={t`Your organization subdomain is where you and your teammates will log in and also where your files will be shared from.`}
          sectionElement={
            <CustomTextInput
              value={subdomain}
              setValue={setSubdomain}
              customRootStyle={{
                width: 350,
                marginBottom: 0,
              }}
              customInputStyle={styles.input}
              ariaLabel={t`Organization Domain`}
              // disabled
            />
          }
        />
        <SectionItem
          title={t`Authorized Email Domain`}
          content={t`Control who can join your organization by specifying email domains to approve sign-ups and streamline invites.`}
          sectionElement={
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}>
              <CustomTextInput
                value={emailDomain}
                setValue={setEmailDomains}
                customRootStyle={{
                  width: 350,
                  marginBottom: 0,
                }}
                errorMessage={'Please enter a valid domain'}
                isInValid={!validDomain(emailDomain)}
                placeholder={t`Enter a domain...`}
                customInputStyle={styles.input}
                ariaLabel={'Authorized Email Domain'}
                onBlur={changeEmailDomain}
              />
              {emailDomainsChangeLoading ? <Loading size="small" /> : null}
            </View>
          }
        />
        {showTimeZone && (
          <SectionItem
            title={t`Time Zone`}
            content={t`Defaults to the location of the teammate who set up this workspace. Changing it will affect time-dependent features across fast.io.`}
            sectionElement={
              <Picker
                style={styles.dropdown}
                selectedValue={timezone}
                onValueChange={setTimezone}>
                {timezoneOptions.map(item => (
                  <Picker.Item
                    key={item.value}
                    value={item.value}
                    label={item.title}
                  />
                ))}
              </Picker>
            }
          />
        )}
      </View>
      <View>
        <PageTitle text={t`Branding and Customization`} />
        <SectionItem
          title={t`Organization Name`}
          content={t`Customize Fast.io by changing the name of your organization.`}
          sectionElement={
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}>
              <CustomTextInput
                value={name}
                setValue={setName}
                customRootStyle={{
                  width: 350,
                  marginBottom: 0,
                }}
                disabled={nameChangeLoading}
                customInputStyle={styles.input}
                ariaLabel={t`Organization Name`}
                onBlur={changeOrgName}
              />
              {nameChangeLoading ? <Loading size="small" /> : null}
            </View>
          }
        />
        <SectionItem
          title={t`Organization Logo`}
          content={t`Upload your logo to customize Fast.io for your organization.`}
          sectionElement={<AddLogo />}
        />
        <SectionItem
          title={t`Organization Description`}
          content={t`Add an organization description to display on your shared link pages.`}
          sectionElement={
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}>
              <TextArea
                text={description}
                customInputStyle={styles.textarea}
                onChangeText={setDescription}
                placeholder={t`Enter organization description...`}
                disabled={descriptionChangeLoading}
                onBlur={changeOrgDescription}
              />
              {descriptionChangeLoading ? <Loading size="small" /> : null}
            </View>
          }
        />
        <OrganizationLinks getOrgDetails={getOrgDetails} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 45,
    paddingHorizontal: 50,
    position: 'relative',
  },
  content: {
    maxWidth: 600,
    paddingLeft: 50,
    marginBottom: 35,
  },
  marginBottom: {
    marginBottom: 10,
  },
  editButton: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 3,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$10,
  },
  button: {
    border: 0,
    backgroundColor: theme.colors.neutral.$white,
    lineHeight: 24,
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$4,
  },
  textarea: {
    width: 350,
    height: 110,
  },
  dropdown: {
    height: 36,
    width: 350,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: theme.colors.neutral.$13,
    border: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  input: {
    height: 36,
  },
});
