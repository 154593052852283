import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';

const REDIRECT_TO_KEY = 'redirectTo';

export function useSetRedirectTo() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const redirectTo = searchParams.get(REDIRECT_TO_KEY);
    if (redirectTo) {
      dispatch(app.default.actions.setRedirectTo(redirectTo));

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(REDIRECT_TO_KEY);
      setSearchParams(newSearchParams);
    }
  }, [searchParams]);
}
