import {Elements} from '@stripe/react-stripe-js';
import type {Stripe} from '@stripe/stripe-js';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {Loading} from 'interface/common/Loading';
import type {BillingPlan} from 'interface/stacks/onboard/lib/types';
import {Fragment} from 'react';
import {StyleSheet, View} from 'react-native';
import {AddCard} from './AddCard';
import BillingPlanDetails from './BillingPlanDetails';

interface Props {
  alert?: React.ReactNode;
  loading: boolean;
  title: string;
  content: string;
  secret: string;
  orgId: string;
  orgDomain: string;
  stripe: Stripe;
  plan: BillingPlan;
  tax: number;
  onSubscriptionSuccess: () => void;
}

export function SubscriptionCheckout(props: Props) {
  return (
    <View style={styles.root}>
      {props.loading && <Loading />}
      {!props.loading && (
        <Fragment>
          {props.alert}
          <Typography
            variant="bold"
            size="3xl"
            color={theme.colors.neutral.$2Base}
            overrides={styles.title}>
            {props.title}
          </Typography>
          <Typography
            variant="regular"
            color={theme.colors.neutral.$2Base}
            overrides={styles.content}>
            {props.content}
          </Typography>
          <View style={styles.container}>
            <View style={styles.paymentContainer}>
              <Elements
                stripe={props.stripe}
                options={{clientSecret: props.secret}}>
                <AddCard
                  stripe={props.stripe}
                  orgDomain={props.orgDomain}
                  orgId={props.orgId}
                  onSubscriptionSuccess={props.onSubscriptionSuccess}
                />
              </Elements>
            </View>
            <View style={styles.planDetailsContainer}>
              <BillingPlanDetails
                title={props.plan.title}
                tax={props.tax}
                free={props.plan.pricing.free}
                freeDays={props.plan.pricing.free_days}
                priceBase={props.plan.pricing.price_base}
                billed={props.plan.pricing.billed}
                discount={(props.plan.pricing.discount as number) || undefined}
              />
            </View>
          </View>
        </Fragment>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 960,
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
  },
  title: {
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  content: {
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
  container: {
    flexDirection: 'row',
    gap: 50,
  },
  paymentContainer: {
    flex: 1,
  },
  planDetailsContainer: {
    flex: 1,
  },
});
