import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {FileListItemLayout} from './FileListItem';

import {slices} from 'store';
import {selectors} from 'store/slices/files';

interface Props {
  layout?: FileListItemLayout;
  onLayoutChange?: () => void;
}

const fileListItemLayoutIcons = {
  [FileListItemLayout.ListNormal]: 'lucide:layout-list',
  [FileListItemLayout.GridSimple]: 'lucide:layout-grid',
};

export function FolderLayoutSwitchButton({layout, onLayoutChange}: Props) {
  const globalLayout = useSelector(selectors.getLayout);
  const dispatch = useDispatch();

  return (
    <Button
      variant="text"
      onPress={() => {
        if (onLayoutChange) {
          onLayoutChange();
        } else {
          dispatch(slices.files.actions.layout());
        }
      }}
      endEnhancer={
        <Icon
          name={fileListItemLayoutIcons[layout ?? globalLayout]}
          color={theme.colors.neutral.$black}
        />
      }
      overrides={{
        Button: {
          style: {
            width: 32,
            height: 32,
          },
        },
      }}
    />
  );
}
