import theme from 'config/theme';
import type {MessageContent} from 'fast-sdk/src/api/workspace/aichat/consts';
import Typography from 'interface/base/Typography';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import Skeleton from 'interface/common/Skeleton';
import {useGetFileDetailsCached} from 'interface/common/hooks/useGetFileDetailsCached';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import {useGetNamespaceContext} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {parseServerDate} from 'utils/common/dates';
import {formatRelativeTime} from '../../helpers';
import {AiChatChip} from '../input/selectFiles/AiChatChip';

interface Props {
  message: MessageContent;
  userId: string;
  createdAt: string;
}

export function AiChatQuestionItem({message, userId, createdAt}: Props) {
  const {user, initials, userName, isLoading} = useGetUserDetailsCached(userId);
  const {namespace, instanceId} = useGetNamespaceContext();
  const createdAtDate = parseServerDate(createdAt);

  const scopeFilesIds =
    message.scope?.specific_files_scope?.files?.map(file => file.node_id) ?? [];
  const attachedFilesIds = message.attached?.map(file => file.node_id) ?? [];

  const scopeFiles: FilesItem[] = [];
  scopeFilesIds.forEach(id => {
    const {item} = useGetFileDetailsCached(id, instanceId, namespace);
    if (item) {
      scopeFiles.push(item);
    }
  });

  const attachedFiles: FilesItem[] = [];
  attachedFilesIds.forEach(id => {
    const {item} = useGetFileDetailsCached(id, instanceId, namespace);
    if (item) {
      attachedFiles.push(item);
    }
  });

  const showFilesContainer = scopeFiles.length > 0 || attachedFiles.length > 0;

  const userNameView = user ? (
    <Typography
      variant="medium"
      size="md"
      selectable
      color={theme.colors.neutral.$700}>
      {userName}
    </Typography>
  ) : (
    <Skeleton width={100} height={20} style={{paddingVertical: 1}} />
  );

  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        {userId && <MemberAvatar initials={initials} memberId={userId} />}
      </View>
      <View style={styles.messageContainer}>
        <View style={styles.header}>
          {userNameView}
          <Typography size="sm" color={theme.colors.neutral.$500}>
            {formatRelativeTime(createdAtDate)}
          </Typography>
        </View>
        <Typography
          size="md"
          selectable
          color={theme.colors.neutral.$700}
          overrides={styles.text}>
          {message.text}
        </Typography>
        {showFilesContainer && (
          <View style={styles.filesContainer}>
            {scopeFiles.map(file => (
              <AiChatChip key={file.id} item={file} type="scope" />
            ))}
            {attachedFiles.map(file => (
              <AiChatChip key={file.id} item={file} type="attachment" />
            ))}
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 12,
    width: '100%',
  },
  avatarContainer: {
    width: 28,
    height: 28,
    marginVertical: 4,
  },
  messageContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 8,
    borderRadius: 12,
    backgroundColor: theme.colors.neutral.$75,
  },
  header: {
    height: 28,
    paddingVertical: 2,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 12,
  },
  text: {
    paddingBottom: 4,
  },
  filesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
    paddingTop: 4,
    paddingBottom: 8,
  },
});
