import {api} from 'fast-sdk';
import {useGetNamespaceContextInModal} from 'interface/stacks/workspace/hooks/useGetNamespaceContext';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as aichat from 'store/slices/aichat';

export const useDeleteChat = () => {
  const dispatch = useDispatch();
  const {namespace, instanceId, instanceName} = useGetNamespaceContextInModal();
  const [isLoading, setIsLoading] = useState(false);

  const deleteChat = async (chatId: string) => {
    setIsLoading(true);
    try {
      const deleteChatResponse = await api.workspaceAichat.deleteChat(
        namespace,
        instanceName,
        chatId,
      );
      if (deleteChatResponse.result) {
        dispatch(
          aichat.default.actions.deleteChat({namespace, instanceId, chatId}),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, deleteChat};
};
