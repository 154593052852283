import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {ItemChip} from 'interface/base/ItemChip';
import Typography from 'interface/base/Typography';
import {Fragment} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';

// @ts-ignore
import imageAiIcon from 'assets/images/storage/ai-icon.png';

export function ItemAiProcessing({tip}: {tip: 'top' | 'left'}) {
  return (
    <Pressable style={styles.root}>
      {({hovered}) => (
        <Fragment>
          <ItemChip
            startEnhancer={
              <Image
                source={imageAiIcon}
                style={{width: 16, height: 16, margin: 2}}
              />
            }
            endEnhancer={
              <View style={{marginLeft: 2, marginRight: 4}}>
                <Icon
                  name="lucide:clock"
                  size={12}
                  color={theme.colors.neutral.$500}
                />
              </View>
            }
            padding={{
              left: false,
              right: false,
            }}
          />
          {hovered && (
            <View
              style={[styles.tooltip, tip === 'left' && styles.tooltipOffset]}>
              <Typography size="xs" color={theme.colors.neutral.$white}>
                {t`Processing this file to enable AI features. It will be available shortly.`}
              </Typography>
              <View
                style={[styles.arrow, tip === 'left' && styles.arrowOffset]}
                pointerEvents="none"
              />
            </View>
          )}
        </Fragment>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    cursor: 'default',
  },
  tooltip: {
    position: 'absolute',
    zIndex: 9999,
    top: -72,
    left: -110,
    width: 230,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  tooltipOffset: {
    top: -20,
    left: -236,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    bottom: -5,
    left: 'calc(50% + 10px)',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5.5,
    borderRightWidth: 5.5,
    borderTopWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: theme.colors.neutral.$2Base,
    zIndex: 99999,
  },
  arrowOffset: {
    left: 'unset',
    right: -7,
    top: 'calc(50% - 10px)',
    transform: [{rotate: '-90deg'}],
  },
});
