import {api} from 'fast-sdk';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {base64ToFile, resizeImage} from 'utils/fast/files';

type Options = {
  lazy?: boolean;
};

const defaultOptions: Options = {
  lazy: false,
};

const useUpdateUser = (isSubscribed: boolean, {lazy} = defaultOptions) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const userDetails = useSelector(user.selectors.getUserDetails);
  const userData = useSelector(onboarding.selectors.getUserData);

  const getResizeUserImage = async () => {
    const file = Object.keys(userData.imgFile).length
      ? userData.imgFile
      : base64ToFile(userData.imgSrc, userData.imgName, userData.imgType);
    return await resizeImage(file);
  };

  const updateUser = useCallback(async () => {
    setLoading(true);
    const isUserDataValid = userData.firstName && userData.lastName;
    if (isUserDataValid) {
      await api.user.updateUserDetails({
        ...userDetails,
        first_name: userData.firstName,
        last_name: userData.lastName,
      });
    }
    if (userData.imgSrc) {
      const resizedFile = await getResizeUserImage();
      const {result} = await api.user.addProfilePhoto(
        userDetails.id,
        resizedFile,
      );
      if (result) {
        dispatch(
          user.default.actions.setUserProfilePic({img: userData.imgSrc}),
        );
      }
    }
    setLoading(false);
  }, [userData]);

  useEffect(() => {
    if (isSubscribed && !lazy) {
      updateUser();
    }
  }, [isSubscribed, lazy]);

  return {updateUser, loading};
};

export default useUpdateUser;
