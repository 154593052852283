import {t} from '@lingui/macro';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {Prompt} from 'interface/common/Prompt';
import {StyleSheet} from 'react-native';
import {usePublishChat} from '../hooks/usePublishChat';

interface Props {
  chatId: string;
  onClose: () => void;
}

export default function AiChatPublishModal({chatId, onClose}: Props) {
  const {isLoading, publishChat} = usePublishChat();
  const onPublish = async () => {
    await publishChat(chatId);
    onClose();
  };

  return (
    <Prompt
      onClose={onClose}
      rootStyle={styles.root}
      buttons={[
        {
          variant: 'secondary',
          text: t`Cancel`,
          onPress: onClose,
        },
        {
          variant: 'primary',
          text: t`Continue`,
          onPress: onPublish,
          isSubmit: true,
        },
      ]}>
      <Typography
        variant="medium"
        size="base"
        color={theme.colors.neutral.$700}
        overrides={styles.modalTitleText}>
        Make this chat public?
      </Typography>
      <Typography variant="regular" size="md" color={theme.colors.neutral.$700}>
        This chat is currently private and only visible to you. When you make
        this public, other members will be able to see and contribute to this
        chat. Once a chat is public, you can not make it private again.
      </Typography>
    </Prompt>
  );
}

const styles = StyleSheet.create({
  root: {
    maxWidth: 600,
    maxHeight: 600,
  },
  resetModalButton: {
    width: 200,
    height: 36,
    marginTop: 20,
    minWidth: 57,
    minHeight: 36,
  },
  modalTitleText: {
    marginBottom: 24,
  },
});
