import theme from 'config/theme';
import {Panel, PanelHandle} from 'interface/base/Panel';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import * as files from 'store/slices/files';
import {SHARE_STATUS_WITHOUT_CONFIG} from 'store/slices/shared/types';
import {useGetCurrentShareDetails} from '../../hooks/useGetCurrentShareDetails';
import {ColumnHeader} from './ColumnHeader';
import {ColumnMinimized} from './ColumnMinimized';

// Props interface for the ColumnPanelToggle component
interface ColumnToggleProps {
  columnKey: ColumnManagerInfoKeys;
  itemsToClose?: number;
  children: React.ReactNode;
}

// Defines the order in which columns appear from left to right
// Lower numbers appear further left
const COLUMN_ORDER = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 1,
  [ColumnManagerInfoKeys.FoldersAndFiles]: 2,
  [ColumnManagerInfoKeys.InfoPanel]: 3,
  [ColumnManagerInfoKeys.ShareConfiguration]: 4,
  [ColumnManagerInfoKeys.AIChat]: 5,
};

export const COLUMN_NAME = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 'Storage and Shares',
  [ColumnManagerInfoKeys.FoldersAndFiles]: 'Files',
  [ColumnManagerInfoKeys.InfoPanel]: 'Info',
  [ColumnManagerInfoKeys.ShareConfiguration]: 'Share Settings',
  [ColumnManagerInfoKeys.AIChat]: 'AI Chat',
};

// Defines the minimum width (in percentage) that each column can be resized to
// This prevents columns from becoming too narrow to be useful
const MIN_SIZE = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 10,
  [ColumnManagerInfoKeys.FoldersAndFiles]: 20,
  [ColumnManagerInfoKeys.InfoPanel]: 15,
  [ColumnManagerInfoKeys.ShareConfiguration]: 15,
  [ColumnManagerInfoKeys.AIChat]: 30,
};

// Default column widths (in percentage) for normal workspace view
// 'undefined' means the column will take up remaining space
const SIZE_DEFAULT = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 15,
  [ColumnManagerInfoKeys.FoldersAndFiles]: undefined,
  [ColumnManagerInfoKeys.InfoPanel]: 15,
  [ColumnManagerInfoKeys.ShareConfiguration]: 20,
  [ColumnManagerInfoKeys.AIChat]: 40,
};

// Default column widths for share context (when viewing shared content)
// Slightly different defaults to accommodate share-specific UI elements
const SIZE_DEFAULT_SHARE = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 15,
  [ColumnManagerInfoKeys.FoldersAndFiles]: undefined,
  [ColumnManagerInfoKeys.InfoPanel]: 15,
  [ColumnManagerInfoKeys.ShareConfiguration]: 20,
  [ColumnManagerInfoKeys.AIChat]: 30,
};

export function ColumnPanelToggle({columnKey, children}: ColumnToggleProps) {
  // Get necessary state from Redux
  const focusedParentId = useSelector(files.selectors.getFocusedParent);
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);
  const columnVisibility = useSelector(app.selectors.columnVisibility);

  // Check if we're in a share context (viewing shared content)
  const isShareContext = !!focusedParentId?.startsWith('5');
  const share = useGetCurrentShareDetails();

  // Determine if we have a valid share configuration
  const hasValidShare =
    isShareContext &&
    share &&
    !SHARE_STATUS_WITHOUT_CONFIG.includes(share.shareViewStatus);

  const dispatch = useDispatch();

  // Don't render the ShareConfiguration column if we don't have a valid share
  if (
    columnKey === ColumnManagerInfoKeys.ShareConfiguration &&
    !hasValidShare
  ) {
    return undefined;
  }

  // Determine column visibility (checking both override and default states)
  const maximized = columnInfoOverride[columnKey] ?? columnInfo[columnKey];
  const visibility = columnVisibility[columnKey];
  const columnOrder = COLUMN_ORDER[columnKey];

  // Select appropriate size defaults based on context (share vs normal)
  const size = isShareContext
    ? SIZE_DEFAULT_SHARE[columnKey]
    : SIZE_DEFAULT[columnKey];

  const onClosePanel = () => {
    dispatch(app.default.actions.setColumnInfoOverride({[columnKey]: false}));
  };

  const onExpandPanel = () => {
    dispatch(app.default.actions.expandColumn({columnKey}));
  };

  if (!visibility) {
    return null;
  }

  // Only render the column if it should be visible
  return maximized ? (
    <Panel
      id={columnKey}
      order={columnOrder}
      minSize={MIN_SIZE[columnKey]}
      defaultSize={size}
      flex>
      <View style={styles.root}>
        <View style={styles.container}>
          <ColumnHeader
            title={COLUMN_NAME[columnKey]}
            onToggle={onClosePanel}
            onExpand={onExpandPanel}
          />
          <View style={styles.child}>{children}</View>
        </View>
      </View>
      {/* Don't show resize handle for AIChat column */}
      {columnKey !== ColumnManagerInfoKeys.AIChat && <PanelHandle />}
    </Panel>
  ) : (
    <ColumnMinimized columnKey={columnKey} />
  );
}

// Styles for the column container
const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$white,
    margin: StyleSheet.hairlineWidth,
    flexDirection: 'column',
  },
  header: {
    padding: 10,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 4,
    margin: 4,
    height: 30,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  child: {
    flexDirection: 'column',
    flex: 1,
  },
});
