import {WORKSPACE_SHARED} from 'constants/routes';
import {
  ACCOUNT_SETTINGS_MENU_LINKS,
  ORGANIZATION_SETTINGS_MENU_LINKS,
} from 'interface/stacks/settings/base/consts';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as files from 'store/slices/files';
import * as shared from 'store/slices/shared';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';

function useDynamicPageTitle() {
  const currentPage =
    useSelector(app.selectors.getCurrentPage) ?? DEFAULT_PAGE_INFO;

  const orgName = useSelector(user.selectors.getSelectedOrganization)?.name;
  let folder = useSelector(files.selectors.getItem(currentPage.folderId))?.name;
  if (currentPage.folderId === WORKSPACE_SHARED) {
    folder = 'All Shares';
  }
  const fileName = useSelector(
    files.selectors.getItem(currentPage.fileId),
  )?.name;

  const shareName =
    useSelector(shared.selectors.getSingleSharedItem(currentPage.shareId))
      ?.name ?? currentPage.shareName;

  const workspaceName = useSelector(
    workspace.selectors.getWorkspaceByAlt(currentPage.workspace),
  )?.name;

  useEffect(() => {
    const routeList = [];

    switch (currentPage.type) {
      case PageType.Storage: {
        routeList.push(orgName);
        if (fileName) {
          routeList.push(fileName);
        } else {
          routeList.push(workspaceName);
          if (folder) routeList.push(folder);
        }
        break;
      }
      case PageType.Settings: {
        routeList.push(orgName);

        const pageTitle = [
          ...ACCOUNT_SETTINGS_MENU_LINKS,
          ...ORGANIZATION_SETTINGS_MENU_LINKS,
        ].find(link => link.sectionName === currentPage.settings)?.title;

        routeList.push(pageTitle);
        break;
      }
      case PageType.Public: {
        routeList.push(currentPage.pageName);
        break;
      }
      case PageType.ShareExternal: {
        routeList.push(orgName);
        routeList.push(shareName);
        if (folder) routeList.push(folder);
        break;
      }
    }

    const title = routeList.length
      ? `${routeList.filter(Boolean).reverse().join(' - ')} - Fast.io`
      : 'Fast.io';
    document.title = title;
  }, [currentPage]);
}

export default useDynamicPageTitle;
