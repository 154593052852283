import {ROUTES} from 'constants/routes';

export const routesGroups = {
  loggedInRoutes: Object.values(ROUTES.LOGGED_IN),
  loggedInWithOrgRoutes: Object.values(ROUTES.LOGGED_IN_WITH_ORG),
  loggedInWithoutOrgRoutes: Object.values(ROUTES.LOGGED_IN_WITHOUT_ORG),
  loggedInWithSubdomainRoutes: Object.values(ROUTES.LOGGED_IN_WITH_SUBDOMAIN),
  notLoggedInRoutes: Object.values(ROUTES.NOT_LOGGED_IN),
  loggedOrNotLoggedRoutes: Object.values(ROUTES.LOGGED_OR_NOT_LOGGED),
};

export const SESSION_VALIDATION_EXEMPT_ROUTES = [
  ...routesGroups.notLoggedInRoutes,
];

export const ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING = [
  ROUTES.LOGGED_IN_WITHOUT_ORG.HOME,
];

export const ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION = [
  ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION,
];

export const LOGOUT_EVENT_KEY = 'app_logout_event';
export const LOGIN_EVENT_KEY = 'app_login_event';

export const SESSION_COOKIE_KEY = 'fastio_cookie_session';
