import {api} from 'fast-sdk';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';
import {useBroadcastLogin} from './useBroadcastLogin';
import {useOrganizationLogo} from './useOrganizationLogo';
import {useProfilePhoto} from './useProfilePhoto';
import {useSubDomain} from './useSubDomain';

export const useLoadUserSessionData = () => {
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();

  useBroadcastLogin();

  const isLoaded = useSelector(app.selectors.isLoaded);
  const selectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const getSelectedOrganization = useCallback(async () => {
    if (isSubdomainValid(subdomain)) {
      const {result, org} =
        await api.organization.getOrganizationPublicDetails(subdomain);
      if (result) {
        if (isLoggedIn) {
          dispatch(user.default.actions.updateOrganization({org}));
        }
        dispatch(user.default.actions.setSelectedOrganization({org}));
      } else if (isLoggedIn) {
        dispatch(app.default.actions.setForceLogout(true));
      }
    }
  }, [subdomain, isLoggedIn]);

  useEffect(() => {
    if (!selectedOrganization && isSubdomainValid(subdomain) && isLoaded) {
      getSelectedOrganization();
    }
  }, [subdomain, isLoaded]);

  useProfilePhoto();
  useOrganizationLogo();
};
