import {Navigate, Route, Routes} from 'extensions/navigation';
import {ModalProvider} from 'extensions/viewport/ModalProvider';
import {PopupProvider} from 'extensions/viewport/PopupProvider';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import {LoginSSO} from 'interface/stacks/auth/pages/LoginSSO';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';

// Layouts
import {LayoutWorkspace} from 'interface/stacks/workspace/layout/LayoutWorkspace';
import {LayoutSettings} from './stacks/settings/layout/LayoutSettings';

import ContentViewer from 'interface/stacks/content-viewer/routes/RouteContentViewer';
// Routes
import Onboard from 'interface/stacks/onboard/routes/RouteOnboard';
import RouteRecents from 'interface/stacks/recents/routes/RouteRecents';
import Settings from 'interface/stacks/settings/routes/RouteSettings';
import Inbox from 'interface/stacks/workspace/routes/RouteInbox';
import Storage from 'interface/stacks/workspace/routes/RouteStorage';

import {
  ROUTES,
  WORKSPACE_SHARED,
  WORKSPACE_SHARED_ARCHIVED,
  WORKSPACE_STORAGE,
} from 'constants/routes';
import {
  OrganizationActivityProvider,
  ShareActivityProvider,
  UserActivityProvider,
  WorkspaceActivityProvider,
} from 'extensions/websockets';

import {WorkspaceSharedList} from 'interface/stacks/workspace/shared/WorkspaceSharedList';
import DefaultRedirect from './common/DefaultRedirect';
import GoRoute from './common/GoRoute';
import {OrganizationAccessLayout} from './common/OrganizationAccessLayout';
import ProtectedRoute from './common/ProtectedRoute';
import VerificationRoute from './common/VerificationRoute';
import ErrorBoundary from './common/error/ErrorBoundary';
import {ErrorWrapper} from './common/error/ErrorWrapper';
import useDynamicPageTitle from './common/hooks/navigator/useDynamicPageTitle';
import {useCheckForceLogout} from './common/hooks/useCheckForceLogout';
import {useLoadUserSessionData} from './common/hooks/useLoadUserSessionData';
import {useSubDomain} from './common/hooks/useSubDomain';
import {Access} from './stacks/access/Access';
import {RequestAccess} from './stacks/access/RequestAccess';
import {ErrorPage} from './stacks/app/ErrorPage';
import AppBaseLayout from './stacks/app/layout/AppBaseLayout';
import {PageLayout} from './stacks/auth/_layout/PageLayout';
import {AuthPageTypes} from './stacks/auth/_layout/RouteLayout';
import {CheckEmail} from './stacks/auth/pages/CheckEmail';
import {ForgotPassword} from './stacks/auth/pages/ForgotPassword';
import {JoinEmail} from './stacks/auth/pages/JoinEmail';
import {Logout} from './stacks/auth/pages/Logout';
import {ResetPassword} from './stacks/auth/pages/ResetPassword';
import {SigninWithEmail} from './stacks/auth/pages/SignInWithEmail';
import {SignUpVerification} from './stacks/auth/pages/SignUpVerification';
import {SignUpWithEmail} from './stacks/auth/pages/SignUpWithEmail';
import {Signin} from './stacks/auth/pages/Signin';
import {VerifyEmail} from './stacks/auth/pages/VerifyEmail';
import {OnboardSteps} from './stacks/onboard/lib/consts';
import ProfileMembership from './stacks/profile-membership';
import {AppTabs} from './stacks/profile-membership/types';
import {ShareRecepientView} from './stacks/share/ShareRecepientView';
import ShareAccessLayout from './stacks/share/layout/ShareAccessLayout';
import {ShareLayout} from './stacks/share/layout/ShareLayout';
import {UpgradeDowngrade} from './stacks/upgrade-downgrade';
import {SubscriptionLayout} from './stacks/upgrade-downgrade/layout/SubscriptionLayout';
import {UpgradeDowngradeLayout} from './stacks/upgrade-downgrade/layout/UpgradeDowngradeLayout';
import {CancelSubscription} from './stacks/upgrade-downgrade/pages/CancelSubscription';
import {RenewSubscription} from './stacks/upgrade-downgrade/pages/RenewSubscription';
import {ArchivedWorkspaces} from './stacks/workspace/browse/ArchivedWorkspaces';
import {BrowseWorkspaces} from './stacks/workspace/browse/BrowseWorkspaces';
import {LayoutWorkspaceContent} from './stacks/workspace/layout/LayoutWorkspaceContent';
import QuickShareViewer from './stacks/workspace/storage/quick-share/QuickShareViewer';

export function Navigator() {
  const isLoaded = useSelector(app.selectors.isLoaded);
  const organizationsList = useSelector(user.selectors.getOrganizationsList);
  const {subdomain} = useSubDomain();

  useLoadUserSessionData();
  useCheckForceLogout();
  useDynamicPageTitle();

  return (
    <ErrorBoundary>
      {isLoaded ? (
        <ModalProvider>
          <PopupProvider>
            <Routes>
              <Route element={<ErrorWrapper />}>
                <Route element={<ProtectedRoute />}>
                  <Route element={<UserActivityProvider />}>
                    {/* The layout that checks the organization access for the current user */}
                    <Route element={<OrganizationActivityProvider />}>
                      <Route element={<OrganizationAccessLayout />}>
                        {/** App pages (inbox, browse)*/}
                        <Route element={<AppBaseLayout />}>
                          <Route
                            path={ROUTES.LOGGED_IN_WITH_ORG.BROWSE_WORKSPACES}
                            element={<BrowseWorkspaces />}
                          />
                          <Route
                            path={ROUTES.LOGGED_IN_WITH_ORG.ARCHIVED_WORKSPACES}
                            element={<ArchivedWorkspaces />}
                          />
                          <Route
                            path={ROUTES.LOGGED_IN_WITH_ORG.INBOX}
                            element={<Inbox />}
                          />
                        </Route>
                        {/** Workspace pages (shared, storage) */}
                        <Route element={<WorkspaceActivityProvider />}>
                          <Route
                            path={`${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/:workspaceId`}
                            element={<LayoutWorkspace />}>
                            <Route element={<LayoutWorkspaceContent />}>
                              <Route
                                path={ROUTES.LOGGED_IN_WITH_ORG.RECENTS}
                                element={<RouteRecents />}
                              />
                              <Route
                                path={WORKSPACE_SHARED}
                                element={<WorkspaceSharedList />}
                              />
                              <Route
                                path={WORKSPACE_SHARED_ARCHIVED}
                                element={<WorkspaceSharedList showArchived />}
                              />
                              <Route path={WORKSPACE_STORAGE}>
                                <Route
                                  path=":folderKey"
                                  element={<Storage />}
                                />
                              </Route>
                              <Route
                                element={
                                  <ShareAccessLayout showLayout={false} />
                                }>
                                <Route element={<ShareLayout />}>
                                  <Route element={<ShareActivityProvider />}>
                                    <Route
                                      path={`${ROUTES.LOGGED_OR_NOT_LOGGED.SHARED}/:sharedCustomName`}
                                      element={<ShareRecepientView />}
                                    />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                          <Route
                            path={`:instanceNs/:instanceId/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/:fileId`}
                            element={<ContentViewer />}
                          />
                          <Route element={<ShareAccessLayout />}>
                            <Route element={<LayoutWorkspace isGuestView />}>
                              <Route
                                element={
                                  <LayoutWorkspaceContent isGuestView />
                                }>
                                <Route element={<ShareLayout />}>
                                  <Route element={<ShareActivityProvider />}>
                                    <Route
                                      path={`${ROUTES.LOGGED_OR_NOT_LOGGED.SHARED}/:sharedCustomName/:sharedTitle`}
                                      element={<ShareRecepientView />}
                                    />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        {/** Organization settings */}
                        <Route path="settings" element={<LayoutSettings />}>
                          <Route index element={<Settings />} />
                          <Route
                            path=":settingsSection"
                            element={<Settings />}
                          />
                        </Route>
                        {/** Upgrade / downgrade */}
                        <Route element={<UpgradeDowngradeLayout />}>
                          <Route
                            path={ROUTES.LOGGED_IN_WITH_ORG.UPGRADE_DOWNGRADE}
                            element={<UpgradeDowngrade />}
                          />
                        </Route>
                        {/** Onboarding process */}
                        <Route path={ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}>
                          <Route
                            index
                            element={
                              <Navigate
                                to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Profile}`}
                              />
                            }
                          />
                          <Route path=":step" element={<Onboard />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path={`${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`}
                      element={<AppBaseLayout />}>
                      <Route
                        index
                        element={
                          <Navigate
                            to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Workspaces}`}
                          />
                        }
                      />
                      <Route
                        path=":activeTab"
                        element={<ProfileMembership />}
                      />
                    </Route>
                    {/** Subscription */}
                    <Route element={<SubscriptionLayout />}>
                      <Route
                        path={ROUTES.LOGGED_IN_WITH_ORG.CANCEL_SUBSCRIPTION}
                        element={<CancelSubscription />}
                      />
                      <Route
                        path={ROUTES.LOGGED_IN_WITH_ORG.RENEW_SUBSCRIPTION}
                        element={<RenewSubscription />}
                      />
                    </Route>
                  </Route>
                  {/** Email check */}
                  <Route
                    path={ROUTES.LOGGED_IN_WITH_SUBDOMAIN.EMAIL_CHECK}
                    element={<PageLayout />}>
                    <Route index element={<CheckEmail />} />
                  </Route>
                  {/** Request access */}
                  <Route
                    path={`${ROUTES.LOGGED_IN.REQUEST_ACCESS}/:type`}
                    element={<RequestAccess />}>
                    <Route path=":id" />
                  </Route>
                  {/** Access */}
                  <Route
                    path={`${ROUTES.LOGGED_IN.ACCESS}/:type`}
                    element={<Access />}>
                    <Route path=":id" />
                  </Route>
                </Route>
                <Route element={<VerificationRoute />}>
                  {/** Email verification */}
                  <Route
                    path={ROUTES.LOGGED_IN_WITHOUT_ORG.VERIFY_EMAIL}
                    element={<VerifyEmail />}
                  />
                  <Route
                    path={ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}
                    element={<PageLayout />}>
                    <Route index element={<SignUpVerification />} />
                  </Route>
                </Route>
                {/** Join profile via email */}
                <Route
                  path={`${ROUTES.LOGGED_OR_NOT_LOGGED.JOIN}/`}
                  element={<PageLayout />}>
                  <Route index element={<JoinEmail />} />
                </Route>
                {/** SSO success redirect w/ user token */}
                <Route
                  path={ROUTES.NOT_LOGGED_IN.SSO_GOOGLE}
                  element={<LoginSSO provider="Google" />}
                />
                <Route
                  path={ROUTES.NOT_LOGGED_IN.SSO_FACEBOOK}
                  element={<LoginSSO provider="Facebook" />}
                />
                <Route
                  path={ROUTES.NOT_LOGGED_IN.SSO_MICROSOFT}
                  element={<LoginSSO provider="Microsoft" />}
                />
                {/** Login / Signup / Logout */}
                <Route element={<PageLayout />}>
                  <Route element={<GoRoute />}>
                    <Route
                      path={ROUTES.NOT_LOGGED_IN.SIGNIN}
                      element={<Signin type={AuthPageTypes.SIGNIN} />}
                    />
                    <Route
                      path={ROUTES.NOT_LOGGED_IN.EMAIL_SIGNIN}
                      element={<SigninWithEmail />}
                    />
                    <Route
                      path={ROUTES.NOT_LOGGED_IN.EMAIL_SIGNUP}
                      element={<SignUpWithEmail />}
                    />
                    <Route
                      path={ROUTES.NOT_LOGGED_IN.SIGNUP}
                      element={<Signin type={AuthPageTypes.SIGNUP} />}
                    />
                  </Route>
                  <Route
                    path={ROUTES.NOT_LOGGED_IN.FORGOT_PASSWORD}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={ROUTES.NOT_LOGGED_IN.RESET_PASSWORD}
                    element={<ResetPassword />}
                  />
                  <Route
                    path={`/${ROUTES.NOT_LOGGED_IN.LOGOUT}`}
                    element={<Logout />}
                  />
                </Route>
                {/** Quick Share */}
                <Route
                  path={`${ROUTES.LOGGED_OR_NOT_LOGGED.QUICK_SHARE}/:quickShareId`}
                  element={<QuickShareViewer />}
                />
                {/** Error page */}
                <Route path="/error" element={<ErrorPage />} />
                {/** Default redirect */}
                <Route element={<ProtectedRoute />}>
                  <Route
                    path="*"
                    element={
                      <DefaultRedirect
                        organizationsList={organizationsList}
                        subdomain={subdomain}
                      />
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </PopupProvider>
        </ModalProvider>
      ) : (
        <AppLoading />
      )}
    </ErrorBoundary>
  );
}
