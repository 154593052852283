import newTheme from 'config/theme';
import {theme} from 'config/themes';
import events from 'extensions/events';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

import type {ReactNode} from 'react';
import type {GestureResponderEvent, ViewComponent} from 'react-native';

export interface PopupMenuProps {
  menuItems: PopupMenuItem[];
  onSelectItem: (command: string) => void;
  close: () => void;
  multiMode?: boolean;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  autoClose?: boolean;
}

export interface PopupMenuItem {
  text: string;
  command: string;
  icon?: ReactNode;
  disabled?: boolean;
  destructive?: boolean;
}

export function PopupMenu(props: PopupMenuProps) {
  const refs = useRef<ViewComponent[]>([]);
  const [hover, setHover] = useState('');
  const [focused, setFocused] = useState(-1);
  const hasHeader = props.menuItems.some(item => item.command === 'header');
  const classes = {
    root: [
      styles.root,
      hasHeader && styles.rootHeader,
      props.multiMode && styles.multiMode,
    ],
  };

  const focusItem = useCallback((index: number) => {
    const lastItem = refs.current.length - 1;
    const target = Math.max(0, Math.min(lastItem, index));
    const ref = refs.current[target];
    setFocused(target);
    try {
      // @ts-ignore
      ref?.focus();
    } catch (e) {}
  }, []);

  const selectItem = useCallback(
    (e: GestureResponderEvent, item: PopupMenuItem) => {
      e.stopPropagation();
      if (!item.disabled && props.onSelectItem) {
        props.onSelectItem(item.command);
      }
    },
    [props.onSelectItem],
  );

  const mouseEnter = useCallback((item: PopupMenuItem) => {
    if (!item.disabled && item.command !== hover) {
      setHover(item.command);
    }
  }, []);

  const mouseLeave = useCallback((item?: PopupMenuItem) => {
    if (!item || (!item.disabled && item.command === hover)) {
      setHover(undefined);
    }
  }, []);

  const menuItems = useMemo(
    () =>
      props.menuItems.map((item, index) => {
        if (index === 0 && item.command === 'header') {
          return (
            <View key="header" focusable={false} style={styles.header}>
              <Text
                style={styles.headerText}
                numberOfLines={1}
                ellipsizeMode="middle">
                {item.text}
              </Text>
            </View>
          );
        }

        if (item.text === '-') {
          return (
            <View
              key={`divider-${index}`}
              focusable={false}
              style={styles.divider}
            />
          );
        }

        const stylesButton: ViewStyle[] = [styles.item];
        const stylesText: TextStyle[] = [styles.itemText];

        if (props.buttonStyle) {
          stylesButton.push(props.buttonStyle);
        }

        if (props.textStyle) {
          stylesText.push(props.textStyle);
        }

        if (item.destructive) {
          stylesText.push(styles.destructive);
        }

        if (item.command === hover && !item.disabled) {
          stylesButton.push(styles.itemHover);
        }

        // TODO: refactor (remove onMouseEnter/Leave and above styling, replace with Pressable state in style prop
        return (
          <Pressable
            focusable
            style={stylesButton}
            key={item.command}
            ref={e => refs.current.push(e)}
            onPress={e => selectItem(e, item)}
            // @ts-ignore
            onMouseEnter={() => mouseEnter(item)}
            onMouseLeave={() => mouseLeave(item)}
            disabled={item.disabled}>
            {item.icon && <View style={styles.itemIcon}>{item.icon}</View>}
            <Text style={stylesText}>{item.text}</Text>
          </Pressable>
        );
      }),
    [props.menuItems, hover],
  );

  // List Hotkeys
  useEffect(
    () =>
      events.addKeyListener('down', e => {
        switch (e.code) {
          // Navigate up or down list
          case 'Tab':
            e.original.preventDefault();
            return focusItem(e.flags.shiftKey ? focused - 1 : focused + 1);
          // Navigate up list
          case 'ArrowUp':
            return focusItem(focused - 1);
          // Navigate down list
          case 'ArrowDown':
            return focusItem(focused + 1);
          // Navigate to top of list
          case 'ArrowLeft':
            return focusItem(0);
          // Navigate to bottom of list
          case 'ArrowRight':
            return focusItem(refs.current.length - 1);
          // Close list
          case 'Escape':
            return props.close();
        }
      }).unsubscribe,
    [props.menuItems, focused],
  );

  // Auto close
  useEffect(() => {
    if (props.autoClose) {
      window.addEventListener('click', props.close, {once: true});
      return () => window.removeEventListener('click', props.close);
    }
  }, [props.autoClose]);

  return (
    <View
      style={classes.root}
      // @ts-ignore
      onMouseLeave={() => mouseLeave()}
    >
      {menuItems}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 217,
    paddingVertical: 7,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: theme.menu.border,
    backgroundColor: theme.menu.fill,
  },
  rootHeader: {
    paddingTop: 0,
  },
  multiMode: {
    // borderColor: theme.menu.header.multiBorder,
  },
  item: {
    minHeight: 36,
    paddingVertical: 4,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  itemHover: {
    backgroundColor: theme.menu.hover,
  },
  destructive: {
    color: newTheme.colors.danger.$35Hover,
  },
  itemText: {
    flex: 1,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    // color: theme.menu.text,
    color: newTheme.colors.neutral.$2Base,
  },
  itemIcon: {
    width: 20,
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    height: 1,
    marginVertical: 4,
    backgroundColor: theme.menu.border,
  },
  header: {
    height: 28,
    borderBottomWidth: 1,
    borderColor: theme.menu.header.border,
    backgroundColor: theme.menu.header.fill,
    marginBottom: 7,
    borderRadius: 3,
  },
  headerText: {
    color: theme.menu.header.text,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 28,
    fontWeight: '400',
    paddingHorizontal: 16,
  },
});
