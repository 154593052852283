import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

export interface ItemChipProps {
  label?: string;
  padding: {left: boolean; right: boolean};
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
}

export function ItemChip(props: ItemChipProps) {
  return (
    <View style={styles.root}>
      {props.padding?.left && <View style={styles.padding} />}
      <View style={styles.enhancer}>{props.startEnhancer}</View>
      {props.label && (
        <View style={styles.labelText}>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            {props.label}
          </Typography>
        </View>
      )}
      <View style={styles.enhancer}>{props.endEnhancer}</View>
      {props.padding?.right && <View style={styles.padding} />}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 999,
    backgroundColor: theme.colors.neutral.$75,
    borderColor: 'rgba(34, 40, 53, 0.12)',
    borderWidth: 1,
  },
  padding: {
    width: 8,
    alignSelf: 'stretch',
  },
  enhancer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    paddingHorizontal: 6,
    paddingVertical: 0,
  },
});
