import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useMenu} from 'extensions/viewport/useMenu';
import {InvitationStatuses} from 'fast-sdk/src/api/share/consts';
import {createRef, useCallback, useMemo} from 'react';
import {
  type GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import IconCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import type {Invite, Member} from 'store/slices/settings/types';

type Props = {
  permission: string;
  member: Member | Invite;
  onChangePermission: (permission: string) => void;
  loggedMember: Member;
};

const PermissionDropdown = ({
  permission,
  onChangePermission,
  member,
  loggedMember,
}: Props) => {
  const isLoggedMemberOwner = loggedMember.permissions === 'owner';
  const isLoggedMemberAdmin = loggedMember.permissions === 'admin';
  const isLoggedMemberMember = loggedMember.permissions === 'member';

  const isOwner = permission === 'owner';
  const isAdmin = permission === 'admin';
  const isMember = permission === 'member';

  const isInvite =
    'inviter' in member && member.state === InvitationStatuses.Pending;

  const accessLabel = useMemo(() => {
    switch (permission) {
      case 'owner':
        return t`Owner`;
      case 'admin':
        return t`Admin`;
      case 'member':
        return t`Member`;
      case 'view':
        return t`Viewer`;
      default:
        if (isInvite) {
          return t`Viewer`;
        }
        return permission;
    }
  }, [permission]);

  const showMember =
    (isLoggedMemberOwner || isLoggedMemberAdmin || isLoggedMemberMember) &&
    !isInvite &&
    !isOwner;
  const showAdmin =
    (isLoggedMemberOwner || isLoggedMemberAdmin) && !isInvite && !isOwner;
  const showOwner = isOwner;

  const getMenuItems = () =>
    [
      showMember && {
        command: 'member',
        text: t`Member`,
        icon: (
          <Icon
            name="check"
            size={20}
            color={isMember ? theme.colors.neutral.$6 : 'transparent'}
          />
        ),
      },
      showAdmin && {
        command: 'admin',
        text: t`Admin`,
        icon: (
          <Icon
            name="check"
            size={20}
            color={isAdmin ? theme.colors.neutral.$6 : 'transparent'}
          />
        ),
      },
      showOwner && {
        command: 'owner',
        text: t`Owner`,
        icon: (
          <Icon
            name="check"
            size={20}
            color={isOwner ? theme.colors.neutral.$6 : 'transparent'}
          />
        ),
      },
    ].filter(e => !!e);

  const memberMenu = useMenu(
    () => getMenuItems(),
    command => {
      onChangePermission(command);
      memberMenu.close();
    },
    false,
  );

  const menuAnchorRef = createRef<View>();

  const menu = useCallback(
    (e: GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (isInvite || permission === 'owner') {
        return;
      }
      if (memberMenu) {
        if (e?.nativeEvent) {
          memberMenu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
        }
        memberMenu.setRef(menuAnchorRef);
      }
      memberMenu.open();
    },
    [memberMenu, menuAnchorRef],
  );

  return (
    <View style={styles.menuWrapper}>
      <Pressable
        ref={menuAnchorRef}
        onPress={menu}
        style={e => [
          styles.menu,
          // @ts-ignore
          e.hovered && styles.menuActive,
        ]}>
        <Text style={styles.menuLabel}>{accessLabel}</Text>
        <IconCommunity
          name="chevron-down"
          size={18}
          color={theme.colors.neutral.$5}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  menuWrapper: {
    maxWidth: 100,
    position: 'relative',
  },
  menu: {
    flexGrow: 0,
    minWidth: 36,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuActive: {
    backgroundColor: theme.colors.neutral.$13,
  },
  menuLabel: {
    fontSize: 14,
    color: theme.colors.neutral.$3,
    fontWeight: '400',
    lineHeight: 24,
    marginRight: 8,
    textTransform: 'capitalize',
  },
});

export default PermissionDropdown;
