import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import {ColumnHeader} from './ColumnHeader';

export const COLUMN_MINIMIZED_ICON = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 'lucide:list-tree',
  [ColumnManagerInfoKeys.FoldersAndFiles]: 'lucide:folder',
  [ColumnManagerInfoKeys.InfoPanel]: 'lucide:info',
  [ColumnManagerInfoKeys.ShareConfiguration]: 'lucide:list-tree',
  [ColumnManagerInfoKeys.AIChat]: 'lucide:messages-square',
};

type Props = {
  columnKey: ColumnManagerInfoKeys;
};

export function ColumnMinimized({columnKey}: Props) {
  const dispatch = useDispatch();

  const onOpenPanel = () => {
    dispatch(app.default.actions.setColumnInfoOverride({[columnKey]: true}));
  };

  const onExpandPanel = () => {
    dispatch(app.default.actions.expandColumn({columnKey}));
  };

  return (
    <View style={styles.container}>
      <ColumnHeader onToggle={onOpenPanel} onExpand={onExpandPanel} />
      <Icon
        name={COLUMN_MINIMIZED_ICON[columnKey]}
        size={16}
        color={theme.colors.neutral.$700}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 38,
    height: '100%',
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 6,
    paddingHorizontal: 4,
    gap: 10,
    marginHorizontal: 1,
    marginVertical: 1,
  },
});
