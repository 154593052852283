import {t} from '@lingui/macro';
import {Picker} from '@react-native-picker/picker';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {
  PermJoin,
  PermManageMembers,
  WorkspaceDetails,
  WorkspaceListDetail,
} from 'fast-sdk/src/api/workspace/consts';
import Typography from 'interface/base/Typography';
import {useCallback, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import * as workspaceSlice from 'store/slices/workspace';

interface WorkspaceSettingsPermissionsProps {
  workspace: WorkspaceListDetail;
}

export function WorkspaceSettingsPermissions({
  workspace,
}: WorkspaceSettingsPermissionsProps) {
  const toast = useToast();

  const [perm_join, setPermJoin] = useState(workspace.perm_join);
  const [perm_member_manage, setPermMemberManage] = useState(
    workspace.perm_member_manage,
  );

  const updateWorkspacePermission = useCallback(
    (permissions: keyof WorkspaceDetails) =>
      async (value: WorkspaceDetails[keyof WorkspaceDetails]) => {
        const {result} = await api.workspace.updateWorkspace(
          workspace.folder_name,
          {
            [permissions]: value,
          },
        );
        if (result) {
          if (permissions === 'perm_join') {
            setPermJoin(value as PermJoin);
          } else if (permissions === 'perm_member_manage') {
            setPermMemberManage(value as PermManageMembers);
          }

          const updatedWorkspace = {...workspace, [permissions]: value};
          workspaceSlice.default.actions.updateWorkspace({
            id: workspace.id,
            workspace: updatedWorkspace,
          });

          toast.show(t`Workspace permissions updated`, {type: 'neutral'});
        }
      },
    [workspace.folder_name],
  );

  return (
    <View style={styles.root}>
      <View style={styles.options}>
        <View style={styles.option}>
          <Typography
            overrides={styles.label}
            variant="semi-bold"
            size="md"
            color={theme.colors.neutral.$3}>
            {t`Joining Workspace`}
          </Typography>
          <Picker
            enabled={!!perm_join}
            selectedValue={perm_join ?? ''}
            onValueChange={updateWorkspacePermission('perm_join')}
            style={styles.picker}>
            <Picker.Item label={t`Join Policy`} value="" enabled={false} />
            <Picker.Item label={t`Only Org Owners`} value={'Only Org Owners'} />
            <Picker.Item label={t`Admin or above`} value={'Admin or above'} />
            <Picker.Item label={t`Member or above`} value={'Member or above'} />
            <Picker.Item
              label={t`No one can join automatically`}
              value={'No one can join automatically'}
            />
          </Picker>
        </View>
        <View style={[styles.option, {borderBottomWidth: 0}]}>
          <Typography
            overrides={styles.label}
            variant="semi-bold"
            size="md"
            color={theme.colors.neutral.$3}>
            {t`Member Management`}
          </Typography>
          <Picker
            enabled={!!perm_member_manage}
            selectedValue={perm_member_manage ?? ''}
            onValueChange={updateWorkspacePermission('perm_member_manage')}
            style={styles.picker}>
            <Picker.Item label={t`Member Policy`} value="" enabled={false} />
            <Picker.Item label={t`Admin or above`} value={'Admin or above'} />
            <Picker.Item label={t`Member or above`} value={'Member or above'} />
          </Picker>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginBottom: 14,
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
  option: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: theme.colors.neutral.$10,
    paddingVertical: 12,
    gap: 8,
  },
  label: {
    flex: 1,
  },
  picker: {
    display: 'flex',
    paddingHorizontal: 8,
    paddingVertical: 6,
    gap: 8,
    borderRadius: 3,
    borderWidth: 0,
    height: 36,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$4,
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$10}`,
    borderColor: 'transparent',
  },
});
