import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Transfers} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {ColorPicker} from 'interface/base/ColorPicker';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Avatar} from 'interface/common/Avatar';
import {CropImage} from 'interface/common/CropImage';
import useDebounce from 'interface/common/hooks/useDebounce';
import {useEffect, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {createInitials} from 'utils/common/data';
import {resizeImage} from 'utils/fast/files';

import type {AvatarSize} from 'interface/common/Avatar';

export interface AvatarPickerProps {
  size?: AvatarSize;
  name?: string;
  color?: string;
  image?: string;
  onColorChange?: (color: string) => void;
  onImageChange?: (file: File, url: string) => Promise<void>;
  onImageDelete?: () => Promise<void>;
}

export function AvatarPicker(props: AvatarPickerProps) {
  const modal = useModal();
  const [color, setColor] = useState(props.color);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageName, setImageName] = useState<string>('');
  const [imageType, setImageType] = useState<string>('');
  const [imageCrop, setImageCrop] = useState<string>('');
  const [imageCropUrl, setImageCropUrl] = useState<string>();

  const handleImageChange = async () => {
    const input = await Transfers.pickFiles(false, 'image/*');
    if (input.length) {
      const file = input.item(0);
      const url = URL.createObjectURL(file);
      setImageName(file.name);
      setImageType(file.type);
      setImageCrop(url);
    }
  };

  const handleImageRemove = async () => {
    setLoading(true);
    setImageCrop(undefined);
    setImageFile(undefined);
    setImageCropUrl(undefined);
    setImageName('');
    setImageType('');
    await props.onImageDelete?.();
    setLoading(false);
  };

  // Set the initial image
  useEffect(() => {
    setImageCropUrl(props.image);
  }, []);

  // Open crop image modal
  useEffect(() => {
    if (imageCrop) {
      modal.open(
        <CropImage
          image={imageCrop}
          imageName={imageName}
          imageType={imageType}
          setImage={setImageFile}
          setCompletedImg={setImageCropUrl}
        />,
      );
    }
  }, [imageCrop]);

  // Handle crop image completion
  useEffect(() => {
    if (
      props.onImageChange &&
      imageFile &&
      imageCropUrl &&
      imageCropUrl !== props.image
    ) {
      (async () => {
        setLoading(true);
        const resizedFile = await resizeImage(imageFile);
        await props.onImageChange(resizedFile, imageCropUrl);
        setLoading(false);
      })();
    }
  }, [imageFile, imageCropUrl, props.onImageChange]);

  return (
    <View style={styles.root}>
      <View style={[styles.preview, {backgroundColor: `#${color}`}]}>
        <Pressable onPress={handleImageChange}>
          <Avatar
            color={color}
            photo={imageCropUrl}
            size={props.size ?? 6}
            shapeKind="rounded-square"
            initials={props.name ? createInitials(props.name) : ''}
            hideBorder
          />
        </Pressable>
      </View>
      <View style={styles.interface}>
        <View style={styles.buttons}>
          {imageCropUrl ? (
            <>
              {!loading && (
                <Button
                  onPress={handleImageChange}
                  variant="secondary"
                  disabled={loading}>
                  <Typography size="xs" color={theme.colors.neutral.$700}>
                    Change
                  </Typography>
                </Button>
              )}
              <Button
                onPress={handleImageRemove}
                variant="secondary"
                loading={loading}
                disabled={loading}
                startEnhancer={
                  <Icon
                    name="lucide:trash"
                    size={18}
                    color={theme.colors.neutral.$700}
                  />
                }
                overrides={{
                  Button: {
                    style: {
                      width: 32,
                    },
                  },
                }}
              />
            </>
          ) : (
            <Button
              onPress={handleImageChange}
              variant="secondary"
              loading={loading}
              disabled={loading}>
              <Typography size="xs" color={theme.colors.neutral.$700}>
                Upload Image
              </Typography>
            </Button>
          )}
        </View>
        {props.onColorChange && (
          <View style={styles.colorPicker}>
            <ColorPicker
              color={color}
              onChange={setColor}
              onBlur={() => props.onColorChange?.(color)}>
              <View style={styles.colorIcon}>
                <Icon
                  name="lucide:pipette"
                  size={18}
                  color={theme.colors.neutral.$700}
                />
              </View>
              <View style={styles.colorValue}>
                <View
                  style={[styles.colorPreview, {backgroundColor: `#${color}`}]}
                />
                <Typography size="xs" color={theme.colors.neutral.$black}>
                  {color}
                </Typography>
              </View>
            </ColorPicker>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  preview: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 0,
    borderRadius: 16,
  },
  interface: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 8,
  },
  buttons: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  colorPicker: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.neutral.$100,
  },
  colorIcon: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    minHeight: 32,
    padding: 4,
    borderRightWidth: 1,
    borderRightColor: theme.colors.neutral.$100,
  },
  colorPreview: {
    width: 16,
    height: 16,
    borderRadius: 4,
    backgroundColor: 'rgba(36, 153, 255, 1)',
  },
  colorValue: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 0,
    height: 32,
    gap: 8,
    borderLeft: 1,
  },
});
