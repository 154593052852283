import useHasAccessToOrg from 'extensions/session/hooks/useHasAccessToOrg';
import {api} from 'fast-sdk';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';

export default function useUserOrgPermission() {
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();
  const {checkOrgAccess} = useHasAccessToOrg();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const permission = useSelector(app.selectors.getUserPermission);
  const currentUser = useSelector(user.selectors.getUserDetails);

  async function getMembers() {
    const {result, users} =
      await api.organization.getOrganizationMembers(subdomain);
    if (result) {
      const permission =
        users.find(user => user.id === currentUser.id)?.permissions || '';
      dispatch(app.default.actions.setUserPermission({permission}));
    }
  }

  useEffect(() => {
    if (
      isSubdomainValid(subdomain) &&
      !permission &&
      isLoggedIn &&
      checkOrgAccess(subdomain)
    ) {
      getMembers();
    }
  }, [subdomain, currentUser, permission]);

  return {permission};
}
