import {useCallback, useMemo, useState} from 'react';

export type SelectionMode = 'none' | 'all' | 'filename';

export const useTextSelection = (
  mode: SelectionMode | undefined,
  value: string | undefined,
) => {
  const initialSelection = useMemo(() => {
    if (!value || !mode || mode === 'none') {
      return undefined;
    }

    if (mode === 'all') {
      return {start: 0, end: value.length};
    }

    const dot = value.lastIndexOf('.');
    const name = value.slice(0, dot);
    return {start: 0, end: name.length};
  }, [mode, value]);

  const [selection, setSelection] = useState(initialSelection);

  const disableSelection = useCallback(() => setSelection(undefined), []);

  return {selection, disableSelection};
};
