import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {
  useControls,
  useTransformComponent,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import {getClosestZoom} from '../../hooks/useZoom';
import ControlBar from '../ControlBar';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';

const ANIM_DURATION = 150;

import type {ReactZoomPanPinchState} from 'react-zoom-pan-pinch';

export type ImageControlsProps = {
  setContentFit: (fit: 'horiz' | 'vert' | null) => void;
  toggleFullscreen: () => void;
  isFullscreen: boolean;
  isThumbnail: boolean;
  metrics: {
    frame: [number, number];
    image: [number, number];
    scale: number;
  };
};

const ImageControls = (props: ImageControlsProps) => {
  const {metrics, isFullscreen, isThumbnail, ...actions} = props;
  const {frame, image, scale} = metrics;
  const {setTransform, instance} = useControls();
  const {setContentFit, toggleFullscreen} = actions;
  const [prevState, setPrevState] = useState<ReactZoomPanPinchState | null>(
    null,
  );

  const transform = useCallback(
    (scale: number, time: number): [number, number, number, number] => [
      (frame[0] - image[0] * scale) / 2,
      (frame[1] - image[1] * scale) / 2,
      scale,
      time,
    ],
    [frame, image],
  );

  const zoom = useTransformComponent(({state}) => state.scale);

  const zoomIn = useCallback(
    () => setTransform(...transform(getClosestZoom(zoom, 'in'), ANIM_DURATION)),
    [transform, zoom],
  );

  const zoomOut = useCallback(
    () =>
      setTransform(...transform(getClosestZoom(zoom, 'out'), ANIM_DURATION)),
    [transform, zoom],
  );

  // Reset zoom when scale changes
  useEffect(() => setTransform(...transform(scale, 0)), [scale]);

  // Update previous state
  useTransformEffect(({state}) => {
    // No previous state, no need to update
    if (!prevState) return;
    // Previous zoom was the base zoom, we're at overview
    if (state.previousScale === scale) {
      // Update previous state with current state
      setPrevState(null);
    }
  });

  return !isFullscreen && !isThumbnail ? (
    <View style={styles.root}>
      <ControlBar
        left={<View />}
        middle={
          <ControlsMiddle
            zoom={zoom}
            handleZoomIn={zoomIn}
            handleZoomOut={zoomOut}
            handleLastZoom={() => {
              setContentFit(null);
              // If state not saved, and zoom is base, go to 100%
              if (!prevState && zoom === scale) {
                setTransform(...transform(1, ANIM_DURATION));
                // Go back to saved state
              } else if (prevState) {
                const {positionX, positionY, scale} = prevState;
                setTransform(positionX, positionY, scale, ANIM_DURATION);
                setPrevState(null);
                // Go to base scale (save state)
              } else {
                setPrevState({...instance.getContext().state});
                setTransform(...transform(scale, 0));
              }
            }}
          />
        }
        right={
          <ControlsRight
            handleExpandHorizontal={() => setContentFit('horiz')}
            handleExpandVertical={() => setContentFit('vert')}
            toggleFullscreen={toggleFullscreen}
            isFullscreen={isFullscreen}
          />
        }
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default ImageControls;
