import {
  ROUTES,
  STORAGE_ROOT,
  WORKSPACE_ROOT,
  WORKSPACE_STORAGE,
} from 'constants/routes';
import {Outlet, useNavigate, useParams} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useMultiplayer} from 'interface/multiplayer/hooks/useMultiplayer';
import type {MultiplayerState} from 'interface/multiplayer/types';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import AppUserBar from 'interface/stacks/app/AppUserBar';
import {Search} from 'interface/stacks/search/views/Search';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import {useUploader} from 'interface/stacks/uploads/hooks/useUploader';
import {Uploads} from 'interface/stacks/uploads/views/Uploads';
import {FolderPicker} from 'interface/stacks/workspace/storage/FolderPicker';
import {Fragment, useEffect, useLayoutEffect, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import {WorkspaceFrame} from '../base/WorkspaceFrame';
import {useGetCurrentShareMap} from '../hooks/useGetCurrentShareMap';
import {useGetQuickShares} from '../hooks/useGetQuickShares';
import {useGetWorkspaceDetails} from '../hooks/useGetWorkspaceDetails';

export interface WorkspaceContext {
  workspace: WorkspaceListDetail;
  uploader: UploaderState;
  multiplayer: MultiplayerState;
  hasFixedMenu: boolean;
  openMenu: () => void;
}

interface LayoutWorkspaceProps {
  isGuestView?: boolean;
}

export function LayoutWorkspace({isGuestView}: LayoutWorkspaceProps) {
  const {workspaceId, folderKey, fileId, sharedCustomName} = useParams<{
    workspaceId: string;
    folderKey?: string;
    fileId?: string;
    sharedCustomName?: string;
  }>();

  const navigate = useNavigate();
  const uploader = useUploader();
  const {fetchAndUpdateQuickShares} = useGetQuickShares();
  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();
  const {share} = useGetShareDetails({sharedCustomName});

  const workspaces = useSelector(workspaceSelectors.getAllJoinedWorkspaces);
  const shareMap = useGetCurrentShareMap();
  const {subdomain} = useSubDomain();

  const workspace = useMemo(() => {
    return workspaces.find(
      w =>
        w.folder_name === workspaceId ||
        shareMap?.workspaceFolderName === w.folder_name,
    );
  }, [workspaces, workspaceId, shareMap]);

  const isValidWorkspace = useMemo(() => {
    return workspaceId === WORKSPACE_ROOT || workspace || isGuestView;
  }, [workspaceId, workspace, isGuestView]);

  const multiplayer = useMultiplayer(
    {
      id: workspace?.id,
      alt: workspace?.folder_name,
      type: 'workspace',
    },
    {
      id: folderKey ?? fileId,
      type: folderKey ? 'folder' : 'file',
    },
  );

  const workspaceContext: WorkspaceContext = useMemo(
    () => ({
      workspace,
      uploader,
      multiplayer,
      hasFixedMenu: false,
      openMenu: () => {},
    }),
    [workspace, uploader, multiplayer],
  );

  const renderContent = () => (
    <View style={styles.content}>
      {(workspace || isGuestView) && <Outlet context={workspaceContext} />}
      {Boolean(multiplayer.following) && (
        <WorkspaceFrame
          mode="follow"
          name={multiplayer.following?.userNameFirst}
          color={multiplayer.following?.userColor}
        />
      )}
    </View>
  );

  const reloadWorkspaceDetails = async () => {
    const workspace = await fetchWorkspaceDetails(workspaceId);
    if (!workspace) {
      navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`, {
        replace: true,
      });
    }
  };

  const redirectToFirstWorkspace = () => {
    const firstWorkspace = workspaces
      .filter(w => w.org_domain === subdomain)
      .at(0);
    if (firstWorkspace) {
      const url = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${firstWorkspace.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;

      navigate(url, {
        replace: true,
      });
    }
  };

  useLayoutEffect(() => {
    if (workspaceId && workspaceId !== WORKSPACE_ROOT && !workspace) {
      reloadWorkspaceDetails();
    } else {
      if (!workspace && !isGuestView) {
        redirectToFirstWorkspace();
      }
    }
  }, [workspaceId, workspace]);

  useEffect(() => {
    if (isValidWorkspace && workspaceId && workspaceId !== WORKSPACE_ROOT) {
      fetchAndUpdateQuickShares(workspaceId);
    }
  }, []);

  if (!isValidWorkspace) {
    return <AppLoading />;
  }

  return (
    <Fragment>
      <View style={styles.root}>
        <AppUserBar />
        {renderContent()}
        <FolderPicker
          instanceNs={share ? 'share' : 'workspace'}
          instanceId={share ? share.id : workspaceId}
          instanceName={share ? share.name : workspace?.name}
        />
      </View>
      <Uploads {...uploader} />
      <Search
        instanceNs={share ? 'share' : 'workspace'}
        instanceId={share ? share.id : workspaceId}
        instanceAlt={share ? share.custom_name : workspaceId}
        instanceName={share ? share.name : workspace?.name}
      />
    </Fragment>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
  },
  content: {
    flex: 1,
    height: '100%',
  },
  menu: {
    height: '100%',
  },
  menuContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
});
