import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys as ColumnKeys} from 'store/slices/app/types';

// List of columns that should respond to screen size changes
const responsiveColumns: ColumnKeys[] = [
  ColumnKeys.FoldersAndFiles,
  ColumnKeys.ShareConfiguration,
  ColumnKeys.AIChat,
  ColumnKeys.WorkspaceBrowser,
  ColumnKeys.InfoPanel,
];

// Determines how many columns should be visible based on screen width
// Returns a number between 1 and the total number of responsive columns
function getMaxVisibleColumns(windowWidth: number): number {
  if (windowWidth < 900) return 1; // Mobile: show only one column
  if (windowWidth < 1000) return 2; // Tablet: show two columns
  if (windowWidth < 1500) return 3; // Small desktop: show three columns
  if (windowWidth < 1800) return 4; // Medium desktop: show four columns
  return responsiveColumns.length; // Large desktop: show all columns
}

export default function useColumnManagerHandler() {
  const dispatch = useDispatch();
  // Get current column states from Redux
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);

  // Helper to update column visibility if it has changed
  const setColumnInfo = (key: ColumnKeys, value: boolean) => {
    if (columnInfo[key] === value) return;
    dispatch(app.default.actions.setColumnInfo({[key]: value}));
  };

  useEffect(() => {
    // Handler for window resize events
    const handleResize = () => {
      const width = window.innerWidth;
      const maxVisible = getMaxVisibleColumns(width);
      let autoVisibleCount = 0;

      // For each responsive column that hasn't been manually overridden,
      // show it if we haven't reached the maximum number of visible columns
      responsiveColumns.forEach(key => {
        if (columnInfoOverride[key] == null) {
          const shouldShow = autoVisibleCount < maxVisible;
          setColumnInfo(key, shouldShow);
          if (shouldShow) {
            autoVisibleCount++;
          }
        }
      });
    };

    // Add resize listener and run initial layout
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup resize listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [columnInfo, columnInfoOverride, dispatch]);
}
