import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useEffect, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import {nowServerDate, parseServerDate} from 'utils/common/dates';
import {
  ACTIVE_CHAT_TIME_CHECK_INTERVAL,
  ACTIVE_CHAT_TIME_THRESHOLD,
} from '../consts';
import {clearMessage} from '../helpers';
import {AiChatListItemPopup} from './AiChatListItemPopup';
import {AiChatMembers} from './AiChatMembers';
import {MessagesLabel} from './AiChatMessagesLabel';
import {AiChatPrivateLabel} from './AiChatPrivateLabel';
import {TimeLabel} from './AiChatTimeLabel';

interface Props {
  chat: EntityChat;
  workspaceMembers: Member[];
  isExpanded: boolean;
  onPress: () => void;
}
export function AiChatItem({
  chat,
  workspaceMembers,
  onPress,
  isExpanded,
}: Props) {
  const date = parseServerDate(chat.createdAt);
  const messageCount = chat.messageCount;
  const isPrivate = chat.privacy?.visibility === 'private';
  const updatedAt = parseServerDate(chat.updatedAt);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const checkActive = () => {
      const now = parseServerDate(nowServerDate());
      setIsActive(
        now.getTime() - updatedAt.getTime() < ACTIVE_CHAT_TIME_THRESHOLD,
      );
    };
    checkActive();
    const interval = setInterval(checkActive, ACTIVE_CHAT_TIME_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [updatedAt]);

  return (
    <Pressable
      style={[styles.container, isActive && styles.backgroundActive]}
      onPress={onPress}>
      <View style={styles.titleRow}>
        <Typography
          size="md"
          variant="medium"
          color={theme.colors.neutral.$700}
          numberOfLines={1}>
          {chat.name}
        </Typography>
        <AiChatListItemPopup isPrivate={isPrivate} chatId={chat.id} />
      </View>
      {isExpanded && (
        <Typography
          size="sm"
          variant="regular"
          color={theme.colors.neutral.$500}
          overrides={styles.body}
          numberOfLines={2}>
          {clearMessage(
            chat.lastMessage?.response?.text ?? chat.lastMessage?.content.text,
          )}
        </Typography>
      )}
      <View style={styles.detailsRow}>
        <AiChatMembers chatId={chat.id} workspaceMembers={workspaceMembers} />
        <TimeLabel date={date} />
        <MessagesLabel messagesCount={messageCount} />
        <View style={styles.spacer} />
        {isPrivate && <AiChatPrivateLabel />}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 6,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$50,
  },
  backgroundActive: {
    backgroundImage: 'linear-gradient(90deg, #F0F6FF 0%, #EBEBFF 100%)',
    backgroundColor: 'transparent',
  },
  titleRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsRow: {
    marginTop: 6,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  popupContent: {
    padding: 12,
    borderRadius: 8,
    gap: 16,
    backgroundColor: theme.colors.neutral.$50,
  },
  spacer: {
    flex: 1,
  },
  body: {
    width: '100%',
    paddingVertical: 6,
  },
});
