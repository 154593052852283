import theme from 'config/theme';
import {URLAccessType} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/common/Button';
import Chip from 'interface/common/Chip';
import {CustomPicker} from 'interface/common/CustomPicker';
import {ModalSection} from 'interface/common/Modal';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import {useGetOrganizationDetailsCached} from 'interface/common/hooks/useGetOrganizationDetailsCached';
import {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector} from 'react-redux';
import type {Share} from 'store/slices/shared/types';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import {copyLink} from 'utils/common/interactions';
import useGenerateShareUrl from '../hooks/useGenerateShareUrl';

interface Props {
  share: Share;
  updateShare: (updatedShare: Partial<Share>) => void;
  isEditable: boolean;
  done: () => void;
}

const ACCESS_TYPE_LABELS = {
  [URLAccessType.MEMBERS_ONLY]: 'Anyone in this Workspace can view',
  [URLAccessType.ORG_MEMBERS]: 'Anyone in this Org can view',
  [URLAccessType.REGISTERED_USERS]: 'Anyone with the link',
};

const ACCESS_TYPE_ICONS = {
  [URLAccessType.MEMBERS_ONLY]: 'lock',
  [URLAccessType.ORG_MEMBERS]: 'lock',
  [URLAccessType.REGISTERED_USERS]: 'web',
};

const ACCESS_TYPE_DESCRIPTIONS = {
  [URLAccessType.MEMBERS_ONLY]:
    ' workspace members can access this share. People not part of this workspace can join by invitation.',
  [URLAccessType.ORG_MEMBERS]:
    ' org members can access this share. People not part of this org can join by invitation.',
  [URLAccessType.REGISTERED_USERS]:
    'Anyone with a link to this Share can access it. They will also be able to invite other people to this Share.',
};

const ACCESS_TYPE_OPTIONS = Object.entries(ACCESS_TYPE_LABELS).map(
  ([key, value]) => ({
    value: key,
    title: value,
  }),
);

export function GeneralAccess({share, updateShare, isEditable, done}: Props) {
  const toast = useToast();

  const {generateShareUrlWithSubdomain} = useGenerateShareUrl();

  const {org: shareOrg} = useGetOrganizationDetailsCached(share?.parent_org);
  const shareWorkspace = useSelector(
    workspaceSelectors.getWorkspaceByAlt(share?.parent_workspace_folder),
  ) ?? {name: 'Workspace', id: share?.parent_workspace};

  const [accessType, setAccessType] = useState<URLAccessType>(
    share?.access_options,
  );

  const copyShareLink = () => {
    const shareURL = generateShareUrlWithSubdomain(share, shareOrg);
    copyLink(shareURL);
    toast.show('The share URL was copied to clipboard', {type: 'success'});
    done();
  };

  const saveAccessType = (newAccessType: URLAccessType) => {
    setAccessType(newAccessType);
    updateShare({access_options: newAccessType});
  };

  useEffect(() => {
    setAccessType(share?.access_options);
  }, [share?.access_options]);

  return (
    <View style={styles.root}>
      <ModalSection title="General Access" />
      <View style={{flexDirection: 'row', gap: 10}}>
        <View style={styles.icon}>
          <MCIcon
            name={ACCESS_TYPE_ICONS[accessType]}
            size={20}
            color={theme.colors.neutral.$white}
          />
        </View>
        <View style={{flexDirection: 'column', gap: 14, flex: 1}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <CustomPicker
              options={ACCESS_TYPE_OPTIONS}
              setSelected={(value: string) =>
                saveAccessType(value as URLAccessType)
              }
              selected={accessType}
              enabled={isEditable}
            />
            <Button
              type="Primary"
              onPress={copyShareLink}
              lIcon={
                <MCIcon
                  name="link"
                  size={20}
                  color={theme.colors.neutral.$white}
                />
              }
              label="Copy Link"
            />
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.accessDescription}>
              {accessType === URLAccessType.MEMBERS_ONLY ? (
                <Chip
                  label={shareWorkspace?.name}
                  icon={<WorkspaceBadge id={shareWorkspace?.id} size={10} />}
                />
              ) : accessType === URLAccessType.ORG_MEMBERS ? (
                <Chip
                  label={shareOrg?.name}
                  icon={
                    <MCIcon
                      name={'office-building'}
                      size={18}
                      color={theme.colors.neutral.$2Base}
                    />
                  }
                />
              ) : undefined}
              {ACCESS_TYPE_DESCRIPTIONS[accessType]}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    zIndex: -1,
  },
  icon: {
    backgroundColor: theme.colors.neutral.$2Base,
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    borderRadius: 50,
  },
  accessDescription: {
    fontSize: 13,
    color: theme.colors.neutral.$4,
    lineHeight: 20,
  },
});
