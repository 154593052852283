/**
 * Truncates a filename to a maximum length
 *
 * @param str The filename to truncate
 * @param len The maximum length of the filename
 * @returns The truncated filename
 */
export function truncateFileName(str: string, len = 100): string {
  if (str.length <= len) return str;
  const dot = str.lastIndexOf('.');
  const name = str.slice(0, dot);
  const extension = str.slice(dot + 1);
  return `${name.slice(0, len - extension.length - 1)}.${extension}`;
}

/**
 * Delays the execution of a function for a given number of milliseconds
 *
 * @param ms The number of milliseconds to delay
 * @returns A promise that resolves after the delay
 */
export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Generates a random float between 0 and 1
 */
export function rand() {
  try {
    const values = crypto.getRandomValues(new Int32Array(1));
    const buffer = values.buffer;
    return new DataView(buffer).getUint32(0);
  } catch (e) {
    return Math.random();
  }
}

/**
 * Generates a unique identifier
 */
export function uuidV4() {
  try {
    return crypto.randomUUID();
  } catch (e) {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (rand() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

/**
 * Converts a Uint8Array to a hexadecimal string
 *
 * @param data The Uint8Array to convert
 * @returns The hexadecimal string representation of the input array
 */
export function bytesToHex(data: Uint8Array): string {
  let str = '';
  for (let i = 0; i < data.length; i++) {
    const h = (data[i] & 0xff).toString(16);
    if (h.length < 2) str += '0';
    str += h;
  }
  return str;
}

/**
 * Converts a Blob to an ArrayBuffer
 *
 * @param blob The Blob to convert
 * @param done A function to handle the resulting ArrayBuffer
 * @param error A function to handle any errors that occur during the conversion
 */
export function blobToBuffer(
  blob: Blob,
  done: (buf: ArrayBuffer) => void,
  error: (err: DOMException) => void,
) {
  if (typeof blob.arrayBuffer === 'function') {
    blob.arrayBuffer().then(done).catch(error);
  } else {
    const reader = new FileReader();
    reader.onload = () => done(reader.result as ArrayBuffer);
    reader.onerror = () => error(reader.error as DOMException);
    reader.onabort = () => error(reader.error as DOMException);
    reader.readAsArrayBuffer(blob);
  }
}

/**
 * Initializes a heap array with a given size
 *
 * @param heap The heap array to initialize. If not provided, a new array will be created
 * @param size The size of the heap array. If not provided, a default size of 65536 will be used
 * @returns The initialized heap array
 * @throws Will throw an error if the heap size is not a positive integer or a multiple of 4096
 */
export function heapInit(heap?: Uint8Array, size?: number): Uint8Array {
  const s = heap ? heap.byteLength : size || 65536;
  if (s & 0xfff || s <= 0)
    throw new Error(
      'heap size must be a positive integer and a multiple of 4096',
    );
  heap = heap || new Uint8Array(new ArrayBuffer(s));
  return heap;
}

/**
 * Writes data to a heap array
 *
 * @param heap The heap array to write to
 * @param hpos The position in the heap array to start writing
 * @param data The data to write to the heap array
 * @param dpos The position in the data array to start writing
 * @param dlen The length of the data to write
 * @returns The number of bytes written
 */
export function heapWrite(
  heap: Uint8Array,
  hpos: number,
  data: Uint8Array,
  dpos: number,
  dlen: number,
): number {
  const hlen = heap.length - hpos;
  const wlen = hlen < dlen ? hlen : dlen;
  heap.set(data.subarray(dpos, dpos + wlen), hpos);
  return wlen;
}

/**
 * Throttles a function to prevent it from being called more than once in a given time period.
 * @param func The function to throttle.
 * @param wait The time period in milliseconds.
 * @param opts Optional options object with leading and trailing properties.
 * @returns The throttled function.
 */
export function throttle(
  func: (...args: any[]) => any,
  wait: number,
  opts?: {leading: boolean; trailing: boolean},
): (...args: Parameters<typeof func>) => ReturnType<typeof func> {
  const options = opts || {leading: false, trailing: false};
  let context: any;
  let args: any;
  let result: any;
  let timeout: any = null;
  let previous = 0;

  const later = () => {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  return function (this: any) {
    const now = Date.now();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}
