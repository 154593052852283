import {useModal} from 'extensions/viewport/useModal';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useCallback} from 'react';
import type {ActiveTab} from '../dialogs/WorkspaceSettings';
import {WorkspaceSettings} from '../dialogs/WorkspaceSettings';

export function useModalWorkspaceSettings(
  workspace: WorkspaceListDetail,
  selfId: string,
  isAdminOrOwner?: boolean,
) {
  const modal = useModal();
  const open = useCallback(
    (initialTab?: ActiveTab) => {
      modal.open(
        <WorkspaceSettings
          workspace={workspace}
          close={modal.close}
          initialTab={initialTab}
          selfId={selfId}
          isAdminOrOwner={isAdminOrOwner}
        />,
      );
    },
    [workspace, modal, selfId, isAdminOrOwner],
  );

  return {open, close: modal.close};
}
