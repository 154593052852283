import type {PayloadAction} from '@reduxjs/toolkit';
import type {PendingInvitationDetails} from 'interface/stacks/auth/consts';

export type Store = {
  user?: AppUser;
  active: boolean;
  loaded: boolean;
  revision: number;
  domainOrigin: string;
  stopRedirect: boolean;
  currentJoinInvitation: PendingInvitationDetails | undefined;
  forceLogout: boolean;
  redirectToRenewSubscription: boolean;
  renewSubscriptionSubdomain: string | undefined;
  userPermission: string | undefined;
  currentPage: PageInfo;
  columnInfo: ColumnManagerInfo;
  columnInfoOverride: ColumnManagerInfo;
  columnVisibility: ColumnManagerInfo;
  endpointCallsLimit: EndpointsCallMap;
  redirectTo: string | undefined;
};

export type Actions = {
  Start: PayloadAction<{}>;
  Revision: PayloadAction<{}>;
  Login: PayloadAction<AppUser>;
  Activate: PayloadAction<{active: boolean}>;
  Load: PayloadAction<{loaded: boolean}>;
  Notify: PayloadAction<{count: number}>;
  DomainOrigin: PayloadAction<{origin: string}>;
  SetCurrentJoinInvitation: PayloadAction<{
    joinInvitation: PendingInvitationDetails;
  }>;
  ClearCurrentJoinInvitation: PayloadAction<{}>;
  SetForceLogout: PayloadAction<boolean>;
  SetRedirectToRenewSubscription: PayloadAction<{
    redirect: boolean;
    subdomain: string;
  }>;
  setUserPermission: PayloadAction<{permission: string}>;
  setColumnInfo: PayloadAction<ColumnManagerInfo>;
  setColumnInfoOverride: PayloadAction<ColumnManagerInfo>;
  setColumnVisibility: PayloadAction<ColumnManagerInfo>;
  SetCurrentPage: PayloadAction<Partial<PageInfo>>;
  setEndpointCallsLimitEntry: PayloadAction<{
    key: string;
    times?: number;
    sent?: Date;
  }>;
  SetRedirectTo: PayloadAction<string>;
  expandColumn: PayloadAction<{columnKey: string}>;
};

export type AppUser = {
  ekey: string;
  name?: string;
  email?: string;
  avatar: string;
  created: string;
  options: number;
  header: AppHeader;
  group: AppUserGroup;
  limit: AppUserLimit;
};

export type AppHeader = {
  custom: boolean;
  logo: string;
  theme: {
    header: string;
  };
  colors: {
    header: string;
    primary: string;
  };
  business: {
    company: string;
    subdomain: string;
  };
};

export type AppUserLimit = {
  storage: {
    used: number;
    total: number;
  };
};

export enum AppUserGroup {
  Unregistered = 0,
  Trial = 1,
  Free = 2,
  Pro = 3,
  Business = 4,
}

export interface ColumnManagerInfo {
  [ColumnManagerInfoKeys.WorkspaceBrowser]?: boolean;
  [ColumnManagerInfoKeys.FoldersAndFiles]?: boolean;
  [ColumnManagerInfoKeys.InfoPanel]?: boolean;
  [ColumnManagerInfoKeys.ShareConfiguration]?: boolean;
  [ColumnManagerInfoKeys.AIChat]?: boolean;
}

export enum ColumnManagerInfoKeys {
  WorkspaceBrowser = 'WorkspaceBrowser',
  FoldersAndFiles = 'FoldersAndFiles',
  InfoPanel = 'InfoPanel',
  ShareConfiguration = 'ShareConfiguration',
  AIChat = 'AIChat',
}

export interface PageInfo {
  type: PageType;
  workspace: string;
  shareName: string;
  folderId: string;
  shareId: string;
  fileId: string;
  settings: string;
  pageName: string;
}

export enum PageType {
  Storage = 'storage',
  Settings = 'settings',
  ShareExternal = 'share-external',
  Public = 'public',
  Unknown = 'unknown',
}

export const DEFAULT_PAGE_INFO: PageInfo = {
  type: PageType.Public,
  workspace: '',
  shareName: '',
  folderId: '',
  shareId: '',
  fileId: '',
  settings: '',
  pageName: '',
};

export const ORG_ADMIN_PERMISSIONS = ['admin', 'owner'];

export const ORG_ARCHIVED_WORKSPACES_PERMISSIONS = ['admin', 'owner', 'member'];

export type ResendInvitationLimit = {
  times: number;
  sent: Date;
};

export type EndpointCallLimit = {
  times?: number;
  sent: Date;
};

export type EndpointsCallMap = {
  [key: string]: EndpointCallLimit;
};
