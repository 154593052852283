import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as app from 'store/slices/app';
import * as shared from 'store/slices/shared';

const LIMIT = 1;
const TIME = 1;

export const useShares = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const fetchAndUpdateShares = async (workspaces: WorkspaceListDetail[]) => {
    try {
      const key = 'shares-all';
      const canBeCalled = app.selectors.canEndpointBeCalled(
        key,
        LIMIT,
        TIME,
      )(store.getState());

      if (!canBeCalled) {
        return;
      }

      const {result, shares} = await api.share.getAllShares();
      if (result) {
        dispatch(shared.default.actions.setShares({shares, workspaces}));
        dispatch(
          app.default.actions.setEndpointCallsLimitEntry({
            key,
            times: LIMIT,
            sent: canBeCalled ? new Date() : undefined,
          }),
        );
      }
    } finally {
      setLoading(false);
      dispatch(shared.default.actions.setSharesLoaded({loaded: true}));
    }
  };

  const setSharesLoaded = () => {
    setLoading(false);
    dispatch(shared.default.actions.setSharesLoaded({loaded: true}));
  };

  return {fetchAndUpdateShares, loading, setSharesLoaded};
};
