import {t} from '@lingui/macro';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {Prompt} from 'interface/common/Prompt';
import {StyleSheet} from 'react-native';
import {useDeleteChat} from '../hooks/useDeleteChat';

interface Props {
  chatId: string;
  onClose: () => void;
}

export default function AiChatDeleteModal({chatId, onClose}: Props) {
  const {isLoading, deleteChat} = useDeleteChat();
  const onDelete = async () => {
    await deleteChat(chatId);
    onClose();
  };

  return (
    <Prompt
      onClose={onClose}
      rootStyle={styles.root}
      buttons={[
        {
          variant: 'secondary',
          text: t`Cancel`,
          onPress: onClose,
        },
        {
          variant: 'primary',
          text: t`Delete`,
          onPress: onDelete,
          isSubmit: true,
        },
      ]}>
      <Typography
        variant="medium"
        size="base"
        color={theme.colors.neutral.$700}
        overrides={styles.modalTitleText}>
        Delete this chat?
      </Typography>
      <Typography variant="regular" size="md" color={theme.colors.neutral.$700}>
        This action will permanently remove the entire conversation and all its
        contents. Once deleted, you cannot recover the chat.
      </Typography>
    </Prompt>
  );
}

const styles = StyleSheet.create({
  root: {
    maxWidth: 600,
    maxHeight: 600,
    gap: 24,
  },
  resetModalButton: {
    width: 200,
    height: 36,
    marginTop: 20,
    minWidth: 57,
    minHeight: 36,
  },
  modalTitleText: {
    marginBottom: 24,
  },
});
