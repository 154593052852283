import {createSelector} from '@reduxjs/toolkit';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import type {Store} from 'store';

const x = (store: Store) => store.organization;

export const getMembers = (orgId: string) =>
  createSelector(x, store => store.membersMap[orgId]);

export const getOrganization = (id: string) =>
  createSelector(x, (store): OrganizationDetails | undefined => {
    return store.items?.[id];
  });
