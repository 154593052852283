import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {AiChatSearchPopup} from './AiChatSearchPopup';

enum ButtonState {
  NoAttachments = 'NoAttachments',
  SelectedFiles = 'SelectedFiles',
  Disabled = 'Disabled',
}

export function AiChatFilterAttachButton() {
  const scopeFiles = useSelector(aichat.selectors.getScopeFiles());
  const attachFiles = useSelector(aichat.selectors.getAttachFiles());

  const getButtonState = (): ButtonState => {
    if (Object.values(scopeFiles).length !== 0) {
      return ButtonState.Disabled;
    }
    if (Object.values(attachFiles).length === 0) {
      return ButtonState.NoAttachments;
    }
    return ButtonState.SelectedFiles;
  };

  const buttonState = getButtonState();

  const renderButton = (onPress: () => void) => {
    if (buttonState === ButtonState.Disabled) {
      return <Disabled onPress={onPress} />;
    }
    if (buttonState === ButtonState.SelectedFiles) {
      return <SelectedFiles onPress={onPress} />;
    }
    return <NoAttachments onPress={onPress} />;
  };

  return (
    <AiChatSearchPopup
      trigger={onPress => renderButton(onPress)}
      type="attachment"
    />
  );
}

function NoAttachments({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, {backgroundColor: theme.colors.neutral.$100}]}>
      <Icon
        name="lucide:paperclip"
        size={18}
        color={theme.colors.neutral.$700}
      />
      <Typography size="xs" color={theme.colors.neutral.$700}>
        Attach Files
      </Typography>
    </Pressable>
  );
}

function SelectedFiles({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, {backgroundColor: theme.colors.neutral.$700}]}>
      <Icon name="lucide:paperclip" size={18} color={theme.colors.neutral.$0} />
      <Typography size="xs" color={theme.colors.neutral.$0}>
        Attached Files
      </Typography>
    </Pressable>
  );
}

function Disabled({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      disabled={true}
      style={[styles.button, {backgroundColor: theme.colors.neutral.$100}]}>
      <Icon
        name="lucide:paperclip"
        size={18}
        color={theme.colors.neutral.$300}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    paddingHorizontal: 8,
    height: 22,
    paddingVertical: 2,
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
