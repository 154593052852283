import theme from 'config/theme';
import {KeyboardListener} from 'interface/KeyboardListener';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {Personality} from 'store/slices/aichat/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatKeyboardLabel} from '../AiChatKeyboardLabel';
import {AiChatMembers} from '../AiChatMembers';
import PersonalityPopup from './PersonalityPopup';
import {AiChatFilterAttachButton} from './selectFiles/AiChatFilterAttachButton';
import {AiChatFilterAttachSection} from './selectFiles/AiChatFilterAttachSection';
import {AiChatFilterScopeButton} from './selectFiles/AiChatFilterScopeButton';
import {AiChatFilterScopeSection} from './selectFiles/AiChatFilterScopeSection';

interface Props {
  workspaceId: string;
  chatId: string;
  workspaceMembers: Member[];
  onSendMessage: (
    message: string,
    personality: Personality,
    webSearch: boolean,
    withRag: boolean,
    scopeFiles: FilesItem[],
    attachFiles: FilesItem[],
  ) => void;
  isEnabled: boolean;
  enableSettings: boolean;
}

export function AiChatInput({
  workspaceId,
  chatId,
  workspaceMembers,
  onSendMessage,
  isEnabled,
  enableSettings,
}: Props) {
  const [message, setMessage] = useState('');
  const toast = useToast();
  const [personality, setPersonality] = useState<Personality>('concise');
  const [withRag, setWithRag] = useState(false);

  const scopeFiles = useSelector(aichat.selectors.getScopeFiles());
  const attachFiles = useSelector(aichat.selectors.getAttachFiles());

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  const submitMessage = () => {
    if (message.length < 2) {
      const error =
        'This value is too short. It should have 2 characters or more.';
      toast.show(error);
      return;
    }
    if (message.trim() && isEnabled) {
      onSendMessage(
        message,
        personality,
        false,
        withRag,
        Object.values(scopeFiles),
        Object.values(attachFiles),
      );
      setMessage('');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputWrapper}>
        <View style={styles.inputContainer}>
          <View style={styles.inputRow}>
            <KeyboardListener
              onKeyPress={handleKeyPress}
              customStyle={{flex: 1}}>
              <TextInput
                placeholder="Type a message..."
                placeholderTextColor={theme.colors.neutral.$400}
                multiline={true}
                scrollEnabled={false}
                numberOfLines={4}
                onChangeText={setMessage}
                value={message}
                style={[
                  styles.input,
                  {
                    // @ts-ignore
                    outline: 'none',
                  },
                ]}
              />
            </KeyboardListener>
            <Button
              onPress={submitMessage}
              variant="primary"
              disabled={!isEnabled}
              startEnhancer={
                <Icon name="lucide:arrow-up" color={theme.colors.neutral.$0} />
              }
              overrides={{
                Button: {
                  style: {
                    backgroundColor: theme.colors.brand.$4,
                    width: 32,
                    height: 32,
                  },
                },
              }}
            />
          </View>
          <AiChatFilterScopeSection />
          <AiChatFilterAttachSection />
          <View style={styles.customizeSection}>
            <PersonalityPopup
              personality={personality}
              onChange={setPersonality}
            />
            <View style={styles.spacer} />
            <AiChatFilterAttachButton />
            <AiChatFilterScopeButton />
          </View>
        </View>
        <Typography
          size="xs"
          color={theme.colors.neutral.$400}
          overrides={styles.aiDisclaimer}>
          AI can be inaccurate, please double check its responses.
        </Typography>
      </View>
      <View style={styles.footer}>
        <AiChatMembers chatId={chatId} workspaceMembers={workspaceMembers} />
        <View style={styles.help}>
          <Typography size="xs" color={theme.colors.neutral.$400}>
            New line:
          </Typography>
          <AiChatKeyboardLabel label="Shift" icon="⇧" />
          <Typography size="xs" color={theme.colors.neutral.$400}>
            +
          </Typography>
          <AiChatKeyboardLabel label="Enter" icon="↵" />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
    borderTopColor: theme.colors.neutral.$75,
    borderTopWidth: 1,
    alignItems: 'center',
  },
  inputWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  inputContainer: {
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 8,
    flex: 1,
    overflow: 'visible',
    gap: 16,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$75,
    width: '100%',
  },
  aiDisclaimer: {
    marginTop: 4,
  },
  customizeSection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: theme.colors.neutral.$100,
    borderTopWidth: 1,
    paddingTop: 8,
    gap: 8,
    paddingHorizontal: 16,
  },
  spacer: {
    flex: 1,
  },
  selectFilesSection: {
    marginHorizontal: 16,
  },
  inputRow: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    paddingHorizontal: 16,
    gap: 12,
  },
  input: {
    width: '100%',
    padding: 0,
    borderWidth: 0.5,
    borderColor: 'transparent',
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    height: 'fit-content',
    paddingEnd: 16,
  },
  help: {
    width: 'auto',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
});
