import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import CreateNewChatPopup from './CreateNewChatPopup';

interface Props {
  onNewChatPress: (isPrivate: boolean) => void;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export function AiChatListHeader({
  onNewChatPress,
  isExpanded,
  setIsExpanded,
}: Props) {
  return (
    <View style={styles.container}>
      <Typography size="md" variant="medium" color={theme.colors.neutral.$700}>
        AI Chats
      </Typography>
      <View style={styles.rightContainer}>
        <CreateNewChatPopup onNewChatPress={onNewChatPress} />
        <AiChatListHeaderButton
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      </View>
    </View>
  );
}

function AiChatListHeaderButton({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) {
  const iconName = isExpanded
    ? 'lucide:chevrons-down-up'
    : 'lucide:chevrons-up-down';
  return (
    <Button variant="secondary" onPress={() => setIsExpanded(!isExpanded)}>
      <Icon name={iconName} size={18} color={theme.colors.neutral.$700} />
    </Button>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    borderBottomColor: theme.colors.neutral.$75,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});
