import theme from 'config/theme';
import {useEffect} from 'react';
import {Animated, StyleSheet, View} from 'react-native';

const time1 = 160;
const time2 = 320;
const time3 = 640;
const jumpHeight = 6;

export const TypingIndicator: React.FC = () => {
  // Create animated values for each dot
  const dot1 = new Animated.Value(0);
  const dot2 = new Animated.Value(0);
  const dot3 = new Animated.Value(0);

  useEffect(() => {
    const animateDots = () => {
      // Reset values
      dot1.setValue(0);
      dot2.setValue(0);
      dot3.setValue(0);

      // Create staggered sequence animation
      Animated.stagger(time1, [
        // Dot 1 animation
        Animated.sequence([
          Animated.timing(dot1, {
            toValue: 1,
            duration: time2,
            useNativeDriver: true,
          }),
          Animated.timing(dot1, {
            toValue: 0,
            duration: time2,
            useNativeDriver: true,
          }),
        ]),
        // Dot 2 animation
        Animated.sequence([
          Animated.timing(dot2, {
            toValue: 1,
            duration: time2,
            useNativeDriver: true,
          }),
          Animated.timing(dot2, {
            toValue: 0,
            duration: time2,
            useNativeDriver: true,
          }),
        ]),
        // Dot 3 animation
        Animated.sequence([
          Animated.timing(dot3, {
            toValue: 1,
            duration: time2,
            useNativeDriver: true,
          }),
          Animated.timing(dot3, {
            toValue: 0,
            duration: time2,
            useNativeDriver: true,
          }),
        ]),
      ]).start(() => {
        // Restart animation when complete
        setTimeout(() => {
          animateDots();
        }, time3);
      });
    };

    animateDots();

    // Cleanup animation when component unmounts
    return () => {
      dot1.setValue(0);
      dot2.setValue(0);
      dot3.setValue(0);
    };
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [
              {
                translateY: dot1.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -jumpHeight],
                }),
              },
            ],
          },
        ]}
      />
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [
              {
                translateY: dot2.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -jumpHeight],
                }),
              },
            ],
          },
        ]}
      />
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [
              {
                translateY: dot3.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -jumpHeight],
                }),
              },
            ],
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    marginLeft: 40,
    paddingVertical: 14,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 12,
    borderBottomLeftRadius: 4,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 4,
    backgroundColor: theme.colors.neutral.$7,
    marginHorizontal: 2,
  },
});
