import {useToast} from 'react-native-toast-notifications';
import {copyLink} from 'utils/common/interactions';

export function useCopyLink() {
  const toast = useToast();

  const copyLinkFunction = (link: string, toastMessage = 'Link copied') => {
    copyLink(link);
    toast.show(toastMessage, {type: 'success'});
  };

  return {copyLink: copyLinkFunction};
}
