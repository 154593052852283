import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useUpdateChat} from '../hooks/useUpdateChat';
import {PrivateChatButton} from './PrivateChatButton';

interface Props {
  workspaceId: string;
  chatId: string;
  title: string;
  isPrivate: boolean;
  onBackPress: () => void;
  onNewChatPress: () => void;
}

export function AiChatHeader({
  workspaceId,
  chatId,
  title,
  isPrivate,
  onBackPress,
  onNewChatPress,
}: Props) {
  const [name, setName] = useState(title);
  const {copyLink} = useCopyLink();
  const {isLoading: updateChatLoading, updateChat} = useUpdateChat(workspaceId);

  const changeChatName = async () => {
    await updateChat(chatId, name);
  };

  const handleCopyLinkPress = () => {
    const baseUrl = window.location.href.split('?')[0];
    copyLink(`${baseUrl}?chatId=${chatId}`, 'Link copied');
  };

  useEffect(() => {
    setName(title);
  }, [title]);

  return (
    <View style={styles.container}>
      <Button
        onPress={onBackPress}
        variant="secondary"
        startEnhancer={
          <Icon name="lucide:arrow-left" color={theme.colors.neutral.$700} />
        }
      />
      <CustomTextInput
        value={name}
        customRootStyle={styles.title}
        customInputStyle={styles.titleInput}
        setValue={setName}
        placeholder={'Chat name...'}
        ariaLabel={'Chat name'}
        disabled={updateChatLoading}
        onBlur={changeChatName}
      />
      {isPrivate && <PrivateChatButton chatId={chatId} />}
      <Button
        onPress={handleCopyLinkPress}
        variant="text"
        startEnhancer={
          <Icon name="lucide:link-2" color={theme.colors.neutral.$700} />
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    borderBottomColor: theme.colors.neutral.$75,
  },
  title: {
    flex: 1,
    marginHorizontal: 8,
  },
  titleInput: {
    backgroundColor: 'transparent',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$700,
    boxShadow: '0',
  },
});
