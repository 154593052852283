import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch} from 'react-redux';
import {resetAppStore} from 'store';
import * as app from 'store/slices/app';

export default function AppNewVersionNotice() {
  const dispatch = useDispatch();

  const handleReload = async () => {
    dispatch(app.default.actions.load({loaded: false}));
    await resetAppStore(false);
    window.location.reload();
  };

  return (
    <View style={styles.root}>
      <Text style={styles.text}>
        A new version of Fast.io is available. Refresh page to update.
      </Text>
      <Button
        type="Primary"
        onPress={handleReload}
        label={'Refresh page'}
        lIcon={
          <MCIcon name={'sync'} size={20} color={theme.colors.neutral.$white} />
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$25,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
    paddingHorizontal: 16,
    paddingVertical: 16,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    alignSelf: 'stretch',
  },
  text: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
  },
});
