import theme from 'config/theme';
import {StyleSheet, View, useWindowDimensions} from 'react-native';

export interface SearchOverlayProps {
  onPress: () => void;
}

export function SearchOverlay(
  props: React.PropsWithChildren<SearchOverlayProps>,
) {
  const {width, height} = useWindowDimensions();
  return (
    <View style={styles.root} onPointerUp={props.onPress}>
      <View
        style={[
          styles.content,
          width < 715 && styles.contentWidthFluid,
          height < 700 && styles.contentHeightFluid,
        ]}
        onPointerUp={e => e.stopPropagation()}>
        {props.children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    alignItems: 'center',
  },
  content: {
    top: 140,
    width: 650,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: theme.colors.neutral.$0,
  },
  contentHeightFluid: {
    top: 20,
    overflow: 'scroll',
  },
  contentWidthFluid: {
    width: '90%',
  },
});
