import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {AiChatSearchPopup} from './AiChatSearchPopup';

enum ButtonState {
  EntireWorkspace = 'EntireWorkspace',
  NoRag = 'NoRag',
  SelectedFiles = 'SelectedFiles',
  Disabled = 'Disabled',
}

export function AiChatFilterScopeButton() {
  const scopeFiles = useSelector(aichat.selectors.getScopeFiles());
  const attachFiles = useSelector(aichat.selectors.getAttachFiles());

  const getButtonState = (): ButtonState => {
    if (Object.values(attachFiles).length !== 0) {
      return ButtonState.Disabled;
    }
    if (Object.values(scopeFiles).length === 0) {
      return ButtonState.EntireWorkspace;
    }
    return ButtonState.SelectedFiles;
  };

  const buttonState = getButtonState();

  const renderButton = (onPress: () => void) => {
    if (buttonState === ButtonState.Disabled) {
      return <Disabled onPress={onPress} />;
    }
    if (buttonState === ButtonState.NoRag) {
      return <NoRag onPress={onPress} />;
    }
    if (buttonState === ButtonState.SelectedFiles) {
      return <SelectedFiles onPress={onPress} />;
    }
    return <EntireWorkspace onPress={onPress} />;
  };

  return (
    <AiChatSearchPopup
      trigger={onPress => renderButton(onPress)}
      type="scope"
    />
  );
}

function EntireWorkspace({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, {backgroundColor: theme.colors.brand.$1_75}]}>
      <Icon name="lucide:filter" size={18} color={theme.colors.brand.$1_400} />
      <Typography size="xs" color={theme.colors.brand.$1_400}>
        Entire Workspace
      </Typography>
    </Pressable>
  );
}

function NoRag({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      disabled={true}
      style={[styles.button, {backgroundColor: theme.colors.neutral.$100}]}>
      <Icon name="lucide:filter" size={18} color={theme.colors.neutral.$400} />
      <Typography size="xs" color={theme.colors.neutral.$300}>
        Search Disabled
      </Typography>
    </Pressable>
  );
}

function SelectedFiles({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, {backgroundColor: theme.colors.brand.$1_400}]}>
      <Icon name="lucide:filter" size={18} color={theme.colors.neutral.$0} />
      <Typography size="xs" color={theme.colors.neutral.$0}>
        Selected Files
      </Typography>
    </Pressable>
  );
}

function Disabled({onPress}: {onPress: () => void}) {
  return (
    <Pressable
      onPress={onPress}
      disabled={true}
      style={[styles.button, {backgroundColor: theme.colors.neutral.$100}]}>
      <Icon name="lucide:filter" size={18} color={theme.colors.neutral.$300} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    paddingHorizontal: 8,
    height: 22,
    paddingVertical: 2,
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
