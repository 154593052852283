import {StyleSheet, View} from 'react-native';
import GradientProgressBar from './GradientProgressBar';
import LoadingImage from './LoadingImage';

const DEFAULT_DURATION = 3000;

interface LoadingProgressProps {
  duration?: number;
}

export default function LoadingProgress({
  duration = DEFAULT_DURATION,
}: LoadingProgressProps) {
  return (
    <View style={styles.root}>
      <LoadingImage />
      <GradientProgressBar duration={duration} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    gap: 20,
    width: 440,
  },
  text: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22,
    paddingVertical: 10,
    width: 240,
  },
});
