import {Loading} from 'interface/common/Loading';
import {StyleSheet, View} from 'react-native';
import Footer from './Footer';
import LoadingProgress from './LoadingProgress';
import LoadingTimeoutPrompt from './LoadingTimeoutPrompt';
import useAppLoaderKind from './hooks/useAppLoaderKind';
import useLoadingTimeout from './hooks/useLoadingTimeout';

const PROGRESS_DURATION = 3000;
const LOADING_TOO_LONG_TIMEOUT = 6000;

export function AppLoading({showFooter = true}: {showFooter?: boolean}) {
  const kind = useAppLoaderKind();
  const isLoadingTooLong = useLoadingTimeout(LOADING_TOO_LONG_TIMEOUT);

  return (
    <View style={styles.root}>
      {isLoadingTooLong ? (
        <LoadingTimeoutPrompt />
      ) : kind === 'progress' ? (
        <LoadingProgress duration={PROGRESS_DURATION} />
      ) : kind === 'spinner' ? (
        <Loading />
      ) : null}
      <View style={styles.footerContainer}>
        <Footer />
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
});
