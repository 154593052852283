import {createSelector} from '@reduxjs/toolkit';
import memoize from 'lodash.memoize';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {WorkspaceInfoPanelTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/types/WorkspaceInfoPanelTab';
import type {Store} from 'store';
import type {Share} from 'store/slices/shared/types';
import type {FilesItem, FilesView} from './types';

const x = (store: Store) => store;

export const isSearch = createSelector(x, store => !!store.files.search);
export const getFollow = createSelector(x, store => store.files.following);
export const getSelection = createSelector(x, store => store.files.selection);
export const getFocusedParent = createSelector(
  x,
  store => store.files.focusedParentId,
);
export const getFocused = createSelector(x, store => store.files.focusedId);
export const getFocusedType = createSelector(
  x,
  store => store.files.focusedType ?? WorkspaceInfoPanelTab.FileInfo,
);
export const getFileSummaryType = createSelector(
  x,
  store => store.files.fileSummaryType,
);
export const getPreview = createSelector(x, store => store.files.preview);
export const getPreviewProgress = createSelector(
  x,
  store => store.files.previewProgress,
);
export const getSearch = createSelector(x, store => store.files.search);
export const getFilter = createSelector(x, store => store.files.filter);
export const getLayout = createSelector(x, store => store.files.layout);
export const getDrag = createSelector(x, store => store.files.drag);
export const getPick = createSelector(x, store => store.files.pick);
export const getSort = createSelector(x, store => store.files.sort);
export const getQuery = createSelector(x, store => store.files.query);
export const getItems = createSelector(x, store => store.files.items);
export const getItemsList = createSelector(x, store =>
  Object.values(store.files.items),
);

export const getItem = (path: string) =>
  createSelector(x, store => store.files.items[path]);

export const getParent = (path: string) =>
  createSelector(x, store => {
    const item = path && store.files.items[path];
    const parentKey = item?.parent || 'root';
    return parentKey;
  });

export const shouldSkipClearFocus = () =>
  createSelector(x, store => store.files.skipClearFocus ?? false);

export const getFolder = (
  instanceNs: StorageNamespace,
  instanceId: string,
  path: string,
) =>
  createSelector(
    (store: Store) => store.files[instanceNs]?.[instanceId]?.[path],
    (store: Store) => store.shared.shares,
    (store: Store) => store.files.items,
    (
      folder: FilesView,
      shares: {[id: string]: Share},
      items: {[id: string]: FilesItem},
    ) => {
      if (!folder) return folder;
      return filterAvailableFiles(folder, shares, items);
    },
  );

export const getFolderAsList = (
  instanceNs: StorageNamespace,
  instanceId: string,
  path: string,
) =>
  createSelector(
    (store: Store) => store.files[instanceNs]?.[instanceId]?.[path],
    (store: Store) => store.shared.shares,
    (store: Store) => store.files.items,
    (
      folder: FilesView,
      shares: {[id: string]: Share},
      items: {[id: string]: FilesItem},
    ) => {
      if (!folder) return [];
      return filterAvailableFiles(folder, shares, items).map(fileId => ({
        id: fileId,
      }));
    },
  );

export const isLoadingFolder = (
  instanceNs: StorageNamespace,
  instanceId: string,
  path: string,
) =>
  createSelector(
    x,
    (store: Store) => store.files[instanceNs]?.[instanceId]?.[path],
    (store: Store, folder: FilesView) => {
      // If folder already exists and has files, it's not loading
      if (folder?.length > 0) return false;
      // Check if we're fetching contents for this folder
      const loading = store.files.loading;
      if (!loading) return false;
      return loading.instanceId === instanceId && loading.folderId === path;
    },
  );

export const selectThumbnail = (thumbnailKey: string) =>
  createSelector(x, store => store.files.thumbnails[thumbnailKey]);

// Helpers

const filterAvailableFiles = memoize(
  (
    folder: FilesView,
    shares: {[id: string]: Share},
    items: {[id: string]: FilesItem},
  ) => {
    return folder.filter(fileId => {
      const shareId = items[fileId]?.target_id;
      const share = shares[shareId];
      return !share || !share.archived;
    });
  },
);
