import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataProvider, LayoutProvider} from 'recyclerlistview/web';

import events from 'extensions/events';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'extensions/navigation';
import {usePopup} from 'extensions/viewport/usePopup';
import useDebounce from 'interface/common/hooks/useDebounce';
import {isTouch} from 'utils/common/platform';

import {FileListItemLayout} from '../FileListItem';
import {FILE_CELL_HEIGHT} from '../FileListItemCell';
import {useWorkspaceContext} from '../hooks/useWorkspaceContext';
import {MEDIUM_THUMBNAIL_SIZE} from '../thumbnail/FileThumbnail';
import {sortItems} from '../utils/sort';

import {slices} from 'store';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import {selectors} from 'store/slices/files';
import * as shared from 'store/slices/shared';

import type {ShareFilesNavigation} from 'interface/stacks/share/hooks/useShareFilesNavigation';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {MouseEvent} from 'react';
import type {
  GestureResponderEvent,
  LayoutChangeEvent,
  PointerEvent,
} from 'react-native';
import type {ScrollEvent} from 'recyclerlistview/dist/reactnative/core/scrollcomponent/BaseScrollView';
import type {RecyclerListView} from 'recyclerlistview/web';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FileListProps} from '../FileList';

const GUTTER_SIZE = 16;
const CELL_PADDING = 16;
const RESIZE_DEBOUNCE_TIMEOUT_MS = 300;

export function useFileList(props: FileListProps) {
  const {view, items, options, multiplayer} = props;
  const {members, following} = multiplayer ?? {};
  const {folderKey, workspaceId} = useParams<'folderKey' | 'workspaceId'>();
  const contextWorkspace = useWorkspaceContext();
  const contextShare =
    useOutletContext<[Share, UploaderState, ShareFilesNavigation]>();

  const location = useLocation();
  const refResize = useRef(undefined);
  const refList = useRef<RecyclerListView<any, any>>();
  const popup = usePopup();
  const layout = useSelector(selectors.getLayout);
  const dragging = useSelector(selectors.getDrag);
  const summaryType = useSelector(selectors.getFileSummaryType);
  const _selection = useSelector(selectors.getSelection);
  const selection = options?.externalSelection ?? _selection;
  const isPremium = useSelector(app.selectors.isPremium);
  const isLoading = useSelector(
    selectors.isLoadingFolder(
      contextWorkspace?.workspace ? 'workspace' : 'share',
      contextWorkspace?.workspace?.folder_name ?? contextShare?.[0]?.id,
      contextWorkspace?.workspace ? folderKey : contextShare?.[2]?.folderKey,
    ),
  );

  const sort = useSelector(
    props.shareView
      ? shared.selectors.getShareSort(props.share?.id)
      : props.shareds
        ? shared.selectors.getSort
        : selectors.getSort,
  );

  const [focusedI, setFocusedI] = useState(-1);
  const [focused, setFocused] = useState('');
  const [offsetX, setOffsetX] = useState(0);
  const [listWidth, setListWidth] = useState(0);
  const [listHeight, setListHeight] = useState(0);

  const viewtype = props.layout || layout;
  const targetFolderId = folderKey ?? contextShare?.[2]?.folderKey;
  const workspaceTarget = options?.overrideWorkspaceId || workspaceId;

  const hasGridView = viewtype >= FileListItemLayout.GridSimple;
  const hasGutter = !options?.hideGutter;
  const hasOpen = !options?.disableOpen;
  const hasDrag = !options?.disableDrag;
  const hasFocus = !options?.disableFocus;
  const hasSelect = !options?.disableSelect;
  const hasExternalSelection = !!options?.externalSelection;

  const itemList = useMemo(
    () =>
      view
        // Filter null ids
        .filter(e => !!e)
        // Filtering types
        .filter(e => {
          const item = items[e];
          if (options?.hideFolders && item.type === StorageItemType.Folder)
            return false;
          if (options?.hideFiles && item.type === StorageItemType.File)
            return false;
          if (options?.hideShares && item.type === StorageItemType.Link)
            return false;
          return !!item;
        })
        // Sorting
        .sort((aID, bID) => sortItems(aID, bID, items, sort)),
    [view, sort, items],
  );

  const highlight = useMemo(
    () => ({
      id: location?.state?.highlightId,
      index: itemList.findIndex(item => item === location?.state?.highlightId),
      parent: location?.state?.highlightParent,
    }),
    [itemList, location?.state?.highlightId, location?.state?.highlightParent],
  );

  const extended = useMemo(
    () => ({
      items,
      members,
      highlight,
      focused,
      selection,
      dragging,
      summaryType,
    }),
    [items, members, highlight, focused, selection, dragging, summaryType],
  );

  const provider = useRef(
    new DataProvider(
      (a, b) => a !== b,
      i => itemList[i] || i.toString(),
    ),
  );

  const listData = useMemo(
    () => provider.current.cloneWithRows(itemList),
    [itemList],
  );

  const listGutter = useMemo(() => (hasGutter ? GUTTER_SIZE : 0), []);

  const listLayout = useMemo(
    () =>
      new LayoutProvider(
        () => viewtype,
        (type, rect, _index) => {
          const widthFull = listWidth - listGutter * 2;
          switch (type) {
            case FileListItemLayout.ListNormal:
              rect.width = widthFull;
              rect.height = isTouch() ? 60 : 43;
              return;
            case FileListItemLayout.GridSimple: {
              const cellSize = MEDIUM_THUMBNAIL_SIZE + CELL_PADDING;
              const columns = Math.floor(widthFull / cellSize);
              rect.width = widthFull / columns;
              rect.height = FILE_CELL_HEIGHT + CELL_PADDING;
              return;
            }
          }
        },
      ),
    [view, viewtype, listWidth],
  );

  const isEmpty = listData.getSize() === 0;

  // Actions

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showInfoPanel = useCallback(
    (open: boolean) => {
      dispatch(
        app.default.actions.setColumnInfoOverride({
          [ColumnManagerInfoKeys.InfoPanel]: open,
        }),
      );
    },
    [dispatch],
  );

  const open = useCallback(
    async (item: FilesItem) => {
      if (props.onOpen) {
        props.onOpen(item);
        return;
      }

      const {id, type} = item;
      const baseUrl = `/workspace/${workspaceTarget}`;
      if (type === StorageItemType.Folder) {
        navigate(`${baseUrl}/storage/${id}`);
      } else if (type === StorageItemType.File) {
        navigate(`${baseUrl}/preview/${id}`);
      }
    },
    [workspaceTarget],
  );

  const focus = useCallback(
    (
      item?: FilesItem,
      openDetails?: boolean,
      event?: GestureResponderEvent,
    ) => {
      if (!event?.shiftKey) {
        setFocused(item ? item.id : '');
      }
      if (openDetails) {
        dispatch(slices.files.actions.focus({id: item ? item.id : ''}));
      }
    },
    [],
  );

  const select = useCallback(
    (item: FilesItem, e?: GestureResponderEvent) => {
      const [isShift, isCtrl] = [e?.shiftKey, e?.metaKey || e?.ctrlKey];
      const isSelected = selection.includes(item.id);

      // We are de-selecting the item
      if (isSelected && isCtrl) {
        // None left, close info panel
        if (selection.length <= 1) {
          showInfoPanel(false);
        }
      } else {
        // We are selecting item, open info panel
        showInfoPanel(true);
      }

      if (isShift) {
        // Select range of items
        dispatch(
          slices.files.actions.select({ids: [item.id], focused, itemList}),
        );
        // Focus last item
        dispatch(slices.files.actions.focus({id: item.id}));
      } else {
        // Focus on item
        focus(item, true);
        // Select item
        dispatch(
          slices.files.actions.select({
            ids: [item.id],
            noToggle: !isCtrl,
          }),
        );
      }
    },
    [focused, selection, itemList],
  );

  const resize = useDebounce(
    (e: LayoutChangeEvent) => {
      setListWidth(e.nativeEvent.layout.width);
      setListHeight(e.nativeEvent.layout.height);
      setOffsetX(e.nativeEvent.layout.x);
      refResize.current = true;
    },
    refResize.current ? RESIZE_DEBOUNCE_TIMEOUT_MS : 0,
  );

  const syncMouse = useCallback(
    (e: PointerEvent) => {
      const scroll = refList?.current?.getCurrentScrollOffset();
      const cursorX = e.nativeEvent.clientX - offsetX;
      const cursorY = e.nativeEvent.clientY + scroll;
      const isOffscreen = listWidth - cursorX < 100;
      multiplayer?.setState(
        isOffscreen
          ? {cursorOffscreen: true}
          : {cursorOffscreen: false, cursorX, cursorY, lastUpdate: Date.now()},
      );
    },
    [multiplayer?.setState, offsetX, listWidth],
  );

  const syncMouseIdle = useCallback(() => {
    multiplayer?.setState({cursorOffscreen: true});
  }, [multiplayer?.setState]);

  const syncScrollPos = useCallback(
    (e: ScrollEvent) => {
      const {x, y} = e.nativeEvent?.contentOffset;
      multiplayer?.setState({scrollX: x, scrollY: y});
    },
    [multiplayer?.setState],
  );

  const noop = useCallback(() => {}, []);

  const noopCancel = useCallback((e: DragEvent | MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  // Focus on highlight
  useEffect(() => {
    if (highlight.id && itemList.includes(highlight.id)) {
      dispatch(slices.files.actions.focus({id: highlight.id}));
    }
  }, [highlight.id, itemList, dispatch]);

  // Reset highlight when selection changes
  useLayoutEffect(() => {
    if (selection.length > 0 && highlight.id) {
      navigate(location.pathname, {state: {highlightId: undefined}});
    }
  }, [selection, highlight.id]);

  // Scroll to following cursor
  useLayoutEffect(() => {
    if (following) {
      const {scrollX, scrollY} = following;
      refList?.current?.scrollToOffset(scrollX, scrollY, false);
    }
  }, [members, following]);

  // Scroll list to top when folder changes
  useLayoutEffect(() => {
    setTimeout(() => {
      refList?.current?.scrollToTop(false);
    }, 0);
  }, [targetFolderId]);

  // Scroll list to highlighted item
  useLayoutEffect(() => {
    if (highlight.index !== -1) {
      refList?.current?.scrollToIndex(highlight.index, true);
    }
  }, [highlight.index]);

  // Handle hotkeys
  useLayoutEffect(() => {
    const keyDown = events.addKeyListener('down', e => {
      switch (e.code) {
        // Navigate up list
        case 'ArrowUp':
          if (!popup.visible) {
            setFocusedI(focusedI - 1);
            // filelist.current.scrollToIndex(focusedI - 1);
          }
          break;
        // Navigate down list
        case 'ArrowDown':
          if (!popup.visible) {
            setFocusedI(focusedI + 1);
            // filelist.current.scrollToIndex(focusedI + 1);
          }
          break;
        // Navigate to top of list
        case 'ArrowLeft':
          if (!popup.visible) {
            refList?.current?.scrollToTop();
          }
          break;
        // Navigate to bottom of list
        case 'ArrowRight':
          if (!popup.visible) {
            refList?.current?.scrollToEnd();
          }
          break;
        // Select all
        case 'KeyA':
          if (e.flags.ctrlKey || e.flags.metaKey) {
            dispatch(
              slices.files.actions.select({ids: itemList, noToggle: true}),
            );
          }
          break;
        // Clear selection
        case 'Escape':
          dispatch(slices.files.actions.select({ids: []}));
          break;
      }
      return true;
    });

    return () => {
      keyDown.unsubscribe();
    };
  }, [itemList, popup, focusedI]);

  return {
    items,
    itemList,
    listData,
    listWidth,
    listHeight,
    listLayout,
    listGutter,
    extended,
    refResize,
    refList,
    workspaceTarget,
    targetFolderId,
    isEmpty,
    isLoading,
    isPremium,
    hasGutter,
    hasOpen,
    hasSelect,
    hasExternalSelection,
    hasFocus,
    hasDrag,
    hasGridView,
    open,
    focus,
    select,
    resize,
    syncMouse,
    syncMouseIdle,
    syncScrollPos,
    noopCancel,
    noop,
  };
}
