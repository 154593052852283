import theme from 'config/theme';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import ControlBar, {HEIGHT_CONTROL_BAR} from '../ControlBar';
import PDFPagination from './PDFPagination';
import ControlsLeft from './controls/ControlsLeft';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import Pagination from '../../components/Pagination';
import useFileUrl from '../../hooks/useFileUrl';
import useFullscreen from '../../hooks/useFullscreen';
import useZoom from '../../hooks/useZoom';
import type {ViewerProps as Props} from '../../types';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';
import PDFContent from './PDFContent';

const PDFViewer = ({file}: Props) => {
  const {loading, url} = useFileUrl(file);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const {isFullscreen, ref, toggleFullscreen} = useFullscreen();
  const {handleZoomIn, handleZoomOut, handleLastZoom, zoom} = useZoom();

  function onDocumentLoadSuccess({numPages}: {numPages: number}) {
    setTotalPages(numPages);
    if (numPages > 1) {
      setShowPagination(true);
    }
  }

  const togglePagination = () => setShowPagination(prev => !prev);

  return (
    <View ref={ref} style={styles.root}>
      <PDFPagination
        file={url}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        handleChangePage={setPageNumber}
        pageNumber={pageNumber}
        totalPages={totalPages}
        showPagination={showPagination}
      />
      <View style={styles.content}>
        <View style={[styles.pdfContainer, isFullscreen && styles.fullscreen]}>
          {loading ? (
            <FileThumbnail
              item={file}
              size={FileThumbnailSizes.Preview}
              options={{previewResizeMode: 'center'}}
            />
          ) : (
            <PDFContent
              fileSrc={url}
              file={file}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              currentPage={pageNumber}
              onPageChange={setPageNumber}
              totalPages={totalPages}
              zoom={zoom}
            />
          )}
        </View>
        <ControlBar
          left={
            <ControlsLeft
              page={pageNumber}
              totalPages={totalPages}
              setPageNumber={setPageNumber}
              togglePagination={togglePagination}
              isPaginationOpen={showPagination}
            />
          }
          middle={
            <ControlsMiddle
              zoom={zoom}
              handleZoomIn={handleZoomIn}
              handleZoomOut={handleZoomOut}
              handleLastZoom={handleLastZoom}
            />
          }
          right={
            <ControlsRight
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
            />
          }
        />
        <Pagination />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#000',
  },
  content: {
    height: '100%',
    flexGrow: 1,
    flexShrink: 1,
  },
  pdfContainer: {
    position: 'relative',
    // Total viewport height - header height - control bar height
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_CONTROL_BAR}px)`,
    flexGrow: 1,
    backgroundColor: '#000',
  },
  pagesContainer: {
    flex: 1,
    maxWidth: 180,
    padding: 15,
    paddingTop: 0,
    backgroundColor: theme.colors.neutral.$1,
  },
  fullscreen: {
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px)`,
  },
});

export default PDFViewer;
