import {useNavigate} from 'extensions/navigation';
import {
  getEnvironmentDomain,
  getEnvironmentProtocol,
} from 'utils/common/version';
import {useSubDomain} from '../useSubDomain';

export function useNavigateToGo() {
  const navigate = useNavigate();
  const {subdomain} = useSubDomain();

  const navigateToGo = async (path: string) => {
    const host = await getEnvironmentDomain();
    const protocol = await getEnvironmentProtocol();
    if (subdomain === 'go') {
      navigate(path);
    } else {
      const finalPath = `${protocol}://${host}/${path}`.replace(
        subdomain,
        'go',
      );
      window.location.href = finalPath;
    }
  };

  return {navigateToGo};
}
