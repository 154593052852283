import {useNavigate} from 'extensions/navigation';
import {
  getEnvironmentDomain,
  getEnvironmentProtocol,
} from 'utils/common/version';
import {useSubDomain} from '../useSubDomain';

export function useNavigateToSubdomain() {
  const navigate = useNavigate();
  const {subdomain} = useSubDomain();

  const navigateToSubdomain = async (
    domainToRedirect: string,
    path: string,
  ) => {
    const host = await getEnvironmentDomain();
    const protocol = await getEnvironmentProtocol();
    if (domainToRedirect === subdomain) {
      navigate(path);
    } else {
      const finalPath =
        `${protocol}://${host}${path.startsWith('/') ? '' : '/'}${path}`.replace(
          subdomain,
          domainToRedirect,
        );
      window.location.href = finalPath;
    }
  };

  return {navigateToSubdomain};
}
