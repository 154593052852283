import analytics from 'extensions/analytics';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {Linking, Platform} from 'react-native';
import config from 'react-native-ultimate-config';

// This function is for getting the origin of the current URL in react native
// alternative for the `window.location.origin` in Reactjs.
export const getOrigin = async () => {
  try {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl) {
      const urlParts = initialUrl.split('/');
      if (urlParts.length >= 3) {
        return urlParts.slice(0, 3).join('/');
      }
    }
  } catch (error) {
    analytics.notify(error);
  }
  return null;
};

export function getHost(noSSL?: boolean) {
  const protocol = noSSL ? 'http' : 'https';
  return `${protocol}://www.${config.APP_ORIGIN}`;
}

export function isTouch() {
  switch (Platform.OS) {
    case 'android':
      return true;
    case 'ios':
      return true;
    case 'macos':
      return false;
    case 'windows':
      return false;
    case 'web':
      return (
        'ontouchstart' in window || (navigator && navigator.maxTouchPoints > 0)
      );
    default:
      return false;
  }
}

export function isWeb() {
  return Platform.OS === 'web';
}

export function isNative() {
  return isAndroid() || isIOS() || isMacOS() || isWindows();
}

export function isAndroid() {
  return Platform.OS === 'android';
}

export function isIOS() {
  return Platform.OS === 'ios';
}

export function isMacOS() {
  return Platform.OS === 'macos';
}

export function isWindows() {
  return Platform.OS === 'windows';
}

export function isSafari() {
  return (
    isWeb() &&
    navigator.userAgent.indexOf('Safari/') !== -1 &&
    navigator.userAgent.indexOf('Chrome/') === -1
  );
}

export function isIE11() {
  return (
    isWeb() && 'MSInputMethodContext' in window && 'documentMode' in document
  );
}

export const getCurrentPath = () => {
  const pathArray = window.location.pathname.split('/');
  if (pathArray.length) {
    return pathArray[0] ? pathArray[0] : pathArray[1];
  }
};

export const getCurrentSubdomain = () => {
  const subdomain = /:\/\/([^\/]+)/.exec(
    window.location.origin.split('.')[0],
  )[1];
  if (
    subdomain !== 'fast' &&
    subdomain !== 'fast.io' &&
    subdomain !== 'fastlocal' &&
    subdomain !== 'fastlocal.com'
  ) {
    return subdomain;
  }
};

export const isSubdomainValid = (subdomain: string) => {
  return subdomain?.length >= 3;
};

export const hasActiveSubscription = (org: OrganizationDetails) => {
  return org && !org.subscription?.intent_client_secret;
};
