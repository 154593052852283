import theme from 'config/theme';
import type {PropsWithChildren} from 'react';
import {
  type StyleProp,
  Text,
  type TextProps,
  type TextStyle,
} from 'react-native';

export const SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  base: 'base',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
  '3xl': '3xl',
  '4xl': '4xl',
  '5xl': '5xl',
  '6xl': '6xl',
} as const;

export const VARIANT = {
  bold: 'bold',
  'semi-bold': 'semi-bold',
  medium: 'medium',
  regular: 'regular',
  display: 'display',
} as const;

type Variant = keyof typeof VARIANT;
type Size = keyof typeof SIZE;

// TODO: Replace stylesheet props with unistyles text styles when ready.

const VARIANTS: Record<Variant, TextStyle> = {
  bold: {
    fontWeight: '700',
  },
  'semi-bold': {
    fontWeight: '600',
  },
  medium: {
    fontWeight: '500',
  },
  regular: {
    fontWeight: '400',
  },
  display: {
    fontWeight: '700',
    fontSize: 72,
    lineHeight: 80,
  },
};

const SIZES: Record<Size, TextStyle> = {
  xs: {
    fontSize: 12,
    lineHeight: 18,
  },
  sm: {
    fontSize: 13,
    lineHeight: 20,
  },
  md: {
    fontSize: 14,
    lineHeight: 22,
  },
  base: {
    fontSize: 16,
    lineHeight: 24,
  },
  lg: {
    fontSize: 18,
    lineHeight: 28,
  },
  xl: {
    fontSize: 20,
    lineHeight: 30,
  },
  '2xl': {
    fontSize: 24,
    lineHeight: 34,
  },
  '3xl': {
    fontSize: 36,
    lineHeight: 46,
  },
  '4xl': {
    fontSize: 48,
    lineHeight: 58,
  },
  '5xl': {
    fontSize: 56,
    lineHeight: 64,
  },
  '6xl': {
    fontSize: 64,
    lineHeight: 70,
  },
};

type Props = {
  /** Style variant of the text. Options are:
   * - 'bold' (700)
   * - 'semi-bold' (600)
   * - 'medium' (500)
   * - 'regular' (400)
   * - 'display' (700, size 72px)
   * @default 'regular'
   */
  variant?: keyof typeof VARIANT;

  /** Font size preset. Available sizes:
   * - 'xs': 12px/18px
   * - 'sm': 13px/20px
   * - 'md': 14px/22px
   * - 'base': 16px/24px
   * - 'lg': 18px/28px
   * - 'xl': 20px/30px
   * - '2xl': 24px/34px
   * - '3xl': 36px/46px
   * - '4xl': 48px/58px
   * - '5xl': 56px/64px
   * - '6xl': 64px/70px
   * @default 'base'
   */
  size?: keyof typeof SIZE;

  /** Text color
   * @default theme.colors.neutral.$7
   */
  color?: string;

  /** Additional style overrides
   * @type React.StyleProp<TextStyle>
   */
  overrides?: StyleProp<TextStyle>;
};

export default function Typography({
  children,
  variant = 'regular',
  size = 'base',
  color = theme.colors.neutral.$7,
  overrides = {},
  ...rest
}: PropsWithChildren<Props & TextProps>) {
  const variantStyles = VARIANTS[variant];
  const sizeStyles = SIZES[size];

  const styles: TextStyle = {
    ...sizeStyles,
    ...variantStyles,
    color,
  };

  return (
    <Text selectable {...rest} style={[styles, overrides]}>
      {children}
    </Text>
  );
}
