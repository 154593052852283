import {SESSION_COOKIE_KEY} from 'constants/sessionRoutes';
import {getAgeFromToken} from 'fast-sdk/src/utils';
import {getHostData} from 'interface/common/hooks/useHost';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {setCookie} from 'utils/common/cookies';
import {GO_SUBDOMAIN} from 'utils/fast/constants';
import type {ISession} from '../types';

export default function useRefreshCookieSession() {
  const {subdomain} = useSubDomain();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const orgs = useSelector(user.selectors.getOrganizationsList);
  const workspaces = useSelector(workspace.selectors.getAllJoinedWorkspaces);
  const token = useSelector(user.selectors.getToken);

  const buildSession = () => {
    return {
      orgs:
        orgs.map(org => ({
          id: org.id,
          domain: org.domain,
        })) ?? [],
      workspaces:
        workspaces.map(workspace => ({
          id: workspace.id,
          folderName: workspace.folder_name,
        })) ?? [],
      userId: userDetails.id,
      token,
    };
  };

  const reloadSession = async () => {
    if (subdomain === GO_SUBDOMAIN) {
      const {protocol, mainDomain} = await getHostData();
      if (token && userDetails) {
        setCookie({
          name: SESSION_COOKIE_KEY,
          value: JSON.stringify(buildSession() as ISession),
          domain: mainDomain.replace(/:\d+$/, ''),
          protocol,
          age: getAgeFromToken(token),
        });
      }
    }
  };

  useEffect(() => {
    reloadSession();
  }, [orgs, workspaces]);
}
