import {t} from '@lingui/macro';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {TextInput} from 'interface/common/TextInput';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

interface WorkspaceSettingsDetailsProps {
  workspace: WorkspaceListDetail;
  close: () => void;
}

export function WorkspaceSettingsDetails({
  workspace,
  close,
}: WorkspaceSettingsDetailsProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(workspace.name);

  const updateWorkspace = async () => {
    try {
      setLoading(true);
      const {result} = await api.workspace.updateWorkspace(
        workspace.folder_name,
        {name},
      );
      if (result) {
        toast.show(t`Workspace details updated`, {type: 'info'});
        dispatch(
          workspaceSlice.default.actions.updateWorkspace({
            id: workspace.id,
            workspace: {...workspace, name},
          }),
        );
        close();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.root}>
      <TextInput
        label={t`Workspace name`}
        value={name}
        customStyle={{marginBottom: 10}}
        customLabelStyle={{marginBottom: 9}}
        placeHolder={workspace.name}
        setInputValue={setName}
      />
      <View style={styles.buttonsContainer}>
        <Button variant="secondary" onPress={close}>
          {t`Cancel`}
        </Button>
        <Button
          overrides={{
            Loading: {
              style: {
                color: theme.colors.neutral.$white,
              },
            },
          }}
          variant="primary"
          disabled={!name || name === workspace.name}
          onPress={updateWorkspace}
          loading={loading}>
          {t`Done`}
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginBottom: 24,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
});
