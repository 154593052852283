import {ROUTES} from 'constants/routes';
import {
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from 'extensions/navigation';
import LoadingProgress from 'interface/stacks/app/LoadingProgress';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ShareViewStatus} from 'store/slices/shared/types';
import useFetchShareViewStatus from '../hooks/useFetchShareViewStatus';
import useGetShareDetails from '../hooks/useGetShareDetails';
import ShareNotAvailable from './ShareNotAvailable';
import {ShareNotAvailableLayout} from './ShareNotAvailableLayout';

interface ShareAccessLayoutProps {
  showLayout?: boolean;
}

export default function ShareAccessLayout({
  showLayout = true,
}: ShareAccessLayoutProps) {
  const {sharedCustomName} = useParams();
  const context = useOutletContext();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const {share, isShareLoaded} = useGetShareDetails({
    sharedCustomName,
    fetchDisabled: !isLoggedIn,
  });

  const {shareViewStatus} = useFetchShareViewStatus({
    share,
    sharedCustomName,
    enabled: isLoggedIn,
  });

  const renderWithLayout = (content: React.ReactNode) => (
    <ShareNotAvailableLayout showLayout={showLayout}>
      {content}
    </ShareNotAvailableLayout>
  );

  if (!isLoggedIn) {
    return renderWithLayout(<ShareNotAvailable />);
  }

  if (shareViewStatus === ShareViewStatus.Loading) {
    return renderWithLayout(<LoadingProgress />);
  }

  const isShareAvailable =
    shareViewStatus !== ShareViewStatus.Closed && !share?.archived;

  if (share && !isShareAvailable) {
    return renderWithLayout(<ShareNotAvailable isLoggedIn />);
  }

  if (!share && isLoggedIn && isShareLoaded) {
    return <Navigate to={ROUTES.LOGGED_IN_WITHOUT_ORG.HOME} />;
  }

  return <Outlet context={context} />;
}
