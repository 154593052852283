import theme from 'config/theme';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import {Icon} from '../Icon';

export interface TabItem<T extends string> {
  key: T;
  label: string;
  icon?: React.ReactNode;
}

export interface Props<T extends string> {
  tabs: Array<TabItem<T>>;
  activeTab: T;
  setActiveTab: (value: T) => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  tabStyle?: StyleProp<ViewStyle>;
  rootStyle?: StyleProp<ViewStyle>;
  activeTabStyle?: StyleProp<ViewStyle>;
  tabTextStyle?: StyleProp<TextStyle>;
}

export const createTabItem = <T extends string>(
  key: T,
  label: string,
  iconName: string,
  activeTab: T,
  iconSize?: number,
): TabItem<T> => ({
  key,
  label,
  icon: (
    <Icon
      name={iconName}
      size={iconSize ?? 20}
      color={
        key === activeTab
          ? theme.colors.neutral.$black
          : theme.colors.neutral.$5
      }
    />
  ),
});

const Tab = <T extends string>({
  tab,
  isActive,
  onPress,
  onHoverIn,
  onHoverOut,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
}: {
  tab: TabItem<T>;
  isActive: boolean;
  onPress: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  tabStyle?: StyleProp<ViewStyle>;
  activeTabStyle?: StyleProp<ViewStyle>;
  tabTextStyle?: StyleProp<TextStyle>;
}) => (
  <Pressable
    key={tab.key}
    style={[
      styles.tab,
      tabStyle,
      isActive && styles.tabActive,
      isActive && activeTabStyle,
    ]}
    onPress={onPress}
    onHoverIn={onHoverIn}
    onHoverOut={onHoverOut}>
    {Boolean(tab.icon) && <View>{tab.icon}</View>}
    {Boolean(tab.label) && (
      <Text
        style={[
          styles.tabLabel,
          isActive && styles.activeTabLabel,
          tabTextStyle,
        ]}>
        {tab.label}
      </Text>
    )}
  </Pressable>
);

export default function Tabs<T extends string>({
  tabs,
  activeTab,
  setActiveTab,
  onHoverIn,
  onHoverOut,
  rootStyle,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
}: Props<T>) {
  return (
    <View style={[styles.root, rootStyle]}>
      <View style={[styles.tabsContainer]}>
        {tabs.map(tab => (
          <Tab
            key={tab.key}
            tab={tab}
            isActive={tab.key === activeTab}
            onPress={() => {
              setActiveTab(tab.key);
            }}
            onHoverIn={onHoverIn}
            onHoverOut={onHoverOut}
            tabStyle={tabStyle}
            activeTabStyle={activeTabStyle}
            tabTextStyle={tabTextStyle}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 8,
    alignSelf: 'flex-start',
  },
  tabsContainer: {
    flexDirection: 'row',
    padding: 2,
  },
  tab: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    gap: 8,
    paddingHorizontal: 32,
    paddingVertical: 8,
    minWidth: 180,
  },
  tabActive: {
    backgroundColor: theme.colors.neutral.$white,
  },
  tabLabel: {
    lineHeight: 24,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: theme.colors.neutral.$5,
  },
  activeTabLabel: {
    color: theme.colors.neutral.$2Base,
  },
});
