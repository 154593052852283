import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from './consts';

import {useWorkspaceMembers} from '../../hooks/useWorkspaceMembers';
import {AiChat} from './pages/AiChat';
import {AiChatList} from './pages/AiChatList';

interface Props {
  namespace: string;
  instanceId: string;
  instanceName: string;
  chatId?: string;
  messageId?: string;
}

export function WorkspaceAiChat({
  namespace,
  instanceId,
  instanceName,
  chatId,
  messageId,
}: Props) {
  const openChat = useSelector(
    aichat.selectors.getCurrentChat(namespace, instanceId),
  );
  const {members, isLoading: membersLoading} = useWorkspaceMembers(instanceId);

  const dispatch = useDispatch();

  const handleNewChatPress = (isPrivate: boolean) => {
    if (isPrivate) {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_PRIVATE_CHAT,
        }),
      );
    } else {
      dispatch(
        aichat.default.actions.setCurrentChat({
          namespace,
          instanceId,
          chatId: NEW_CHAT,
        }),
      );
    }
  };

  const handleChatPress = (chatId: string) => {
    dispatch(
      aichat.default.actions.setCurrentChat({namespace, instanceId, chatId}),
    );
  };

  useEffect(() => {
    if (chatId) {
      handleChatPress(chatId);
    }
  }, [chatId]);

  return (
    <View style={styles.container}>
      {!openChat && (
        <AiChatList
          namespace={namespace}
          instanceId={instanceId}
          onNewChatPress={handleNewChatPress}
          onChatPress={chat => handleChatPress(chat.id)}
          workspaceMembers={members}
        />
      )}
      {openChat && (
        <AiChat
          namespace={namespace}
          instanceId={instanceId}
          instanceName={instanceName}
          chatId={openChat}
          messageId={messageId}
          onBackPress={() => handleChatPress(null)}
          onNewChatPress={() => handleNewChatPress(false)}
          workspaceMembers={members}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
});
