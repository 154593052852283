import {t} from '@lingui/macro';
import theme from 'config/theme';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Typography from 'interface/base/Typography';
import {InvitedBy} from 'interface/stacks/auth/components/InvitedBy';
import {useState} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {ShareTypeColor} from 'utils/fast/shares';

interface ShareInviteCardProps {
  loading?: boolean;
  share: ShareItem;
  org: OrganizationDetails;
  onJoinButtonPress: (share: ShareItem) => Promise<void>;
  invitedByEmail?: string;
}

export default function ShareInviteCard({
  loading,
  share,
  org,
  invitedByEmail,
  onJoinButtonPress,
}: ShareInviteCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {width} = useWindowDimensions();

  const handleJoinWorkspace = async () => {
    try {
      setIsLoading(true);
      await onJoinButtonPress(share);
    } finally {
      setIsLoading(false);
    }
  };

  const ShareIcon = (
    <View
      style={[
        styles.iconWrapper,
        {borderColor: ShareTypeColor[share.share_type]},
      ]}>
      <ShareTypeBadge shareType={share.share_type} size={40} />
    </View>
  );

  const ShareInfo = (
    <View style={styles.titleContainer}>
      <Typography variant="semi-bold" color={theme.colors.neutral.$2Base}>
        {share.title}
      </Typography>
      <Typography size="md" color={theme.colors.neutral.$4}>
        ({org.name})
      </Typography>
    </View>
  );

  const JoinButton = (
    <Button
      variant="primary"
      size="lg"
      onPress={handleJoinWorkspace}
      loading={isLoading || loading}>
      {t`Join`}
    </Button>
  );

  return (
    <View style={styles.container}>
      <View style={styles.mainContent}>
        <View style={styles.leftSection}>
          <View style={styles.shareDetails}>
            {ShareIcon}
            <View style={styles.infoWrapper}>{ShareInfo}</View>
          </View>
        </View>
        <View
          style={[
            styles.buttonContainer,
            width < 790 && styles.buttonContainerMobile,
          ]}>
          {JoinButton}
        </View>
      </View>
      {invitedByEmail && <InvitedBy invitedByEmail={invitedByEmail} />}
    </View>
  );
}

// Styles
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    backgroundColor: theme.colors.neutral.$14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderRadius: 14,
    width: '100%',
    gap: 20,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  shareDetails: {
    flexDirection: 'row',
    gap: 15,
  },
  infoWrapper: {
    flexDirection: 'column',
    gap: 8,
  },
  titleContainer: {
    gap: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    padding: 12,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 8,
    borderWidth: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainerMobile: {
    flexDirection: 'column',
    gap: 10,
  },
});
