import theme from 'config/theme';
import type {ViewStyle} from 'react-native';
import {hexToRGBA} from 'utils/common/color';
import {
  SIZE as TYPOGRAPHY_SIZE,
  VARIANT as TYPOGRAPHY_VARIANT,
} from '../Typography';
import type {ButtonStateStyles, TextProps, TextStateStyles} from './types';

export const SIZE = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;

export const VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  text: 'text',
} as const;

type Size = keyof typeof SIZE;
type Variant = keyof typeof VARIANT;

export const SIZES: Record<Size, ViewStyle> = {
  sm: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    minHeight: 24,
  },
  md: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    minHeight: 32,
  },
  lg: {
    paddingHorizontal: 26,
    paddingVertical: 13,
    minHeight: 40,
  },
};

// TODO: replace hardcoded colors with unistyles or new theme file
export const VARIANTS: Record<Variant, ButtonStateStyles> = {
  primary: {
    default: {
      light: {
        backgroundColor: theme.colors.brand.$4,
      },
      dark: {
        backgroundColor: theme.colors.neutral.$0,
      },
    },
    hover: {
      light: {
        backgroundColor: theme.colors.brand.$1_600,
      },
      dark: {
        backgroundColor: theme.colors.neutral.$0,
      },
    },
    focus: {
      light: {
        backgroundColor: theme.colors.brand.$1_500,
      },
      dark: {
        backgroundColor: theme.colors.neutral.$0,
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  secondary: {
    default: {
      light: {
        backgroundColor: theme.colors.neutral.$75,
      },
      dark: {
        backgroundColor: theme.colors.neutral.$700,
      },
    },
    hover: {
      light: {
        backgroundColor: theme.colors.neutral.$100,
      },
      dark: {
        backgroundColor: hexToRGBA(theme.colors.neutral.$0, 0.2),
      },
    },
    focus: {
      light: {
        backgroundColor: theme.colors.neutral.$0,
      },
      dark: {
        backgroundColor: theme.colors.neutral.$700,
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  text: {
    default: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    hover: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    focus: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    disabled: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
  },
};

export const TEXT_SIZES: Record<Size, TextProps> = {
  sm: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.xs,
  },
  md: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.xs,
  },
  lg: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.sm,
  },
};

export const TEXT_VARIANTS: Record<Variant, TextStateStyles> = {
  primary: {
    default: {
      light: {
        color: theme.colors.neutral.$0,
      },
      dark: {
        color: theme.colors.neutral.$700,
      },
    },
    hover: {
      light: {
        color: theme.colors.neutral.$0,
      },
      dark: {
        color: theme.colors.brand.$1_600,
      },
    },
    focus: {
      light: {
        color: theme.colors.neutral.$0,
      },
      dark: {
        color: theme.colors.neutral.$700,
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  secondary: {
    default: {
      light: {
        color: theme.colors.neutral.$700,
      },
      dark: {
        color: theme.colors.neutral.$0,
      },
    },
    hover: {
      light: {
        color: theme.colors.neutral.$700,
      },
      dark: {
        color: theme.colors.neutral.$0,
      },
    },
    focus: {
      light: {
        color: theme.colors.neutral.$700,
      },
      dark: {
        color: theme.colors.neutral.$0,
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  text: {
    default: {
      light: {
        color: theme.colors.neutral.$700,
      },
      dark: {
        color: theme.colors.neutral.$0,
      },
    },
    hover: {
      light: {
        color: theme.colors.brand.$1_600,
      },
      dark: {
        color: theme.colors.brand.$1_300,
      },
    },
    focus: {
      light: {
        color: theme.colors.neutral.$700,
      },
      dark: {
        color: theme.colors.neutral.$0,
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
};
