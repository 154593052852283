import theme from 'config/theme';
import {WORKSPACE_ROOT} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import Typography from 'interface/base/Typography';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import useWorkspaceMenuSettings from 'interface/stacks/app/hooks/useWorkspaceMenuSettings';
import {Fragment, useMemo} from 'react';
import type {GestureResponderEvent} from 'react-native';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import * as user from 'store/slices/user';
import {useGetCurrentShareDetails} from '../hooks/useGetCurrentShareDetails';
import {FolderBreadcrumbsItem} from './FolderBreadcrumbsItem';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

export interface FolderBreadcrumbsProps {
  workspaceName: string;
  workspaceId: string;
  folderId: string;
  members?: MultiplayerMember[];
  onFolderPress?: (e: GestureResponderEvent) => void;
  share?: Share;
  isGuestView?: boolean;
}

export function FolderBreadcrumbs(props: FolderBreadcrumbsProps) {
  const {folderKey, workspaceId} = useParams();
  const navigate = useNavigate();
  const {workspace} = useWorkspaceContext();
  const share = useGetCurrentShareDetails();

  const currentUser = useSelector(user.selectors.getUserDetails);
  const folder = useSelector(selectors.getItem(props.folderId));
  const items = useSelector(selectors.getItems);

  const {handleOpenMenu} = useWorkspaceMenuSettings(workspace, currentUser.id);

  const root = useMemo(
    () => [props.workspaceId, props.workspaceName],
    [props.workspaceId, props.workspaceName],
  );

  const buildHierarchy = (fileItem: FilesItem): FilesItem[] => {
    return fileItem
      ? [...buildHierarchy(items[fileItem.parent]), fileItem]
      : [];
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleMenuPress = (event: GestureResponderEvent) => {
    props.onFolderPress?.(event);
  };

  const handleWorkspacePress = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleOpenMenu(event);
  };

  const baseUrl = `/workspace/${workspaceId}`;
  const hierarchy = useMemo(
    () => [root, ...buildHierarchy(folder)],
    [root, folder?.id],
  );

  return (
    <View style={styles.root}>
      <View style={styles.breadcrumbs}>
        {!props.isGuestView &&
          hierarchy.map((item, ind, paths) => {
            const first = ind === 0;
            const last = ind === paths.length - 1 && !share;
            const isFolder = 'id' in item;
            const folderId = isFolder ? item.id : item[0];
            const folderName = isFolder ? item.name : item[1];
            const link = first
              ? `${baseUrl}/storage/${WORKSPACE_ROOT}`
              : `${baseUrl}/storage/${folderId}`;
            return (
              <Fragment key={`folder-breadcrumb-${ind}`}>
                <FolderBreadcrumbsItem
                  id={folderId}
                  name={folderName}
                  root={first ? workspace?.id : undefined}
                  state={last && !share ? 'Current' : 'Default'}
                  workspaceId={workspace?.id}
                  members={props.members}
                  isLast={last}
                  onPressRoot={handleWorkspacePress}
                  onPress={e =>
                    last && folderKey
                      ? first
                        ? handleWorkspacePress(e)
                        : handleMenuPress(e)
                      : handleNavigation(link)
                  }
                />
                {!last && (
                  <Typography size="md" variant="medium">
                    /
                  </Typography>
                )}
              </Fragment>
            );
          })}
        {share && (
          <Fragment key={`folder-breadcrumb-${hierarchy.length}`}>
            <FolderBreadcrumbsItem
              id={share.id}
              name={share.name}
              root={undefined}
              state={'Current'}
              workspaceId={workspace?.id}
              members={props.members}
              isLast
              onPressRoot={undefined}
              onPress={undefined}
              isShared
            />
          </Fragment>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$white,
  },
  breadcrumbs: {
    gap: 4,
    marginVertical: 6,
    marginHorizontal: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
