import {t} from '@lingui/macro';
import theme from 'config/theme';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import Typography from 'interface/base/Typography';
import {Fragment, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {slices} from 'store';
import {selectors} from 'store/slices/files';

export enum FileSummaryTabs {
  Details = 'details',
  Summary = 'summary',
}

export function FolderHeaderSummary() {
  const [tabHovered, setTabHovered] = useState(false);
  const active = useSelector(selectors.getFileSummaryType);
  const tabs = [
    createTabItem(FileSummaryTabs.Details, '', 'lucide:file', active, 18),
    createTabItem(FileSummaryTabs.Summary, '', 'lucide:text', active, 18),
  ];

  const dispatch = useDispatch();
  return (
    <Pressable style={styles.root}>
      {({hovered}) => (
        <Fragment>
          <Tabs
            tabs={tabs}
            activeTab={active}
            rootStyle={styles.root}
            tabStyle={styles.tab}
            onHoverIn={() => setTabHovered(true)}
            onHoverOut={() => setTabHovered(false)}
            setActiveTab={(value: FileSummaryTabs) =>
              dispatch(slices.files.actions.fileSummaryType({type: value}))
            }
          />
          {(tabHovered || hovered) && (
            <View style={styles.tooltip}>
              <Typography size="xs" color={theme.colors.neutral.$white}>
                {active === FileSummaryTabs.Summary
                  ? t`File summary enabled`
                  : t`File summary disabled`}
              </Typography>
              <View style={styles.arrow} pointerEvents="none" />
            </View>
          )}
        </Fragment>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    height: 32,
  },
  tab: {
    minWidth: 32,
    paddingLeft: 0,
    paddingRight: 0,
    height: 28,
    flex: 1,
  },
  tooltip: {
    position: 'absolute',
    zIndex: 10,
    top: -55,
    left: -80,
    width: 160,
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    bottom: -5,
    left: 'calc(50% + 30px)',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5.5,
    borderRightWidth: 5.5,
    borderTopWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: theme.colors.neutral.$2Base,
    zIndex: 99999,
  },
});
