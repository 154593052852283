import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import React from 'react';
import {StyleSheet, View} from 'react-native';

interface ShareWorkspaceProps {
  workspaceId: string;
  workspaceName?: string;
}

const ShareWorkspace = ({workspaceId, workspaceName}: ShareWorkspaceProps) => {
  return (
    <View style={styles.shareWorkspace}>
      {workspaceName ? (
        <WorkspaceBadge id={workspaceId} size={16} />
      ) : (
        <Icon name={'lucide:lock'} size={16} />
      )}
      <Typography size="xs" color={theme.colors.neutral.$2Base}>
        {workspaceName ?? <Trans>Private</Trans>}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  shareWorkspace: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});

export default ShareWorkspace;
