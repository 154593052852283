import {DefaultLayout, type ShareItem} from 'fast-sdk/src/api/share/consts';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {resetSlice} from 'store/utils';
import {uuid} from 'utils/common/random';
import {formatFileId} from 'utils/fast/files';
import {defaultSort, initialState} from '.';
import {
  type Actions,
  type Share,
  type ShareComment,
  ShareViewStatus,
  type Store,
} from './types';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

function buildShare(
  newShare: ShareItem,
  workspaceFolderName: string,
  oldShare?: Share | undefined,
): Share {
  const shareLayoutToFileListLayout = {
    [DefaultLayout.Thumbnail]: FileListItemLayout.GridSimple,
    [DefaultLayout.List]: FileListItemLayout.ListNormal,
    [DefaultLayout.Blog]: FileListItemLayout.ListNormal,
    [DefaultLayout.Gallery]: FileListItemLayout.ListNormal,
  };

  return {
    ...(oldShare ?? {}),
    ...newShare,
    type: StorageItemType.Link,
    name: newShare.title ?? 'Untitled',
    modified: newShare.updated,
    currentLayout: shareLayoutToFileListLayout[newShare.display_type],
    sort: defaultSort,
    share_link: newShare.share_link
      ? {
          graft_point: formatFileId(newShare.share_link.graft_point),
          id: formatFileId(newShare.share_link.id),
        }
      : null,
    shareViewStatus: oldShare?.shareViewStatus ?? ShareViewStatus.Loading,
    parent_workspace_folder: workspaceFolderName,
    workspaceId: workspaceFolderName,
  };
}

function findComment(comments: Array<ShareComment>, id: string) {
  for (const node of comments) {
    if (node.id === id) return node;
    if (node.reply) {
      const child = findComment(node.reply, id);
      if (child) return child;
    }
  }
}

function addSharedCustomNameMapping(state: Store, share: ShareItem) {
  if (!state.sharedsByCustomName) {
    state.sharedsByCustomName = {};
  }
  state.sharedsByCustomName[share.custom_name] = {
    shareId: share.id,
    workspaceFolderName: share.parent_workspace_folder,
  };
}

export function setIsListLoading(
  state: Store,
  action: Actions['listShared']['setIsListLoading'],
) {
  const isListLoading = action.payload;
  state.isListLoading = isListLoading;
}

export function updateShareDetails(
  state: Store,
  action: Actions['listShared']['updateShareDetails'],
) {
  const {workspaceFolderName, shareDetails: share} = action.payload;

  if (!state.workspaces[workspaceFolderName]) {
    state.workspaces[workspaceFolderName] = [];
  }

  const oldShare = state.shares[share.id];
  const newShareItem = buildShare(share, workspaceFolderName, oldShare);
  state.shares[share.id] = newShareItem;
  if (!state.workspaces[workspaceFolderName].find(id => id === share.id)) {
    state.workspaces[workspaceFolderName].push(share.id);
  }
  addSharedCustomNameMapping(state, newShareItem);
}

export function sort(state: Store, action: Actions['listShared']['sorting']) {
  state.sort = action.payload;
}

export function setShareMembers(
  state: Store,
  action: Actions['setShareMembers'],
) {
  const {share, members} = action.payload;
  state.shares[share.id].members = members;
}

export function setShareViewStatus(
  state: Store,
  action: Actions['setShareViewStatus'],
) {
  const {share, status} = action.payload;
  state.shares[share.id].shareViewStatus = status;
}

export function addCommentToShare(
  state: Store,
  action: Actions['addCommentToShare'],
) {
  const {commentText, sender, share} = action.payload;
  const item = state.shares[share.id];
  if (item) {
    const newComment: ShareComment = {
      id: uuid(),
      content: commentText,
      createdAt: new Date(),
      reply: [],
      sender,
      seen: false,
    };
    item.commentsList.push(newComment);
  }
}

export function addReplyForShare(
  state: Store,
  action: Actions['addReplyForShare'],
) {
  const {commentId, replyText, share, sender} = action.payload;
  const item = state.shares[share.id];
  if (item) {
    const comment = findComment(item.commentsList, commentId);
    if (comment) {
      comment.reply.push({
        content: replyText,
        createdAt: new Date(),
        reply: [],
        sender,
        id: uuid(),
        seen: false,
      });
    }
  }
}

export function setShareLayout(
  state: Store,
  action: Actions['setShareLayout'],
) {
  const {share, layout} = action.payload;
  state.shares[share.id].currentLayout = layout;
}

export function setShareSort(state: Store, action: Actions['setShareSort']) {
  const {share, sort} = action.payload;
  state.shares[share.id].sort = sort;
}

export function setShares(state: Store, action: Actions['setShares']) {
  const {shares, workspaces} = action.payload;

  const shareMap = {};
  const workspaceMap = {externals: []};
  for (const share of shares) {
    const workspace = workspaces.find(w => w.id === share.parent_workspace);
    const field = workspace ? workspace.folder_name : 'externals';
    const workspaceSlice = workspaceMap[field] ?? [];
    workspaceSlice.push(share.id);
    workspaceMap[field] = workspaceSlice;
    const oldShare = state.shares[share.id];
    const newShareItem = buildShare(share, field, oldShare);
    shareMap[share.id] = newShareItem;
    addSharedCustomNameMapping(state, newShareItem);
  }

  state.shares = shareMap;
  state.workspaces = workspaceMap;
}

export function setSharesLoaded(
  state: Store,
  action: Actions['setSharesLoaded'],
) {
  state.sharesLoaded = action.payload.loaded;
}

export function archiveShare(state: Store, action: Actions['archiveShare']) {
  const share = action.payload;
  const shareToArchive = state.shares[share.id];
  if (shareToArchive) {
    shareToArchive.archived = true;
  }
}

export function unarchiveShare(
  state: Store,
  action: Actions['unarchiveShare'],
) {
  const share = action.payload;
  const shareToUnarchive = state.shares[share.id];
  if (shareToUnarchive) {
    shareToUnarchive.archived = false;
  }
}

export function deleteShare(state: Store, action: Actions['deleteShare']) {
  const share = action.payload;
  delete state.shares[share.id];
  delete state.sharedsByCustomName[share.custom_name];
  state.workspaces[share.parent_workspace_folder] = state.workspaces[
    share.parent_workspace_folder
  ].filter(id => id !== share.id);
}
