import {t} from '@lingui/macro';
import {TextInput} from 'interface/common/TextInput';
import {View} from 'react-native';

import {TextArea} from 'interface/base/TextArea';
import type {ShareState} from '../hooks/useShareActions';

interface ShareDetailsProps {
  shareState: ShareState;
}

export function ShareDetails({shareState}: ShareDetailsProps) {
  const {title, description, setTitle, setDescription} = shareState;

  return (
    <View>
      <TextInput
        label={t`Title`}
        value={title}
        placeHolder={'Enter a title'}
        setInputValue={setTitle}
      />
      <TextArea
        label="Description"
        text={description}
        onChangeText={setDescription}
        placeholder="Enter a description"
      />
    </View>
  );
}
