import {ROUTES} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import useFetchOrgs from 'interface/common/hooks/useFetchOrgs';
import {useShares} from 'interface/common/hooks/useShares';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {AppLoading} from '../app/AppLoading';
import {OnboardSteps} from '../onboard/lib/consts';
import {useLayoutWorkspaces} from '../workspace/hooks/useLayoutWorkspaces';
import PageContent from './components/content';
import PageHeader from './components/header';
import PageSkeleton from './components/skeleton';
import useRedirectTo from './hooks/useRedirecTo';
import {AppTabs} from './types';

export default function ProfileMembership() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {activeTab} = useParams<{activeTab: AppTabs}>();

  const {fetchOrganizations, loading: isFetchingOrgs} = useFetchOrgs();
  const {
    fetchAndUpdateShares,
    loading: isSharesLoading,
    setSharesLoaded,
  } = useShares();
  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces({
    onWorkspacesRefresh: fetchAndUpdateShares,
    setSharesLoaded,
  });
  const {redirectTo} = useRedirectTo();

  const organizations = useSelector(user.selectors.getOrganizationsList);
  const {externalShareDomains, externalWorkspaceDomains} = useSelector(
    user.selectors.getExternalOrgDomains,
  );

  const setActiveTab = (tab: AppTabs) => {
    if (tab !== activeTab)
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${tab}`);
  };

  const setCurrentPage = (tabPageName: string) => {
    dispatch(
      app.default.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: `Home - ${tabPageName}`,
      }),
    );
  };

  const handleCreateNewOrganization = () => {
    dispatch(onboarding.default.actions.restartOnboarding());
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Organization}`,
    );
  };

  useEffect(() => {
    fetchOrganizations();
    fetchAndUpdateWorkspaces();
  }, []);

  useEffect(() => {
    const tabPageName =
      activeTab === AppTabs.Workspaces ? 'Workspaces' : 'Shares';
    setCurrentPage(tabPageName);
  }, [activeTab]);

  useEffect(() => {
    const shouldDefaultToShares =
      isWorkspacesHidden && activeTab === AppTabs.Workspaces;

    if (shouldDefaultToShares) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Shares}`);
    }
  }, [isFetchingOrgs]);

  const isWorkspaces = activeTab === AppTabs.Workspaces;
  const loading =
    isFetchingOrgs &&
    !organizations.length &&
    !externalShareDomains.length &&
    !externalWorkspaceDomains.length;

  const isWorkspacesHidden = Boolean(
    !organizations.length &&
      !externalWorkspaceDomains?.length &&
      externalShareDomains?.length,
  );

  if (redirectTo) {
    return <AppLoading />;
  }

  return (
    <View style={styles.root}>
      <PageHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onNewOrganizationPress={handleCreateNewOrganization}
        isWorkspacesHidden={isWorkspacesHidden}
      />
      {loading ? (
        <PageSkeleton isWorkspaces={isWorkspaces} />
      ) : (
        <PageContent
          externalShareDomains={externalShareDomains}
          externalWorkspaceDomains={externalWorkspaceDomains}
          organizations={organizations}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
          onNewOrganizationPress={handleCreateNewOrganization}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    gap: 48,
  },
});
