import {t} from '@lingui/macro';
import analytics from 'extensions/analytics';
import {useModal} from 'extensions/viewport/useModal';
import {Transfers} from 'fast-sdk';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {CropImage} from 'interface/common/CropImage';
import {Loading} from 'interface/common/Loading';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {type ReactElement, useEffect, useState} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import {fileListToFile, resizeImage} from 'utils/fast/files';

export function AddLogo() {
  const [showFile, setShowFile] = useState<string>();
  const [completedImg, setCompletedImg] = useState<string>();
  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<'image' | 'document'>();
  const [loading, setLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);

  const logo = useSelector(settings.selectors.getOrganizationLogo);

  const modal = useModal();
  const dispatch = useDispatch();
  const toast = useToast();
  const {subdomain} = useSubDomain();

  const pickPhoto = async () => {
    const files = await Transfers.pickFiles();
    const splittedFiles = fileListToFile(files);
    if (splittedFiles.length > 0) {
      const file = splittedFiles[0];
      setFile(file);
      setFileType(file.type.includes('image') ? 'image' : 'document');
      const objectUrl = URL.createObjectURL(file);
      setShowFile(objectUrl);
    }
  };

  const removeLogo = () => {
    setRemoveLoading(true);
    api.organization
      .removeAsset(subdomain, 'logo')
      .then(res => {
        if (res?.result) {
          dispatch(settings.default.actions.setOrganizationLogo({logo: ''}));
          setCompletedImg('');
          setShowFile('');
          toast.show(t`The organization logo is removed successfully`, {
            type: 'success',
          });
        }
      })
      .catch(err => {
        analytics.notify(err);
        toast.show(err.message, {type: 'danger'});
      })
      .finally(() => setRemoveLoading(false));
  };

  useEffect(() => {
    if (showFile) {
      modal.open(
        <CropImage
          imageName={file.name}
          imageType={file.type}
          setImage={(image: File) => setFile(image)}
          image={showFile}
          setCompletedImg={setCompletedImg}
        />,
      );
    }
  }, [showFile]);

  useEffect(() => {
    async function updateOrgLogo() {
      setLoading(true);
      const resizedFile = await resizeImage(file);
      const res = await api.organization.addAsset(
        subdomain,
        'organization logo',
        fileType,
        resizedFile,
      );
      if (res?.result) {
        toast.show(t`The organization Logo is successfully changed`, {
          type: 'success',
        });
        dispatch(
          settings.default.actions.setOrganizationLogo({
            logo: completedImg,
          }),
        );
      }
      setLoading(false);
    }

    if (completedImg) {
      updateOrgLogo();
    }
  }, [completedImg]);

  const renderImage = () => {
    // Add the proper image
    let image: ReactElement;
    if (completedImg) {
      image = (
        <Image
          source={{uri: completedImg}}
          alt={t`Organization Logo`}
          style={styles.logoImg}
        />
      );
    } else if (logo) {
      image = (
        <Image
          source={{uri: logo}}
          alt={t`Organization Logo`}
          style={styles.logoImg}
        />
      );
    }

    return image;
  };

  const renderButtons = () => {
    // Add the proper buttons
    let buttons: ReactElement;
    if (!loading) {
      if (completedImg || logo) {
        buttons = (
          <>
            <Button
              variant="secondary"
              onPress={pickPhoto}
              disabled={removeLoading}>
              {t`Edit Logo`}
            </Button>
            <Button
              variant="secondary"
              onPress={removeLogo}
              loading={removeLoading}
              disabled={removeLoading}>
              {t`Remove Logo`}
            </Button>
          </>
        );
      } else {
        buttons = (
          <Button variant="secondary" onPress={pickPhoto}>
            {t`Add Logo`}
          </Button>
        );
      }
    }
    return buttons;
  };

  return (
    <View style={styles.logoContainer}>
      {renderImage()}
      {renderButtons()}
      {loading ? (
        <View>
          <Loading />
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  logoImg: {
    width: 53,
    height: 53,
    borderRadius: 100,
  },
  logoContainer: {
    gap: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
