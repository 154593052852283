import {WORKSPACE_ROOT, WORKSPACE_TRASH} from 'constants/routes';
import {useLocation, useNavigate} from 'extensions/navigation';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import * as $files from 'store/slices/files';

import type {FilesItem} from 'store/slices/files/types';

export interface ShareFilesNavigation {
  breadcrumbs: FilesItem[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<FilesItem[]>>;
  setTrashView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFolder: FilesItem | undefined;
  folderKey: string;
}

export function useShareFilesNavigation(): ShareFilesNavigation {
  const [breadcrumbs, setBreadcrumbs] = useState<FilesItem[]>([]);
  const [isTrashView, setTrashView] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Highlight info (linked from search)
  const highlightInfo = useMemo(
    () => location?.state as {highlightParent: string; highlightId: string},
    [location?.state],
  );
  const highlightParent = useSelector(
    $files.selectors.getItem(highlightInfo?.highlightParent),
  );

  const selectedFolder =
    breadcrumbs.length > 0 ? breadcrumbs.at(-1) : undefined;

  const folderKey = isTrashView
    ? WORKSPACE_TRASH
    : (selectedFolder?.id ?? WORKSPACE_ROOT);

  // Navigate to highlight parent
  useEffect(() => {
    if (highlightInfo?.highlightParent) {
      if (highlightInfo?.highlightParent === WORKSPACE_ROOT) {
        setBreadcrumbs([]);
      } else {
        setBreadcrumbs([...breadcrumbs, highlightParent]);
      }
      navigate(location.pathname, {
        state: {
          highlightParent: undefined,
          highlightId: highlightInfo?.highlightId,
        },
      });
    }
  }, [highlightParent, highlightInfo, breadcrumbs]);

  return {
    breadcrumbs,
    setBreadcrumbs,
    setTrashView,
    selectedFolder,
    folderKey,
  };
}
