import {useEffect} from 'react';

export function useFormSubmit(callback: () => void, disabled: boolean) {
  useEffect(() => {
    const handleSubmitForm = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !disabled) {
        callback();
      }
    };

    if (window) {
      window.removeEventListener('keypress', handleSubmitForm);
      window.addEventListener('keypress', handleSubmitForm);
    }

    return () => {
      if (window) {
        window.removeEventListener('keypress', handleSubmitForm);
      }
    };
  }, [callback, disabled]);
}
