import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {Mouse} from 'interface/common/Mouse';
import {useFileListItem} from 'interface/stacks/workspace/storage/hooks/useFileListItem';
import {Fragment, memo, useCallback, useMemo, useRef} from 'react';
import isEqual from 'react-fast-compare';
import {Pressable, StyleSheet, View, useColorScheme} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {isTouch} from 'utils/common/platform';
import {FileListItemCell} from './FileListItemCell';
import {FILE_ROW_THUMBNAIL_SIZE, FileListItemRow} from './FileListItemRow';
import FileThumbnail from './thumbnail/FileThumbnail';

import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {GestureResponderEvent} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';

export interface FileListItemProps {
  item: FilesItem;
  layout: FileListItemLayout;
  workspaceId: string;
  members: MultiplayerMember[];
  highlighted?: boolean;
  focused?: boolean;
  selected?: boolean;
  dragging?: boolean;
  isPremium?: boolean;
  linkParent?: boolean;
  multiSelect?: boolean;
  showSummary?: boolean;
  disableDetails?: boolean;
  disableActions?: boolean;
  isPrevSelected?: boolean;
  isNextSelected?: boolean;
  tooltipPos?: 'top' | 'left';
  recents?: boolean;
  shareds?: boolean;
  share?: Share;
  shareView?: boolean;
  archivedShares?: boolean;
  onOpen(item: FilesItem, share?: ShareItem): void;
  onFocus(
    item: FilesItem,
    openDetails?: boolean,
    event?: GestureResponderEvent,
  ): void;
  onSelect(item: FilesItem, event?: GestureResponderEvent): void;
}

export enum FileListItemLayout {
  ListNormal = 1,
  GridSimple = 2,
}

export const FileListItem = memo(
  (props: FileListItemProps) => {
    const ref = useRef<View>(null);
    const item = useFileListItem(ref, props);
    const scheme = useColorScheme();
    const classes = {
      root: [
        styles.root,
        item.isRow && styles.row,
        item.isCell && styles.cell,
        item.isUpload && styles.uploading,
        props.selected && !props.dragging && styles.selected,
        props.dragging && styles.dragging,
      ],
      outline: [
        styles.outline,
        item.isCell && styles.outlineCell,
        item.isRow &&
          props.isPrevSelected &&
          !item.isDropping && {
            borderTopWidth: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        item.isRow &&
          props.isNextSelected &&
          !item.isDropping && {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        {
          borderWidth: item.isDropping ? 2 : 1,
          borderColor: item.isDropping
            ? theme.colors.brand.$4Base
            : props.selected
              ? theme.colors.brand.$1_400
              : item.membersSelected?.pop()?.userColor,
        },
        (props.highlighted || item.isDropping) && styles.toplevel,
      ],
    };

    const title = props.item
      ? props.showSummary
        ? (props.item.summary?.title ?? props.item.name)
        : props.item.name
      : 'Unknown';

    const renderThumbnail = useMemo(() => {
      return (
        <FileThumbnail
          item={item.fileNode}
          uploading={item.isUpload}
          options={
            item.isRow
              ? {
                  previewResizeMode: 'contain',
                  imageResizeMode: 'contain',
                  imageWidth: FILE_ROW_THUMBNAIL_SIZE.width,
                  imageHeight: FILE_ROW_THUMBNAIL_SIZE.height,
                  containerStyle: FILE_ROW_THUMBNAIL_SIZE,
                  imageContainerStyle: {
                    backgroundColor: theme.colors.neutral.$50,
                  },
                }
              : undefined
          }
          size={
            item.isCell ? FileThumbnailSizes.Medium : FileThumbnailSizes.Small
          }
          shareType={
            // @ts-ignore
            props.item?.share_type ??
            (item.isLink ? item?.shareTarget?.share_type : undefined)
          }
        />
      );
    }, [
      item.fileNode?.id,
      item.isCell,
      item.isUpload,
      item?.shareTarget?.id,
      props.item?.id,
    ]);

    const renderMembers = useCallback(
      () => (
        <View style={styles.members}>
          {props.members
            ?.filter(m => m.currentItemId === item.fileNode?.id)
            ?.map(member => (
              <View
                key={member.clientId}
                style={[styles.dot, {backgroundColor: member.userColor}]}
              />
            ))}
        </View>
      ),
      [props.members],
    );

    return (
      <Pressable
        ref={ref}
        style={classes.root}
        android_ripple={{
          color:
            scheme === 'dark'
              ? 'rgba(255, 255, 255, 0.10)'
              : 'rgba(0, 0, 0, 0.10)',
        }}>
        <Mouse onDoubleClick={item.open} onContextMenu={item.menu}>
          {item.hasOutline && (
            <Fragment>
              <View
                style={[styles.checks, item.isCell && styles.checksCell]}
                pointerEvents="none">
                {props.members
                  ?.filter(m => m.selection?.includes(item.fileNode?.id))
                  ?.map(member => (
                    <View
                      key={member.clientId}
                      style={[
                        styles.checkBox,
                        {backgroundColor: member.userColor},
                      ]}>
                      <View style={styles.checkIcon}>
                        <Svg viewBox="0 0 16 16" width="16" height="16">
                          <Path
                            fill="#ffffff"
                            d="M 6.210000038146973 15.539999961853027 L 0 9.329999923706055 L 2.8299999237060547 6.5 L 6.210000038146973 9.890000343322754 L 16.09000015258789 0 L 18.919998168945312 2.8299999237060547 L 6.210000038146973 15.539999961853027 Z"
                          />
                        </Svg>
                      </View>
                    </View>
                  ))}
              </View>
              <View pointerEvents="none" style={classes.outline} />
            </Fragment>
          )}
          {item.isRow ? (
            <FileListItemRow
              {...props}
              title={title}
              focus={item.focus}
              recents={props.recents}
              shareds={props.shareds}
              thumbnail={renderThumbnail}
              menuAnchor={item.menuFiles.reference}
              fileLoading={item.fileLoading}
              renderMembers={renderMembers}
              onSelectThumbnail={item.select}
              onDropDownMenu={item.menu}
              onTitlePress={!isTouch() ? item.open : undefined}
              onLongPress={item.longPress}
              onPress={
                props.multiSelect || !isTouch() ? item.select : item.open
              }
            />
          ) : (
            <FileListItemCell
              {...props}
              title={title}
              focus={item.focus}
              thumbnail={renderThumbnail}
              menuAnchor={item.menuFiles.reference}
              fileLoading={item.fileLoading}
              onDropDownMenu={item.menu}
              onPress={
                props.multiSelect || !isTouch() ? item.select : item.open
              }
            />
          )}
        </Mouse>
      </Pressable>
    );
  },
  (prev, next) => isEqual(prev, next),
);

const styles = StyleSheet.create({
  // Global
  root: {
    flex: 1,
    position: 'relative',
  },
  outline: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderWidth: 1,
    borderRadius: 8,
    zIndex: 0,
    overflow: 'visible',
    borderColor: theme.colors.brand.$1_400,
  },
  toplevel: {
    zIndex: 10,
  },
  outlineCell: {
    borderWidth: 1,
    borderRadius: 8,
  },
  icons: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    minWidth: 42,
    height: 42,
    flexDirection: 'row',
    alignItems: 'center',
  },
  large: {
    width: 84,
    height: 74,
  },
  // Layouts
  row: {
    flexDirection: 'row',
  },
  cell: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    margin: 3,
  },
  // States
  selected: {
    backgroundColor: theme.colors.neutral.$75,
    //backgroundColor: theme.colors.brand.$9,
    borderRadius: 8,
  },
  dragging: {
    opacity: 0.6,
  },
  uploading: {
    /* @ts-ignore Web Only */
    pointerEvents: 'none',
  },
  // Awareness
  members: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  dot: {
    display: 'flex',
    flexDirection: 'column',
    width: 7,
    height: 7,
    borderRadius: 99999,
  },
  checks: {
    position: 'absolute',
    flexDirection: 'row',
    top: 2,
    left: 2,
  },
  checksCell: {
    left: 12,
    top: 14,
    borderRadius: 3,
    overflow: 'hidden',
  },
  checkBox: {
    width: 16,
    height: 16,
    backgroundColor: theme.colors.neutral.$black,
  },
  checkIcon: {
    width: 16,
    height: 16,
    padding: 4,
    alignItems: 'center',
    marginLeft: -1,
  },
});
