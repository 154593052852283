import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import type {Personality} from 'store/slices/aichat/types';
import {PERSONALITY_ICON} from '../../consts';

interface Props {
  personality: Personality;
  onChange: (personality: Personality) => void;
}

export default function PersonalityPopup({personality, onChange}: Props) {
  const [hasMenu, setHasMenu] = useState(false);

  return (
    <Popup
      triggerElement={
        <PersonalityButton
          personality={personality}
          onPressed={() => setHasMenu(true)}
        />
      }
      isOpen={hasMenu}
      placement="top"
      showBorder={false}
      customContainerStyle={styles.popupContainer}
      customPopupStyle={styles.customPopupStyle}
      close={() => setHasMenu(false)}>
      <PersonalityPopupContent
        onSelected={personality => {
          setHasMenu(false);
          onChange(personality);
        }}
      />
    </Popup>
  );
}

function PersonalityButton({
  personality,
  onPressed,
}: {personality: Personality; onPressed: () => void}) {
  return (
    <Pressable onPress={onPressed} style={styles.button}>
      <Image
        source={{uri: PERSONALITY_ICON[personality]}}
        style={{width: 18, height: 18}}
      />
      <Typography size="xs" variant="regular" color={theme.colors.neutral.$700}>
        {personality.charAt(0).toUpperCase() + personality.slice(1)}
      </Typography>
      <Icon
        name="lucide:chevron-down"
        color={theme.colors.neutral.$700}
        opacity={0.3}
      />
    </Pressable>
  );
}

function PersonalityPopupContent({
  onSelected,
}: {onSelected: (personality: Personality) => void}) {
  return (
    <>
      <Typography
        size="sm"
        variant="regular"
        color={theme.colors.neutral.$500}
        overrides={styles.title}>
        Choose the style of AI responses:
      </Typography>
      <PersonalityItem
        title="Concise (Ripley)"
        description="Provides brief, straightforward answers"
        icon={PERSONALITY_ICON.concise}
        onPress={() => onSelected('concise')}
      />
      <PersonalityItem
        title="Detailed (Agatha)"
        description="Adds personality and humor to responses"
        icon={PERSONALITY_ICON.detailed}
        onPress={() => onSelected('detailed')}
      />
      <PersonalityItem
        title="Fun (Chandler)"
        description="Adds personality and humor to responses"
        icon={PERSONALITY_ICON.fun}
        onPress={() => onSelected('fun')}
      />
    </>
  );
}

function PersonalityItem({
  title,
  description,
  icon,
  onPress,
}: {title: string; description: string; icon: any; onPress: () => void}) {
  return (
    <HoveredView onPress={onPress} style={styles.hoveredView}>
      <Image
        source={{uri: icon}}
        style={{width: 18, height: 18, marginVertical: 5}}
      />
      <View style={styles.personalityItemContent}>
        <Typography
          size="sm"
          variant="medium"
          color={theme.colors.neutral.$700}>
          {title}
        </Typography>
        <Typography
          size="xs"
          variant="regular"
          color={theme.colors.neutral.$700}>
          {description}
        </Typography>
      </View>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 280,
    padding: 24,
    flexDirection: 'column',
  },
  title: {
    marginBottom: 16,
  },
  hoveredView: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 6,
    flexDirection: 'row',
    gap: 12,
  },
  personalityItemContent: {
    flexDirection: 'column',
    paddingVertical: 4,
    gap: 4,
  },
  button: {
    paddingHorizontal: 8,
    height: 24,
    paddingVertical: 2,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$100,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 24,
    width: 380,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
  customPopupStyle: {
    backgroundColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    paddingHorizontal: 8,
  },
});
