import theme from 'config/theme';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {getVersion} from 'utils/common/version';
import AdvancedSettingsButton from './AdvancedSettingsButton';
import useLegalNavigation from './hooks/useLegalNavigation';

export default function Footer() {
  const {navigateToTerms, navigateToPrivacy, navigateToHelp} =
    useLegalNavigation();

  return (
    <View style={styles.root}>
      <AdvancedSettingsButton label="©2025 Fast.io" textStyle={styles.text} />
      <AdvancedSettingsButton
        label={`v${getVersion()}`}
        textStyle={styles.text}
      />

      <Pressable onPress={navigateToTerms}>
        <Text style={styles.text}>Terms</Text>
      </Pressable>
      <Pressable onPress={navigateToPrivacy}>
        <Text style={styles.text}>Privacy Policy</Text>
      </Pressable>
      <Pressable onPress={navigateToHelp}>
        <Text style={styles.text}>Support</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: 76,
    flexDirection: 'row',
    gap: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  popup: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: 15,
  },
  resetContainer: {
    flexDirection: 'row',
    gap: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
