import theme from 'config/theme';
import {useParams} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useDragDropTarget} from 'interface/stacks/workspace/storage/hooks/useDragDropTarget';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {load} from 'utils/fast/storage';
import {useFolderActions} from '../../hooks/useFolderActions';
import type {IFolderItem} from '../../types';

interface FolderItemProps {
  item: IFolderItem;
  workspace: WorkspaceListDetail;
  icon?: string;
  level?: number;
  onPress: (id: string) => void;
  selectedFolderId?: string;
  parentPath?: string[];
}

const PADDING_LEFT_PER_LEVEL = 16;

export function FolderItem({
  workspace,
  item,
  icon,
  level = 0,
  onPress,
  selectedFolderId,
  parentPath = [],
}: FolderItemProps) {
  const dispatch = useDispatch();
  const {folderKey} = useParams();

  const {menuRef, openContextMenu} = useFolderActions({item, workspace});
  const {uploader} = useWorkspaceContext();
  const dragDropRef = useRef<View>(null);
  const {isDropping} = useDragDropTarget({
    ref: dragDropRef,
    targetNs: 'workspace',
    targetId: item.id,
    targetName: item.name,
    instanceNs: 'workspace',
    instanceId: workspace.id,
    uploader,
  });

  const shouldBeExpanded = useMemo(() => {
    if (selectedFolderId === item.id) return true;

    const containsSelectedFolder = (folder: IFolderItem): boolean => {
      if (folder.id === selectedFolderId) return true;
      return folder.children.some(child => containsSelectedFolder(child));
    };

    return item.children.some(child => containsSelectedFolder(child));
  }, [item, selectedFolderId]);

  const [isExpanded, setIsExpanded] = useState(shouldBeExpanded);

  const navigateToFolder = () => {
    setIsExpanded(!isExpanded);
    if (onPress) onPress(item.id);
  };

  const toggleExpand = () => {
    const newExpanded = !isExpanded;
    setIsExpanded(newExpanded);
    if (newExpanded) {
      load(dispatch, item.id, workspace.folder_name, 'workspace');
    }
  };

  useEffect(() => {
    if (shouldBeExpanded) {
      setIsExpanded(shouldBeExpanded);
    }
  }, [shouldBeExpanded, selectedFolderId]);

  const hasChildren = item.children && item.children.length > 0;
  const showChildren = level === 0 || (isExpanded && hasChildren);
  const isSelected = selectedFolderId === item.id && folderKey;
  const currentPath = [...parentPath, item.id];
  const paddingLeft = 10 + (level - 1) * PADDING_LEFT_PER_LEVEL;

  return (
    <>
      <View ref={dragDropRef} style={[{paddingLeft}]}>
        <HoveredView onPress={navigateToFolder} style={styles.borderRadius}>
          <View
            //@ts-expect-error
            onContextMenu={openContextMenu}
            ref={menuRef}
            style={[
              styles.folderItem,
              styles.borderRadius,
              isSelected && styles.selectedItem,
              level === 0 && styles.folderItemIcon,
            ]}>
            {level > 0 && (
              <Pressable onPress={toggleExpand} style={styles.folderItemIcon}>
                <Icon
                  name={
                    isExpanded ? 'lucide:chevron-down' : 'lucide:chevron-right'
                  }
                  size={16}
                  color={
                    isSelected
                      ? theme.colors.neutral.$2Base
                      : theme.colors.neutral.$200
                  }
                />
              </Pressable>
            )}
            <View style={styles.folderTitle}>
              <Icon
                name={
                  icon ??
                  (isExpanded || level === 0
                    ? 'lucide:folder-open'
                    : 'lucide:folder-closed')
                }
                size={18}
              />
              <Typography
                variant="regular"
                size="sm"
                color={theme.colors.neutral.$2Base}>
                {item.name}
              </Typography>
            </View>
          </View>
        </HoveredView>
        {isDropping && <View pointerEvents="none" style={styles.drop} />}
      </View>
      {showChildren && (
        <View>
          {item.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(child => (
              <FolderItem
                key={child.id}
                workspace={workspace}
                item={child}
                level={level + 1}
                onPress={onPress}
                selectedFolderId={selectedFolderId}
                parentPath={currentPath}
              />
            ))}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  folderItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  folderTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  selectedItem: {
    backgroundColor: theme.colors.neutral.$100,
  },
  folderItemIcon: {
    paddingVertical: 8,
    paddingHorizontal: 6,
  },
  borderRadius: {
    borderRadius: 6,
  },
  drop: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: theme.colors.brand.$4Base,
    overflow: 'visible',
  },
});
