import type {AnyAction, Dispatch} from '@reduxjs/toolkit';
import {ROUTES} from 'constants/routes';
import {LOGIN_EVENT_KEY} from 'constants/sessionRoutes';
import {Requests, api} from 'fast-sdk';
import Auth, {
  type PendingInvitationDetails,
} from 'interface/stacks/auth/consts';
import {authDb} from 'store';
import type {SSOMethod, User} from 'store/slices/user/types';

export async function setPreviouslySignedInMethod(type: SSOMethod) {
  return await authDb.setItem('signin_method', type);
}

export async function setPreviouslySignedInResult(result: boolean) {
  return await authDb.setItem('signin_result', result);
}

export async function getPreviouslySignedInMethod() {
  return await authDb.getItem('signin_method');
}

export async function getPreviouslySignedInResult() {
  const res = await authDb.getItem('signin_result');
  return !!res;
}

export async function checkUserExists(
  setToken: (input: string) => void,
  successCallBack: (input: User) => void,
  errorCallBack: (input: Error) => void,
) {
  const userToken = await Auth.getAuthToken();
  if (userToken) {
    Requests.setAuthToken(userToken);
    setToken(userToken);
    api.user
      .userDetails()
      .then(res => res.result && successCallBack(res.user))
      .catch(err => errorCallBack(err));
  }
}

export async function loginApple(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getAppleRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export async function loginGoogle(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getGoogleRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export async function loginMicrosoft(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getMicrosoftRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export async function loginFacebook(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getFacebookRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

interface HandleSignNavigationProps {
  authToken: string;
  user: User;
  hasOrgs: boolean;
  hasWorkspaces: boolean;
  hasShares: boolean;
}

export const handleSignNavigation = (
  navigate: (path: string) => void,
  invitation: PendingInvitationDetails | null,
  handleInvitationFlow: (invitation: PendingInvitationDetails) => void,
  {
    authToken,
    user,
    hasOrgs,
    hasWorkspaces,
    hasShares,
  }: HandleSignNavigationProps,
) => {
  const shouldRedirectToEmailValidation = authToken && !user.email_address;
  const shouldRedirectToOnboarding =
    authToken &&
    user.email_address &&
    user.valid_email &&
    !hasOrgs &&
    !hasWorkspaces &&
    !hasShares;

  localStorage.setItem(LOGIN_EVENT_KEY, Date.now().toString());

  if (shouldRedirectToEmailValidation) {
    return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
  }
  if (invitation) {
    return handleInvitationFlow(invitation);
  }
  if (shouldRedirectToOnboarding) {
    return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
  }
  return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
};
