import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, type TextStyle} from 'react-native';

interface Props {
  text: string;
  rootStyle?: TextStyle;
}

export function PageContent(props: Props) {
  return (
    <Typography
      overrides={styles.root}
      size="md"
      color={theme.colors.neutral.$700}>
      {props.text}
    </Typography>
  );
}

const styles = StyleSheet.create({
  root: {
    marginBottom: '1.5rem',
  },
});
