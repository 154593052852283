import {StyleSheet} from 'react-native';
import {AiChatQuestionItem} from './AiChatQuestionItem';

import type {EntityChatMessage} from 'store/slices/aichat/types';
import {AiChatResponseItemMarkdown} from './AiChatResponseItemMarkdown';

interface Props {
  message: EntityChatMessage;
  shouldStream: boolean;
}

export function AiChatMessageItem({message, shouldStream}: Props) {
  const {content, response} = message;
  return (
    <>
      {content && (
        <AiChatQuestionItem
          key={`${message.id}-question`}
          message={content}
          userId={message.creator.id}
          createdAt={message.createdAt}
        />
      )}
      <AiChatResponseItemMarkdown
        key={`${message.id}-response`}
        shouldStream={shouldStream}
        message={message}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
});
