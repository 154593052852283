import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Markdown from 'interface/base/Markdown';
import Typography from 'interface/base/Typography';
import {useCopyLink} from 'interface/stacks/app/hooks/useCopyLink';
import {useMemo} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import type {EntityChatMessage} from 'store/slices/aichat/types';
import {
  PERSONALITY_ICON,
  PERSONALITY_NAMES,
  TEMP_MESSAGE_ID,
} from '../../consts';
import {AiChatMessageItemPopup} from '../AiChatMessageItemPopup';
import {TypingIndicator} from '../TypingIndicator';
import {AiChatCitationsSection} from './AiChatCitationsSection';
import {AiChatStreamingResponseItem} from './AiChatStreamingResponseItem';

interface Props {
  message: EntityChatMessage;
  shouldStream: boolean;
}

export function AiChatResponseItemMarkdown({message, shouldStream}: Props) {
  const {copyLink} = useCopyLink();

  const responseText = message.response?.text;

  const isErrored = message.state === 'errored';
  const isComplete = message.state === 'complete';
  const isInProgress = message.state === 'in_progress';
  const isReady = message.state === 'ready';
  const isTempMessage = message.id === TEMP_MESSAGE_ID;

  const isStreaming =
    !isTempMessage && shouldStream && (isInProgress || isReady);

  const isTyping = !isComplete && !isErrored && !isStreaming;

  const handleCopyPress = () => {
    copyLink(responseText, 'Response Copied');
  };

  const renderTypingIndicator = useMemo(() => {
    return <TypingIndicator />;
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image
          source={{uri: PERSONALITY_ICON[message.personality]}}
          style={{width: 28, height: 28}}
        />
        <Typography
          variant="medium"
          size="md"
          selectable
          color={theme.colors.neutral.$700}>
          {PERSONALITY_NAMES[message.personality]}
        </Typography>
        <View style={styles.spacer} />

        <View style={styles.buttons}>
          <Pressable onPress={handleCopyPress} style={styles.iconContainer}>
            <Icon name="lucide:copy" color={theme.colors.neutral.$700} />
          </Pressable>

          <AiChatMessageItemPopup
            chatId={message.chatId}
            messageId={message.id}
          />
        </View>
      </View>

      {isTyping && renderTypingIndicator}

      {isStreaming && (
        <AiChatStreamingResponseItem
          chatId={message.chatId}
          messageId={message.id}
        />
      )}

      {isComplete && <Markdown style={markdownStyles}>{responseText}</Markdown>}
      {isComplete && (
        <AiChatCitationsSection citations={message.response?.citations} />
      )}

      {isErrored && (
        <Markdown style={markdownStyles}>
          "**Error:** Sorry, an error occurred while processing your request."
        </Markdown>
      )}
    </View>
  );
}

const markdownStyles = StyleSheet.create({
  body: {
    color: theme.colors.neutral.$700,
    fontSize: 14,
    lineHeight: 24,
    paddingStart: 40,
  },
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 8,
    width: '100%',
  },
  buttons: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
