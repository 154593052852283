import theme from 'config/theme';
import {
  formatExifMetadataItem,
  getFileExifMetadata,
} from 'interface/stacks/content-viewer/utils';
import {StyleSheet, View} from 'react-native';
import {InfoPanelDetailItem} from './InfoPanelDetailItem';

import type {FileExifMetadata} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

interface Props {
  item: FilesItem;
}

const renderMetadata = (metadata: Partial<FileExifMetadata>) => {
  const entries = metadata
    ? Object.entries(metadata).map(formatExifMetadataItem)
    : [];
  entries.sort((a, b) => a[0].localeCompare(b[0]));
  return entries.map(([k, v]) => renderMetadataRow([k, v]));
};

const renderMetadataRow = ([k, v]: [string, string | number]) => {
  return <InfoPanelDetailItem title={k} value={v.toString()} />;
};

export function InfoPanelDetailsTab({item}: Props) {
  const rows = renderMetadata(getFileExifMetadata(item));
  return rows.length > 0 ? <View style={styles.root}>{rows}</View> : null;
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    padding: 8,
    gap: 8,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
  },
});
