import {Section} from 'interface/base/Section';
import {SearchMenuItem} from 'interface/stacks/search/base/SearchMenuItem';
import {StyleSheet} from 'react-native';

import {BaseSearch} from 'interface/stacks/search/views/BaseSearch';
import type {FilesItem} from 'store/slices/files/types';

interface SearchProps {
  instanceNs: 'workspace' | 'share';
  instanceId: string;
  instanceAlt: string;
  instanceName: string;
  files: FilesItem[];
  onAdd: (file: FilesItem) => void;
  onRemove: (file: FilesItem) => void;
  filterForShowing: (file: FilesItem) => boolean;
  filterForEnabling: (file: FilesItem) => boolean;
}

export function AiChatSearch({
  instanceName,
  instanceId,
  instanceNs,
  instanceAlt,
  files,
  onAdd,
  onRemove,
  filterForShowing,
  filterForEnabling,
}: SearchProps) {
  const addedSection = files.length > 0 && (
    <Section label="Added">
      {files.map((item, index) => (
        <SearchMenuItem
          key={item.id}
          isEnabled={filterForEnabling?.(item) ?? true}
          onItemPress={() => {
            onRemove(item);
          }}
          {...{
            item,

            instanceId,
            instanceNs,
          }}
        />
      ))}
    </Section>
  );

  return (
    <BaseSearch
      instanceId={instanceId}
      instanceName={instanceName}
      instanceNs={instanceNs}
      instanceAlt={instanceAlt}
      onItemPress={onAdd}
      isSearchOpen={true}
      alwaysShowResults={true}
      addedSection={addedSection}
      filterForShowing={filterForShowing}
      filterForEnabling={filterForEnabling}
      scrollStyles={styles.scroll}
    />
  );
}

const styles = StyleSheet.create({
  scroll: {
    minHeight: 300,
    width: 420,
  },
});
