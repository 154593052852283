import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

export function SearchNoResults() {
  return (
    <View style={styles.root}>
      <Typography size="sm" color={theme.colors.neutral.$500}>
        No matches found.
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    minHeight: 150,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
});
