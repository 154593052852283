import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

export function MenuItemSkeleton(props: {icon?: boolean}) {
  return (
    <View style={styles.root}>
      {props.icon && <Skeleton width={20} height={20} style={styles.icon} />}
      <Skeleton height={12} style={styles.bar} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    padding: 6,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$0,
    gap: 6,
  },
  icon: {
    borderRadius: 6,
  },
  bar: {
    flex: 1,
    borderRadius: 999,
  },
});
