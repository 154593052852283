import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useFetchSingleChatData} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useFetchSingleChatData';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {
  type ActivityTypeWithTimestamp,
  EntityType,
} from 'store/slices/activity/types';
import * as aichat from 'store/slices/aichat';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';
import useItemChangeDetector from './useItemChangeDetector';

interface UseSyncChatDataOptions {
  namespace: StorageNamespace;
  profileId: string;
  profileName: string;
  activities: ActivityTypeWithTimestamp;
}

const useSyncChatData = ({
  namespace,
  profileId,
  profileName,
  activities,
}: UseSyncChatDataOptions) => {
  const dispatch = useDispatch();
  const currentChatId = useSelector(
    aichat.selectors.getCurrentChat(namespace, profileId),
  );
  const {fetchChatData} = useFetchSingleChatData(
    namespace,
    profileId,
    profileName,
  );

  const updateEntityLastClientUpdate = (
    namespace: string,
    profileId: string,
    activityType: string,
    chatId: string,
  ) => {
    const lastClientUpdate = getCurrentUTCDateTime();
    const entityType =
      namespace === 'workspace'
        ? EntityType.Workspace
        : namespace === 'share'
          ? EntityType.Share
          : undefined;
    if (entityType) {
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [profileId],
          activities: [`${activityType}:${chatId}`],
          entityType,
          lastClientUpdate,
        }),
      );
    }
  };

  const startStreaming = (chatId: string) => {
    dispatch(aichat.default.actions.setTriggerStreaming(true));
    const entityType =
      namespace === 'workspace'
        ? EntityType.Workspace
        : namespace === 'share'
          ? EntityType.Share
          : undefined;
    if (entityType) {
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [profileId],
          activities: [`${ActivityTypes.AI_CHAT}:${chatId}`],
          entityType,
          lastClientUpdate: getCurrentUTCDateTime(),
        }),
      );
    }
  };

  const onAiChatChange = ({id: storageActivityKey, lastServerUpdate}) => {
    const shouldStream = storageActivityKey.endsWith('-IN_PROGRESS');

    if (shouldStream) {
      const cleanStorageKey = storageActivityKey.replace('-IN_PROGRESS', '');
      const cleanChatId = cleanStorageKey.split(':')[1];
      const chatActivity = activities[cleanStorageKey];
      if (shouldUpdateCache(lastServerUpdate, chatActivity?.lastClientUpdate)) {
        if (currentChatId === cleanChatId) {
          startStreaming(cleanChatId);
        }
      }
    } else {
      const cleanChatId = storageActivityKey.split(':')[1];
      const chatActivity = activities[storageActivityKey];
      if (shouldUpdateCache(lastServerUpdate, chatActivity?.lastClientUpdate)) {
        if (currentChatId === null || currentChatId === cleanChatId) {
          fetchChatData(cleanChatId);
        }
      }
    }
  };

  useItemChangeDetector(
    activity.selectors.getWorkspaceActivityForChat(
      profileId,
      `${ActivityTypes.AI_CHAT}:`,
    ),
    onAiChatChange,
  );
};

export default useSyncChatData;
