import theme from 'config/theme';
import {StyleSheet} from 'react-native';
import {Circle, Svg} from 'react-native-svg';

type Props = {
  progress: number;
  size?: number;
  outerFill?: string;
  innerFill?: string;
};

export function ProgressCircle({
  progress,
  size = 18,
  outerFill = 'transparent',
  innerFill = 'transparent',
}: Props) {
  // Calculate scaled values based on size
  const center = size / 2;
  const outerRadius = size / 2;
  // Maintains proportion of original 7/9 ratio
  const innerRadius = (size / 2) * 0.78;
  // Scaled from original 4/18 ratio
  const strokeWidth = size * 0.22;
  return (
    <Svg style={[styles.root, {width: size, height: size}]}>
      <Circle
        cx={center}
        cy={center}
        r={outerRadius}
        stroke={theme.colors.brand.$5}
        strokeOpacity={1}
        strokeWidth={strokeWidth}
        fill={outerFill}
        strokeDasharray={`${(progress * 100) / 1.7} 100`}
      />
      <Circle cx={center} cy={center} r={innerRadius} fill={innerFill} />
    </Svg>
  );
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 50,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{rotate: '-90deg'}],
  },
});
