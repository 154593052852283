import {useRef, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Skeleton from 'interface/common/Skeleton';
import {ItemAiProcessing} from './chips/ItemAiProcessing';
import {ItemParentLink} from './chips/ItemFileParentLink';
import {ItemUpload} from './chips/ItemUpload';

import type {FileListItemProps} from 'interface/stacks/workspace/storage/FileListItem';
import type {MouseEvent, ReactNode} from 'react';
import type {GestureResponderEvent} from 'react-native';

export const FILE_ROW_THUMBNAIL_SIZE = {
  width: 36,
  height: 28,
};

export interface FileListItemRowProps extends FileListItemProps {
  title: string;
  thumbnail?: ReactNode;
  recents?: boolean;
  shareds?: boolean;
  menuAnchor?: any;
  fileLoading?: boolean;
  focus?: (openDetails?: boolean, event?: GestureResponderEvent) => void;
  renderMembers?: () => ReactNode;
  onPress?: (e: GestureResponderEvent) => void;
  onLongPress?: (e: any) => void;
  onTitlePress?: (e: GestureResponderEvent) => void;
  onSelectThumbnail?: (e: MouseEvent | GestureResponderEvent) => void;
  onDropDownMenu?: (
    e: MouseEvent | GestureResponderEvent,
    ref?: React.RefObject<any>,
  ) => void;
}

export function FileListItemRow(props: FileListItemRowProps) {
  const {item, selected, recents, shareds} = props;
  const [hovered, setHovered] = useState<boolean>(false);
  const menuRef = useRef<any>(null);

  const hasUpload = Boolean(item.upload);
  const hasParentLink = (recents || shareds) && !props.archivedShares;
  const hasAiProcessing =
    item.ai?.state === 'pending' || item.ai?.state === 'in_progress';

  const classes = {
    root: [
      styles.root,
      recents && {paddingVertical: 4},
      selected && {borderColor: 'rgba(0, 0, 0, 0)'},
      hovered &&
        !hasUpload &&
        !selected && {backgroundColor: theme.colors.neutral.$15},
    ],
  };

  if (props.fileLoading) {
    return <Skeleton height={36} />;
  }

  return (
    <Pressable
      style={[
        classes.root,
        {
          // @ts-ignore
          outline: 'none',
        },
      ]}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={e => {
        props.focus(false, e);
        props.onPress?.(e);
      }}
      onLongPress={props.onLongPress}>
      <View style={styles.icon}>
        <View style={styles.thumbnail}>
          <View style={styles.preview}>
            <Pressable
              onPress={props?.onSelectThumbnail}
              onHoverIn={() => setHovered(true)}>
              {props.thumbnail}
            </Pressable>
          </View>
        </View>
      </View>
      <View style={styles.name}>
        <Text
          style={[styles.title, hasUpload && {color: theme.colors.neutral.$5}]}
          accessibilityLabel={props.item.name}
          onPress={props.onTitlePress}
          ellipsizeMode="middle"
          lineBreakMode="middle"
          numberOfLines={1}>
          <span className="text-link">{props.title}</span>
        </Text>
        {props.renderMembers()}
      </View>
      <View style={styles.chips}>
        {hasUpload && <ItemUpload upload={item.upload} size={item.size} />}
        {hasParentLink && <ItemParentLink item={props.item} />}
        {hasAiProcessing && <ItemAiProcessing tip={props.tooltipPos} />}
      </View>
      <View style={[styles.menu, hasUpload && {opacity: 0.5}]}>
        <Button
          ref={menuRef}
          disabled={hasUpload}
          onPress={e => props.onDropDownMenu(e, menuRef)}
          variant="text"
          startEnhancer={
            <Icon
              name="lucide:ellipsis"
              size={20}
              color={
                hovered && !hasUpload
                  ? theme.colors.neutral.$700
                  : theme.colors.neutral.$5
              }
            />
          }
        />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  icon: {
    width: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnail: {
    ...FILE_ROW_THUMBNAIL_SIZE,
    overflow: 'hidden',
    borderRadius: 4,
  },
  preview: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    alignSelf: 'stretch',
  },
  name: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 13,
    marginRight: 8,
    marginBottom: 1,
  },
  chips: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
  },
  menu: {
    flex: 0,
    gap: 8,
    minWidth: 70,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
});
