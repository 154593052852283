import theme from 'config/theme';
import type {ShareType} from 'fast-sdk/src/api/share/consts';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';
import {ShareTypeColor, ShareTypeIcon} from 'utils/fast/shares';

interface Props {
  shareType: ShareType;
  size?: number;
}

const CONTAINER_PADDING = 3;
const DEFAULT_SIZE = 24;

export function ShareTypeBadge({shareType, size = DEFAULT_SIZE}: Props) {
  const backgroundColor = ShareTypeColor[shareType];
  const icon = ShareTypeIcon[shareType];

  return (
    <View style={[styles.shareItemIcon, {backgroundColor}]}>
      <Icon
        name={icon}
        size={size - CONTAINER_PADDING * 2}
        color={theme.colors.neutral.$2Base}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  shareItemIcon: {
    padding: CONTAINER_PADDING,
    borderRadius: 4,
  },
});
