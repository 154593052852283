import {useCreateNewChat} from 'interface/stacks/workspace/layout/WorkspaceAiChat/hooks/useCreateNewChat';
import {useEffect, useRef, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChat, Personality} from 'store/slices/aichat/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatHeader} from '../components/AiChatHeader';
import {AiChatInput} from '../components/input/AiChatInput';
import {AiChatMessageItem} from '../components/messages/AiChatMessageItem';
import {AiChatMessageSkeleton} from '../components/skeletons/AiChatMessageSkeleton';
import {NEW_CHAT, NEW_PRIVATE_CHAT} from '../consts';
import {useCreateNewMessage} from '../hooks/useCreateNewChatMessage';
import {useFetchSingleChatData} from '../hooks/useFetchSingleChatData';

interface Props {
  namespace: string;
  instanceId: string;
  instanceName: string;
  chatId: string;
  messageId: string;
  onBackPress: () => void;
  onNewChatPress: () => void;
  workspaceMembers: Member[];
}

export function AiChat({
  namespace,
  instanceId,
  instanceName,
  chatId,
  messageId,
  workspaceMembers,
  onBackPress,
  onNewChatPress,
}: Props) {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const {isLoading: chatLoading, fetchChatData} = useFetchSingleChatData(
    namespace,
    instanceId,
    instanceName,
  );
  const {isLoading: createMessageLoading, createChatMessage} =
    useCreateNewMessage();
  const {isLoading: createChatLoading, createChat} = useCreateNewChat();

  const chat: EntityChat = useSelector(
    aichat.selectors.getChat(namespace, instanceId, chatId),
  );
  const isStreaming = useSelector(aichat.selectors.getIsStreaming());
  const messages = chat?.messages ?? [];

  const isNewChat = chatId === NEW_CHAT || chatId === NEW_PRIVATE_CHAT;
  const isPrivate =
    chat?.privacy?.visibility === 'private' || chatId === NEW_PRIVATE_CHAT;

  const lastMessage = messages[messages.length - 1];
  const isEnabled =
    !lastMessage ||
    lastMessage?.state === 'complete' ||
    lastMessage?.state === 'errored';
  const scrollViewRef = useRef<ScrollView>(null);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const scrollToMessage = (messageId: string) => {
    // TODO: AICHAT: Fix this
    const messageIndex = messages.findIndex(
      message => message.id === messageId,
    );
    if (messageIndex !== -1) {
      scrollViewRef.current?.scrollTo({y: messageIndex * 30, animated: true});
    }
  };

  const createMessageOrNewChat = async (
    message: string,
    personality: Personality,
    webSearch: boolean,
    withRag: boolean,
    scopeFiles: FilesItem[],
    attachFiles: FilesItem[],
  ) => {
    if (isNewChat) {
      setFirstLoad(false);
      return await createChat(
        message,
        personality,
        isPrivate,
        scopeFiles,
        attachFiles,
      );
    }
    return await createChatMessage(
      chatId,
      message,
      personality,
      scopeFiles,
      attachFiles,
    );
  };

  const stickToBottom = () => {
    if (isAtBottom) {
      scrollViewRef.current?.scrollToEnd({animated: false});
    }
  };

  const handleScroll = (event: any) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    const contentHeight = event.nativeEvent.contentSize.height;
    const scrollViewHeight = event.nativeEvent.layoutMeasurement.height;

    const isBottom = contentHeight - offsetY - scrollViewHeight < 50;
    setIsAtBottom(isBottom);
  };

  useEffect(() => {
    if (messageId) {
      scrollToMessage(messageId);
    }
  }, [messageId]);

  useEffect(() => {
    if (isNewChat) {
      return;
    }
    const fetchData = async () => {
      await fetchChatData(chatId);
      setFirstLoad(false);
    };
    fetchData();
  }, [instanceId, chatId]);

  const lastMessageId = lastMessage?.id;

  useEffect(() => {
    if (lastMessage?.state === 'complete') {
      dispatch(aichat.default.actions.setTriggerStreaming(false));
    }
  }, [chatId, lastMessageId, lastMessage?.state]);

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <AiChatHeader
          workspaceId={instanceId}
          chatId={chatId}
          isPrivate={isPrivate}
          title={chat?.name ?? 'New chat'}
          onBackPress={onBackPress}
          onNewChatPress={onNewChatPress}
        />
        <ScrollView
          ref={scrollViewRef}
          style={styles.messagesContainer}
          contentContainerStyle={styles.scrollContent}
          onContentSizeChange={() => stickToBottom()}
          onScroll={handleScroll}
          showsVerticalScrollIndicator={false}>
          <View style={styles.messages}>
            {chatLoading && firstLoad ? (
              <AiChatMessageSkeleton />
            ) : (
              messages.map((message, index) => (
                <AiChatMessageItem
                  key={message.id}
                  message={message}
                  shouldStream={
                    !isNewChat && index === messages.length - 1 && isStreaming
                  }
                />
              ))
            )}
          </View>
        </ScrollView>
        <AiChatInput
          workspaceId={instanceId}
          chatId={chatId}
          workspaceMembers={workspaceMembers}
          onSendMessage={createMessageOrNewChat}
          isEnabled={isEnabled}
          enableSettings={true}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
  },
  messagesContainer: {
    flex: 1,
    padding: 16,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  scrollContent: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  messages: {
    gap: 30,
    width: '100%',
  },
});
