import {ROUTES, WORKSPACE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {Navigate, useNavigate, useParams} from 'extensions/navigation';
import {InvitationTypes} from 'fast-sdk/src/api/share/consts';
import {useNavigateToSubdomain} from 'interface/common/hooks/navigator/useNavigateToSubdomain';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import {AppTabs} from 'interface/stacks/profile-membership/types';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import {useEffect} from 'react';
import {View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import AddProfilePicture from '../AddProfilePicture';
import CheckoutSuccess from '../CheckoutSuccess';
import InviteCoworkers from '../InviteCoworkers';
import OnboardError from '../OnboardError';
import OrganizationName from '../OrganizationName';
import Subdomain from '../Subdomain';
import useUpdateUser from '../hooks/useUpdateUser';
import LayoutOnboard from '../layout/LayoutOnboard';
import {
  OnboardSteps,
  getCurrentStepIndex,
  getStepFromIndex,
  totalSteps,
} from '../lib/consts';
import {Payment} from '../payment';

function RouteOnboard() {
  const params = useParams<'step'>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();
  const step = params.step as OnboardSteps;

  const startOnboardingEmail = useSelector(
    onboarding.selectors.getStartOnboardingEmail,
  );
  const userDetails = useSelector(user.selectors.getUserDetails);
  const error = useSelector(onboarding.selectors.getError);
  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);
  const userProfilePhoto = useSelector(user.selectors.getUserProfilePic);
  const {navigateToSubdomain} = useNavigateToSubdomain();

  const {updateUser, loading} = useUpdateUser(true, {lazy: true});
  const {generateShareUrlWithSubdomain} = useGenerateShareUrl();

  const next = () => {
    if (stepIndex !== totalSteps) {
      const nextStepIndex = Math.min(stepIndex + 1, totalSteps);
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${getStepFromIndex(nextStepIndex)}`,
      );
    }
  };

  const done = async () => {
    await updateUser();

    if (!invitation) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
    }

    switch (invitation.entity_type) {
      case InvitationTypes.Shared:
        {
          const shareUrl = generateShareUrlWithSubdomain(
            invitation.share,
            invitation.org,
          );
          dispatch(app.default.actions.setRedirectTo(shareUrl));
        }
        break;
      case InvitationTypes.Workspace:
        {
          const origin = location.origin.replace(
            subdomain,
            invitation.workspace.org_domain,
          );
          const workspaceUrl = `${origin}/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${invitation.workspace.folder_name}/${WORKSPACE_STORAGE}/${WORKSPACE_ROOT}`;
          dispatch(app.default.actions.setRedirectTo(workspaceUrl));
        }
        break;
    }

    dispatch(app.default.actions.clearCurrentJoinInvitation());
    navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${AppTabs.Workspaces}`);
  };

  useEffect(() => {
    if (
      startOnboardingEmail &&
      startOnboardingEmail !== userDetails.email_address
    ) {
      dispatch(onboarding.default.actions.restartOnboarding());
    }
    dispatch(
      onboarding.default.actions.setStartOnboardingEmail({
        email: userDetails.email_address,
      }),
    );
  }, [startOnboardingEmail]);

  useEffect(() => {
    if (invitation && userProfilePhoto) {
      done();
    }
  }, [invitation]);

  const stepIndex = getCurrentStepIndex(step);
  const loadingInvitationRedirect = invitation && userProfilePhoto;

  return (
    <LayoutOnboard step={step} stepIndex={stepIndex} next={next}>
      {error ? (
        <OnboardError />
      ) : loadingInvitationRedirect ? (
        <AppLoading />
      ) : (
        <View>
          {step === OnboardSteps.Profile && (
            <AddProfilePicture next={invitation ? done : next} />
          )}
          {step === OnboardSteps.Organization && (
            <OrganizationName next={next} />
          )}
          {step === OnboardSteps.Subdomain && <Subdomain next={next} />}
          {step === OnboardSteps.Payment && <Payment />}
          {step === OnboardSteps.Success && <CheckoutSuccess next={next} />}
          {step === OnboardSteps.Invites && (
            <InviteCoworkers next={done} loadingUpdateUser={loading} />
          )}
          {(stepIndex < 1 || stepIndex > totalSteps) && (
            <Navigate
              to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Profile}`}
            />
          )}
        </View>
      )}
    </LayoutOnboard>
  );
}

export default RouteOnboard;
